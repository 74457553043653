<div class="footer" *ngIf="isFooterActive && footerData"
     [ngStyle]="!(isBottom || isAlumniUnsub) && {'z-index': '-1'}">
    
    <div class="footer--container" *ngIf="((isBottom || isAlumniUnsub) && componentsService.isDesktopMode()) || !componentsService.isDesktopMode()">
        <div class="logo-column">
            <img class="logo" *ngIf="!useSimLinks" src="{{logo_scuro}}">
            <img class="logo" *ngIf="useSimLinks" src="{{logo_advisor_scuro}}">
        </div>

        <div class="info-column">
            <p class="first-row">{{nowYear}} {{ 'FOOTER.CREDITS' | translate }}</p>
            <p *ngIf="!useSimLinks">{{ 'FOOTER.P_IVA' | translate }}</p>
            <p *ngIf="useSimLinks">{{ 'FOOTER.P_IVA_SIM' | translate }}</p>
        </div>

        <div class="spacer"></div>

        <div class="leed-column">
            <img class="leed" src="/cdn/general/LEED_badge.png" aria-hidden="true" alt="LEED">
        </div>

        <div class="links-column">
            <p *ngFor="let fl of footerData.links">
                <a *ngIf="fl.link" download href="{{fl.link}}">
                    {{fl.label | translate}}
                </a>
                <a *ngIf="fl.route && !fl.id" [routerLink]="['/', translate.currentLang, fl.route]">
                    {{fl.label | translate}}
                </a>
                <a *ngIf="fl.route && fl.id" [routerLink]="['/', translate.currentLang, fl.route, fl.id]">
                    {{fl.label | translate}}
                </a>
                <a *ngIf="!fl.route && fl.id" [routerLink]="['/', translate.currentLang, 'article', fl.id]">
                    {{fl.label | translate}}
                </a>
            </p>
        </div>
    </div>
</div>