import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { IBaseSection, IButtonLink } from 'src/app/models/commons';
import { INews } from 'src/app/models/components.interface';
import { BasePageComponent } from '../base-page.component';
import { IRiskPlanning } from '../../models/pages.interface';
import { BASE_NEWS_PER_PAGE } from '../../components/news-list/news-list.component';

@Component({
  selector: 'app-risk-planning',
  templateUrl: './risk-planning.component.html',
  styleUrls: ['./risk-planning.component.scss']
})
export class RiskPlanningComponent extends BasePageComponent<IRiskPlanning> {
  
  outroSubscription: Subscription;
  outroThemeColor: string;
  outroThemeOpacity: string;
  isDarkTheme: boolean = false;
  s0: IBaseSection;
  private pageSub: Subscription;
  f = false;

  newsLength: number = 0;

  ngOnInit() {
    super.ngOnInit();

    this.pageSub?.unsubscribe();
    this.pageSub = this.dataServices.pageDataReady.subscribe(() => {
      this.s0 = this.data.sections[0];
      if (!this.f) {
        // console.log("s0 is", this.s0, this.highlightColor);
        this.f = true;
      }

      this.checkNews();

      this.outroThemeColor = "intro-theme--" + this.s0.theme.split("#")[0];
      this.outroThemeOpacity = this.s0.theme.split("#")[1] + "%";

      if (this.outroThemeColor.endsWith("primary") || this.outroThemeColor.endsWith("blue")) {
        this.isDarkTheme = true;
      }

      // if (this.slug != slugs.financialAdvisory)
      //   this.data.sections.forEach((element: ISectionAccordionList) => {
      //     if(element.type && element.type == "accordion-list") {
      //       element.list = this.sort.byTitle(element.list);
      //     }
      //   });
    });
  }

  protected async checkNews() {
    try {
      let news: INews[] = await this.api.saasCloud.getNewsList(0, BASE_NEWS_PER_PAGE + 1);

      this.newsLength = news.length;

      if (this.data.sections.find(s => s.type == 'news-frontpage')) {
        this.data.sections.find(s => s.type == 'news-frontpage').buttons = news.map(n => {
          const b: IButtonLink = n as any;
          b.label = n.title;
          b.route = "news-article";
          (b as any)._id = n.id;
          b.id = n.slug;
          return b;
        });
      }
    }
    catch(ex) {
      console.error(ex);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.pageSub != null) {
      this.pageSub.unsubscribe();
      this.pageSub = null;
    }
  }
}
