<div class="prom-btn {{data.theme}}"
     *ngIf="data && (data.link || data.route || data.customBehaviour || data._meta?.reservedArea) && !(data.link && data.route)"
     [ngClass]="{'disabled': disabled, 
                 'one-line': data._meta?.forceOneLine,
                 'shorter-hor-padd': data._meta?.forceShorterHorizontalPadding,
                 'on-light-bg': data.bgType != 'dark',
                 'on-dark-bg': data.bgType == 'dark',
                 'use-lightgrey-shadow': useLightgreyShadow,
                 'mini-mode': isMini,
                 'browser--safari': browserType == 'safari',
                 'browser--firefox': browserType == 'firefox'}">

    <ng-container *ngIf="data._meta?.isDownloadable && !data._meta?.reservedArea">
        <a download *ngIf="data.link && !disabled" class="dl w-link btn-label no-underline" href='{{data.link}}' target="_blank">
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
            <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}" *ngIf="data._meta?.isDownloadable"><span class="icon icon-bottoni_download"></span></div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}">{{data.label}}</div>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        </a>

        <a download *ngIf="data.route && !data.id && !disabled" class="dl w-route btn-label no-underline" href="/{{translate.currentLang}}/{{data.route}}" target="_blank">
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
            <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}" *ngIf="data._meta?.isDownloadable"><span class="icon icon-bottoni_download"></span></div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="!data._meta || !data._meta.translate">{{data.label}}</div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="data._meta?.translate">{{data.label | translate}}</div>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        </a>

        <a download *ngIf="data.route && data.id && !disabled" class="dl w-route btn-label no-underline" href="/{{translate.currentLang}}/{{data.route}}/{{data.id}}" target="_blank">
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
            <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}" *ngIf="data._meta?.isDownloadable"><span class="icon icon-bottoni_download"></span></div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="!data._meta || !data._meta.translate">{{data.label}}</div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="data._meta?.translate">{{data.label | translate}}</div>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        </a>

        <a download *ngIf="(data.link || data.route) && disabled" class="dl disabled btn-label no-underline">
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
            <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}" *ngIf="data._meta?.isDownloadable"><span class="icon icon-bottoni_download"></span></div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="!data._meta || !data._meta.translate">{{data.label}}</div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="data._meta?.translate">{{data.label | translate}}</div>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        </a>
    </ng-container>

    <ng-container *ngIf="data && !data._meta?.isDownloadable && !data._meta?.reservedArea">
        <a *ngIf="data.link && !disabled" class="w-link btn-label no-underline" [target]="data._meta?.target || '_blank'" href='{{data.link}}'>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
            <!-- <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}" *ngIf="data._meta?.isDownloadable"><span class="icon icon-bottoni_download"></span></div> -->
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}">{{data.label}}</div>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        </a>

        <a *ngIf="data.route && !data.id && !disabled" class="w-route btn-label no-underline" [routerLink]="['/', translate.currentLang, data.route]" routerLinkActive="active">
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
            <!-- <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}" *ngIf="data._meta?.isDownloadable"><span class="icon icon-bottoni_download"></span></div> -->
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="!data._meta || !data._meta.translate">{{data.label}}</div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="data._meta?.translate">{{data.label | translate}}</div>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        </a>

        <a *ngIf="data.route && data.id && !disabled" class="w-route btn-label no-underline" [routerLink]="['/', translate.currentLang, data.route, data.id]" routerLinkActive="active">
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
            <!-- <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}" *ngIf="data._meta?.isDownloadable"><span class="icon icon-bottoni_download"></span></div> -->
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="!data._meta || !data._meta.translate">{{data.label}}</div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="data._meta?.translate">{{data.label | translate}}</div>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        </a>

        <a *ngIf="(data.link || data.route) && disabled" class="disabled btn-label no-underline">
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
            <!-- <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}" *ngIf="data._meta?.isDownloadable"><span class="icon icon-bottoni_download"></span></div> -->
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="!data._meta || !data._meta.translate">{{data.label}}</div>
            <div [ngClass]="{'btn prom-button capslock': true, 'btn--with-download': data._meta?.isDownloadable}" *ngIf="data._meta?.translate">{{data.label | translate}}</div>
            <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        </a>
    </ng-container>

    <a *ngIf="data._meta?.reservedArea" class="w-route interactive btn-label no-underline" (click)="clickOnAR()">
        <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        <div [ngClass]="{'download-icon': true, 'download-icon--adjust': hasTwoRows()}">
            <!-- <span class="icon icon-bottoni_download"></span> -->
            <img class="tool--padlock" *ngIf="data.theme == 'btn_color__blue'"
                 src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_chiuso_bianco.svg">
            <img class="tool--padlock" *ngIf="data.theme != 'btn_color__blue'" 
                 src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_chiuso_blu.svg">
        </div>
        <div class="btn prom-button capslock btn--with-download">{{data.label}}</div>
        <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
    </a>

    <a *ngIf="data.customBehaviour" class="w-route btn-label no-underline">
        <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
        <div class="btn prom-button capslock" *ngIf="data.customBehaviour == 'more'">{{'LOAD_MORE' | translate}}</div>
        <div class="btn prom-button capslock" *ngIf="data.customBehaviour == 'sign-up'">{{'SIGN_UP' | translate}}</div>
        <div class="btn prom-button capslock" *ngIf="data.customBehaviour == 'subscribe'">{{'SUBSCRIBE' | translate}}</div>
        <div class="btn prom-button capslock" *ngIf="data.customBehaviour == 'register'">{{'REGISTER' | translate}}</div>
        <div class="btn prom-button capslock" *ngIf="data.customBehaviour == 'log-in'">{{'LOG_IN' | translate}}</div>
        <div class="btn prom-button capslock" *ngIf="data.customBehaviour == 'calc'">{{'CALC' | translate}}</div>

        <div class="btn prom-button capslock" *ngIf="data.customBehaviour == 'label-translation'">{{data.label | translate}}</div>
        <div [ngClass]="{'spacer': true, 'spacer--short': hasTwoRows()}"></div>
    </a>
</div>
