import { Component } from '@angular/core';
import { IButtonLink } from 'src/app/models/commons';
import { IAnteo, IAnteoArchive } from 'src/app/models/pages.interface';
import { paths, separator } from 'src/app/models/paths';
import { slugs } from 'src/app/models/slugs';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-anteo-archivio',
  templateUrl: './anteo-archivio.component.html',
  styleUrls: ['./anteo-archivio.component.scss']
})
export class AnteoArchiveComponent extends BasePageComponent<IAnteoArchive> {

  typeNews: string = "financial-advisory";
  routeArchive: string = paths.anteoArchive;
  route: string = paths.anteoArticle;
  isAnteo: boolean = false;

  buttonLoadMore: IButtonLink = {
    type: "button",
    label: "",
    theme: 'btn_color__blue',
    customBehaviour: "more"
  }

  ngOnInit() {
    super.ngOnInit();

    const currentRouteBranch: string = this.slug.split("-archive")[0] || null;
    // console.log("NOW ON BRANCH", currentRouteBranch);
    this.routeArchive = currentRouteBranch + "-archive";
    this.route = currentRouteBranch;
    
    this.dataServices.pageDataReady.subscribe(() => {
      // console.log("AnteoArchivioComponent", this.data);

      if (currentRouteBranch == null) {
        this.routeArchive = currentRouteBranch + "-archive";
        this.route =  currentRouteBranch;
      }

      this.isAnteo = (this.slug == paths.anteoArchive);

      this.loadArchive();
    });
  }

  protected async loadArchive() {
    try {
      let category = this.slug.replace(/financial-advisory_/g, "").replace(/-archive/g, "").replace(/-/g, "_");
      if (category.endsWith("s")) category = category.slice(0, -1);
      let archive: IAnteo[] = await this.api.archives.get(category);
      if (category == "anteo") {
        let newerIssues: IAnteo[] = await this.api.anteo.getList() as unknown as IAnteo[];
        // console.log("newerIssues", newerIssues);
        
        if (!(archive?.length > 0)) archive = [];
        if (!(newerIssues?.length > 0)) {
          newerIssues = [];
        } else {
          newerIssues.map((nI) => {
            if (!nI.slug) nI.slug = paths.anteo + separator + "anteo" + nI.issueNumber;
          })
        }
        archive.push(...newerIssues);
        // console.log("newerIssues", newerIssues, "archive", archive);

        archive = this.sort.byYear_newerAnteo(archive);
      } else if (category == "information_path") {
        archive = this.sort.byYear_infoPaths(archive);
      } else {
        archive = this.sort.byYear_newer(archive);
      }
      // console.log(category, archive);
      this.data.news = archive;
      this.filterResetEvent();
    }
    catch(ex) {
      console.error(ex);
    }
  }

  getNewsCategory(archive): string {
    return archive.date.split("-")[1];
  }

  getNewsID(archivies): string {
    const id: string = archivies.slug.split("__")[1];
    return id;
  }

  getAnteoNumber(issueNumber: number): string {
    return slugs.anteo.replace(/financial-advisory_/g, "") + issueNumber;
  }

  loadMore() {
    // console.log("load more");
  }

  /** SEARCH-BAR */
  filteredNewsList = [];
  useFilter: boolean = false;

  filter(filterString: string) {
    // console.log(this.slug, "filtered by", filterString);
    
    if (filterString && filterString.length > 0) {
      if (this.isAnteo) {
        this.filteredNewsList = this.data.news.filter((news: {pageTitle: string, issueNumber: string, date: string}) => 
          news && (
            this.searchSubString(filterString, news.pageTitle) 
            || this.searchSubString(filterString, news.issueNumber) 
            || this.searchSubString(filterString, news.date)
          )
        );
      } else {
        this.filteredNewsList = this.data.news.filter((news: {sections: {title: string}[]}) => 
          news && (
            this.searchSubString(filterString, news.sections[0].title)
            || this.searchSubString(filterString, this.getNewsCategory(news.sections[0]))
          )
        );
      }
      

      // console.log(this.filteredNewsList.length, "risultati trovati");
      this.useFilter = true;
    }
  }

  searchSubString(filterString: string, string: string): boolean {
    if (!string) return false;

    const fs = this.cleanString(filterString).toLowerCase();
    const str = this.cleanString(string).toLowerCase();

    return str.includes(fs);
  }

  private cleanString(string: string): string {
    if (string.includes("-")) string = string.replace(/-/g, "");
    if (string.includes("_")) string = string.replace(/_/g, "");
    if (string.includes(" ")) string = string.replace(/ /g, "");

    return string;
  }

  filterResetEvent() {
    // console.log("filter reset");

    this.filteredNewsList = this.data.news;
    this.useFilter = false;

    // console.log(this.filteredNewsList.length, "risultati totali");
  }
  /** SEARCH-BAR - end */


  capitolize(s: string): string {
    return s[0].toUpperCase() + s.substring(1);
  }
}
