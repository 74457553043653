<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>

<ng-container *ngIf="data">
    <app-side-navbar class="side-navbar desktop-only" [data]="data.sections" [bulletColor]="highlightColor"></app-side-navbar>
    <app-long-arrow-down class="desktop-only" [color]="highlightColor"></app-long-arrow-down>

    <div class="alumni">
        <ng-container *ngFor="let s of data.sections; let i = index">
            <app-section-intro-bg *ngIf="s.type == 'intro'" [data]="s" [mainTitle]="data.title" [highlightColor]="highlightColor"></app-section-intro-bg>

            <ng-container *ngIf="!showAlumniDialogAuth">
                <app-section-collapsable-list [data]="s" [highlightColor]="highlightColor" [noPaddingBottom]="checkIfNoPaddingBottom(i)" [shortPaddingTop]="checkIfShortPaddingTop(i)"></app-section-collapsable-list>

                <app-section-general *ngIf="s.type != 'intro' && s.type != 'accordion-list' &&  s.type != 'contacts' &&  s.type != 'special' && s.type != 'news-frontpage'" [data]="s" [opt]="{'highlightColor': highlightColor, 'titleColor': highlightColor}"></app-section-general>

                <section *ngIf="s.type == 'contacts'" spyTarget="{{s.anchorId}}" class="{{s.theme}} section center-vertical office-contacts">
                    <div class="section--image-filter" *ngIf="s.backImage"></div>
                    <div class="section--BGI" [ngStyle]="{'background-image': 'url('+s.backImage+')' }" *ngIf="s.backImage"></div>

                    <h1 data-aos="fade-up" class="section_h1 neutra2_book text--on-dark">{{ s.title }}</h1>

                    <div class="office-contacts--list">
                        <app-business-card-office data-aos="fade-up" *ngFor="let office of s.offices" class="office-contacts--list--item" [data]="office"></app-business-card-office>
                    </div>
                </section>

                <section *ngIf="s.type == 'news-frontpage'" spyTarget="{{s.anchorId}}">
                    <app-news-list [data]="s" [highlightColor]="highlightColor"></app-news-list>
                </section>

                <section *ngIf="s.type == 'special'" spyTarget="{{s.anchorId}}" class="special--job center-vertical">
                    <h2 class="prom-list neutra2_book" *ngIf="s.text">{{s.text}}</h2>

                    <ng-container *ngFor="let b of s.buttons; let isFirst = first">
                        <app-prometeia-button [ngClass]="{'subscribe-button interactive': true, 'w-margin-top': !isFirst}"
                                              [data]="b" *ngIf="b.type == 'button'"></app-prometeia-button>
                    </ng-container>

                    <div class="special--job--links">
                        <ng-container *ngFor="let link of s.buttons">
                            <p *ngIf="link.type == 'link' || link.type != 'button'" class="prom-caption___lato-regular text--primary">
                                {{link.label}} <a class="prom-caption___lato-regular text--primary" href="mailto:{{link.link}}">{{link.link}}</a>
                            </p>
                        </ng-container>
                    </div>
                </section>
            </ng-container>

        </ng-container>

    </div>

    <app-alumni-dialog-auth *ngIf="showAlumniDialogAuth"></app-alumni-dialog-auth>
</ng-container>
