import { Component } from '@angular/core';
import { ScrollSpyService } from 'ng-spy';
import { IAboutUs } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent extends BasePageComponent<IAboutUs> {
  private pageDataReadySubscription: Subscription = null;

  constructor(private spyService: ScrollSpyService) {
    super();
    this.pageDataReadySubscription?.unsubscribe();
    this.pageDataReadySubscription = this.dataServices.pageDataReady.subscribe(() => {
      this.spyService.spy({ 
        thresholdTop: this.componentsServices.getInnerHeight() / 2, 
        thresholdBottom: this.componentsServices.getInnerHeight() / 2 
      });
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    
    this.spyService.stopSpying();
    super.ngOnDestroy();
    this.pageDataReadySubscription?.unsubscribe();
    this.pageDataReadySubscription = null;

  }
}
