<ng-container *ngIf="!data && loadDate ">
    <app-not-found-data *ngIf="notFound || (data && !data.news)"></app-not-found-data>
</ng-container>

<div class="single-trending-topics-article prom-page theme--{{data.news.type}}"
     [ngClass]="{'article--on-iphone': deviceType == 'iphone'}"
     *ngIf="data && data.news && loadDate">

    <!-- <app-atlante-app-banner></app-atlante-app-banner> -->

    <div class="neutra2_book single-trending-topics-article--header">
        <span class="icon-{{data.news.type}} static-icon"></span>
        <div class="single-trending-topics-article--header--bg">
            <div [ngClass]="{'single-trending-topics-article--header--bg--filter' : data.news.type == 'news' }"></div>

            <img class="single-trending-topics-article--header--bg--img" src="{{data.news.heroImage}}">
        </div>
        <div class="single-trending-topics-article--header--content section-max-content">
            <span>{{data.news.mediaType}}</span>
            <div class="single-trending-topics-article--header--content--wrap">
                <span class="single-trending-topics-article--header--content--wrap--category">{{data.news.category}}</span> {{data.news.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}
            </div>
            <h2 class="neutra2_demi single-trending-topics-article--header--content--title">{{data.news.title}}</h2>
            <!-- <p class="single-trending-topics-article--header--content--subtitle">{{data.news.subtitle}}</p> -->
        </div>
    </div>

    <div class="section-max-content">
        <div class="single-trending-topics-article--content-wrap">
            <div class="single-trending-topics-article--content-text">
                
                <div class="single-trending-topics-article--body">

                    <div class="single-trending-topics-article--content">
                        <p class="prom-caption___neutra-book single-trending-topics-article--author">{{data.news.author}}</p>

                        <div class="single-trending-topics-article--text inner-html" [innerHtml]="data.news.body | safe:'html'"></div>

                        <div class="single-trending-topics-article--wrap" *ngIf="data.news.type == 'report'">
                            <img class="single-trending-topics-article--wrap-img" src="{{data.news.heroImage}}">
                            <app-prometeia-button class="other--btn" [data]="download(data.news.attachment)" *ngIf="data.news.attachment"></app-prometeia-button>
                        </div>
                    </div>
                    <div class="single-trending-topics-article--other" *ngIf="data.news.type === 'news'">
                        <span class="single-trending-topics-article--other-title">{{ 'RELATED_ARTICLES.TITLE' | translate }}</span>
                        <div class="single-trending-topics-article--list" *ngFor="let a of data.news.related">
                            <span class="single-trending-topics-article--list-category">{{getRelatedArticleCategory(a)}}</span>
                            <a class="single-trending-topics-article--list-title" href="{{a.type != 'video' && a.type != 'podcast' ? translate.currentLang+'/'+route+'/'+a.slug : a.permalink}}">{{a.title}}</a> {{a.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="single-trending-topics-article--other"
                 *ngIf="data.news.type != 'news'">
                <span class="single-trending-topics-article--other-title">{{ 'RELATED_ARTICLES.TITLE' | translate }}</span>
                <div class="single-trending-topics-article--list" *ngFor="let a of data.news.related">
                    <span class="single-trending-topics-article--list-category">{{getRelatedArticleCategory(a)}}</span>
                    <a class="single-trending-topics-article--list-title" *ngIf="(data.news.type != 'dataviz' && data.news.type != 'tableau') && data.news.type != 'report'" [routerLink]="['/', translate.currentLang, route, a.id]">{{a.title}}</a>
                    <a class="single-trending-topics-article--list-title" *ngIf="(data.news.type == 'dataviz' || data.news.type == 'tableau') || data.news.type == 'report'" [routerLink]="['/', translate.currentLang, route, a.slug]">{{a.title}}</a> {{a.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}
                </div>
            </div>
        </div>


        <div class="bottomline">
            <div class="bottomline--socials">
                <app-social-share *ngFor="let soc of socials_list" [data]="soc" [theme]="'accent'"></app-social-share>
            </div>
    
            <div class="bottomline--arrow interactive" (click)="scrollToTop()">
                <span class="icon icon-freccia-scroll-up_freccia-su text--green"></span>
            </div>
        </div>
    </div>
</div>

<div class="no-content" *ngIf="data && !data.news && loadDate">
    <p class="no-content-warning text--primary neutra2_bold">{{ 'ARTICLE_NOT_AVAIBLE' | translate }}</p>
</div>
