import { Component } from '@angular/core';
import { IButtonLink } from 'src/app/models/commons';
import { ISocial } from 'src/app/models/components.interface';
import { paths } from 'src/app/models/paths';
import { INewsArticle } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.scss']
})
export class NewsArticleComponent extends BasePageComponent<INewsArticle> {
  loadDate : boolean = false;

  dowloadButton: IButtonLink = {
    label: "download",
    link: "",
    theme: "btn_color__blue",
    type: "button",
    _meta: { isDownloadable: true }
  }

  socials_list: ISocial[] = [
    { type: "linkedin", link: "https://linkedin.com" },
    { type: "twitter", link: "https://twitter.com" },
    { type: "facebook", link: "https://facebook.com" },
  ];
  
  ngOnInit(): void {
    super.ngOnInit();

    this.dataServices.pageDataReady.subscribe(() => {
      // console.log("NewsArticleComponent", this.data, this.id);
      /* if (this.id == undefined) {
        this.id = this.activatedRoute.snapshot.paramMap.get('lang');
      } */
      
      if (this.data) this.loadNewsArticle();
    });
  }

  private async loadNewsArticle() {
    this.loadDate = false;
    try {
      const news = await this.api.insights.getNewsContent(this.id);

      if (news) {
        // console.log(news);
        // news.attachment = "test";
        this.dowloadButton.link = news.attachment;

        const inID: string = news.slug;

        // console.log(this.id, inID, this.id != inID);

        if (this.id != inID) {
          this.router.navigate(['/', this.translate.currentLang, paths.newsArticle, inID], { replaceUrl: true });
        } else {
          this.data.newsArticle = news;
        
          if (this.data.newsArticle) {
            this.loadDate = true;
          }
        }
      } else {
        this.loadDate = true;
      }
    } catch (error) {
      console.error(error);
      this.loadDate = true;
    }
  }

  beautifyCategory(cat: string) {
    return (cat ? cat.replace(/_/g, " ") : " ");
  }

  scrollToTop() {
    this.componentsServices.scrollToTop();
  }

}
