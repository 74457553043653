<div class="search-results gradient--primary">
    <section class="search-results--filter">
        <div class="search-results--filter--search-box">
            <p>{{ 'SEARCH_RESULTS.FILTER' | translate }}:</p>
            <form #searchBoxForm="ngForm" class="search-results--filter--search-box--form">
                <input  class="prom-caption___lato-regular searchField pi-p"
                        type="text" id="search" name="search" (ngModelChange)="queryReceived = false;"
                        [(ngModel)]="query" #search="ngModel">
                <button class="interactive button" type="submit" status="success" (click)="submit(searchBoxForm)">
                    <img class="tool--magnifier" src="../../../assets/icons-svg/menu_tools/svg-icon-lente_bianca.svg">
                </button>
            </form>
            
        </div>

        <!-- <p>Ricerca: <span *ngIf="query">{{query}}</span><span style="opacity: 0.5" *ngIf="!query">{{'nessun criterio'}}</span></p> -->
        <!-- <div class="search-results--filter--refiners">
            <p>
                {{ 'SEARCH_RESULTS.PAGES_FILTERS' | translate }}:
            </p>
            <div>
                <ng-container *ngFor="let ht of resultsArray; let i = index">
                    <a  (click)="setCategory(i)"
                        [ngClass]="{
                            'refiner interactive': true, 
                            'refiner--selected': ht.isSelected, 
                            'refiner--not-selected': !ht.isSelected}">
                        {{ht.title}}
                    </a>
                </ng-container>
            </div>
        </div> -->
    </section>

    <ng-container *ngIf="loadingResults">
        <mat-spinner class="loading-spinner"
                     color="primary"
                     mode="indeterminate"></mat-spinner>
    </ng-container>
    
    <ng-container *ngIf="!isResultsEmpty() && !loadingResults">
        <section *ngFor="let resultItem of resultsArray">
            <div class="search-results--content results--item" *ngIf="checkCategory(resultItem.id)">
                <h2 class="neutra2_bold text--accent search-results--content--title" *ngIf="resultItem.id != 'site'">
                    {{resultItem.title}}
                </h2>
                <h2 class="neutra2_bold text--accent search-results--content--title" *ngIf="resultItem.id == 'site'">
                    {{'SEARCH_RESULTS.RESULTS__GENERAL' | translate}}
                </h2>
                <ng-container *ngFor="let x of resultItem.list | slice:0:resultItem.displayedResultsSite">
                    <!-- <a class="search-results--content--list-item text--on-dark no-underline" *ngIf="x.title && x.slug && !getRouteId(x.slug, resultItem.id)" [routerLink]="['/', translate.currentLang, getRoute(x.slug, resultItem.id)]">
                        <h3 class="neutra2_demi result--title" *ngIf="x.title">{{x.title}}</h3>
                        <p class="result--text" *ngIf="x.text">{{x.text | shorten: textMaxLength: ' ...': false}}</p>
                    </a>

                    <a class="search-results--content--list-item text--on-dark no-underline" *ngIf="x.title && x.slug && getRouteId(x.slug, resultItem.id)" [routerLink]="['/', translate.currentLang, getRoute(x.slug, resultItem.id), getRouteId(x.slug, resultItem.id)]">
                        <h3 class="neutra2_demi result--title" *ngIf="x.title">{{x.title}}</h3>
                        <p class="result--text" *ngIf="x.text">{{x.text | shorten: textMaxLength: ' ...': false}}</p>
                    </a> -->

                    <!-- PAGINA SENZA ID -->
                    <a class="search-results--content--list-item text--on-dark no-underline" *ngIf="x.title && x.slug && !getRouteId(x.slug, resultItem.id)" href="/{{translate.currentLang}}/{{getRoute(x.slug, resultItem.id)}}" target="_blank">
                        <h3 class="neutra2_demi result--title" *ngIf="x.pageTitle || x.sectionTitle || x.title">
                            <ng-container *ngIf="x.pageTitle && x.pageTitle != x.title">
                                {{x.pageTitle}} <span class="result--title--category">({{x.title}})</span>
                            </ng-container>
                            <ng-container *ngIf="x.sectionTitle && !x.pageTitle && x.sectionTitle != x.title">
                                {{x.sectionTitle}} <span class="result--title--category">({{x.title}})</span>
                            </ng-container>
                            <ng-container *ngIf="(!x.sectionTitle && !x.pageTitle) || (x.pageTitle == x.title || x.sectionTitle == x.title)">
                                {{x.title}}
                            </ng-container>
                        </h3>
                        <!-- <p class="result--text" *ngIf="x.text">{{x.text | shorten: textMaxLength: ' ...': false}}</p> -->
                        
                        <!-- <p class="result--text">
                            title: {{x.title}} | pageTitle: {{x.pageTitle}} | sectionTitle: {{x.sectionTitle}}
                        </p> -->
                    </a>

                    <!-- PAGINA CON ID -->
                    <a class="search-results--content--list-item text--on-dark no-underline" *ngIf="x.title && x.slug && getRouteId(x.slug, resultItem.id)" href="/{{translate.currentLang}}/{{getRoute(x.slug, resultItem.id)}}/{{getRouteId(x.slug, resultItem.id)}}" target="_blank">
                        <h3 class="neutra2_demi result--title" *ngIf="x.pageTitle || x.sectionTitle || x.title">
                            <ng-container *ngIf="x.pageTitle && x.pageTitle != x.title">
                                {{x.pageTitle}} <span class="result--title--category">({{x.title}})</span>
                            </ng-container>
                            <ng-container *ngIf="x.sectionTitle && !x.pageTitle && x.sectionTitle != x.title">
                                {{x.sectionTitle}} <span class="result--title--category">({{x.title}})</span>
                            </ng-container>
                            <ng-container *ngIf="(!x.sectionTitle && !x.pageTitle) || (x.pageTitle == x.title || x.sectionTitle == x.title)">
                                {{x.title}}
                                <span class="result--title--category" *ngIf="x.category">({{x.category}})</span> 
                                <span class="result--title--pub-date" *ngIf="x.pubDate">
                                    — {{x.pubDate | date:"MMMM yyyy" : '' : translate.currentLang}}
                                </span>
                            </ng-container>
                        </h3>
                        <!-- <p class="result--text" *ngIf="x.text">{{x.text | shorten: textMaxLength: ' ...': false}}</p> -->
                        
                        <!-- <p class="result--text">
                            title: {{x.title}} | pageTitle: {{x.pageTitle}} | sectionTitle: {{x.sectionTitle}}
                        </p> -->
                    </a>

                    <!-- GESTIONE PERMALINK -->
                    <a class="search-results--content--list-item text--on-dark no-underline" *ngIf="x.title && !x.slug && x.permalink" href="{{x.permalink}}" target="_blank">
                        <h3 class="neutra2_demi result--title" *ngIf="x.pageTitle || x.sectionTitle || x.title">
                            <!-- <ng-container *ngIf="x.pageTitle && x.pageTitle != x.title">
                                {{x.pageTitle}} <span class="result--title--category">({{x.title}})</span>
                            </ng-container>
                            <ng-container *ngIf="x.sectionTitle && !x.pageTitle && x.sectionTitle != x.title">
                                {{x.sectionTitle}} <span class="result--title--category">({{x.title}})</span>
                            </ng-container> -->
                            <ng-container *ngIf="(!x.sectionTitle && !x.pageTitle) || (x.pageTitle == x.title || x.sectionTitle == x.title)">
                                {{x.title}}
                                <span class="result--title--category" *ngIf="x.category">({{x.category}})</span> 
                                <span class="result--title--pub-date" *ngIf="x.pubDate">
                                    — {{x.pubDate | date:"MMMM yyyy" : '' : translate.currentLang}}
                                </span>
                            </ng-container>
                        </h3>
                        <!-- <p class="result--text" *ngIf="x.text">{{x.text | shorten: textMaxLength: ' ...': false}}</p> -->
                        
                        <!-- <p class="result--text">
                            title: {{x.title}} | pageTitle: {{x.pageTitle}} | sectionTitle: {{x.sectionTitle}}
                        </p> -->
                    </a>
                </ng-container>
                
                <div class="load-more-btn load-more--{{resultItem.id || 'results'}}" *ngIf="resultItem.list?.length > resultItem.displayedResultsSite">
                    <app-prometeia-button class="buttonLoadMore--btn interactive" [data]="buttonLoadMore" (click)="increaseDisplayedResults(resultItem.id)"></app-prometeia-button>
                    <!-- <p>{{resultItem.displayedResultsSite}} / {{resultItem.list?.length}}</p> -->
                </div>
            </div>
        </section>
    </ng-container>

    <ng-container *ngIf="(isResultsEmpty() || areAllCategoriesOff()) && !loadingResults">
        <ng-container *ngIf="queryReceived && query?.length > 0">
            <p class="no-results">{{ 'SEARCH_RESULTS.NO_RESULTS' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="!queryReceived || !(query?.length > 0)">
            <p class="no-results">{{ 'SEARCH_RESULTS.EMPTY_FILTER' | translate }}</p>
        </ng-container>
    </ng-container>
</div>
