import { Component, OnInit } from '@angular/core';
import { SectionGeneralComponent } from '../section-general/section-general.component';

@Component({
  selector: 'app-section-overview',
  templateUrl: './section-overview.component.html',
  styleUrls: ['./section-overview.component.scss']
})
export class SectionOverviewComponent extends SectionGeneralComponent {

}
