<section *ngIf="data.type == 'stacked'" spyTarget="{{data.anchorId}}" class="{{data.theme || 'theme--lighter'}} section center-vertical">
    <div [ngClass]="{'section-max-content': true, 'single-pic--full-size': true}">
        <ng-container *ngFor="let block of data.buttons; let i = index; let isLast = last;">
            <div class="block block-{{i}} flexible">
                <div class="column1">
                    <h1 data-aos="fade-up" class="block_h1 neutra2_bold text--primary">
                        {{block.label}}
                    </h1>
        
                    <ng-container *ngIf="block.text">
                        <p data-aos="fade-up" class="block--text" *ngIf="!block.text.includes('</')">{{block.text}}</p>
                        <div data-aos="fade-up" class="inner-html block--text" *ngIf="block.text.includes('</')" [innerHtml]="block.text"></div>
                    </ng-container>
        
                    <div data-aos="fade-up" class="block--buttons-container" *ngIf="block.route">
                        <app-prometeia-button class="block--button" [data]="{
                            type: 'button',
                            label: btnLabel,
                            theme: block.theme,
                            route: block.route
                        }"></app-prometeia-button>
                    </div>
                </div>
        
                <div [ngClass]="{'column2': true}" data-aos="fade-up">
                    <div class="img-wrap">
                        <img data-aos="fade-up" [src]="block.media.contentUrl" [alt]="block.media.alt" data-aos="fade-up" *ngIf="block.media">
                    </div>
                </div>
            </div>

            <div class="block-separator" *ngIf="!isLast">
                <hr data-aos="fade-up">
            </div>
        </ng-container>
    </div>
</section>
