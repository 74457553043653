<section spyTarget="{{data.anchorId}}" class="{{data.theme || 'theme--lighter'}} section center-vertical">
    <h1 data-aos="fade-up" class="section_h1 neutra2_bold text--primary" *ngIf="!['theme--dark'].includes(data.theme)">
        {{ data.title }}
    </h1>
    <h1 data-aos="fade-up" class="section_h1 neutra2_book text--accent" *ngIf="['theme--dark'].includes(data.theme)">
        {{ data.title }}
    </h1>

    <p data-aos="fade-up" class="section--text" *ngIf="data.text">
        {{ data.text }}
    </p>

    <div class="grid-cards desktop-only">
        <ng-container *ngFor="let card of data.buttons">
            <!-- <a class="no-underline" [routerLink]="['/', card.route]" *ngIf="card.route" data-aos="fade-up">
                <mat-card class="grid-cards--single-card">
                    <mat-card-header class="card-header clickable">
                        <app-highlighted-text class="highlight card-header--title" [text]="card.label" [highlighter]="sectionOptions.highlightColor" [customStyle]="gridCardsCustomStyle"></app-highlighted-text>
                    </mat-card-header>
                
                    <mat-card-content class="card-content">
                        <div class="card-content--text" [innerHtml]="card.text"></div>
                    </mat-card-content>
                </mat-card>
            </a> -->

            <mat-card class="grid-cards--single-card" data-aos="fade-up">
                <mat-card-header class="card-header">
                    <app-highlighted-text class="highlight card-header--title" [text]="card.label" [highlighter]="sectionOptions.highlightColor" [customStyle]="gridCardsCustomStyle" *ngIf="!['theme--dark'].includes(data.theme)"></app-highlighted-text>

                    <p class="no-highlight card-header--title text--center neutra2_bold text--primary" *ngIf="['theme--dark'].includes(data.theme)">
                        {{ card.label }}
                    </p>
                </mat-card-header>
            
                <mat-card-content class="card-content">
                    <!-- <p class="card-content--text">{{card.text}}</p> -->
                    <div class="inner-html inner-html--grid-card card-content--text" [innerHtml]="card.text"></div>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </div>
    
    <div class="mobile-cards mobile-only">
        <app-flipping-cards [cards]="data.cards" [highlightColor]="sectionOptions.highlightColor" [cardsOnDarkTheme]="['theme--dark'].includes(data.theme)"></app-flipping-cards>
    </div>
</section>
