import { Component } from '@angular/core';
import { IOurPartnership } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';

const IS_FIRST_HALF: number = 1;
const IS_SECOND_HALF: number = 2;
const ERROR: number = -1;

@Component({
  selector: 'app-our-partnership',
  templateUrl: './our-partnership.component.html',
  styleUrls: ['./our-partnership.component.scss']
})
export class OurPartnershipComponent extends BasePageComponent<IOurPartnership> {

  checkHalf(index: number): number {
    if (this.data.sections[index].type.toLowerCase() == 'half-section') {
      if ((index < this.data.sections.length - 1) && this.data.sections[index + 1].type.toLowerCase() == 'half-section') return IS_FIRST_HALF;
      if ((index > 0) && this.data.sections[index - 1].type.toLowerCase() == 'half-section') return IS_SECOND_HALF;
    }
    
    return -1;
  }

}
