import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AmplifyAuthService, AwsExportsType } from 'src/app/services/amplify-auth.service';

@Component({
  selector: 'app-alumni-auth',
  templateUrl: './alumni-auth.component.html',
  styleUrls: ['./alumni-auth.component.scss']
})
export class AlumniAuthComponent implements OnInit, OnDestroy{
  @Input() pool: AwsExportsType;
  private changeConfigSubscription: any = null;
  show : boolean = false;
  formFieldsLogin  = [
    {
      type: 'email', 
      label: '', 
      placeholder: 'E-mail', 
      required: true
    },
    {
      type: 'password', 
      label: '', 
      placeholder: 'Password', 
      required: true
    }
  ];

  formFieldForgotPassword = [
    {
      type: 'email', 
      label: '', 
      placeholder: 'E-mail', 
      required: true
    }
  ];  
  private poolStatusSubscription: Subscription = null;
  constructor(public amplifyAuthService: AmplifyAuthService, private router: Router, public translate: TranslateService) { }

  ngOnInit(): void {
    this.changeConfigSubscription = setTimeout(()=>{
      this.amplifyAuthService.config(this.pool);
      console.log('INIT AUTH', this.pool);
      this.show = true;
    }, 1000);

   /* this.poolStatusSubscription = this.amplifyAuthService.poolARStatus.subscribe( status => {      
      console.log(status);
      if(status.islogged){        
        window.location.href = this.translate.currentLang+'/reserved-area';        
      } 
   });*/
  }

  ngOnDestroy(): void{
    this.amplifyAuthService.config('alumni');
    this.changeConfigSubscription = null;
    console.log('INIT AUTH', 'alumni');

    if (this.poolStatusSubscription) {
      this.poolStatusSubscription.unsubscribe();
      this.poolStatusSubscription = null;
    }
  }

}
