<div class="card {{getTheme()}}" *ngIf="card" [ngClass]="{ 'job-offer' : card.app_code == 'CAR' }">

    <div class="card--img" *ngIf="card.app_code != 'CAR'">
        <img class="cover-image--{{card.type || 'default'}}" src="{{card.heroImage}}" *ngIf="card.app_code == 'ATL'">
        <div class="card--img-bg" *ngIf="card.app_code == 'WWW'"></div>
    </div>
    <div class="card--close" (click)="eventCloseCard.emit()">
        <img src="../../../assets/icons-svg/svg-icon-X-chiusura.svg">
    </div>
    <div class="card--content">
        <div class="card--content-h">
            <span class="text--grey prom-caption___lato-regular">{{card.pubDate | date:'dd MMMM yyyy' : '' : translate.currentLang}}</span>
            <span class="category text--green prom-caption___lato-regular">{{card.app_code == 'WWW' ? category  : card.category}}</span>
        </div>
        <div class="card--content-subtitle neutra2_demi" *ngIf="card.app_code == 'CAR'">
            {{ card.subtitle }}
        </div>
        <div class="card--content-title neutra2_demi">
            {{ card.title }}
        </div>
        <div [ngClass]="{'card--content-f': true, 'permalink': (!card?.slug && card?.permalink), 'route': card?.slug || (card?.app_code == 'CAR' && card?.id)}">
            <a *ngIf="card?.slug || (card?.app_code == 'CAR' && card?.id)" [routerLink]="['/', translate.currentLang, getRoute(), getID() ]" [ngClass]="{'text--primary' :card.app_code != 'CAR' }">{{'READ_MORE' | translate}}</a>
            <a *ngIf="(!card?.slug && card?.permalink)" href="{{card?.permalink}}" [ngClass]="{'text--primary' :card.app_code != 'CAR' }">{{'READ_MORE' | translate}}</a>
        </div>
    </div>

</div>
