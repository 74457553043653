import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_LANG, Locale } from './models/commons';

import localeIt from '@angular/common/locales/it';
import localeEnGb from '@angular/common/locales/en-GB';

import { filter, pairwise } from 'rxjs/operators';

// doc: https://michalsnik.github.io/aos/
import * as AOS from 'aos';
import { TranslateService } from '@ngx-translate/core';
import { Debounce, Throttle } from 'angular-debounce-throttle';

import { ComponentsServices } from './services/components.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import { BrowserServices } from './services/browser.service';
import { CookieService } from './services/cookie.service';
import { LangServices } from './services/lang.service';
import { paths, specialPaths } from './models/paths';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // providers: [ComponentsServices]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Prometeia';

  // private routerSubscription: Subscription = null;
  private menuOpeningStateSubscription: Subscription = null;
  isMenuOpen: boolean = false;

  wasCountryPage: boolean = false;

  specialPathsArray: string[] = [];

  gtagAlreadyAdded: boolean = false;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private componentsServices: ComponentsServices,
    private browserServices: BrowserServices,
    public cookieServices: CookieService
    /* , private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer */
  ) {
    translate.addLangs(Object.values(Locale));
    translate.setDefaultLang(DEFAULT_LANG);
    translate.use(DEFAULT_LANG);

    /* this.matIconRegistry.addSvgIcon(
      "svg-icon-panino-mob",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons-svg/svg-icon-nav-menu_menu-mobile.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "svg-icon-panino-desk",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons-svg/svg-icon-nav-menu_menu.svg")
    ); */



    /** Google Analytics 
     * 
     * https://medium.com/madhash/how-to-properly-add-google-analytics-tracking-to-your-angular-web-app-bc7750713c9e
    */
    this.createGTAG();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const path = event.urlAfterRedirects;

        // if (this.cookieServices.isAnalyticsEnabled()) {
        //   console.log("cookies: Analytics enabled", path);
        //   gtag('config', environment.GOOGLE_ANALYTICS_TRACKING_CODE, { 'page_path': path });
        // }

        /* if(this.cookieServices.isMarketingEnabled()) {
          console.log("cookies: Marketing enabled");
        } */

        // console.log('page_path:', path);

        const u = path.substring(1).split("/");
        const r = u[1] || null;
        const browserLang = this.browserServices.myLanguage();
        const loc = (u[0] === Locale.it || u[0] === Locale.en) ? u[0] : null;
        const defaultLang = loc || browserLang;
        const isCookieLangStored = this.cookieServices.isLangStored();
        const cookieLang = isCookieLangStored ? this.cookieServices.getLang() : null;

        // console.log("url", loc, "cookie", cookieLang, "browser", browserLang);

        if (loc && r != paths.countryPage) {
          // console.log(loc, "<<<");
          if (!isCookieLangStored || cookieLang != loc) {
            if (isCookieLangStored && this.wasCountryPage) {
              this.translate.use(cookieLang);
              setTimeout(() => {
                // console.log('/' + Locale.it + '/' + r)
                this.router.navigate(['/', cookieLang, r], { replaceUrl: true });
                this.translate.use(cookieLang);
                this.cookieServices.setLangCookie(cookieLang);
              });
            } else {
              this.translate.use(defaultLang);
              this.cookieServices.setLangCookie(defaultLang);
            }
          }
        }

        if (r != paths.countryPage) {
          this.wasCountryPage = false;
        } else {
          // console.log("countryPage lang", cookieLang);
          this.wasCountryPage = true;
        }
      }
    });

    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        // console.log('previous url', events[0].urlAfterRedirects);
        // console.log('current url', events[1].urlAfterRedirects);
        this.componentsServices.updatePreviousRoute(events[0].urlAfterRedirects);
      });
  }

  createGTAG() {
    if (!this.gtagAlreadyAdded && environment.GOOGLE_ANALYTICS_TRACKING_CODE && this.cookieServices.isAnalyticsEnabled()) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GOOGLE_ANALYTICS_TRACKING_CODE}`;
      document.head.appendChild(gTagManagerScript);
  
      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${environment.GOOGLE_ANALYTICS_TRACKING_CODE}', {
          'send_page_view': false,
          'anonymize_ip': true 
        });
      `;
      document.head.appendChild(gaScript);

      this.gtagAlreadyAdded = true;
      // console.log("createGTAG done?", this.gtagAlreadyAdded, document.head.getElementsByTagName("script"));
    }
  }

  ngOnInit() {
    this.specialPathsArray = Object.keys(specialPaths).map(p => specialPaths[p]);

    registerLocaleData(localeIt, 'it');
    registerLocaleData(localeEnGb, 'en');

    this.browserServices.myLanguage();

    try {
      const lang = (window.location.pathname.split('/')[1] || '').toLowerCase();
      if (Object.values(Locale).map(l => l.toString()).includes(lang)) {
        this.translate.use(lang);
      }
    }
    catch (ex) {
      console.error(ex);
    }

    // cheatsheet here: https://easings.net/
    AOS.init({
      easing: 'ease',
      duration: 1000,
      offset: -100,
      delay: 250,
      once: false
    });
    this.menuOpeningStateSubscription?.unsubscribe();
    this.menuOpeningStateSubscription = this.componentsServices.isMenuOpenEvent.subscribe((isMenuOpen: boolean) => {
      this.isMenuOpen = isMenuOpen;
    });
  }

  isDesktopMode(): boolean {
    return this.componentsServices.isDesktopMode();
  }

  ngOnDestroy(): void {
    // if(this.routerSubscription != null) {
    //   this.routerSubscription.unsubscribe();
    //   this.routerSubscription = null;
    // }

    this.menuOpeningStateSubscription?.unsubscribe();
    this.menuOpeningStateSubscription = null;
  }

  @HostListener('window:scroll', ['$event'])
  @Throttle(300)
  onWindowScroll() {
    this.componentsServices.yScrollEvent(window.scrollY);
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(500)
  onResize(event) {
    this.componentsServices.resizeEvent(event.target.innerHeight, event.target.innerWidth);
  }

  areCookiesAccepted(): boolean {
    return false;
  }

  isNotSpecialPage(): boolean {
    return !this.specialPathsArray.includes(window.location.pathname.split('/')[2]);
  }
}