import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { DefaultLangs, ExtraLangs, EXTRA_LANGS } from 'src/app/models/commons';
import { noBilingual, paths } from 'src/app/models/paths';
import { AmplifyAuthService } from 'src/app/services/amplify-auth.service';
import { ApiArService } from 'src/app/services/api-ar.service';
import { ComponentsServices } from 'src/app/services/components.service';
import { LangServices } from 'src/app/services/lang.service';
import { ArDialogAuthComponent } from '../ar-dialog-auth/ar-dialog-auth.component';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-menu-tools',
  templateUrl: './menu-tools.component.html',
  styleUrls: ['./menu-tools.component.scss']
})
export class MenuToolsComponent implements OnInit, OnDestroy{

  searchPage: string = paths.searchResults;

  @Input() data;
  @Input() blueTheme: boolean = false;

  isLangsListOpen: boolean = false;
  isLoggedAR : boolean = false;

  forcedLang: string = null;
  isForced: boolean = false;

  tokenCognito : string;
  dataLoginCognito : any;

  private poolStatusSubscription: Subscription = null;
  COOKIE_TOKEN_AR : string = 'prom-ar-token';
  
  private session = window.sessionStorage; 

  constructor(private router: Router, 
              public translate: TranslateService, 
              private dialog: MatDialog, 
              private amplifyService: AmplifyAuthService, 
              public langServices: LangServices,
              public apiAR: ApiArService,
              private _snackBar: MatSnackBar,
              private cookieService: CookieService,
              private route: ActivatedRoute,
              private componentsServices: ComponentsServices) {}

  ngOnInit(): void {
    if(this.poolStatusSubscription != null){
      this.poolStatusSubscription.unsubscribe();
      this.poolStatusSubscription = null;      
    } 
    this.poolStatusSubscription = this.amplifyService.poolARStatus.subscribe( status => {        
      if(this.isLoggedAR != status.islogged && status.islogged){
        this.isLoggedAR = status.islogged; 
      }
      
    });


    this.setIsLoggedAR();
  }

  ngOnDestroy(): void{
    if (this.poolStatusSubscription) {
      this.poolStatusSubscription.unsubscribe();
      this.poolStatusSubscription = null;
    }
  }

  async login() {    
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px'
    });
  }
 
  

  resevedArea(){
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate(['/', this.translate.currentLang, 'reserved-area']);
    else window.location.reload();
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async logout() {    
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant("LOGOUT"), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      try {
        const logout = await this.apiAR.libraries.logout();
        if(logout) this.router.navigate(['/', this.translate.currentLang, 'home']); 
      } catch (error) {
        console.log("Error logour AR", error)
      }
          
    });
  }

  async setIsLoggedAR(){   
    this.isLoggedAR = this.cookieService.get(this.COOKIE_TOKEN_AR) ? true : false; 
    //TODO 
    if(await this.amplifyService.isLoggedIn('ar')) await this.amplifyService.logout('ar');
  }

  toggleLangs() {
    this.isLangsListOpen = !this.isLangsListOpen;
  }

  changeLang() {
    let nextLang: string;

    let activeLang = this.translate.currentLang.toLowerCase();

    switch (activeLang) {
      case "en": 
        nextLang = "it";
        break;

      case "it": 
        nextLang = "en";
        break;

      default: 
        nextLang = "en";
        break;
    }
    
    /** updates the translation */
    this.translate.use(nextLang);


    /** updates the URL too (TODO: find the url in a better way)*/
    let r: string = this.router.url.split('/')[2].split('#')[0].split('%2523')[0];
    let i=3;
    while (this.router.url.split('/')[i] && this.router.url.split('/')[i].length > 0) {
      let x: string = this.router.url.split('/')[i];
      r = r + '/' + x;
      
      i++;
    }
    this.componentsServices.toggleMenu(false);
    r = '/' + nextLang + '/' + r;
    this.router.navigateByUrl(r);
    // if (r.includes("/")) {
    //   this.router.navigate(['/', r.split("/")[0], r.split("/")[1]], {replaceUrl: true});
    // } else {
    //   this.router.navigate(['/', r], {replaceUrl: true});
    // }
  }


  /* hasMultiLang() {
    const cond1 = this.langServices.getLangLabel() != this.langServices.getNextLangLabel();
    let pageSlug: string = this.router.url.split('/')[2];
    if (pageSlug) {
      if (pageSlug.includes("#")) pageSlug = pageSlug.split("#")[0];
      const cond2 = !noBilingual.includes(pageSlug);
      // console.log(pageSlug, cond2);

      return cond1 && cond2;
    } else {
      return cond1;
    }
  } */

  hideLangLabel() {
    const pageSlug: string = this.router.url.split('/')[2];
    if (pageSlug == paths.countryPage) return true;
    else return false;
  }
}
