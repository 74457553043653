import { Component, Input, OnInit } from '@angular/core';
import { INewsletter } from '../../models/components.interface';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { NewsletterFormComponent } from '../newsletter-form/newsletter-form.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentsServices } from 'src/app/services/components.service';

@Component({
  selector: 'app-single-newsletter',
  templateUrl: './single-newsletter.component.html',
  styleUrls: ['./single-newsletter.component.scss']
})
export class SingleNewsletterComponent implements OnInit {

  @Input() data: INewsletter;
  category: string;
  insight_route: string = "insight_route";

  showForm: boolean = false;
  private overlayRef: OverlayRef;
  private formPortal: ComponentPortal<NewsletterFormComponent>;

  constructor(public overlay: Overlay, private componentsServices: ComponentsServices) { }

  ngOnInit() {
    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
    this.formPortal = new ComponentPortal(NewsletterFormComponent);

    this.componentsServices.newsletterCloseSubscribeForm.subscribe(() => {
      this.overlayRef.detach();
      this.showForm = false;
    });
  }

  subscribeToNewsletter() {
    // console.log("subscribing to", this.data.buttons[0].id);
    this.showForm = true; //!this.showForm;

    if (this.showForm) {
      this.componentsServices.updateNewletterFormId(this.data.buttons[0].id);
      this.overlayRef.attach(this.formPortal);
    }
  }

}
