import { Component } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { IRole, ITeam } from '../../models/pages.interface';
import { IMedia } from 'src/app/models/commons';
import { ShortenPipe } from 'ngx-pipes';
import { Subscription } from 'rxjs';
import { ComponentsServices } from 'src/app/services/components.service';

const LINES: number = 4;

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss'],
  providers: [ShortenPipe]
})
export class OurTeamComponent extends BasePageComponent<ITeam> {

  private pageDataReadySubScription: Subscription;

  defaultMeta: {maxLength: number};

  ngOnInit() {
    super.ngOnInit();

    this.setMobMeta();

    if (this.componentsServices.isDesktopMode()) {
      this.setDeskMeta();
      // console.log("D mode");
    }

    this.pageDataReadySubScription?.unsubscribe();
    this.pageDataReadySubScription = this.dataServices.pageDataReady.subscribe(() => {
      this.data.roles.forEach((role: IRole) => {
        role.isOpened = false;

        role.members.forEach((member: IMedia) => {
          member._meta = this.defaultMeta;
          member.downloadable = false;
        });
      });
    });

    this.componentsServices.pageResizeInMobileMode.subscribe(() => {
      // console.log("resize M");
      this.setDeskMeta();
    });

    this.componentsServices.pageResizeInDesktopMode.subscribe(() => {
      // console.log("resize D");
      this.setMobMeta();
    });
  }

  setMobMeta() {
    this.defaultMeta = {
      maxLength: LINES * (this.componentsServices.getInnerWidth() / 7)
    };
    // console.log("defaultMeta M", this.defaultMeta.maxLength);
  }

  setDeskMeta() {
    this.defaultMeta = {
      maxLength: LINES * (this.componentsServices.getInnerWidth() / 5) / 3
    };
    // console.log("defaultMeta D", this.defaultMeta.maxLength);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.pageDataReadySubScription?.unsubscribe();
  }

  readMore(member: IMedia) {
    // console.log("more about", member.title, member.id, member.text.length);
    //member._meta.maxLength = member.text.length;
    member.downloadable = true;
  }

  onRoleClick(role: IRole) {
    role.isOpened = !role.isOpened;
  }

}
