<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">
    <app-side-navbar class="side-navbar desktop-only" [data]="data.sections" [bulletColor]="highlightColor"></app-side-navbar>
    <app-long-arrow-down class="desktop-only" [color]="highlightColor"></app-long-arrow-down>


    <div class="culture">


        <ng-container *ngFor="let s of data.sections; let i = index">
            <app-section-intro *ngIf="s.type == 'intro'" [data]="s" [highlightColor]="highlightColor"></app-section-intro>

            <app-section-general [data]="s" [opt]="{'highlightColor': highlightColor, 'titleColor': titleColor}"></app-section-general>

            <app-section-collapsable-list [data]="s" [highlightColor]="highlightColor" [noPaddingBottom]="checkIfNoPaddingBottom(i)" [shortPaddingTop]="checkIfShortPaddingTop(i)"></app-section-collapsable-list>

            <section *ngIf="s.type == 'last'" spyTarget="last" class="section center-vertical section--last">
                <div class="section--last-bg">
                    <div class="lc lc--background-color--{{highlightColor || 'default'}}">
                    </div>
                </div>
                <div class="container section-max-content">
                    <div class="lc lc--background-color--{{highlightColor || 'default'}}">
                        <div class="lc--sub lc--sub1">
                            <img class="career-app-logo" src="{{s.list[0].contentUrl}}" />
                        </div>

                        <div class="lc--sub lc--sub2">
                            <div class="lc--sub2--text">
                                <p class="text--primary prom-contact lc--sub--title title1 neutra2_bold">{{s.list[0].title.split('\n')[0]}}</p>
                                <p class="text--primary prom-contact lc--sub--title title2 neutra2_bold">{{s.list[0].title.split('\n')[1]}}</p>
                                <p class="text--primary lc--sub--text">{{s.list[0].text}}</p>
                            </div>

                            <a class="badge--wrap--appstore" target="_blank" href="https://apps.apple.com/it/app/prometeia-career/id1458111294">
                                <input class="badge--appstore" type="image" src="{{ 'BADGE.APP_STORE' | translate }}" />
                            </a>
                            <a class="badge--wrap--googleplay" target="_blank" href="https://play.google.com/store/apps/details?id=it.prometeia.career&hl={{translate.currentLang}}">
                                <input class="badge--googleplay" type="image" src="{{ 'BADGE.GOOGLE_PLAY' | translate }}" />
                            </a>
                        </div>
                    </div>

                    <div class="rc">
                        <div class="rc--sub">
                            <img class="prom-next-logo" src="{{s.list[1].contentUrl}}" />
                        </div>

                        <div class="rc--sub">
                            <p class="text--primary prom-contact title neutra2_bold">{{s.list[1].title}}</p>
                        </div>

                        <div class="rc--sub">
                            <!-- <p class="spacer"></p> -->
                            <app-prometeia-button class="subscribe-button interactive" [routerLink]="['/', translate.currentLang, 'newsletter']" [fragment]="s.list[2].id" routerLinkActive="active" [data]="s.list[2]"></app-prometeia-button>
                            <!-- <p class="spacer"></p> -->
                        </div>
                    </div>
                </div>
            </section>
        </ng-container>

    </div>
</ng-container>
