import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { paths } from '../models/paths';
import { AmplifyAuthService } from './amplify-auth.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private cookieService: CookieService, private router: Router, public translate: TranslateService, private amplifyAuthService: AmplifyAuthService) { }

  async canActivate() {
    const isAuthenticated = await this.amplifyAuthService.isLoggedIn('alumni');
    if (!isAuthenticated) {
      this.router.navigate(["/" + this.translate.currentLang, paths.alumni]);
    }
    return isAuthenticated;
  }
}