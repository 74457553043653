<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>

<div class="reserved-area prom-page  " *ngIf="data">
    <div class="section-max-content">
        <!-- <div class="logout" (click)="logout()" *ngIf="!componentsServices.getARnotLogged()">
            <img class="tool--padlock color-on" src="../../../assets/icons-svg/logout_blu.svg">
            <img class="tool--padlock color-off" src="../../../assets/icons-svg/logout_green.svg">
        </div> -->
        
        <div class="reserved-area--content ">
            <h4 class="breadcrumb-text neutra2_demi" *ngIf="componentsServices.getARnotLogged()">Attenzione, area riservata. Accedere per visualizzare i contenuti</h4>
            <ng-container *ngIf="!componentsServices.getARnotLogged()">
                <a class="breadcrumb-text" [routerLink]="['/', translate.currentLang, route_ar]">Documenti</a>
                <span *ngFor="let item of breadcrumbs"> <span class="material-icons breadcrumb-icon"> arrow_forward_ios </span>
                <a class="breadcrumb-text neutra2_demi" [routerLink]="['/', translate.currentLang, route_ar , item.path]">{{item.name}}</a>
                </span>
                <hr>
                <table class="folder-explorer">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="prom-caption___lato-regular">Nome</th>
                            <th class="prom-caption___lato-regular">Tipo</th>
                            <th class="prom-caption___lato-regular text-align-right">Ultima modifica</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="currentFolder?.children?.length">
                            <tr [ngClass]="{'tb-row': true, 'has-children': item.children?.length}" *ngFor="let item of currentFolder.children">
                                <td><span class="material-icons icon-folder"> folder_open </span></td>
                                <td>
                                    <a class="library-item neutra2_demi" [routerLink]="['/',translate.currentLang, route_ar , item.path]">{{item.name}}</a>
                                </td>
                                <td class="prom-caption___lato-regular "> Folder </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="currentFolder?.attachments?.length">
                            <tr *ngFor="let item of currentFolder.attachments">
                                <td><span class="material-icons icon-file"> insert_drive_file </span></td>
                                <td>
                                    <a class="library-item neutra2_demi" [href]="item.uri">{{item.title}}</a>
                                </td>
                                <td class="prom-caption___lato-regular type-file"> {{getExtention(item.name)}} </td>
                                <td class="prom-caption___lato-regular text-align-right">{{item.updatedAt | date: 'dd/MM/yyyy'}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!currentFolder?.children?.length && !currentFolder?.attachments?.length">
                            <tr><td></td><td>Nessun file</td></tr>
                        </ng-container>
                    </tbody>
                </table>                
            </ng-container>
        </div>
    </div>
</div>
