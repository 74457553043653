import { trigger, transition, style, animate, query, AnimationMetadataType, AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { QueryList } from '@angular/core';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Debounce, Throttle } from 'angular-debounce-throttle';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';
import { timeInterval } from 'rxjs/operators';
import { HighlightColor, IHorizontalScrollCarousel, IMedia } from 'src/app/models/commons';
import { ComponentsServices } from 'src/app/services/components.service';
import { VideoService } from 'src/app/services/video.service';

interface Player {
  player: AnimationPlayer,
  bit_start:boolean,
  bit_pause:boolean
}
const TIME_SPEED = 500000;


@Component({
  selector: 'app-horizontal-scroll-animation',
  templateUrl: './horizontal-scroll-animation.component.html',
  styleUrls: ['./horizontal-scroll-animation.component.scss']
})
export class HorizontalScrollAnimationComponent implements OnInit {
  @Input() data: IHorizontalScrollCarousel;
  @Input() filters: string[];
  @ViewChild('withBuilder') animationsView!: ElementRef;
  @ViewChildren('logo') logosView!: QueryList<ElementRef>;
  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  slides: IMedia[];
  text_image: string;
  mobile:boolean;

  player:AnimationPlayer = null;
  /** if (data.highlightColor != null) we have a zoomable circular image instead of a regular slide */
  highlightColor: HighlightColor;
  interval:NodeJS.Timeout|null = null;

  bit_start = false;
  slide_number:number;
  config: SwiperConfigInterface = {
    direction: 'horizontal',
    slideToClickedSlide: false,
    mousewheel: false,
    scrollbar: false,
    freeMode:true,
    watchSlidesProgress: false,
    navigation: false,
    keyboard: false,
    pagination: false,
    centeredSlides:false,
    loop: true,
    loopAdditionalSlides: 0,
    speed: 2000,
    cssMode:false,
    noSwiping: false,
    simulateTouch:true,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    effect:"slide",
    spaceBetween:0,
    slidesPerView:1.3
  };

 constructor(private videoService: VideoService, private animationBuilder:AnimationBuilder, private componentService:ComponentsServices, public translate: TranslateService) {
  this.mobile = !componentService.isDesktopMode();
  componentService.pageResizeAll.subscribe( ()=> {
      this.mobile  = !componentService.isDesktopMode();
      setTimeout(()=>{
        if(!this.mobile)
          this.createNewAnimation();
      },1000)
   })
 }

 ngOnInit(): void {

   if (this.data.carousel__highlight_color) {
     this.highlightColor = this.data.carousel__highlight_color;
   }

   this.slides = this.data.slides;
   this.slide_number= Math.ceil(50/this.slides.length);
   this.text_image = this.slides[0].text;
 }

 ngOnChanges(): void {
   // if (this.filters) console.log("HorizontalScrollCarousel", "filters:", this.filters);
   // else console.log("HorizontalScrollCarousel", "filters disabled", this.filters);

   this.filterSlides();
 }

 ngAfterViewInit():void {
    if(!this.mobile)
      this.createNewAnimation();
 }

 @Throttle(1000)
 filterSlides() {
   // if filters==null  -> display all
   // if filters==[???] -> display only filtered

   if (this.filters) {
    //  console.log("HorizontalScrollCarousel", "filters:", this.filters);

     this.slides = [];
     this.data.slides.forEach((slide: IMedia) => {
       if ( (slide.title && this.filters.includes(slide.title.toLowerCase())) || (slide.text && this.filters.includes(slide.text.toLowerCase())) ) {
         this.slides.push(slide);
       }

       let slideTagsArray = [];
       if (slide.title?.includes(" ")) slideTagsArray = slide.title.split(" ");
       slideTagsArray.forEach((singleTag: string) => {
         if (this.filters.includes(singleTag.toLowerCase())) {
           this.slides.push(slide);
         }
       });

       if (slide.text?.includes(" ")) slideTagsArray = slide.text.split(" ");
       slideTagsArray.forEach((singleTag: string) => {
         if (this.filters.includes(singleTag.toLowerCase())) {
           this.slides.push(slide);
         }
       });
     });
   } else {
    //  console.log("HorizontalScrollCarousel", "filters disabled", this.filters);
     this.slides = this.data.slides;
   }
 }

 openVideo(video: IMedia) {
   this.videoService.playThisVideo(video);
 }

 stopAnimation() {
  if(this.mobile)
    return;
  if(this.interval){
    clearInterval(this.interval);
    this.interval=null;
  }
  this.player.pause();
}


startAnimation() {
  if(this.mobile)
    return;

  if(this.interval){
    clearInterval(this.interval);
    this.interval = null;
  }
    this.player.play();
    this.interval = setInterval(() => {
      this.logosView.forEach((view,i)=>{
        let bound = <DOMRect>view.nativeElement.getBoundingClientRect();
        if(0<bound.x && bound.x<200)
          this.text_image = this.slides[i%this.slides.length].text;
      });
    },1000);

 }


 createNewAnimation(){
      let pos_x = this.animationsView.nativeElement.getBoundingClientRect().x;
      if(this.player){
        this.player.destroy();
      }
      const myAnimation = this.animationBuilder.build([
        style({
          transform: 'translateX('+pos_x+'px)',
        }),
        animate(TIME_SPEED+'ms' ,
          style({
            transform: 'translateX(calc(20 * -100vw))',
          }),
        ),
      ]);
      this.player = myAnimation.create(this.animationsView.nativeElement);
      this.startAnimation();
 }

 increaseVelocity(){
  if(this.player){
    let pos_x = this.animationsView.nativeElement.getBoundingClientRect().x;
    let time_speed=90000;
    this.player.destroy();
    const myAnimation = this.animationBuilder.build([
      style({
        transform: 'translateX('+pos_x+'px)',
      }),
      animate(time_speed+'ms' ,
        style({
          transform: 'translateX(calc(20 * -100vw))',
        }),
      ),
    ]);
    this.player = myAnimation.create(this.animationsView.nativeElement);
    this.startAnimation();
  }

 }

 decreaseVelocity(){
  this.createNewAnimation();
 }

 onIndexChange(index){
  this.text_image = this.slides[index].text;
 }

 onClick(slide:IMedia){
  this.text_image = slide.text;
 }

}
