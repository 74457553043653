import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { SideNavbarComponent } from '../side-navbar/side-navbar.component';

@Component({
  selector: 'app-side-pagination-dots',
  templateUrl: './side-pagination-dots.component.html',
  styleUrls: ['./side-pagination-dots.component.scss']
})
export class SidePaginationDotsComponent extends SideNavbarComponent {

  @Input() index: number = 0;
  @Input() increasedRightMargin: boolean = false;
  @Output() goToIndex: EventEmitter<number> = new EventEmitter();

  updateIndex(index: number) {
    this.goToIndex.emit(index);
  }

}
