import { Component, Input, OnInit } from '@angular/core';
import { HighlightColor, IMedia, IMultiText } from 'src/app/models/commons';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-multi-text',
  templateUrl: './multi-text.component.html',
  styleUrls: ['./multi-text.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ 'opacity': '1' })),
      state('out', style({ 'opacity': '0' })),
      transition('* <=> *', [
        animate(300)
      ])
    ])
  ]
})
export class MultiTextComponent implements OnInit {

  @Input() data: IMultiText;
  @Input() highlighter: HighlightColor;

  description: string;
  imageUrl: string;
  
  private clicked: boolean;
  animations = {
    state: 'in',
    nextImage: ''
  };

  constructor() { }

  ngOnInit(): void {
    this.data.list.forEach((media: IMedia) => {
      if (!media.selected) media.selected = false;
    });

    this.less();
    this.clicked = false;
  }

  onDone($event) {
    if(this.animations.state === 'out') {
      this.imageUrl = this.animations.nextImage;
      this.animations.state = 'in';
    }
  }

  animateChange(newImg) {
    this.animations.nextImage = newImg;
    this.animations.state = 'out';
  }

  more(m: IMedia) {
    if (!this.data) return;
    
    // console.log(">>", m.title);
    
    this.clicked = false;
    this.description = m.text;
    // this.imageUrl = m.contentUrl;
    
    // Animazione
    this.animateChange(m.contentUrl);
    
    this.data.list.forEach((media: IMedia) => {
      media.selected = false;
    });
    m.selected = true;
  }

  less(m?: IMedia) {
    if (!this.data || this.clicked) return;
    this.description = this.data.default.text; 
    // this.imageUrl = this.data.default.contentUrl;
    // Animazione
    this.animateChange(this.data.default.contentUrl);

    if (m) {
      m.selected = false;
      // console.log(m);
    }
  }

  click() {
    this.clicked = true;
  }

  

}
