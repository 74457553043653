<div class="single-trending-topics">
    <a class="single-trending-topics--wrap--logo type-{{data.type}}"
        *ngIf="data.type != 'video' && data.type != 'podcast'"
        [routerLink]="['/', translate.currentLang, route, getSlug()]">

        <img class="single-trending-topics--play" src="../../../assets/icons-svg/video-player-player.svg">
        <img class="single-trending-topics--logo"
            src="{{ (data.type == 'news' || data.type == 'dataviz') ? data.heroImage : imgNewsDefault[data.type]}}">
        <div [ngClass]="{'filter' : data.type == 'news'}"></div>
        <span class="icon-{{data.type}} static-icon"></span>
    </a>
    <a class="single-trending-topics--wrap--logo type-{{data.type}}"
        [ngClass]="{'single-trending-topics--show-video' : data.type == 'video' }"
        *ngIf="data.type == 'video' || data.type == 'podcast'"
        [href]="data.permalink" target="_blank">

        <img class="single-trending-topics--play" src="../../../assets/icons-svg/video-player-player.svg">
        <img class="single-trending-topics--logo"
            src="{{ (data.type == 'video') ? data.heroImage : imgNewsDefault[data.type]}}">
        <!-- <div [ngClass]="{'filter' : data.type == 'news'}"></div> -->
        <span class="icon-{{data.type}} static-icon"></span>
    </a>
    <div class="single-trending-topics--content">
        <div class="single-trending-topics--content--wrap">
            <span
                class="single-trending-topics--content--wrap--category prom-caption___lato-regular">{{category}}</span>
            <span class="single-trending-topics--content--wrap--date prom-caption___lato-regular">{{data.pubDate |
                date:"dd MMMM yyyy" : '' : translate.currentLang}}</span>
        </div>
        <h3 class="single-trending-topics--content--title ">
            <a class="title--highlight neutra2_demi" *ngIf="data.type != 'video' && data.type != 'podcast'"
                [routerLink]="['/', translate.currentLang, route, getSlug()]">
                {{data.title}}
            </a>
            <a class="title--highlight neutra2_demi" *ngIf="data.type == 'video' || data.type == 'podcast'"
                [href]="data.permalink" target="_blank">
                {{data.title}}
            </a>
        </h3>
        <!--  <p class="pi-p single-trending-topics--content--text">{{data.text}}</p> -->
        <a class="single-trending-topics--all" *ngIf="data.type != 'news' && data.type != 'video'"
            [routerLink]="['/', translate.currentLang, archiveRoute, data.type]">
            All {{data.type}}<span *ngIf="data.type != 'dataviz'">s</span>
        </a>
    </div>
</div>