import { Component } from '@angular/core';
import { ITrendingTopicsArchive } from 'src/app/models/pages.interface';
import { BasePageComponent } from '../base-page.component';
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { paths } from 'src/app/models/paths';

@Component({
  selector: 'app-trending-topics-archive',
  templateUrl: './trending-topics-archive.component.html',
  styleUrls: ['./trending-topics-archive.component.scss']
})
export class TrendingTopicsArchiveComponent extends BasePageComponent<ITrendingTopicsArchive> {

  typeNews : string;
  routeArchive: string = paths.trendingTopicsArchive;
  route : string = paths.trendingTopicsArticle;

  constructor(
    public aRoute: ActivatedRoute 
    ){
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    
    this.dataServices.pageDataReady.subscribe(() => {
      console.log("TrendingTopicsArchiveComponent", this.data);      
      this.loadPage();
      console.log(this.data);
    });
   
  }

  async loadPage(){
    this.typeNews = this.aRoute.snapshot.params.id;
    const typeNews = this.aRoute.snapshot.params.id == 'dataviz' ? 'tableau' : this.aRoute.snapshot.params.id;

    this.data.news = await this.api.insights.getTrendingTopicsArchive( typeNews);
    
  }

}
