<section [ngClass]="{'accordion-list center-vertical': true, 'accordion-list--with-sidenavbar': isSidenavbarActive,
                     'accordion-list--no-padding-bottom': noPaddingBottom, 'accordion-list--short-padding-top': shortPaddingTop,
                     'accordion-list--no-minimum-height': noPaddingBottom || shortPaddingTop}"
         class="{{data.theme || 'theme--lighter'}}"
         spyTarget="{{data.anchorId}}" data-aos="fade-up">
    <app-video-embedder *ngIf="marketingCookieEnabled" [isOpenEvt]="openVideoSubject.asObservable()"></app-video-embedder>

    <div class="section-max-content">
        <ng-container *ngIf="data.title && !data.hideTitle">
            <p class="accordion-list--title text--primary neutra2_bold" *ngIf="data.theme != 'theme--light'">
                {{data.title}}
            </p>

            <h1 class="accordion-list--title text--accent neutra2_book text--center" style="font-weight: 100;" *ngIf="data.theme == 'theme--light'">
                {{data.title}}
            </h1>
        </ng-container>

        <div class="accordion-list--item" data-aos="fade-up" *ngFor="let item of data.list; let i = index">
            <ng-container *ngIf="item.title && item.text">
                <h1 class="accordion-list--item--decoration-wrap decoration-color--{{highlightColor}}">
                    <div [ngClass]="{'accordion-list--item--decoration': true,
                                    'decoration-unselected': somethingIsOpen && !item.isOpen,
                                    'decoration-selected': somethingIsOpen && item.isOpen}"></div>
                </h1>

                <div class="accordion-list--item-container">
                    <h1 class="accordion-list--item--title neutra2_demi prom-list text--{{highlightColor}} interactive" (click)="toggleContentOpen(item, i)">{{item.title}}</h1>

                    <div [ngClass]="{'accordion-list--item--content': true, 'hidden': !item.isOpen, 'open': item.isOpen}">
                        <div class="item-text neutra2_book inner-html" [innerHtml]="item.text | safe:'html'"></div>

                        <div class="other">
                            <div class="other--video" *ngIf="(item.video?.id || item.video?.link) && marketingCookieEnabled">
                                <img class="video-icon interactive" src="../../../assets/icons-svg/video-player-player.svg" (click)="openVideo(item.video)" />
                                <p class="prom-contact___lato text--grey video-label">{{ 'WATCH_VIDEO' | translate }}</p>
                            </div>
                            
                            <app-prometeia-button class="other--btn" [data]="item.button" [useLightgreyShadow]="data.theme == 'theme--light'" *ngIf="item.button"></app-prometeia-button>

                            <ng-container *ngIf="item.mail">
                                <p class="text--primary prom-contact___lato contact-us--label">{{'MORE_INFO' | translate}}</p>
                                <a class="text--primary prom-contact no-underline contact-us--email" href="mailto:{{item.mail}}">{{item.mail}}</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <p class="extra-link" *ngIf="data?.buttons && data?.buttons[0]" data-aos="fade-up">
            <a class="text--{{highlightColor}} extra-link--link" *ngIf="data.buttons[0].route && !data.buttons[0].id" [routerLink]="['/', translate.currentLang, data.buttons[0].route]">{{data.buttons[0].label}}</a>
            <a class="text--{{highlightColor}} extra-link--link" *ngIf="!data.buttons[0].route && data.buttons[0].id" [routerLink]="['/', translate.currentLang, 'customRoute', data.buttons[0].id]">{{data.buttons[0].label}}</a>
            <a class="text--{{highlightColor}} extra-link--link" *ngIf="data.buttons[0].route && data.buttons[0].id" [routerLink]="['/', translate.currentLang, data.buttons[0].route, data.buttons[0].id]">{{data.buttons[0].label}}</a>
        </p>
    </div>
</section>
