<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">

    <div class="partnership">

        <!-- dynamic sections: -->
        <ng-container *ngFor="let s of data.sections; let isFirst = first; let i = index">
            <app-section-network *ngIf="s.type == 'network'" [data]="s" [topExtraSpace]="isFirst"></app-section-network>

            <section data-aos="fade-up" [ngClass]="{'section half-section': true, 'first-half': checkHalf(i) == 1, 'second-half': checkHalf(i) == 2}" *ngIf="s.type == 'half-section'">

                <h1 data-aos="fade-up" class="neutra2_book text--primary section_h1">{{s.title}}</h1>

                <div data-aos="fade-up" [ngClass]="{'section--carousel': true}" *ngIf="s.carousel">
                    <app-horizontal-scroll-animation [data]="s.carousel"></app-horizontal-scroll-animation>
                </div>

                <!--<p data-aos="fade-up" class="prom-caption___neutra-book text--grey text">{{s.text}}</p> -->
            </section>

            <app-section-general *ngIf="s.type != 'network' && s.type != 'half-section'" [data]="s" [opt]="{titleColor: 'primary'}"></app-section-general>

            <app-section-collapsable-list [data]="s" [highlightColor]="highlightColor" [noPaddingBottom]="checkIfNoPaddingBottom(i)" [shortPaddingTop]="checkIfShortPaddingTop(i)"></app-section-collapsable-list>
        </ng-container>

    </div>
</ng-container>
