import { Injectable } from '@angular/core';
import Amplify, { Auth, Hub, Logger } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { I18n } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { Locale } from '../models/commons';
export type AwsExportsType = 'alumni' | 'ar';

const logger = new Logger('Event-Amplify');





@Injectable({  
  providedIn: 'root'
})
export class AmplifyAuthService {

  status = {
    islogged : false,
    token : null
  }

  languageIT = {
    'Back to Sign In' : 'Indietro',
    'Forgot your password?' : 'Hai dimenticato la password?',
    'Send Code' : 'Invia codice',
    'New password' : 'Nuova password',
    'Enter your new password' : 'Inserisci la nuova password',
    'Submit' : 'Invia',
    'Verification code' : 'Codice di verifica ricevuto via email',
    'Enter code' : 'Inserisci il codice ricevuto',
    'Change Password' : 'Cambio password',
    'Change' : 'Invia'
  }

  private poolARStatusSource = new BehaviorSubject(this.status);
  poolARStatus = this.poolARStatusSource.asObservable();

  private poolAlumniStatusSource = new BehaviorSubject(this.status);
  poolAlumniStatus = this.poolAlumniStatusSource.asObservable();

  private poolConfigStatusDefault: any = environment.CONFIG_COGNITO_ALUMNI;
  private poolConfigStatusSource = new BehaviorSubject(this.poolConfigStatusDefault);
  poolConfigStatus = this.poolConfigStatusSource.asObservable();

  listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
            this.setState(data.payload?.data?.pool?.userPoolId, data.payload?.event,  data.payload?.data?.signInUserSession?.accessToken?.jwtToken);
            logger.info('user signed in');
            break;
        case 'signUp':
            logger.info('user signed up');
            break;
        case 'signOut':
            this.setState(data.payload?.data?.pool?.userPoolId, data.payload?.event);
            logger.info('user signed out');
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':

            logger.info('the Auth module is configured');
    }
    console.log('event service amplify', data.payload);
  }

  constructor(public translate: TranslateService) {
    I18n.putVocabulariesForLanguage('it', this.languageIT);
    I18n.setLanguage(this.translate.currentLang?.toLowerCase() || Locale.it);
    Hub.listen('auth', this.listener);
    this.translate.onLangChange.subscribe(() => {console.log('reload language', this.translate.currentLang.toLowerCase()); I18n.setLanguage(this.translate.currentLang.toLowerCase())});
  }

  private getFileConfig(pool: AwsExportsType){
    return (pool == 'alumni' && environment.CONFIG_COGNITO_ALUMNI) ||  (pool == 'ar' &&  environment.CONFIG_COGNITO_AR) || null; 
  }

  async setState(userPoolId, status, token?){
    let pool = this.getPool(userPoolId); 
    if(pool == 'alumni'){
      if(status == 'signIn'){
        const status  = {
          islogged : true,
          token : token
        }
        this.poolAlumniStatusSource.next( status );
      }
      else if(status == 'signOut'){
        const status  = {
          islogged : false,
          token : null
        }
        this.poolAlumniStatusSource.next( status );
      }
    }
    else if(pool == 'ar'){
      if(status == 'signIn'){
        const status  = {
          islogged : true,
          token : token
        }
        this.poolARStatusSource.next( status );
      }
      else if(status == 'signOut'){
        const status  = {
          islogged : false,
          token : null
        }
        this.poolARStatusSource.next( status );
      }
    } 
  }

  private getPool(userPoolId) : AwsExportsType{
    if( environment.CONFIG_COGNITO_ALUMNI.aws_user_pools_id == userPoolId) return 'alumni';
    else if(  environment.CONFIG_COGNITO_AR.aws_user_pools_id == userPoolId ) return 'ar';
    else null;
  }

  public config(pool) {      
    let configPoll = this.getFileConfig(pool);
    if(configPoll && this.poolConfigStatusDefault !== configPoll ) {
      Amplify.configure(configPoll);
      this.poolConfigStatusDefault = configPoll;
      this.poolConfigStatusSource.next( configPoll );
    }
    // console.log('change pool', pool);
  }

  async isLoggedIn(pool : AwsExportsType){
    try{
      
      this.config(pool);
      
      let islogged = await Auth.currentAuthenticatedUser().then(() => { return true; }).catch(() => {  return false; });
      
      return islogged;
    } 
    catch (error) {
      console.log('error isLoggedIn: ', error);
      return false;
    }    
  }

  async logout(pool: AwsExportsType){  
    try {
      this.config(pool);
      return await Auth.signOut();
    } 
    catch (error) {
        console.log('error signing out: ', error);
        return false;
    }
  }

  async signUp( params: any, pool: AwsExportsType ) {
    this.config(pool);
    try {
        const { user } = await Auth.signUp(params);
        return {
          success: true,
          result: user
        } 
    } catch (error) {
        console.log('error signing up:', error);
        return {
          success: false,
          result: error
        };
    }
  }

  async getToken(pool: AwsExportsType){
    this.config(pool);
    return (await (await Auth.currentSession()).getAccessToken()).getJwtToken();
  }

}
