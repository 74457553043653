<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>
<ng-container *ngIf="data">
    <app-side-navbar class="side-navbar desktop-only" [data]="data.sections" [bulletColor]="highlightColor" [forceShowOnLastSection]="true"></app-side-navbar>
    <app-long-arrow-down class="desktop-only" [color]="highlightColor"></app-long-arrow-down>

    <div class="careers">

        <!-- dynamic sections: -->
        <ng-container *ngFor="let s of data.sections; let i = index">
            <app-section-intro-video *ngIf="s.type == 'intro'" [data]="s" [highlightColor]="highlightColor"></app-section-intro-video>

            <app-section-multi-image *ngIf="s.type == 'multi-image'" [data]="s" [opt]="{'titleColor': 'blue', 'highlightColor': highlightColor}"></app-section-multi-image>
            <app-section-general *ngIf="!s.type || s.type == 'generic'" [data]="s" [opt]="{'titleColor': highlightColor, 'highlightColor': highlightColor}"></app-section-general>
            <app-section-wide-cards-list *ngIf="s.type == 'special' && s.subType == 'cards'" [data]="s" [opt]="{'titleColor': 'white', 'highlightColor': highlightColor}"></app-section-wide-cards-list>
            <app-section-grid-cards *ngIf="s.type == 'special' && s.subType == 'grid'" [data]="s" [opt]="{'titleColor': 'white', 'highlightColor': highlightColor}"></app-section-grid-cards>

            <app-section-fullsize-carousel *ngIf="s.type == 'special' && s.subType == 'carousel'" [data]="s" [opt]="{'highlightColor': highlightColor}"></app-section-fullsize-carousel>

            <app-section-contacts *ngIf="s.type == 'contacts-people' && s.people" [data]="s"></app-section-contacts>

            <section *ngIf="s.type == 'last'" spyTarget="last" class="section center-vertical section--last">
                <div class="container">
                    <!-- LEFT -->
                    <div class="lc lc--background-color--white">
                        <div class="lc--sub lc--sub2">
                            <img class="career-app-logo" src="{{s.list[0].contentUrl}}" />
                        
                            <div class="lc--sub2--text">
                                <p class="text--primary prom-contact lc--sub--title title1 neutra2_bold">{{s.list[0].title.split('\n')[0]}}</p>
                                <p class="text--primary prom-contact lc--sub--title title2 neutra2_bold">{{s.list[0].title.split('\n')[1]}}</p>
                                <p class="text--primary lc--sub--text">{{s.list[0].text}}</p>
                            </div>

                            <div class="badges atlante">
                                <a class="badge--wrap--appstore" target="_blank" href="https://apps.apple.com/it/app/prometeia-atlante/id1458111287">
                                    <input class="badge--appstore" type="image" src="{{ 'BADGE.APP_STORE' | translate }}" />
                                </a>
                                <a class="badge--wrap--googleplay" target="_blank" href="https://play.google.com/store/apps/details?id=it.prometeia.atlante&hl={{translate.currentLang}}">
                                    <input class="badge--googleplay" type="image" src="{{ 'BADGE.GOOGLE_PLAY' | translate }}" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- CENTER -->
                    <div class="lc lc--background-color--{{highlightColor || 'default'}}">
                        <div class="lc--sub lc--sub2">
                            <img class="career-app-logo" src="{{s.list[1].contentUrl}}" />
                        
                            <div class="lc--sub2--text">
                                <p class="text--primary prom-contact lc--sub--title title1 neutra2_bold">{{s.list[1].title.split('\n')[0]}}</p>
                                <p class="text--primary prom-contact lc--sub--title title2 neutra2_bold">{{s.list[1].title.split('\n')[1]}}</p>
                                <p class="text--primary lc--sub--text">{{s.list[1].text}}</p>
                            </div>

                            <div class="badges career">
                                <a class="badge--wrap--appstore" target="_blank" href="https://apps.apple.com/it/app/prometeia-career/id1458111294">
                                    <input class="badge--appstore" type="image" src="{{ 'BADGE.APP_STORE' | translate }}" />
                                </a>
                                <a class="badge--wrap--googleplay" target="_blank" href="https://play.google.com/store/apps/details?id=it.prometeia.career&hl={{translate.currentLang}}">
                                    <input class="badge--googleplay" type="image" src="{{ 'BADGE.GOOGLE_PLAY' | translate }}" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- RIGHT -->
                    <div class="rc">
                        <div class="rc--sub">
                            <img class="prom-next-logo" src="{{s.list[2].contentUrl}}" />
                        </div>

                        <div class="rc--sub">
                            <p class="text--primary prom-contact title neutra2_bold">{{s.list[2].title}}</p>
                        </div>

                        <div class="rc--sub">
                            <!-- <p class="spacer"></p> -->
                            <app-prometeia-button class="subscribe-button interactive" [routerLink]="['/', translate.currentLang, 'newsletter']" [fragment]="s.list[3].id" routerLinkActive="active" [data]="s.list[3]"></app-prometeia-button>
                            <!-- <p class="spacer"></p> -->
                        </div>
                    </div>
                </div>
            </section>
        </ng-container>

    </div>
</ng-container>
