import { Component, Input } from '@angular/core';
import { HighlightColor } from '../../models/commons';
import { ICollapsableList } from '../../models/components.interface';

@Component({
  selector: 'app-collapsable-list',
  templateUrl: './collapsable-list.component.html',
  styleUrls: ['./collapsable-list.component.scss']
})
export class CollapsableList {
  
  @Input() data: ICollapsableList;
  @Input() highlightColor: HighlightColor = 'accent';
  @Input() keepImagesSize: boolean;
  @Input() forceHideTitle: boolean;

}
