import { Component, Input } from '@angular/core';
import { IIntro, HighlightColor } from 'src/app/models/commons';

@Component({
  selector: 'app-section-intro-video',
  templateUrl: './section-intro-video.component.html',
  styleUrls: ['./section-intro-video.component.scss']
})
export class SectionIntroVideoComponent {

  @Input() data : IIntro;
  @Input() highlightColor: HighlightColor = 'accent';

}

