<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>

<ng-container *ngIf="data">
    <section class="anteo prom-page">
        <p class="prom-caption___lato-regular text--primary capslock anteo--newsletter">NEWSLETTER</p>
        <h1 class="prom-list neutra2_demi text--red anteo--title">{{data.pageTitle}} {{data.issueNumber}}</h1>
        <p class="prom-caption___lato-regular text--primary anteo--date" *ngIf="data.pubDate">{{capitolize(data.pubDate | date:"MMMM yyyy" : '' : translate.currentLang)}}</p>
        <p class="prom-caption___lato-regular text--primary anteo--date" *ngIf="!data.pubDate && data.date">{{data.date}}</p>

        <div class="anteo--download-box">
            <!-- TODO: se esiste "attachment" (è una stringa) usare una config di default -->
            <app-prometeia-button class="anteo--download-box--download" [data]="data.sections[0].buttons[0]" *ngIf="!data.attachment"></app-prometeia-button>
            <app-prometeia-button class="anteo--download-box--download" [data]="dowloadButton" *ngIf="data.attachment"></app-prometeia-button>

            <a class="prom-caption___lato-regular text--primary interactive anteo--download-box--archive" [routerLink]="['/', translate.currentLang, articleArchive]">
                Edizioni passate
            </a>
        </div>

        <p class="anteo--this-issue">In questo numero:</p>
        <hr>

        <div class="anteo--news">
            <ng-container *ngFor="let n of data.sections; let isLast = last">
                <ng-container *ngIf="!isLast || (isLast && !n.buttons)">
                    <p class="prom-caption___lato-regular text--grey anteo--news--author" *ngIf="!n.author?.includes(';')">{{n.author}}</p>
                    <p class="prom-caption___lato-regular text--grey anteo--news--author" *ngIf="n.author?.includes(';')">
                        <span *ngFor="let a of n.author.split(';'); let isFirst = first">
                            <br *ngIf="!isFirst">
                            {{a}}
                        </span>
                    </p>
                    <p class="anteo--news--title title--main bold">{{n.title}}</p>
                    <p class="anteo--news--title title--sub bold" *ngIf="n.subtitle">{{n.subtitle}}</p>

                    <a *ngIf="n.id" class="prom-caption___lato-regular text--primary anteo--news--more" [routerLink]="['/', translate.currentLang, articleRoute, n.id]">{{'READ_MORE' | translate}}</a>

                    <hr>
                </ng-container>

                <div *ngIf="isLast && n.buttons && n.buttons.length">
                    <br><br>
                    <h2 class="neutra2_bold text--red">{{n.title}}</h2>
                    <ul>
                        <li *ngFor="let button of n.buttons">
                            <a class="neutra2_bold text--red link" *ngIf="button.type == 'link' && button.link" href="{{button.link}}" target="_blank">{{button.label}}</a>
                        </li>
                    </ul>
                </div>

            </ng-container>
        </div>


    </section>

    <section class="last center-vertical">
        <h2 class="prom-list neutra2_book">Resta informato con la nostra newsletter <span class="bold">Anteo</span></h2>

        <app-prometeia-button class="subscribe-button interactive" [routerLink]="['/', translate.currentLang, 'newsletter']" fragment="anteo" routerLinkActive="active" [data]="{
                                    type: 'button',
                                    label: '',
                                    id: 'anteo',
                                    theme: 'btn_color__white',
                                    customBehaviour: 'subscribe'
                                }"></app-prometeia-button>
    </section>
</ng-container>
