<section *ngIf="!(data.type == 'intro' || data.type == 'last') && 
    (
        data.type == 'generic' || data.type == 'generic-left' || data.type == 'documents-list' || 
        data.text || data.multi_text || data.carousel || data.buttons?.length > 0
    )"
    spyTarget="{{data.anchorId}}" class="{{data.theme || 'theme--lighter'}} section center-vertical">

    <app-video-embedder *ngIf="marketingCookieEnabled" [isOpenEvt]="openVideoSubject.asObservable()"></app-video-embedder>

    <div class="section--image-filter" *ngIf="backImage"></div>
    <div class="section--BGI" [ngStyle]="{'background-image': 'url('+backImage+')' }" *ngIf="backImage"></div>

    <div [ngClass]="{'section-max-content flexible': true, 'single-pic--full-size': data.mediaList?.length == 1 && data.mediaList[0]._meta?.hasFullSize}">
        <div class="col column1">
            <h1 data-aos="fade-up" class="section_h1 neutra2_{{sectionOptions.titleWeight || 'bold'}} text--{{sectionOptions.titleColor || 'accent'}}">
                <ng-container *ngIf="!data.title.endsWith('∀')">{{ data.title }}</ng-container>
                <ng-container *ngIf="data.title.endsWith('∀')">{{ data.title.slice(0, -1) }}<span class="text--yellow">∀</span></ng-container>
            </h1>

            <p data-aos="fade-up" class="section--text" *ngIf="data.text && !data.text.includes('</')">{{data.text}}</p>
            <div data-aos="fade-up" class="inner-html section--text" *ngIf="data.text && data.text.includes('</')" [innerHtml]="data.text"></div>

            <div data-aos="fade-up" class="section--buttons-container two-columns--other--btn" *ngIf="data.buttons?.length > 0">
                <ng-container *ngFor="let btn of data.buttons">
                    <app-prometeia-button   *ngIf="btn.type == 'button'" 
                                            class="sections--button two-columns--other--btn--button" [data]="btn"></app-prometeia-button>
    
                    <!-- <a  data-aos="fade-up" class="text--primary prom-caption___lato-italic two-columns--other--link"
                        *ngIf="!btn.type || btn.type == 'link'" style="display: block"
                        [routerLink]="['/', translate.currentLang, btn.route.split('#')[0] ]" [fragment]="btn.route.split('#')[1]" routerLinkActive="active">
                        {{btn.label}}
                    </a> -->
                </ng-container>
            </div>
        </div>

        <div [ngClass]="{'column2': true, 'pic-list': data.mediaList?.length}">
            <ng-container *ngIf="data.media?.type == 'VID' && (data.media?.contentUrl?.endsWith('.mp4') || data.media?.previewUrl?.endsWith('.mp4') || data.media?.contentUrl?.endsWith('.ogg') || data.media?.previewUrl?.endsWith('.ogg'))">
                <video width="100%" controls data-aos="fade-up">
                    <source [src]="data.media.contentUrl" type="video/mp4" *ngIf="data.media?.contentUrl?.endsWith('.mp4')">
                    <source [src]="data.media.previewUrl" type="video/mp4" *ngIf="data.media?.previewUrl?.endsWith('.mp4')">
                    <source [src]="data.media.contentUrl" type="video/ogg" *ngIf="data.media?.contentUrl?.endsWith('.ogg')">
                    <source [src]="data.media.previewUrl" type="video/ogg" *ngIf="data.media?.previewUrl?.endsWith('.ogg')">
                    <source [src]="data.media.contentUrl" type="video/webm" *ngIf="data.media?.contentUrl?.endsWith('.webm')">
                    <source [src]="data.media.previewUrl" type="video/webm" *ngIf="data.media?.previewUrl?.endsWith('.webm')">
                    Your browser does not support HTML video.
                </video>
            </ng-container>

            <!-- <ng-container *ngIf="data.media?.type == 'VID' && data.media?.contentUrl && (!data.media?.contentUrl?.endsWith('.mp4') && !data.media?.contentUrl?.endsWith('.ogg'))">
                <iframe class="video-embedder--video" [src]="data.media?.contentUrl | safe: 'resourceUrl'" frameborder="0" allowfullscreen width="100%" data-aos="fade-up">
                </iframe>
            </ng-container> -->

            <ng-container *ngIf="data.media && data.media.type == 'VID' && data.media.contentUrl && !data.media.id && !(data.media.contentUrl?.endsWith('.mp4') || data.media.previewUrl?.endsWith('.mp4') || data.media.contentUrl?.endsWith('.ogg') || data.media.previewUrl?.endsWith('.ogg'))">
                <iframe [src]="data.media.contentUrl | safe: 'resourceUrl'" frameborder="0" allowfullscreen width="680" height="400" data-aos="fade-up"></iframe>
            </ng-container>

            <ng-container *ngIf="data.media &&
                                data.media?.type == 'VID' &&
                                data.media?.id &&
                                data.media?.previewUrl">
                <div class="wrap-icon-video interactive" (click)="openVideo(data.media)" *ngIf="marketingCookieEnabled">
                    <img style="max-width: 100%;" [src]="data.media.previewUrl" [alt]="data.media.alt" data-aos="fade-up">
                </div>

                <div class="wrap-icon-video interactive" *ngIf="!marketingCookieEnabled">
                    <a href="https://www.youtube.com/watch?v={{data.media.id}}" target="_blank">
                        <img style="max-width: 100%;" [src]="data.media.previewUrl" [alt]="data.media.alt" data-aos="fade-up">
                    </a>
                </div>
            </ng-container>

            <div class="pic-list--list">
                <ng-container *ngFor="let pic of data.mediaList">
                    <img [ngClass]="{'double-width': pic._meta?.hasDoubleWidth, 'full-size': pic._meta?.hasFullSize}" [src]="pic.contentUrl" [alt]="pic.id" *ngIf="pic.type == 'IMG'" data-aos="fade-up" matTooltip="{{pic.text}}" matTooltipClass="tooltip tooltip--blue">
                </ng-container>
            </div>

            <div class="some-numbers">
                <ng-container *ngFor="let x of data.some_numbers">
                    <div class="some-numbers--item" data-aos="fade-up">
                        <p class="neutra2_bold text--blue number">{{x.number}}</p>
                        <p class="neutra2_demi text--yellow capslock label" [innerHtml]="x.title"></p>
                        <!-- <app-highlighted-text class="highlight" [text]="x.title" [highlighter]="sectionOptions.highlightColor"></app-highlighted-text> -->
                    </div>
                </ng-container>
            </div>

            <!-- <div data-aos="fade-up" class="section--buttons-container two-columns--other--btn mobile-only" *ngIf="data.buttons?.length > 0">
                <ng-container *ngFor="let btn of data.buttons">
                    <app-prometeia-button   *ngIf="btn.type == 'button'" 
                                            class="sections--button two-columns--other--btn--button" [data]="btn"></app-prometeia-button>
                </ng-container>
            </div> -->
        </div>
    </div>
</section>
