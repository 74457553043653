import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { paths } from 'src/app/models/paths';

@Component({
  selector: 'app-single-slider-cards',
  templateUrl: './single-slider-cards.component.html',
  styleUrls: ['./single-slider-cards.component.scss']
})
export class SingleSliderCardsComponent implements OnInit {

  @Input() card: {
    type?: string,

    id? : number,
    category? : string,
    title? : string,
    subtitle? : string,
    heroImage? : string,
    pubDate? : string,
    typeNews? : string,
    hidden? : boolean,
    app_code : string,
    slug? : string,
    permalink? : string
  };
  
  @Output('eventCloseCard') eventCloseCard: EventEmitter<any> = new EventEmitter();
  
  category: string;
  route : string = paths.trendingTopicsArticle;
  newsRoute : string = paths.newsArticle;
  offerRoute : string = paths.offer;
 

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    this.category = this.card?.category.replace(/_/g, " ").toUpperCase();
  }

  getRoute() {    
    if(this.card?.app_code == 'ATL')
      return this.route;
    else if(this.card?.app_code == 'WWW')
      return this.newsRoute;
    else if(this.card?.app_code == 'CAR')
      return this.offerRoute;  
  }

  getID() {
    if (this.card?.app_code == 'ATL' || this.card?.app_code == 'WWW')
      return this.card?.slug;
    else if(this.card?.app_code == 'CAR')
      return this.card?.id;
  }

  getTheme(): string {
    const type = this.card?.category.replace(/ /g, "_").toUpperCase();
    return type ? "theme--" + type : "";
  }
}
