<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">

    <div [ngClass]="{'social-responsibility mobile-only': true,
                     'browser--safari': browserType == 'safari',
                     'browser--on-iphone': browserType == 'iphone'}">
        <div class="prom-caption___neutra-bold text--primary capslock back-link"
            (click)="closeElement()" *ngIf="isIntroHidden()">
            <{{ 'BACK' | translate }}
        </div>

        <section
            [ngClass]="{'social-responsibility--categories category-intro': true, 'category-intro--hidden': isIntroHidden()}">
            <div class="section--BGI" [ngStyle]="{'background-image': 'url('+getCoverImg()+')'}"
                *ngIf="getCoverImg() != null && !isIntroHidden()"></div>

            <div [ngClass]="{'intro': true, 'hidden': isIntroHidden()}">
                <div class="intro--banner">
                    <h1 [ngClass]="{'neutra2_demi intro--title text--on-dark': true}">
                        {{data.sections[0].title}}
                    </h1>

                    <p class="neutra2_bookItalic text--on-dark intro--text" *ngIf="data.sections[0].text">
                        {{data.sections[0].text}}
                    </p>

                    <div class="intro--links" *ngFor="let link of data.sections[0].buttons">
                        <a class="prom-caption___lato-italic text--on-dark" *ngIf="link.link"
                            href="{{link.link}}">
                            <span class="icon-bottoni_download" *ngIf="link._meta?.isDownloadable"></span>
                            {{link.label}}
                        </a>
                        <a class="prom-caption___lato-italic text--on-dark" *ngIf="link.route && !link.id"
                            [routerLink]="['/', translate.currentLang, link.route]">
                            <span class="icon-bottoni_download" *ngIf="link._meta?.isDownloadable"></span>
                            {{link.label}}
                        </a>
                        <a class="prom-caption___lato-italic text--on-dark" *ngIf="link.route && link.id"
                            [routerLink]="['/', translate.currentLang, link.route, link.id]">
                            <span class="icon-bottoni_download" *ngIf="link._meta?.isDownloadable"></span>
                            {{link.label}}
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section
            [ngClass]="{'social-responsibility--data': true, 'category--data--showing': isIntroHidden(), 'category-intro': !isIntroHidden()}">
            <ng-container *ngFor="let s of data.sections; let isFirst = first; let i = index">
                <h1 class="prom-list neutra2_bold text--primary section-title"
                    *ngIf="isIntroHidden() && isElementSelected(i)">{{s.title}}</h1>

                <div class="element--content" *ngIf="isElementSelected(i, s)">
                    <div [ngClass]="{'item': true, 'item--inverted': isLast}"
                        *ngFor="let item of s.mediaList; let isLast = last">
                        <h1 data-aos="fade-up" class="prom-list neutra2_bold text--primary item--title"
                            *ngIf="item.title">/ {{item.title}}</h1>

                        <div class="item--text-container">
                            <p data-aos="fade-up" class="text--primary text" *ngIf="item.text">{{item.text}}</p>
                            <ng-container *ngIf="!item.route">
                                <img [ngClass]="{'image--side-text': item.text, 'image--no-text': !item.text, 'image--no-title': item.text && !item.title}"
                                    data-aos="fade-up" src="{{item.contentUrl}}" alt="{{item.alt}}"
                                    *ngIf="!item.previewUrl">

                                <img [ngClass]="{'image--side-text': item.text, 'image--no-text': !item.text, 'image--no-title': item.text && !item.title}"
                                    data-aos="fade-up" src="{{item.previewUrl}}" alt="{{item.alt}}"
                                    *ngIf="item.previewUrl">
                            </ng-container>
                            <a [routerLink]="['/', translate.currentLang, item.route]" *ngIf="item.route">
                                <img [ngClass]="{'image--side-text': item.text, 'image--no-text': !item.text, 'image--no-title': item.text && !item.title}"
                                    data-aos="fade-up" src="{{item.contentUrl}}" alt="{{item.alt}}">
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </section>

        <section
            [ngClass]="{'social-responsibility--categories': true, 'category-intro': !isIntroHidden(), 'category-intro--hidden': isIntroHidden()}">
            <ng-container *ngFor="let s of data.sections; let isFirst = first; let i = index">
                <div class="section--BGI under" [ngStyle]="{'background-image': 'url('+getSelectedImg()+')'}"
                    *ngIf="getSelectedImg() != null && getSelectedImg() != getCoverImg() && !isFirst"></div>

                <div [ngClass]="{'element': true, 'selected': isElementSelected(i, s)}" *ngIf="!isFirst">
                    <div class="section--BGI" [ngStyle]="{'background-image': 'url('+getBgImg(i)+')'}"
                        *ngIf="getBgImg(i)"></div>
                    <div class="element--banner interactive" (click)="toggleElement(i)">
                        <h1 [ngClass]="{'neutra2_bold element--title': true,
                                'text--primary': isElementSelected(i, s),
                                'text--on-dark': !isElementSelected(i, s)}">
                            {{s.title}}
                        </h1>
                    </div>
                </div>
            </ng-container>
        </section>
    </div>

    <div [ngClass]="{'social-responsibility desktop-only': true, 'browser--safari': browserType == 'safari'}"
        [ngStyle]=" !isIntroHidden() && {'overflow': 'hidden'}">
        <!-- <div class="section--BGI under" [ngStyle]="{'background-image': 'url('+data.sections[getSelectedElement()].backImage+')', 'opacity': opacityMainBGI}"
             *ngIf="data.sections[getSelectedElement()].backImage"></div> -->
        <img class="main-bgi" [src]="getSelectedImg()" [ngStyle]="isIntroHidden() && {'opacity': opacityMainBGI}">

        <section [ngClass]="{'social-responsibility--categories': true, 'category-intro': !isIntroHidden()}">
            <ng-container *ngFor="let s of data.sections; let isFirst = first; let i = index">
                <!-- <div class="section--BGI under" [ngStyle]="{'background-image': 'url('+data.sections[getSelectedElement()].backImage+')', 'opacity': opacityMainBGI}"
                         *ngIf="data.sections[getSelectedElement()].backImage && isFirst"></div> -->

                <div [ngClass]="{'element': true, 'selected': isElementSelected(i, s), 'close-section-animation': isApplyAnimationActive}"
                    *ngIf="!isFirst" (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave()">

                    <div [ngClass]="{'image-wrap': true, 'smaller': isIntroHidden()}">
                        <!-- <div class="section--BGI above"
                             [ngStyle]="{'background-image': 'url('+data.sections[i].backImage+')', 'transform': getTransformForBGI(i) + 'scale(' + getTransformScale(i) + ')'}"
                             *ngIf="data.sections[i].backImage">
                        </div> -->
                        <img [ngClass]="{'section--image': true}" [src]="getBgImg(i)" *ngIf="getBgImg(i)"
                            [ngStyle]="{'transform': 'scale(' + getTransformScale(i) + ')'}">
                    </div>

                    <div class="element--banner interactive" (click)="toggleElement(i)">
                        <h1 [ngClass]="{'neutra2_bold element--title': true,
                                        'text--primary': isElementSelected(i, s),
                                        'text--on-dark': !isElementSelected(i, s)}">
                            {{s.title}}
                        </h1>
                    </div>
                    <div class="element--content" *ngIf="isElementSelected(i, s)">
                        <div [ngClass]="{'item': true, 'item--inverted': item._meta?.inverted, 'item--only-text': !item.title && !item.contentUrl}"
                            *ngFor="let item of s.mediaList; let j = index; let isLast = last">
                            <h1 data-aos="fade-up" class="prom-list neutra2_bold text--primary item--title"
                                *ngIf="item.title">/ {{item.title}}</h1>

                            <div class="item--text-container">
                                <p data-aos="fade-up" class="text--primary text" *ngIf="item.text">{{item.text}}</p>
                                <ng-container *ngIf="!item.route && browserType != 'safari'">
                                    <img [ngClass]="{'image--side-text': item.text, 'image--no-text': !item.text, 'image--no-title': item.text && !item.title}"
                                        data-aos="fade-up" src="{{item.contentUrl}}" alt="{{item.alt}}">
                                </ng-container>
                                <div *ngIf="!item.route && browserType == 'safari'"
                                     [ngClass]="{'safari-box--image--side-text': item.text, 'safari-box--image--no-text': !item.text, 'safari-box--image--no-title': item.text && !item.title}">
                                    <img [ngClass]="{'image--side-text': item.text, 'image--no-text': !item.text, 'image--no-title': item.text && !item.title}"
                                        data-aos="fade-up" src="{{item.contentUrl}}" alt="{{item.alt}}">
                                </div>
                                <a class="image-link" [routerLink]="['/', translate.currentLang, item.route]"
                                    *ngIf="item.route" (mouseenter)="swapImageLink(i, j)"
                                    (mouseleave)="swapImageLink(i, j)">
                                    <img [ngClass]="{'image--side-text': item.text, 'image--no-text': !item.text, 'image--no-title': item.text && !item.title}"
                                        data-aos="fade-up" src="{{item.contentUrl}}" alt="{{item.alt}}">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div [ngClass]="{'intro': true, 'hidden': isIntroHidden()}">
                <!-- <div class="section--BGI under" [ngStyle]="{'background-image': 'url('+data.sections[0].backImage+')'}" *ngIf="data.sections[0].backImage && !isIntroHidden()"></div> -->
                <div class="intro--banner">
                    <h1 [ngClass]="{'neutra2_demi intro--title text--on-dark': true}">{{data.sections[0].title}}</h1>

                    <p class="neutra2_bookItalic text--on-dark intro--text" *ngIf="data.sections[0].text">
                        {{data.sections[0].text}}</p>

                    <div class="intro--links" *ngFor="let link of data.sections[0].buttons">
                        <a class="prom-caption___lato-italic text--on-dark" *ngIf="link.link" href="{{link.link}}">
                            <span class="icon-bottoni_download" *ngIf="link._meta?.isDownloadable"></span>
                            {{link.label}}
                        </a>
                        <a class="prom-caption___lato-italic text--on-dark" *ngIf="link.route && !link.id"
                            [routerLink]="['/', translate.currentLang, link.route]">
                            <span class="icon-bottoni_download" *ngIf="link._meta?.isDownloadable"></span>
                            {{link.label}}
                        </a>
                        <a class="prom-caption___lato-italic text--on-dark" *ngIf="link.route && link.id"
                            [routerLink]="['/', translate.currentLang, link.route, link.id]">
                            <span class="icon-bottoni_download" *ngIf="link._meta?.isDownloadable"></span>
                            {{link.label}}
                        </a>
                    </div>
                </div>
            </div>

        </section>
        <div
            style="position: absolute; top: 0; left: 0; width: 100%; height: 110px; background-color: white; z-index: 10;">
        </div>
    </div>

</ng-container>