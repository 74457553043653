import { Component, OnInit } from '@angular/core';
import { IReservedArea } from 'src/app/models/pages.interface';
import { BasePageComponent } from '../base-page.component';
import { ApiArService } from '../../services/api-ar.service'
import { IARAttachment, IARDocumentFolder } from 'src/app/models/components.interface';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { paths } from 'src/app/models/paths';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AmplifyAuthService } from 'src/app/services/amplify-auth.service';
import { ArDialogAuthComponent } from 'src/app/components/ar-dialog-auth/ar-dialog-auth.component';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentsServices } from 'src/app/services/components.service';

@Component({
  selector: 'app-reserved-area',
  templateUrl: './reserved-area.component.html',
  styleUrls: ['./reserved-area.component.scss']
})
export class ReservedAreaComponent extends BasePageComponent<IReservedArea> {
  public loading = true;
  private library: any; // IARDocumentFolder;
  public currentFolder: IARDocumentFolder;
  public breadcrumbs: IARDocumentFolder[] = [];
  private attachmentsCache = new Map<string, any>();
  route_ar: string = paths.reservedArea;
  private isLoggedAR : boolean;
  TOKEN_AR : string = 'prom-ar-token';

  constructor(
    private apiArService : ApiArService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private dialog: MatDialog,
    private amplifyService: AmplifyAuthService,
    private cookieService: CookieService,
    private _snackBar: MatSnackBar,
    public componentsServices: ComponentsServices,
    ) {
    super();
  }

  
  
  protected async loadPageData() {
    if(this.data) {      
      return;
    }
    else {
      return super.loadPageData();
    }
  } 

  ngOnInit(): void {
    super.ngOnInit();
    this.getLibrary().then(() =>
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.currentFolder = this.library;
      this.breadcrumbs = [];

      for (const folder of (params.get('folder') || '').split('#')) {
        const currentFolder = this.currentFolder?.children?.find(c => c._id == folder);
        if (currentFolder) {
          this.currentFolder = currentFolder;
          this.breadcrumbs.push(currentFolder);
        }
      }
      if(this.currentFolder && this.currentFolder._id){
        // console.log('attachments',this.currentFolder);
        this.currentFolder.attachments = await this.updateAttachments(this.currentFolder._id);
        // console.log(this.currentFolder);
      }
      
    }));  
  }

  async getLibrary() {
    this.loading = true;
    try {
      this.library = await this.apiArService.libraries.get('ar');
    }
    catch (ex) {
      console.error("Si è verificato un errore durante il caricamento dei documenti", ex);
      if(ex.status == '401'){
        this.componentsServices.setARnotLogged(true);
        this.openDialoglogin();
        this.cookieService.delete(this.TOKEN_AR);
      }
      else{
        this.openSnackBar(this.translate.instant('Attenzione, si è verificato un errore durante il caricamento dei dati'));
      }
      
    }
    this.loading = false;
  }

  async updateAttachments(folderId: string) {
    if(!this.attachmentsCache.has(folderId)) {
      this.loading = true;
      try {
        let attachments = await this.apiArService.libraries.getContent(folderId);
        this.attachmentsCache.set(folderId, attachments);
        return attachments;
      }
      catch(ex) {
        console.log("Si è verificato un errore durante il caricamento dei documenti", ex);
        if(ex.status == '401'){
          this.componentsServices.setARnotLogged(true);
          this.openDialoglogin();
          this.cookieService.delete(this.TOKEN_AR);
        }
        else{
          this.openSnackBar(this.translate.instant('Attenzione, si è verificato un errore durante il caricamento dei dati'));
        }
        
      }
      this.loading = false;
    }
    else{
      return this.attachmentsCache.get(folderId);
    }
  }

  getExtention(fileName){
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }

  async logout() {    
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant("LOGOUT"), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      if(result){
        this.cookieService.delete('prom-ar-token');
        await this.apiArService.libraries.logout();
        this.router.navigate(['/', this.translate.currentLang, 'home']); 
      }        
    });
  }

  async openDialoglogin() {    
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px'
    });
    dialogRef.afterClosed().subscribe( async () => { 
      this.isLoggedAR = await this.amplifyService.isLoggedIn('ar');
      if(this.isLoggedAR){ this.loginAR(); } 
    });
  }

  async loginAR(){
    try{
      const tokenCognito = await this.amplifyService.getToken('ar');
      const login = await this.apiArService.libraries.login(tokenCognito); 
      if(login){
        window.location.reload();
      }      
    }
    catch(error){
      this.openSnackBar(this.translate.instant('Attenzione, Account non attivo'));
    }
    finally {
      await this.amplifyService.logout('ar');
   } 
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

 
}
