import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { IHome } from '../../models/pages.interface';
import globalJSON from '../../../assets/json/global.json';
import { paths } from 'src/app/models/paths';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CookieService } from 'src/app/services/cookie.service';
import { BasePageComponent } from '../base-page.component';
import { INews, ITrendingTopicsList } from 'src/app/models/components.interface';
import { Subscription } from 'rxjs';
import { slugs } from 'src/app/models/slugs';
import { Debounce } from 'angular-debounce-throttle';
import { IBaseSection } from 'src/app/models/commons';

export interface SimplifiedMenuType {
  code?: string,
  level1: { selected: boolean, item: { id: string, key: string, label: string, href: string, submenu: any[] } },
  level2: { selected: boolean, item: { id: string, key: string, label: string, href: string, submenu: any[] } },
  menu: any
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BasePageComponent<IHome> {
  public pageClass = '';
  images = globalJSON["images"];
  windowHeight: number;
  public selectedMenu1: null;

  superPinned: INews = null;

  backgroundImage_selected: string = "none";
  backgroundImage_isBlank: boolean = true;
  animation = {
    fadeUp: '',
    fadeDown: ''
  };
  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  configHome: SwiperConfigInterface = {
    direction: 'vertical',
    slidesPerView: 1,
    speed: 500,
    mousewheel: true,
    scrollbar: false,
    keyboard: true,
    pagination: {
      el: '.swiper-pagination-home',
      clickable: true,
    },
    breakpoints: {
      800: {
        allowTouchMove: true,
        touchAngle: 60
      }
    }
  };

  configHome__touchOn: SwiperConfigInterface = {
    direction: 'vertical',
    slidesPerView: 1,
    speed: 500,
    mousewheel: true,
    scrollbar: false,
    keyboard: true,
    pagination: {
      el: '.swiper-pagination-home',
      clickable: true,
    },
    breakpoints: {
      800: {
        allowTouchMove: true,
        touchAngle: 60
      }
    }
  };

  configHome__touchOff: SwiperConfigInterface = {
    direction: 'vertical',
    slidesPerView: 1,
    speed: 500,
    mousewheel: true,
    scrollbar: false,
    keyboard: true,
    pagination: {
      el: '.swiper-pagination-home',
      clickable: true,
    },
    breakpoints: {
      800: {
        allowTouchMove: false,
        touchAngle: 0
      }
    }
  };

  menuItems = [
    {
      key: 'career', tagline: 'Proactive Thinkers.', selected: false, 'label': /* 'CAREER' */ 'PEOPLE', 'submenu': [
        // { 'label': 'OUR CULTURE', 'size': 40, 'submenu': [], 'href': paths.ourCulture },
        { 'label': 'CAREERS', 'size': 40, 'submenu': [], 'href': paths.careers },
        { 'label': 'OPEN POSITIONS', 'size': 45, 'submenu': [], 'href': paths.jobOffer },
        { 'label': 'ALUMNI', 'size': 35, 'submenu': [], 'href': paths.alumni },
      ],
      backgroundImage: globalJSON["home_and_menu"].cdn + globalJSON["home_and_menu"].career
    },
    {
      key: 'whoweare', tagline: 'Proficient Innovators.', selected: false, 'label': 'WHO WE ARE', 'submenu': [
        { 'label': 'ABOUT US', 'size': 28, 'submenu': [], 'href': paths.aboutUs },
        { 'label': 'SOCIAL RESPONSIBILITY', 'size': 54, 'submenu': [], 'href': paths.socialResponsibility },
        { 'label': 'OUR NETWORK', 'size': 38, 'submenu': [], 'href': paths.ourNetwork },
      ],
      backgroundImage: globalJSON["home_and_menu"].cdn + globalJSON["home_and_menu"].who_we_are
    },
    {
      key: 'ourexpertise', tagline: 'Proud Souls.', selected: false, 'label': 'OUR EXPERTISE', 'submenu': [
        {
          'label': 'ADVISORY', 'submenu': [
            { 'label': 'Risk, Planning & Control', 'submenu': [], 'href': paths.riskPlanningControl },
            { 'label': 'Wealth Management & Financial Distribution', 'submenu': [], 'href': paths.financialDistribution },
            { 'label': 'Strategic Advisory & Corporate Finance', 'submenu': [], 'href': paths.strategicAdvisory },
            { 'label': 'Financial Advisory for Institutional Investors', 'submenu': [], 'href': paths.financialAdvisory },
            { 'label': 'ESG & Climate', 'submenu': [], 'href': paths.esgClimate },
          ]
        },
        // { 'label': 'TECH SOLUTIONS', 'submenu': [], 'href': paths.riskTechAndWealthTech },
        { 'label': 'TECH SOLUTIONS', 'submenu': [
          { 'label': 'SaaS & Cloud', 'submenu': [], 'href': paths.saasCloud },
          { 'label': 'Applied Data Science & Advanced Analytics', 'submenu': [], 'href': paths.appliedDataScienceAndAdvancedAnalytics },
          { 'label': 'RiskTech', 'submenu': [], 'href': paths.riskTech },
          { 'label': 'WealthTech', 'submenu': [], 'href': paths.wealthTech },
        ] },
        {
          'label': 'R&D', 'submenu': [
            { 'label': 'Research, BI & Reporting', 'submenu': [], 'href': paths.research_bi_reporting },
            { 'label': 'Data Science', 'submenu': [], 'href': paths.dataScience },
          ]
        },
        {
          'label': 'INSIGHTS', 'submenu': [
            { 'label': 'Trending Topics', 'submenu': [], 'href': paths.trendingTopics },
            { 'label': 'News', 'submenu': [], 'href': paths.news },
            { 'label': 'Newsletter', 'submenu': [], 'href': paths.newsletter },
          ]
        }
      ],
      backgroundImage: globalJSON["home_and_menu"].cdn + globalJSON["home_and_menu"].our_expertise
    }
  ];

  news : ITrendingTopicsList[] = [];
  events: INews[] = [];

  logo: string = globalJSON["images"].cdn + globalJSON["images"].logo;
  isBlueTheme: boolean = false;
  forceBlueTheme: boolean = true;
  isHomeMobile:boolean;
  subResize:Subscription = null;
  translateSubscription:Subscription = null;
 
  constructor(private cookieService: CookieService) {
    super();
  }

  ngOnInit(): void {
    // console.log(globalJSON["test"]);
    super.ngOnInit();
    this.translateSubscription?.unsubscribe();
    this.translateSubscription = this.translate.onLangChange.subscribe(() => {
      this.loadHome();
    });

    this.isHomeMobile = !this.componentsServices.isDesktopMode();
    this.subResize?.unsubscribe();
    this.subResize = this.componentsServices.pageResizeAll.subscribe( () => {
      if (this.slug == slugs.home) {
        if(this.isHomeMobile && this.componentsServices.isDesktopMode()){ 
          this.router.navigate(['/'],{replaceUrl:true}); 
          this.isHomeMobile = false; 
        }
        if(!this.isHomeMobile && !this.componentsServices.isDesktopMode()){ 
          this.router.navigate(['/'],{replaceUrl:true}); 
          this.isHomeMobile = true; 
        }
      }
    });
    
    this.loadHome();

   

    // this.headerDataService.setTitleAndDescription("Prometeia");
  }

  private loadHome() {
    if (this.slug == slugs.home) {
      this.getNews();
      this.getSuperPinned();
      this.getSaveTheDate();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.translateSubscription?.unsubscribe();
    this.translateSubscription = null;
    if (this.subResize) {
      this.subResize.unsubscribe();
      this.subResize = null;
    }
  }

  async getNews(){
    try{
      this.news = await this.api.home.getNewsList()
    }
    catch(error){
      console.error('Home news - error get news', error);
    }
  }

  async getSuperPinned() {
    try{
      const receivedSuperPinned = await this.api.home.getSuperPinned();

      if (receivedSuperPinned) {
        this.superPinned = receivedSuperPinned;
      }

      // this.superPinned = { //test
      //   title: "Prometeia and Basinghall Analytics partner to provide comprehensive modeling solutions",
      //   categoryTheme: "GREEN",
      //   slug: "Prometeia-and-Basinghall-Analytics-partner-to-provide-comprehensive-modeling-solutions"
      // }
    }
    catch(error){
      console.error('Home super pinned - error get super pinned', error);
    }
  }

  saveTheDateAlreadyRequested: string = null;
  @Debounce(100)
  async getSaveTheDate() {
    try {
      if ((!this.events || this.events?.length <= 0) && !this.saveTheDateAlreadyRequested) {
        this.saveTheDateAlreadyRequested = this.translate.currentLang;

        const receivedEvents = await this.api.home.getSaveTheDate();
        // console.log("save the date", receivedEvents);
  
        if (receivedEvents && receivedEvents.length > 0) {
          this.events = receivedEvents;
        }
      } else if (this.saveTheDateAlreadyRequested != this.translate.currentLang) {
        this.saveTheDateAlreadyRequested = this.translate.currentLang;

        const receivedEvents = await this.api.home.getSaveTheDate();
        // console.log("save the date", receivedEvents);

        this.events = null;

        setTimeout(() => {
          if (receivedEvents && receivedEvents.length > 0) {
            this.events = receivedEvents;
          }
        }, 100);
  
      }/*  else {
        console.log(this.events, this.saveTheDateAlreadyRequested);
      } */
    }
    catch(error){
      console.error('Home save the date - error get news', error);
    }
  }

  nextSlide(): void {
    this.componentRef.directiveRef.setIndex(1);
  }

  public selectItem(item) {
    const tmp = [];
    for (const mi of this.menuItems) {
      if (item.selected) {
        // Deselezione elemento selezionato
        mi.selected = false;
      } else {
        if (mi.key == item.key) {
          mi.selected = true;
        } else {
          mi.selected = false;
        }
      }
      tmp.push(mi);
    }
    this.menuItems = tmp;
  }

  private deselectAll() {
    const tmp = [];
    for (const mi of this.menuItems) {
      mi.selected = false;
      tmp.push(mi);
    }
    this.menuItems = tmp;
  }


  public menuItemSelected(menu: SimplifiedMenuType) {
    /*
    level1:{
      item: {id: 'generato', key: '', label: "CAREER", href: null, submenu: Array(3), id: "mi_75989", arcStart: -240, …}
      selected: true
    },
    level2:{
      item: {label: "OUR CULTURE", href: null, submenu: Array(0), id: "mi2_5646", arcStart: -319, …}
      selected: true
    }
    menu: array ricevuto
    */

    if (menu.level1.selected) {
      if (menu.level1.item) {
        this.pageClass = 'menu_' + menu.level1.item.key;

        this.menuItems.forEach(element => {
          if (element.key == menu.level1.item.key) {
            this.backgroundImage_selected = 'url(' + element.backgroundImage + ')';
            this.backgroundImage_isBlank = false;
            // console.log(">>>", this.backgroundImage_selected, this.backgroundImage_isBlank);
          }
        });
      }
    } else {
      this.pageClass = 'no_selection';

      this.backgroundImage_selected = "none";
      this.backgroundImage_isBlank = false;
      // console.log(">>>", this.backgroundImage_selected, this.backgroundImage_isBlank);
    }

  }

  timeoutAnimation: any;
  public onIndexChange(index: number): void {
    if (index == 1) {

      this.timeoutAnimation = setTimeout(() => {
        this.animation.fadeUp = "animation__fade_up";
        this.animation.fadeDown = "";
        this.isBlueTheme = true;
      }, 100);

      this.componentsServices.emulateScrollBottom();
    }
    else {
      clearInterval(this.timeoutAnimation);
      this.animation.fadeUp = "";
      this.animation.fadeDown = "animation__fade_up_fast";
      this.isBlueTheme = false;

      this.componentsServices.emulateScrollTop();
    }
  }

  showSpecialMessage(): boolean {
    if (!this.superPinned || this.superPinned == null) return false;

    const session = this.cookieService.loadSpecialMessageSessionCookie();
    // console.log(session, session != null);
    if (session != null) return false;

    // console.log("showSpecialMessage");
    return true;
  }



  onClick() {
    if (!this.componentsServices.isDesktopMode()) return;

    // console.log("The container was clicked");

    this.pageClass = 'no_selection';
    this.backgroundImage_selected = "none";
    this.backgroundImage_isBlank = false;
    this.deselectAll();
    return;
  }

  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();
  onMenuClick(event) {
    // console.log("The button was clicked");
    event.stopPropagation();
    this.click.emit(event);
  }

  onMenuHover($event) {
    // console.log("+menu:", "mouse enter");
    this.configHome = this.configHome__touchOff;
  }

  onMenuLeave($event) {
    // console.log("menu:", "mouse exit");
    this.configHome = this.configHome__touchOn;
  }



  index = 0;
  paginationMock: IBaseSection[] = [
    {title: "menu", theme: null}, 
    {title: "news", theme: null}
  ];
  goToIndex(index: number) {
    this.index = index;
  }


  swipeUp(event) {
    // console.log("U", event);
    this.goToIndex(1);
  }

  swipeDown(event) {
    // console.log("D", event);
    this.goToIndex(0);
  }

}
