import { Component, Input } from '@angular/core';
import { HighlightColor, IMedia } from '../../models/commons';

@Component({
  selector: 'app-zoomable-circular-image',
  templateUrl: './zoomable-circular-image.component.html',
  styleUrls: ['./zoomable-circular-image.component.scss']
})
export class ZoomableCircularImageComponent {

  @Input() data: IMedia;
  @Input() highlightColor: HighlightColor;

  constructor() { }

}
