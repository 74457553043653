import { Component, Input } from '@angular/core';
import { paths } from 'src/app/models/paths';
import { HighlightColor, IButtonLink, ISectionNetwork } from '../../models/commons';

@Component({
  selector: 'app-section-network',
  templateUrl: './section-network.component.html',
  styleUrls: ['./section-network.component.scss']
})
export class SectionNetworkComponent {

  @Input() data: ISectionNetwork;
  @Input() highlightColor: HighlightColor = 'accent';
  @Input() topExtraSpace: boolean = false;

  changeButtonData(buttonData: IButtonLink): IButtonLink {
    if (buttonData.route == paths.ourPartnership && (buttonData.label.toLowerCase() == "area riservata" || buttonData.label.toLowerCase() == "reserved area")) {
      buttonData.route = null;
      buttonData._meta = { reservedArea: true };
      // console.log(buttonData);
    }
    return buttonData;
  }

}
