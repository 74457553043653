<section class="feedback-page">
    <div class="feedback-page--header" style="display: flex; justify-content: space-between;">
        <h2 class="neutra2_book text--primary">{{ 'ARCHETYPES_TEST.TITLE' | translate }}</h2>
        <button class="not-printable transparent-button" *ngIf="showResult" (click)="print()">
            <mat-icon class="icon">print</mat-icon>
        </button>
    </div>
    <p *ngIf="!showResult">
        <i>{{ 'ARCHETYPES_TEST.DESCRIPTION' | translate }}</i>
        <br>
        1. = {{ 'ARCHETYPES_TEST.ANSWER_VALUE_1' | translate }} /
        2. = {{ 'ARCHETYPES_TEST.ANSWER_VALUE_2' | translate }} /
        3. = {{ 'ARCHETYPES_TEST.ANSWER_VALUE_3' | translate }} /
        4. = {{ 'ARCHETYPES_TEST.ANSWER_VALUE_4' | translate }} /
        5. = {{ 'ARCHETYPES_TEST.ANSWER_VALUE_5' | translate }}
    </p>

    <!-- <div class="TEST-ONLY" *ngIf="!isProduction() && !showResult" style="display: flex;">
        <button (click)="setValues('medium')" style="margin-left: 30px; margin-right: 30px;">valori medi</button>

        <button (click)="setValues('random')" style="margin-left: 30px; margin-right: 30px;">valori casuali</button>

        <button (click)="setValues()" style="margin-left: 30px; margin-right: 30px;">svuota risposte</button>
    </div> -->

    <form autocomplete="off" novalidate #imeForm="ngForm"
        [ngClass]="{'imeForm-form': true, 'submitted': imeForm.submitted}" (ngSubmit)="submit(imeForm)"
        *ngIf="!showResult">
        <!-- <div style="text-align: center;" *ngIf="!isProduction()">
            <button type="submit" style="margin-top: 12px; margin-right: 12px;">Calcola</button>
        </div> -->

        <div class="form-group" *ngFor="let item of ime; let i = index">
            <label [ngClass]="{'label': true, 'invalid-field': !item.answer}" for="item--{{i}}">
                <p>{{i+1}}. {{item.question | translate}}</p>
            </label>

            <!-- to really activate ticks, tickInterval must have a number (eg: 1), not "true" -->
            <mat-slider class="slider" min="1" max="5" step="1"
                thumbLabel="true" tickInterval="true" name="item--{{i}}" [(ngModel)]="item.answer" required>
            </mat-slider>

            <p class="description">
                {{item.answer ? item.answer + ". " : ""}}{{item.answer ? ('ARCHETYPES_TEST.ANSWER_VALUE_' + item.answer | translate) : ('ARCHETYPES_TEST.ANSWER_VALUE_UNDEFINED' | translate)}}
            </p>
        </div>

        <div class="calc-button">
            <button class="interactive transparent-button" type="submit">
                <app-prometeia-button class="buttonLoadMore--btn interactive" [data]="buttonCalc">
                </app-prometeia-button>
            </button>
        </div>
    </form>

    <div *ngIf="showResult">
        <!-- <p *ngFor="let a of archetypes">{{a.label}}: {{a.value}}</p> -->

        <app-chart [labels]="archetypesLabels" [values]="archetypesValues" *ngIf="archetypesValues?.length"></app-chart>

        <p class="top-archetypes" *ngIf="topArchetypes.length">
            <ng-container *ngIf="topArchetypes.length == 1">
                {{ 'ARCHETYPES_TEST.TOP_ARCHETYPES__ONE' | translate }}
            </ng-container>
            <ng-container *ngIf="topArchetypes.length > 1">
                {{ 'ARCHETYPES_TEST.TOP_ARCHETYPES__TWO_OR_MORE' | translate }}
            </ng-container>
            <ng-container *ngFor="let a of topArchetypes; let isLast = last">
                <span class="text--primary bold">{{a.label}}</span> ({{a.value}})<ng-container *ngIf="!isLast">,
                </ng-container>
                <ng-container *ngIf="isLast">.</ng-container>
            </ng-container>
        </p>
    </div>
</section>