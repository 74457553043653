<ng-container *ngIf="isBannerVisible"><div class="block-all"></div></ng-container>

<div class="privacy-and-cookies {{bannerOpenStateClass}}" *ngIf="isBannerVisible">
    <div class="privacy-and-cookies--wrapper">
        <div class="privacy-and-cookies--main">
            <div class="privacy-and-cookies--title"><p class="prom-caption___lato-regular text--on-light">{{ 'COOKIES.TITLE' | translate }}</p></div>
            <div class="privacy-and-cookies--text">
                <p class="prom-caption___lato-regular text--on-light">
                    {{ 'COOKIES.TEXT' | translate }}
                    <a class="prom-caption___lato-regular text--on-light"
                       [routerLink]="['/', translate.currentLang, 'article', 'cookie']" target="_blank">
                       link
                    </a>.
                </p>
            </div>
        </div>
        
        
        <div class="privacy-and-cookies--buttons">
            <button class="privacy-and-cookies--buttons--preferences capslock interactive" (click)="preferences()">{{ 'COOKIES.SETTINGS' | translate }}</button>
            
            <button class="privacy-and-cookies--buttons--accept capslock interactive" (click)="accept()">{{ 'COOKIES.ACCEPT' | translate }}</button>

            <div class="privacy-and-cookies--buttons--necessary-cookies-only">
                <a class="prom-caption___lato-regular text--on-light neutra2_bookItalic interactive underline" (click)="accept(true)">
                    {{ 'COOKIES.CONTINUE_WITH_MIN' | translate }}
                </a>
            </div>
        </div>

        <!-- <div class="privacy-and-cookies--links">
            <a class="text--accent privacy-and-cookies--links--purposes-link" href="#">Finalità</a>
            <p>|</p>
            <a class="text--accent privacy-and-cookies--links--partners-link" href="#">Partner</a>
            <p>|</p>
            <a class="text--accent privacy-and-cookies--links--legitimate-link" href="#">Legittimo interesse</a>
        </div> -->
    </div>
</div>

<div class="pac-overlay" *ngIf="isDetailVisible || cookieService.forceReopenCookieModal"></div>
<div class="pac-detail" *ngIf="isDetailVisible || cookieService.forceReopenCookieModal">
    <div class="pac-detail--wrapper">
        <div class="close-icon" (click)="closeDetail()">
            <img class="close-icon--image interactive" src="../../../assets/icons-svg/svg-icon-X-chiusura_scura.svg">
        </div>
        
        <div class="top">
            <img class="logo" src="{{logo_scuro}}">
            <h3 class="neutra2_demi capslock text--grey title capslock">{{ 'COOKIES.BANNER_TITLE' | translate }}</h3>
            <!-- <div class="close interactive" (click)="closeDetail()">
                <img class="icon-box-chiudi_x interactive" src="../../../assets/icons-svg/svg-icon-X-chiusura_scura.svg">
            </div> -->
        </div>

        <div class="cookies-list">
            <div class="cookies-list--item">
                <p class="text--accent bold">{{ 'COOKIES.NECESSARY_COOKIES_TITLE' | translate }}:</p>
                <p class="justified">
                    {{ 'COOKIES.NECESSARY_COOKIES_TEXT' | translate }}
                </p>
                <div class="control">
                    <p class="control--label interactive" (click)="toggleCookieDetailOpenState(0)">
                        <span class="icon--dropdown text--accent icon-freccia_freccina-giù-grigia" *ngIf="!isCookieDetailOpen(0)"></span>
                        <span class="icon--dropdown text--accent icon-freccia_freccina-su-grigia" *ngIf="isCookieDetailOpen(0)"></span>
                        {{ 'COOKIES.SHOW_REQUESTED' | translate }}
                    </p>
                </div>
                <div class="detail" *ngIf="isCookieDetailOpen(0)">
                    <!-- <p>Fornire accesso sicuro</p>
                    <p>Ricordare a che punto dell’ordine ci si trova</p> -->

                    <table class="table">
                        <thead>
                            <tr class="table--header">
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.COMPANY' | translate }}</p></th>
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.DOMAIN' | translate }}</p></th>
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.MORE_INFO' | translate }}</p></th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr class="table--row no-border-bottom">
                                <td class="table--row--cell cell"><p>Prometeia</p></td>
                                <td class="table--row--cell cell"><p>prometeia.it, prometeia.com</p></td>
                                <td class="table--row--cell cell"><a class="text--primary" [routerLink]="['/', translate.currentLang, 'article', 'cookie']" target="_blank">Cookie Policy</a></td>
                            </tr>
                            <tr class="table--row">
                                <td class="table--row--cell cell justified" colspan="3"><p>{{ 'COOKIES.PROMETEIA_DESCRIPTION' | translate }}</p></td>
                            </tr>
                        </tbody>
                        
                    </table>
                </div>
            </div>

            <div class="cookies-list--item">
                <p class="text--accent bold">{{ 'COOKIES.STAT_COOKIES_TITLE' | translate }}:</p>
                <p class="justified">
                    {{ 'COOKIES.STAT_COOKIES_TEXT' | translate }} 
                </p>
                <div class="control">
                    <p class="control--label interactive" (click)="toggleCookieDetailOpenState(1)">
                        <span class="icon--dropdown text--accent icon-freccia_freccina-giù-grigia" *ngIf="!isCookieDetailOpen(1)"></span>
                        <span class="icon--dropdown text--accent icon-freccia_freccina-su-grigia" *ngIf="isCookieDetailOpen(1)"></span>
                        {{ 'COOKIES.SHOW_REQUESTED' | translate }}
                    </p>
                    <div class="horizontal-spacer"></div>
                    <div [ngClass]="{'cookie-button control--button capslock': true, 'selected': isCookieAccepted(1) > 0}" (click)="setCookiePreference(1, true)">{{'YES' | translate}}</div>
                    <div [ngClass]="{'cookie-button control--button capslock': true, 'selected': isCookieAccepted(1) < 0}" (click)="setCookiePreference(1, false)">{{'NO' | translate}}</div>
                </div>
                <div class="detail" *ngIf="isCookieDetailOpen(1)">
                    <!-- <p>Ricordare i dati di accesso</p>
                    <p>Ricordare cosa c’è nel carrello</p>
                    <p>Assicurare che il sito abbia un aspetto coerente</p> -->

                    <table class="table">
                        <thead>
                            <tr class="table--header">
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.COMPANY' | translate }}</p></th>
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.DOMAIN' | translate }}</p></th>
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.MORE_INFO' | translate }}</p></th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr class="table--row no-border-bottom">
                                <td class="table--row--cell cell"><p>Google Inc.</p></td>
                                <td class="table--row--cell cell"><p>ajax.googleapis.com, google.com, www.google-analytics.com, www.google.com, www.google.ie, www.googletagmanager.com, www.gstatic.com</p></td>
                                <td class="table--row--cell cell">
                                    <a class="text--primary" href="https://policies.google.com/terms" target="_blank">Terms and Conditions</a>
                                    <a class="text--primary" href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a>
                                </td>
                            </tr>
                            <tr class="table--row">
                                <td class="table--row--cell cell justified" colspan="3"><p>Google Inc. is an American multinational technology company that specializes in Internet-related services and products, which includes a search engine, cloud computing, software, and hardware.</p></td>
                            </tr>

                            <tr class="table--row no-border-bottom">
                                <td class="table--row--cell cell">Tableau Software</td>
                                <td class="table--row--cell cell">public.tableau.com, tableau.com</td>
                                <td class="table--row--cell cell">
                                    <a class="text--primary" href="https://www.tableau.com/tos">Terms and Conditions</a>
                                    <a class="text--primary" href="https://www.tableau.com/privacy">Privacy Policy</a>
                                </td>
                            </tr>
                            <tr class="table--row">
                                <td class="table--row--cell cell justified" colspan="3"><p>Tableau Software provides easy-to-use software applications for fast analytics and visualization.</p></td>
                            </tr>

                            <tr class="table--row no-border-bottom">
                                <td class="table--row--cell cell">The jQuery Foundation</td>
                                <td class="table--row--cell cell">code.jquery.com</td>
                                <td class="table--row--cell cell">
                                    <a class="text--primary" href="https://www.linuxfoundation.org/cookies">Cookie Policy</a>
                                </td>
                            </tr>
                            <tr class="table--row">
                                <td class="table--row--cell cell justified" colspan="3"><p>The jQuery Foundation is a non-profit trade association dedicated to supporting development of the jQuery Core, jQuery UI, and jQuery Mobile projects; providing jQuery documentation and support; and fostering the jQuery community.</p></td>
                            </tr>

                            <tr class="table--row no-border-bottom">
                                <td class="table--row--cell cell">HubSpot</td>
                                <td class="table--row--cell cell">hubspot.com, app.hubspot.com</td>
                                <td class="table--row--cell cell">
                                    <a class="text--primary" href="https://legal.hubspot.com/cookie-policy">Cookie Policy</a>
                                </td>
                            </tr>
                            <tr class="table--row">
                                <td class="table--row--cell cell justified" colspan="3"><p>HubSpot is an American developer and marketer of software products for inbound marketing, sales, and customer service.</p></td>
                            </tr>
                        </tbody>
                        
                    </table>
                </div>
            </div>

            <div class="cookies-list--item">
                <p class="text--accent bold">{{ 'COOKIES.MARKETING_COOKIES_TITLE' | translate }}:</p>
                <p class="justified">
                    {{ 'COOKIES.MARKETING_COOKIES_TEXT' | translate }}
                </p> 
                <!-- <ul>
                    <li><p>IP anonimizzati</p></li>
                    <li><p>accettazione emendamento Google (Google Analytics Data Processing Amendment)</p></li>
                    <li><p>nessuna meta profilazione (remarketing, signals etc.)</p></li>
                </ul> -->
                <div class="control">
                    <p class="control--label interactive" (click)="toggleCookieDetailOpenState(2)">
                        <span class="icon--dropdown text--accent icon-freccia_freccina-giù-grigia" *ngIf="!isCookieDetailOpen(2)"></span>
                        <span class="icon--dropdown text--accent icon-freccia_freccina-su-grigia" *ngIf="isCookieDetailOpen(2)"></span>
                        {{ 'COOKIES.SHOW_REQUESTED' | translate }}
                    </p>
                    <div class="horizontal-spacer"></div>
                    <div [ngClass]="{'cookie-button control--button capslock': true, 'selected': isCookieAccepted(2) > 0}" (click)="setCookiePreference(2, true)">{{'YES' | translate}}</div>
                    <div [ngClass]="{'cookie-button control--button capslock': true, 'selected': isCookieAccepted(2) < 0}" (click)="setCookiePreference(2, false)">{{'NO' | translate}}</div>
                </div>
                <div class="detail" *ngIf="isCookieDetailOpen(2)">
                    <!-- <p>Ricordare i dati di accesso</p>
                    <p>Ricordare cosa c’è nel carrello</p>
                    <p>Assicurare che il sito abbia un aspetto coerente</p> -->

                    <table class="table">
                        <thead>
                            <tr class="table--header">
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.COMPANY' | translate }}</p></th>
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.DOMAIN' | translate }}</p></th>
                                <th class="table--header--cell cell"><p>{{ 'COOKIES.MORE_INFO' | translate }}</p></th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr class="table--row no-border-bottom">
                                <td class="table--row--cell cell"><p>Google Ads</p></td>
                                <td class="table--row--cell cell"><p>doubleclick.net, googleads.g.doubleclick.net, static.doubleclick.net, stats.g.doubleclick.net</p></td>
                                <td class="table--row--cell cell">
                                    <a class="text--primary" href="https://policies.google.com/terms">Terms and Conditions</a>
                                    <a class="text--primary" href="https://policies.google.com/privacy">Privacy Policy</a>
                                </td>
                            </tr>
                            <tr class="table--row">
                                <td class="table--row--cell cell justified" colspan="3"><p>Google Ads is an online advertising platform developed by Google, where advertisers pay to display brief advertisements, service offerings, product listings, video content and generate mobile application installs within the Google ad network to web users.</p></td>
                            </tr>

                            <tr class="table--row no-border-bottom">
                                <td class="table--row--cell cell"><p>Google Inc.</p></td>
                                <td class="table--row--cell cell"><p>s.ytimg.com, www.youtube.com, youtube.com</p></td>
                                <td class="table--row--cell cell">
                                    <a class="text--primary" href="https://policies.google.com/terms">Terms and Conditions</a>
                                    <a class="text--primary" href="https://policies.google.com/privacy">Privacy Policy</a>
                                </td>
                            </tr>
                            <tr class="table--row">
                                <td class="table--row--cell cell justified" colspan="3"><p>Google Inc. is an American multinational technology company that specializes in Internet-related services and products, which includes a search engine, cloud computing, software, and hardware.</p></td>
                            </tr>
                        </tbody>
                        
                    </table>
                </div>
            </div>
        </div>

        <div class="bottom">
            <div [ngClass]="{'update-preferences-button cookie-button': true}" *ngIf="allCookiesAreSetted()" (click)="send()">{{ 'COOKIES.SEND_PREF' | translate }}</div>
            <div [ngClass]="{'update-preferences-button cookie-button invalid': true}" *ngIf="!allCookiesAreSetted()">{{ 'COOKIES.SEND_PREF' | translate }}</div>
        </div>
    </div>
    <div class="bottom-margin"></div>
</div>
