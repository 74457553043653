<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>



<ng-container *ngIf="data">

    <div class="our-team prom-page">
        <h2 class="neutra2_demi text--primary our-team--page-title">{{data.pageTitle}}</h2>

        <div class="our-team--lists">
            <div class="role" *ngFor="let r of data.roles">
                <div *ngIf="r.type == 'chairman' || r.type == 'vice-chairmen'">
                    <h3 class="prom-caption___neutra-demi text--primary capslock our-team--lists--role-label main-roles">{{r.title}}</h3>
                    <hr>

                    <div class="our-team--lists--member" *ngFor="let m of r.members; first as isFirst">
                        <img [ngClass]="{'member-pic round-image': true, 'top-pic': isFirst}" src="{{m.contentUrl || '/cdn/default-user.png'}}">
                        <h3 class="neutra2_demi text--accent member-name">{{m.title}}</h3>
                        <div class="separator desktop-only"></div>

                        <p *ngIf="!m.downloadable" class="member-description prom-caption___lato-regular">
                            {{m.text | shorten: m._meta.maxLength: ' ...': false}}
                            <a *ngIf="!m.downloadable && m.text?.length >= m._meta.maxLength" class="prom-caption___lato-regular text--primary underline interactive" (click)="readMore(m)">{{'READ_MORE' | translate}}</a>
                        </p>

                        <p *ngIf="m.downloadable" class="member-description prom-caption___lato-regular">{{m.text}}</p>
                    </div>
                </div>

                <div *ngIf="r.type != 'chairman' && r.type != 'vice-chairmen'">
                    <h3 class="prom-caption___neutra-demi text--primary capslock our-team--lists--role-label other-roles">{{r.title}}</h3>
                    <span [ngClass]="{'icon-freccia_freccina-giù-grigia icona-dropdown interactive': true, 'opened-dropdown': r.isOpened}" (click)="onRoleClick(r)"></span>
                    <hr>

                    <div [ngClass]="{'our-team--lists--member': true, 'hidden-dropdown-content': !r.isOpened}" *ngFor="let m of r.members; first as isFirst">
                        <img [ngClass]="{'member-pic round-image': true, 'top-pic': isFirst}" src="{{m.contentUrl || '/cdn/default-user.png'}}">
                        <h3 class="neutra2_demi text--accent member-name">{{m.title}}</h3>
                        <div class="separator desktop-only"></div>

                        <p *ngIf="!m.downloadable" class="member-description prom-caption___lato-regular">
                            {{m.text | shorten: m._meta.maxLength: ' ...': false}}
                            <a *ngIf="!m.downloadable && m.text?.length >= m._meta.maxLength" class="prom-caption___lato-regular text--primary underline interactive" (click)="readMore(m)">{{'READ_MORE' | translate}}</a>
                        </p>

                        <p *ngIf="m.downloadable" class="member-description prom-caption___lato-regular">{{m.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



</ng-container>
