<ng-container *ngIf="!data && loadDate">
    <app-not-found-data *ngIf="notFound || (data && !data.newsArticle)"></app-not-found-data>
</ng-container>


<div class="news-article prom-page" *ngIf="data && data.newsArticle">
    <div class="theme--{{data.newsArticle.categoryTheme}}">
        <ng-container>
            <div class="news-article--title-bg">
                <div class="news-article--title-bg--content news-article--title-wrapper">
                    <h2 class="neutra2_demi prom-list news-article--title">{{data.newsArticle.title}}</h2>
                </div>

                <div class="news-article--title-bg--content news-article--image-wrapper">
                    <div class="details">
                        <hr>

                        <div class="details--content">
                            <p class="prom-caption___lato-regular details--content--category capslock">{{beautifyCategory(data.newsArticle.category)}}</p>
                            <p class="prom-caption___lato-regular details--content--date">{{data.newsArticle.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}</p>
                        </div>
                    </div>

                    <img class="round-image news-article--image" src="{{data.newsArticle.heroImage}}" aria-hidden="true" alt="{{data.newsArticle.heroImage}}" *ngIf="data.newsArticle.heroImage">
                </div>
            </div>

            <p class="prom-caption___neutra-book news-article--author" *ngIf="data.newsArticle.author">{{data.newsArticle.author}}</p>

            <div class="text--on-light news-article--text inner-html inner-html--news" [innerHtml]="data.newsArticle.body | safe:'html'"></div>

            <div class="news-article--download-button" *ngIf="data.newsArticle.attachment?.length > 0">
                <app-prometeia-button class="interactive news-article--download-button--btn" [data]="dowloadButton"></app-prometeia-button>
            </div>
        </ng-container>
    </div>


    <div class="bottomline">
        <div class="bottomline--socials">
            <app-social-share *ngFor="let soc of socials_list" [data]="soc"></app-social-share>
        </div>

        <div class="bottomline--arrow interactive" (click)="scrollToTop()">
            <span class="icon icon-freccia-scroll-up_freccia-su text--primary"></span>
        </div>
    </div>
</div>

<div class="no-content" *ngIf="data && !data.newsArticle && loadDate">
    <p class="no-content-warning text--primary neutra2_bold">{{ 'ARTICLE_NOT_AVAIBLE' | translate }}</p>
</div>
