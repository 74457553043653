import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMedia } from 'src/app/models/commons';
import { IAnteoArchive } from 'src/app/models/pages.interface';
import { paths, separator } from 'src/app/models/paths';
import { ApiService } from 'src/app/services/api.service';
import { SortingServices } from 'src/app/services/sorting.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  @Input() data: IMedia;
  @Input() slug: string;
  /**
     data has:
     "_meta": {
        "edition": string,
        "past": {
          "title": string,
          "route": string //(a route)
        }
      }
   */
  /*
   "type": "IMG",
    "contentUrl": "/cdn/tmp/malmo.png",
    "title": "Malmö 2019",
    "text": "I mercati della sfida della politica",
    "route": "information-paths",
    "_meta": {
        "edition": "15° edizione",
        "past": {
            "title": "Edizioni passate",
            "route": "information-paths-archive"
        }
    } 
   */

   constructor(public translate: TranslateService, private api: ApiService, private sort: SortingServices) {}

   ngOnInit() {
      if (this.slug) {
        this.loadOverview();
      }
   }

   protected async loadOverview() {
    try {
      let category = this.slug.replace(/financial-advisory_/g, "").replace(/-/g, "_");
      if (category.endsWith("s")) category = category.slice(0, -1);
      const archive: any[] = await this.api.archives.get(category);
      if (archive?.length <= 0) return;
      const sections = this.sort.byYear_infoPaths(archive)[0].sections;

      // console.log("TEST", sections);

      // console.log("richiedere", category + "/", " => ", archive);
      const overviewSlug = archive[0]?.slug?.split(separator)?.[1];
      const firstSection = sections[0];

      this.data = firstSection.media;
      const edition = firstSection.edition != null ? firstSection.edition : firstSection.date;
      this.data.title = edition.split("-")[1];
      if (this.data.title.startsWith(" ")) this.data.title = this.data.title.substring(1);
      this.data.text = firstSection.title;
      this.data._meta = {};
      this.data._meta.edition = edition.split("-")[0];
      this.data.route = paths.informationPaths;

      this.data.id = overviewSlug ?? "informazione" + this.data.title.split(" ")[1];
    }
    catch(ex) {
      console.error(ex);
    }
  }
}
