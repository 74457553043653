<div [ngClass]="['page', pageClass]">
    
    <app-sidebar-socials class="socialbar"></app-sidebar-socials>
    <div class="menu-tools-container clearfix">
        <app-menu-tools class="tools" [data]="{lock_isOpen: true}" [blueTheme]="isBlueTheme"></app-menu-tools>
    </div>
   
    <swiper #swiperHome class="swiper-container swiper-home home--desktop-only" [useSwiperClass]="true"
            [config]="configHome" [(index)]="index" (indexChange)="onIndexChange($event)">        
        <div class="swiper-slide" (click)="onClick()" (swipeup)="swipeUp($event)">
            <div class="home-menu--image-filter"></div>
            <div class="home-menu--BGI" [ngStyle]="{'background-image': backgroundImage_selected, 'opacity': backgroundImage_isBlank ? 0 : 1}"></div>
        
            <app-main-menu (menuItemSelected)="menuItemSelected($event)" [menu_items]="menuItems"
                           (click)="onMenuClick($event)" (mouseenter)="onMenuHover($event)" (mouseleave)="onMenuLeave($event)"
                           [logo_image]="this.images.cdn + images.logo_chiaro" class="{{animation.fadeDown}}">
            </app-main-menu>
    
            <app-long-arrow-down [color]="'accent'" [isInHome]="true" (click)="nextSlide()"></app-long-arrow-down>
            <div class="tagline desktop-only">
                <span *ngFor="let item of menuItems" [ngClass]="[item.key]" (click)="selectItem(item); onMenuClick($event)">{{item.tagline}}</span>
            </div>
            <div class="tagline mobile-only" *ngIf="!pageClass || pageClass == '' || pageClass == 'no_selection'">
                <span *ngFor="let item of menuItems" [ngClass]="[item.key]" (click)="selectItem(item); onMenuClick($event)">{{item.tagline}}</span>
            </div>
        </div>
        <div class="swiper-slide swiper-slide--news" (swipedown)="swipeDown($event)">
            <div class="slider-header">
                <img class="slider-header--logo {{animation.fadeUp}}" src="{{logo}}">
            </div>

            <app-slider-cards [animation]="animation.fadeUp" [news]="news" [events]="events" *ngIf="events?.length > 0"></app-slider-cards>
            <app-slider-cards [animation]="animation.fadeUp" [news]="news" *ngIf="!events || events.length <= 0"></app-slider-cards>
        </div> 
    </swiper>  

    <!-- <div class="swiper-pagination-home desktop-only"></div> -->
    <app-side-pagination-dots [ngClass]="{'side-paginations-dots-container desktop-only': true}"
                              [data]="paginationMock" [index]="index" (goToIndex)="goToIndex($event)"
                              [increasedRightMargin]="true">
    </app-side-pagination-dots>


    <div class="home mobile-only">
        <div class="home--menu">
            <div class="home-menu--image-filter"></div>
            <div class="home-menu--BGI" [ngStyle]="{'background-image': backgroundImage_selected, 'opacity': backgroundImage_isBlank ? 0 : 1}"></div>
        
            <app-main-menu (menuItemSelected)="menuItemSelected($event)" [menu_items]="menuItems"
            [logo_image]="this.images.cdn + images.logo_chiaro" class="{{animation.fadeDown}}"></app-main-menu>
    
            <app-long-arrow-down [color]="'accent'" [isInHome]="true" (click)="nextSlide()"></app-long-arrow-down>
            <div class="tagline desktop-only">
                <span *ngFor="let item of menuItems" [ngClass]="[item.key]" (click)="selectItem(item); onMenuClick($event)">{{item.tagline}}</span>
            </div>
            <div class="tagline mobile-only" *ngIf="!pageClass || pageClass == '' || pageClass == 'no_selection'">
                <span *ngFor="let item of menuItems" [ngClass]="[item.key]" (click)="selectItem(item); onMenuClick($event)">{{item.tagline}}</span>
            </div>
        </div>
        <div class="home--news">
            <div class="slider-header">
                <img class="slider-header--logo {{animation.fadeUp}}" src="{{logo}}">
                <div class="menu-tools-container clearfix">
                    <app-menu-tools class="tools" [data]="{lock_isOpen: true}" [blueTheme]="forceBlueTheme"></app-menu-tools>
                </div>
            </div>
            <app-slider-cards [animation]="animation.fadeUp" [news]="news" [events]="events"></app-slider-cards>
        </div>
    </div>

    
    <ng-container *ngIf="showSpecialMessage()">
        <app-special-message [data]="superPinned"></app-special-message>
    </ng-container>
</div>
