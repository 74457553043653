<section spyTarget="{{data.anchorId}}" class="theme--dark media-and-contacts center-vertical" *ngIf="data?.type == 'contacts-people' && data?.people">
    <!-- <h1 class="section_h1 inner-html" data-aos="fade-up" [innerHtml]="data.text">{{ data.title }}</h1> -->
    <div class="inner-html inner-html--on-dark inner-html--no-bold-default" style="width: 100%;" data-aos="fade-up" *ngIf="data.text">
        <h1 style="width: 5em;"><strong>{{data.text}}</strong></h1>
    </div>
    <div class="inner-html inner-html--on-dark inner-html--no-bold-default" style="width: 100%;" [innerHtml]="'SECTIONS.CONTACTS.TITLE' | translate" data-aos="fade-up" *ngIf="!data.text"></div>
    <div class="contacts-wrap">
        <div class="contacts">
            <app-business-card-v2 data-aos="fade-up" *ngFor="let p of data.people" [data]="p"></app-business-card-v2>
        </div>
    </div>
</section>
