import { EventEmitter, Injectable } from '@angular/core';
import { AlumniFormMode, DESKTOP_MIN_WIDTH, resizeEventResult } from '../models/commons';
import { simTree } from '../models/paths';
import { BrowserServices } from './browser.service';

@Injectable({
  providedIn: 'root',
})
export class ComponentsServices {
  pageScroll: EventEmitter<{ newY: number }> = new EventEmitter();
  pageScrollIsTop: EventEmitter<{ newY: number }> = new EventEmitter();
  pageScrollIsBottom: EventEmitter<{ newY: number }> = new EventEmitter();
  pageScrollUp: EventEmitter<{ newY: number }> = new EventEmitter();
  pageScrollDown: EventEmitter<{ newY: number }> = new EventEmitter();

  pageResizeAll: EventEmitter<resizeEventResult> = new EventEmitter();
  pageResizeInMobileMode: EventEmitter<resizeEventResult> = new EventEmitter();
  // pageResizeInTabletMode: EventEmitter<resizeEventResult> = new EventEmitter();
  pageResizeInDesktopMode: EventEmitter<resizeEventResult> = new EventEmitter();
  pageResizeVerticalOnly: EventEmitter<resizeEventResult> = new EventEmitter();
  pageResizeHorizontalOnly: EventEmitter<resizeEventResult> = new EventEmitter();

  newsletterCloseSubscribeForm: EventEmitter<boolean> = new EventEmitter();
  alumniRegisterOrLoginForm: EventEmitter<boolean> = new EventEmitter();
  alumniFormMode: AlumniFormMode = 'login';
  alumniFormModeChange: EventEmitter<AlumniFormMode> = new EventEmitter();
  isAlumniFormOpen: boolean = false;
  isAlumniFormPaused: boolean = false;

  isMenuOpenEvent: EventEmitter<boolean> = new EventEmitter();
  isMenuOpen: boolean = false;

  collapsableListItemEvent: EventEmitter<boolean> = new EventEmitter();

  updateIdEvent: EventEmitter<string> = new EventEmitter();
  updatePreviousRouteEvent: EventEmitter<string> = new EventEmitter();
  updateNewletterFormIdEvent: EventEmitter<string> = new EventEmitter();

  private ARnotLogged: boolean = false;

  constructor(private browserServices: BrowserServices) {}

  public isDesktopMode(): boolean {
    return (window.innerWidth >= DESKTOP_MIN_WIDTH);
  }

  isPathSim(activeRoute: string) {
    let res = false;

    simTree.forEach(element => {
      if (activeRoute.startsWith(element)) {
        res = true;
      }
    });

    // console.log(">> footer >>", res, activeRoute);
    return res;
  }

  public toggleMenu(isOpen: boolean) {
    this.isMenuOpen = isOpen;
    this.isMenuOpenEvent.emit(isOpen);

    if (!isOpen) this.resumeAlumniRegisterOrLoginForm();

    // console.log("componentsServices", "menu is open?", isOpen);
  }

  public getTopSectionH(): number {
    return 30;
  }

  public getBottomSectionH(): number {
    return document.documentElement.scrollHeight - this.windowHeight - this.getTopSectionH();
  }

  lastY = 0;
  yScrollEvent(y: number) {
    if (!this.isDesktopMode()) {
      return;
    }

    /** is running in Desktop */
    const newY = window.scrollY;
    const delta = newY - this.lastY;
    this.lastY = newY;

    if (Math.abs(delta) > 3) {
      this.pageScroll.emit({ newY: newY });

      if (delta < 0) {
        this.pageScrollUp.emit({ newY: newY });
        // console.log("emit", "up");

        /** we scrolled (up) to the top */
        if (newY < this.getTopSectionH()) {
          this.pageScrollIsTop.emit({ newY: newY });
          // console.log("emit", "top");
        }
      } else {
        this.pageScrollDown.emit({ newY: newY });
        // console.log("emit", "down");

        /** we scrolled (up) to the top */
        if (newY > this.getBottomSectionH()) {
          this.pageScrollIsBottom.emit({ newY: newY });
          // console.log("emit", "bottom", newY);
        }
      }
    }
  }

  emulateScrollTop() {
    this.pageScroll.emit({ newY: 0 });
    this.pageScrollUp.emit({ newY: 0 });
    this.pageScrollIsTop.emit({ newY: 0 });
  }

  emulateScrollBottom() {
    this.pageScroll.emit({ newY: window.innerHeight });
    this.pageScrollDown.emit({ newY: window.innerHeight });
    this.pageScrollIsBottom.emit({ newY: window.innerHeight });
  }

  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;
  resizeEvent(h: number, w: number) {
    /** sends a general event */
    this.pageResizeAll.emit({ height: h, width: w });

    /** checks if the resize moved us to the desktop state */
    if (this.isDesktopMode()) {
      this.pageResizeInDesktopMode.emit({ height: h, width: w });
      // console.log("compService", "resize, desktop");
    } else { /* on mobile/tablet */
      this.pageResizeInMobileMode.emit({ height: h, width: w });
      // console.log("compService", "resize, mobile");
    }

    /** the resize is a horizontal resize ONLY, height is the same */
    if (this.windowHeight == h) {
      if (this.isDesktopMode()) {
        this.pageResizeHorizontalOnly.emit({ width: w, mode: "desktop" });
      } else {
        this.pageResizeHorizontalOnly.emit({ width: w, mode: "mobile" });
      }
      // console.log("compService", "resize, horizontal");
    }

    /** the resize is a vertical resize ONLY, width is the same */
    if (this.windowWidth == w) {
      if (this.isDesktopMode()) {
        this.pageResizeVerticalOnly.emit({ height: h, mode: "desktop" });
      } else {
        this.pageResizeVerticalOnly.emit({ height: h, mode: "mobile" });
      }
      // console.log("compService", "resize, vertical");
    }

    /** updates "windowHeight" and "windowWidth" */
    this.windowHeight = h;
    this.windowWidth = w;
  }

  public getInnerHeight(): number {
    return this.windowHeight;
  }

  public getInnerWidth(): number {
    return this.windowWidth;
  }


  closeNewsletterSubscribeForm() {
    this.newsletterCloseSubscribeForm.emit(true);
  }

  private openAlumniRegisterOrLoginForm() {
    // console.log("openAlumniRegisterOrLoginForm()");
    this.isAlumniFormOpen = true;
    this.alumniRegisterOrLoginForm.emit(true);
  }

  closeAlumniRegisterOrLoginForm() {
    if (this.isAlumniFormOpen) {
      console.log("closeAlumniRegisterOrLoginForm()");
      this.isAlumniFormOpen = false;
      this.isAlumniFormPaused = false;
    }
    this.alumniRegisterOrLoginForm.emit(false);
  }

  pauseAlumniRegisterOrLoginForm() {
    this.isAlumniFormPaused = true;
    this.alumniRegisterOrLoginForm.emit(false);
  }

  private resumeAlumniRegisterOrLoginForm() {
    if (this.isAlumniFormPaused) {
      this.isAlumniFormPaused = false;
      this.alumniFormModeChange.emit(this.alumniFormMode);
      this.alumniRegisterOrLoginForm.emit(true);
    }
  }

  setAlumniFormMode(mode: AlumniFormMode): void {
    // console.log("setAlumniFormMode", mode);

    this.alumniFormMode = mode;
    this.alumniFormModeChange.emit(mode);
    this.openAlumniRegisterOrLoginForm();
  }

  getAlumniFormMode(): AlumniFormMode {
    return this.alumniFormMode;
  }

  toggleCollapsableListItem(wasOpen: boolean) {
    this.collapsableListItemEvent.emit(!wasOpen);
  }


  updateID(newID: string) {
    this.updateIdEvent.emit(newID);
  }


  previousRoute: string = "";
  updatePreviousRoute(r: string) {
    this.previousRoute = r;
    this.updatePreviousRouteEvent.emit(r);
  }

  getPreviousRoute(): string {
    return this.previousRoute;
  }


  newsletterFormId: string = "";
  updateNewletterFormId(id: string) {
    console.log(id);
    this.newsletterFormId = id;
    this.updateNewletterFormIdEvent.emit(id);
  }

  public getNewletterFormId(): string {
    return this.newsletterFormId;
  }



  public scrollToTop() {
    window.scrollTo(0, 0);
  

    /** window.scrollTo doesn't work on iOS, so... */
    if (
      this.browserServices.myBrowser().toLowerCase().toLowerCase() == "safari" || 
      this.browserServices.myDevice().toLowerCase().startsWith("iOS")
    ) {
      window.scroll(0, 0);
      // document.body.scrollTop = 0;
      // this.viewportScroller.scrollToPosition([0, 0]);
      // document.getElementById("top")[0].scrollIntoView();
      // setTimeout(() => window.scroll(0, 0), 0);
      /* // this.viewportScroller.scrollToAnchor('social-responsibility-top'); */
    }
  }

  setARnotLogged(b: boolean) {
    this.ARnotLogged = b;
  }

  getARnotLogged() : boolean {
    return this.ARnotLogged;
  }
}
