import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Debounce } from 'angular-debounce-throttle';
import { ISearchBar } from 'src/app/models/components.interface';

type corners = "rounded-corners" | "squared-corners";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  @Input() data: ISearchBar;
  @Input() useDarkerBG: boolean;
  @Output() filterChange = new EventEmitter<string>();
  @Output() filterReset = new EventEmitter<any>();

  private filter: string;

  cornersType: corners;

  constructor() {  }

  ngOnInit(): void {
    if (this.data.isRounded) this.cornersType = "rounded-corners";
    else this.cornersType = "squared-corners";
  }

  search() {
    // console.log("corners?", this.cornersType);
    this.filterChange.emit(this.filter);
  }

  @Debounce(200)
  filterChanged(event) {
    this.filter = event.target.value;
    // console.log("filter", this.filter);

    if (!event || !event.target || !event.target.value || this.filter.length <= 0) {
      // console.log("search filter is empty");
      this.filterReset.emit();
    } else {
      // console.log("search filter is...", this.filter);
      this.filterChange.emit(this.filter);
    }
  }
}
