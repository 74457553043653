<div class="main_menu_wrap">
    <div #mainMenu [ngClass]="{main_menu: true, paused: animation.rotationPaused || animation.firstLevelSelected}">
        <svg width="100%" height="100%" viewBox="0 0 350 350" (mouseenter)="mouseOver()" (mouseleave)="mouseOut()"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

            <circle cx="175" cy="175" r="180" fill="transparent"></circle>
            <g [ngClass]="{first_level: true, hasSelection: animation.firstLevelSelected, noSelection: !animation.firstLevelSelected}">
                <g [ngClass]="{selected: item.selected, unselected: !item.selected && animation.firstLevelSelected}" *ngFor="let item of menuItems; let i = index" (click)="clickedLev1(item)">
                    <path class="arc" fill="none" [attr.d]="item.d" stroke="#ffffff80" stroke-width="8" />
                    <path [id]="item.id" fill="none" [attr.d]="item.d2" stroke="transparent" stroke-width="30" />
                    <text>
                        <textPath [attr.href]="'#' + item.id" [attr.xlink:href]="'#' + item.id" letter-spacing="1" fill="#ffffff" font-size="16" dominant-baseline="auto" class="mobile-only">
                            {{item.label}}
                        </textPath>
                        <textPath [attr.href]="'#' + item.id" [attr.xlink:href]="'#' + item.id" letter-spacing="1" fill="#ffffff" font-size="13" dominant-baseline="auto" class="desktop-only">
                            {{item.label}}
                        </textPath>
                    </text>
                </g>
            </g>
            <g [ngClass]="{second_level: true, 'desktop-only': true, hasSelection: animation.secondLevelSelected, noSelection: !animation.secondLevelSelected}">
                <g [ngClass]="{selected: item.selected, unselected: !item.selected && animation.secondLevelSelected}" *ngFor="let item of subMenuItems; let i = index" (click)="clickedLev2(item)">
                    <path class="arc" fill="none" [attr.d]="item.d" stroke="#ffffff80" stroke-width="8" />
                    <path [id]="item.id" fill="none" [attr.d]="item.d2" stroke="transparent" stroke-width="30" />
                    <text>
                        <textPath [attr.href]="'#' + item.id" [attr.xlink:href]="'#' + item.id" letter-spacing="1" fill="#ffffff" font-size="11" dominant-baseline="auto">
                            {{item.label}}
                        </textPath>
                    </text>
                </g>
            </g>
        </svg>
    </div>
    <img [src]="logo_image" class="logo" />
    <div #thirdLevelMenuOrigin [ngClass]="{'desktop-only': true, thirdLevelMenu: true, visible: thirdLevelMenu.length > 0, start: thirdLevelStart}">
        <div class="menu_wrap desktop-only">
            <ul>
                <li *ngFor="let item of thirdLevelMenu; let i = index">
                    <a [routerLink]="['/', translate.currentLang, item.href]" routerLinkActive="active">{{item.label}}<span *ngIf="item.label === 'Newsletter' && isEn">s</span></a>
                </li>
            </ul>
        </div>
    </div>

    <nav [ngClass]="{'mobile-only': true, 'absolute-nav': isCurrentLv1Absolute()}">
        <ul [ngClass]="{'has_selection': subMenuItems.length > 0}">
            <li [ngClass]="{selected: item.selected}" *ngFor="let item of subMenuItems; let i = index" (click)="clickedLev2(item)">
                <a class="no-underline" *ngIf="item.href" [routerLink]="['/', translate.currentLang, item.href]" routerLinkActive="active">
                    {{item.label}}
                </a>
                <a class="no-underline" *ngIf="!item.href">
                    {{item.label}}
                </a>

                <ul>
                    <li *ngFor="let item of thirdLevelMenu; let i = index">
                        <a [routerLink]="['/', translate.currentLang, item.href]" routerLinkActive="active">{{item.label}}<span *ngIf="item.label === 'Newsletter' && isEn">s</span></a>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</div>
