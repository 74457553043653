<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>

<div class="single-trending-topics-article prom-page theme--{{data.news.typeNews || 'news'}}" *ngIf="data">

    <ng-container>
        <div class="single-trending-topics-article--content-wrap">
            <div class="single-trending-topics-article--content-text">
                <div class="single-trending-topics-article--header">
                    <!-- <span class="icon-{{data.news.typeNews}} static-icon"></span> -->
                    <div class="single-trending-topics-article--header--bg">
                        <img class="single-trending-topics-article--header--bg--img" src="{{data.news.heroImage}}">
                    </div>
                    <div class="single-trending-topics-article--header--content">
                        <span>{{data.news.mediaType}}</span>
                        <div class="prom-caption___lato-regular single-trending-topics-article--header--content--wrap">
                            <span class="text--red single-trending-topics-article--header--content--wrap--category">{{data.news.category}}</span> {{data.news.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}
                        </div>
                        <h2 class="neutra2_demi single-trending-topics-article--header--content--title">{{data.news.title}}</h2>
                        <p class="single-trending-topics-article--header--content--subtitle" *ngIf="data.news.subtitle">{{data.news.subtitle}}</p>
                    </div>
                </div>
                <div class="single-trending-topics-article--body">

                    <div class="single-trending-topics-article--content">
                        <p class="prom-caption___neutra-book single-trending-topics-article--author">{{data.news.author}}</p>

                        <div class="single-trending-topics-article--text neutra2_book inner-html inner-html--SIM" *ngIf="data.news.typeNews != 'report'" [innerHtml]="data.news.body | safe:'html'"></div>

                        <div class="single-trending-topics-article--wrap" *ngIf="data.news.typeNews == 'report'">
                            <img class="single-trending-topics-article--wrap-img" src="{{data.news.heroImage}}">
                            <app-prometeia-button class="other--btn" [data]="download(data.news.download)" *ngIf="data.news.download"></app-prometeia-button>
                        </div>
                    </div>
                    <!-- <div class="single-trending-topics-article--other" *ngIf="data.news.typeNews === 'news'">
                        <span class="single-trending-topics-article--other-title">{{ 'RELATED_ARTICLES.TITLE' | translate }}</span>
                        <div class="single-trending-topics-article--list" *ngFor="let a of relatedNewsList">
                            <span class="text--red single-trending-topics-article--list-category">{{a.category}}</span>
                            <a class="single-trending-topics-article--list-title" [routerLink]="['/', translate.currentLang, route, a.id]">{{a.title}}</a> {{a.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="single-trending-topics-article--other">
                <span class="single-trending-topics-article--other-title">{{ 'RELATED_ARTICLES.TITLE' | translate }}</span>
                <div class="single-trending-topics-article--list" *ngFor="let a of relatedNewsList">
                    <span class="text--red single-trending-topics-article--list-category">{{a.category || 'Anteo ' + anteoIssueNumber}}</span>
                    <a class="single-trending-topics-article--list-title" [routerLink]="['/', translate.currentLang, route, a.id]">{{a.title}}</a> {{a.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}
                </div>
                <div *ngIf="relatedNewsList?.length < 1"><p>{{'SEARCH_RESULTS.NO_RESULTS' | translate}}</p></div>
            </div>
        </div>
    </ng-container>



    <div class="bottomline">
        <div class="bottomline--socials">
            <app-social-share *ngFor="let soc of socials_list" [data]="soc"></app-social-share>
        </div>

        <div class="bottomline--arrow interactive" (click)="scrollToTop()">
            <span class="icon icon-freccia-scroll-up_freccia-su text--primary"></span>
        </div>
    </div>
</div>
