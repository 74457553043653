import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { separator } from '../models/paths';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private pagesCache = new Map<string, any>();
  private componentsCache = new Map<string, any>();
  private archivesCache = new Map<string, any>();

  constructor(private api: ApiService, public translate: TranslateService) { }

  public async pagesGet(slug: string) {
    const cachedKey: string = this.translate.currentLang + separator + slug;
    if (this.pagesCache.has(cachedKey)) {
      console.log(cachedKey, "from cache");
      return this.pagesCache.get(cachedKey);
    } else {
      console.log(cachedKey, "from service");
      const res = await this.api.pages.get(slug);
      this.pagesCache.set(cachedKey, res);
      return res;
    }
  }

  public async pagesGetWithLang(slug: string, requestedLocale: string) {
    const cachedKey: string = requestedLocale + separator + slug;
    if (this.pagesCache.has(cachedKey)) {
      // console.log(cachedKey, "from cache"/* , this.pagesCache.get(cachedKey) */);
      return this.pagesCache.get(cachedKey);
    } else {
      // console.log(cachedKey, "from service");
      const res = await this.api.pages.getWithLang(slug, requestedLocale);
      this.pagesCache.set(cachedKey, res);
      return res;
    }
  }

  public async componentsGet(slug: string) {
    const cachedKey: string = this.translate.currentLang + separator + slug;
    if (this.componentsCache.has(cachedKey)) {
      // console.log(cachedKey, "from cache");
      return this.componentsCache.get(cachedKey);
    } else {
      // console.log(cachedKey, "from service");
      const res = await this.api.components.get(slug);
      this.componentsCache.set(cachedKey, res);
      return res;
    }
  }

  public async archivesGet(category: string) {
    const cachedKey: string = this.translate.currentLang + separator + category;
    if (this.archivesCache.has(cachedKey)) {
      console.log(cachedKey, "from cache");
      return this.archivesCache.get(cachedKey);
    } else {
      console.log(cachedKey, "from service");
      const res = await this.api.archives.get(category);
      this.archivesCache.set(cachedKey, res);
      return res;
    }
  }
}
