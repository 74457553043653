import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { INews } from 'src/app/models/components.interface';
import { paths, separator } from 'src/app/models/paths';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { SortingServices } from 'src/app/services/sorting.service';
import { ButtonsTheme, cancelletto, HighlightColor, IBaseSection, IButtonLink } from '../../models/commons';

export const BASE_NEWS_PER_PAGE: number = 4;

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  @Input() data: IBaseSection;
  @Input() highlightColor: HighlightColor;
  @Input() cityID: string;

  @Input() pageType?: string; // es: "saas-cloud"

  newsToDisplay: number = BASE_NEWS_PER_PAGE;
  newsToDisplayIncrement: number = this.newsToDisplay * 2;

  hasMoreSaasCloudNews: boolean = false;

  cityNews: IButtonLink[];

  constructor(public translate: TranslateService, private api: ApiService, private sort: SortingServices, private cache: CacheService) { }

  ngOnInit(): void {
    if (this.highlightColor == 'primary') this.highlightColor = 'blue';
    else if (this.highlightColor == 'accent') this.highlightColor = 'green';

    if (this.data.title.toLowerCase().replace(/ /g, "-") == 'prom-life' || this.data.anchorId == 'prom-life') {
      this.loadPromLife();
    } else if (this.cityID && (this.data.title.toLowerCase().replace(/ /g, "-") == 'news' || this.data.anchorId == 'news')) {
      this.loadCityNews();
    } else if (this.pageType == "saas-cloud") {
      this.hasMoreSaasCloudNews = this.data.buttons.length > this.newsToDisplay;
    }
  }

  isSectionVisible(): boolean {
    let ret: boolean = this.data && this.data != null;

    if (this.cityID) {
      if (this.cityNews?.length < 1) ret = false;
    }

    return ret;
  }

  increaseNewsToDisplay(): void {
    this.newsToDisplay = this.newsToDisplay + this.newsToDisplayIncrement;
  }

  protected async loadPromLife() {
    try {
      let category = "alumni" + cancelletto + "promlife";
      let archive: any[] = await this.cache.archivesGet(category);
      // console.log(category, ">", archive);
      
      let btns = [];
      archive.forEach(element => {
        const btn = {
          type: "link",
          route: element.slug.split(separator)[0],
          id: element.slug.split(separator)[1],
          pubDate: element.sections[0].pubDate,
          date: element.sections[0].date,
          label: element.sections[0].title,
          _meta: {pubDate: element.sections[0].pubDate || element.sections[0].date},                  
        }
        btns.push(btn);
      });
      btns = this.sort.byDate_meta(btns);
      // console.log("new content", btns);


      this.data.buttons = btns;
    }
    catch(ex) {
      console.error(ex);
    }
  }

  protected async loadCityNews() {
    console.log("load", this.cityID + "'s news");

    this.cityNews = [];

    try {
      let cn: INews[] = await this.api.insights.getCityNewsList(0, 100000, this.cityID);
      // console.log(">", cn);
      
      let btns = [];
      cn.forEach(element => {
        const btn = {
          type: "link",
          route: paths.newsArticle,
          id: element.slug,
          pubDate: element.pubDate,
          label: element.title,
          _meta: {pubDate: element.pubDate},                  
        }
        btns.push(btn);
      });
      btns = this.sort.byDate_meta(btns);
      // console.log("new content", btns);


      this.data.buttons = this.cityNews = btns;
    }
    catch(ex) {
      console.error(ex);
    }
  }

  async loadMoreSaasCloudNews() {
    try {
      const news: INews[] = await this.api.saasCloud.getNewsList(this.newsToDisplay, BASE_NEWS_PER_PAGE + 1);
      this.hasMoreSaasCloudNews = (news ?? []).length > BASE_NEWS_PER_PAGE;

      /** the first one was the last one in the previous request (wich was hidden, but it's already there) */
      news.splice(0, 1);

      const moreBtns = news.map(n => {
        const b: IButtonLink = n as any;
        b.label = n.title;
        b.route = "news-article";
        (b as any)._id = n.id;
        b.id = n.slug;
        return b;
      });

      moreBtns.forEach(b => this.data.buttons.push(b));

      this.newsToDisplay += BASE_NEWS_PER_PAGE;
    } catch(ex) {
      console.error(ex);
    }
  }

  getLoadMoreBtn(highlightColor: string): IButtonLink {
    return {
      type: 'button',
      label: '',
      id: 'more-stories',
      theme: ('btn_color__' + highlightColor) as ButtonsTheme,
      customBehaviour: 'more'
    }
  }
}
