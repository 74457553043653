import { Component } from '@angular/core';
import { DOWN, IMedia, IMultiText, LEFT, RIGHT, SCROLL_Y, UP } from 'src/app/models/commons';
import { IRnDOurStory } from 'src/app/models/pages.interface';
import { ComponentsServices } from 'src/app/services/components.service';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-r-n-d-our-story',
  templateUrl: './r-n-d-our-story.component.html',
  styleUrls: ['./r-n-d-our-story.component.scss']
})
export class RnDOurStoryComponent extends BasePageComponent<IRnDOurStory> {

  title: string = " ";
  description: string = " ";
  private clicked: boolean;
  multiText: IMultiText = null;

  swipedLeftCounter: number = 0;

  ngOnInit(): void {
    super.ngOnInit();

    this.dataServices.pageDataReady.subscribe(() => {
      console.log("RnD: Our Story Component", this.data);

      this.multiText = this.data.sections[0].multi_text;
      this.less();
      this.clicked = false;
    });

    this.componentsServices.pageResizeInDesktopMode.subscribe(() => this.resetMargin());
    
  }

  isMediaSelected(m: IMedia): boolean {
    return m.selected || false;
  }

  areAllMediaNotSelected(): boolean {
    let res: boolean = false;

    this.multiText.list.forEach((media: IMedia) => {
      res = res || media.selected;
    });

    return !res;
  }

  more(m: IMedia) {
    if (!this.multiText) return;
    
    // console.log(">>", m.title);
    
    this.clicked = false;
    this.title = m.title;
    this.description = m.text;
    
    this.multiText.list.forEach((media: IMedia) => {
      media.selected = false;
    });
    m.selected = true;
  }

  less(m?: IMedia) {
    if (!this.multiText || this.clicked) return;

    this.title = this.multiText.default?.title || " ";
    this.description = this.multiText.default?.text || " ";

    if (m) {
      m.selected = false;
      // console.log(m);
    }
  }

  click() {
    this.clicked = true;
  }

  swipe(event) {
    // console.log(event, " --- s: " + event.direction, event.srcEvent.pageY, event.distance);
    if (this.componentsServices.isDesktopMode()) {
      return;
    }

    switch (event.direction) {
      case LEFT: {
        // console.log("LEFT");
        // this.isSwipeLeft = true;
        this.swipedLeftCounter++;
        break;
      }
      case RIGHT: {
        // console.log("RIGHT");
        // this.isSwipeRight = true;
        this.swipedLeftCounter--;
        break;
      }
      case UP: {
        // console.log("UP");
        window.scroll(0, event.srcEvent.pageY + SCROLL_Y);
        break;
      }
      case DOWN: {
        // console.log("DOWN");
        window.scroll(0, event.srcEvent.pageY - event.distance * 2.5 - SCROLL_Y);
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
  }

  getMargin(): number {
    const offset = 186 + 25;

    if (this.swipedLeftCounter < 0) this.swipedLeftCounter = 0;
    else if (this.swipedLeftCounter >= this.data.sections[0].multi_text.list.length){
      this.swipedLeftCounter = this.data.sections[0].multi_text.list.length - 1;
    } 

    return - this.swipedLeftCounter * offset;
  }

  resetMargin(): void {
    this.swipedLeftCounter = 0;
  }

}
