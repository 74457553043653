import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { RestClientService } from './rest-client.service';
import { AmplifyAuthService } from 'src/app/services/amplify-auth.service';
import { IARAttachment } from '../models/components.interface';

@Injectable({
    providedIn: 'root',
})
export class ApiArService {    
    public offlineException : EventEmitter<HttpErrorResponse> = new EventEmitter();  
    public serverErrorException : EventEmitter<HttpErrorResponse> = new EventEmitter(); 
    constructor(private restClient:RestClientService) {
    }

    public libraries = {
        login: (token: string) => this.restClient.post(`/ar/login`, {token : token}),
        logout: () => this.restClient.post(`/ar/logout`, {}),
        get: <T>(slug: string) => this.restClient.get<T>(`/libraries/${slug}`),
        getContent: (folder: string) => this.restClient.get<IARAttachment[]>(`/libraries/ar/folders/content/${folder}`),
    }

}