import { Component } from '@angular/core';
import { SectionGeneralComponent } from '../section-general/section-general.component';

@Component({
  selector: 'app-section-multi-image',
  templateUrl: './section-multi-image.component.html',
  styleUrls: ['./section-multi-image.component.scss']
})
export class SectionMultiImageComponent extends SectionGeneralComponent {

}
