import { EventEmitter, Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HighlightColor, SCROLL_TO_ANCHOR_TIMEOUT } from '../models/commons';
import { slugs } from '../models/slugs';
import { CookieService } from './cookie.service';
import { SEOService } from './seo.service';

declare let gtag: Function;

/*
const DEFAULT_TITLE: string = "";
const DEFAULT_HLC: highlightColor = "accent";

export type HeaderDataType = {newTitle: string, newHighlightColor: highlightColor};
*/

export interface IHeaderData { //see IRouteDataOptions
    title: string,
    highlightColor: HighlightColor,
    slug: string,
    id: string,
    forceDarkHeader: boolean,
    forceTitle: boolean,
    isAdvisorPage: boolean,
    showLangSelector: boolean,
    forceBG: boolean,
    meta_description: string,
}

export class HeaderData implements IHeaderData {
    title: string;
    highlightColor: HighlightColor;
    slug: string;
    id: string;
    forceDarkHeader: boolean;
    forceTitle: boolean;
    isAdvisorPage: boolean;
    showLangSelector: boolean;
    forceBG: boolean;
    meta_description: string;

    constructor(data: IHeaderData) {
        this.title = data.title;
        this.highlightColor = data.highlightColor || 'accent';
        this.slug = data.slug;
        this.id = data.id;
        this.forceDarkHeader = data.forceDarkHeader || false;
        this.forceTitle = data.forceTitle || false;
        this.isAdvisorPage = data.isAdvisorPage || false;
        this.showLangSelector = data.showLangSelector || false;
        this.forceBG = data.forceBG || false;
        this.meta_description = data.meta_description || "";
    }
}
@Injectable({
    providedIn: 'root',
})
export class HeaderDataService {
    public headerDataReady: EventEmitter<IHeaderData> = new EventEmitter();
    lastHeaderData: HeaderData;

    private anteoNumberGoBack: string = null;
    
    constructor(router: Router, private cookieServices: CookieService, private seo: SEOService) {
        this.routeEvent(router);
    }

    private routeEvent(router: Router) {
        router.events.subscribe(e => {
            /** if the router event is a "navigation start" event & the url is not an anchor, the emit resets header data */
            if (e instanceof NavigationStart && !e.url.includes("#") && !(e.url.slice(4) == this.lastHeaderData?.slug)) {
                this.headerDataReady.emit({
                    title: null,
                    highlightColor: null,
                    slug: null,
                    id: null,
                    forceDarkHeader: null,
                    forceTitle: null,
                    isAdvisorPage: null,
                    showLangSelector: null,
                    forceBG: null,
                    meta_description: null,
                })
            }

            /** if there was an anchor, the page scrolls to it after a timeout */
            else if (e instanceof NavigationEnd && e.url.includes("#")) {
                const a: string = e.url.substring(e.url.indexOf("#"));
                // console.log("anchor:", a);
                setTimeout(function() {
                    document.querySelector(a)?.scrollIntoView();
                    if (!document.querySelector(a)) {
                        setTimeout(function() {
                            document.querySelector(a)?.scrollIntoView();
                          }, SCROLL_TO_ANCHOR_TIMEOUT);
                    }
                  }, SCROLL_TO_ANCHOR_TIMEOUT);
            }
        });
    }

    public setData(headerData: IHeaderData) {
        // this.lastHeaderData = headerData;
        // this.headerDataReady.emit(headerData);

        // console.log("HD >>", headerData, headerData.showLangSelector);


        if (headerData && headerData.slug && headerData.slug != null) {
            this.lastHeaderData = headerData;
            this.headerDataReady.emit(headerData);
            
            let title = "Prometeia";
            switch (headerData.slug) {
                /** Advisory */
                case slugs.financialAdvisory:
                    title = "Financial Advisory";
                    break;

                case slugs.financialDistribution:
                    title = "Wealth Management & Financial Distribution";
                    break;

                case slugs.riskPlanningControl:
                    title = "Risk, Planning & Control";
                    break;

                case slugs.strategicAdvisory:
                    title = "Strategic Advisory & Corporate Finance";
                    break;

                /** Insights */
                case slugs.trendingTopics: 
                    title = "Trending Topics";
                    break;
          
                case slugs.news: 
                    title = "News";
                    break;
          
                case slugs.newsletter: 
                    title = "NewsLetter";
                    break;
          
                default: 
                    title = headerData.title || headerData.slug;
                    break;
            }
            
            this.setTitleAndDescription(title, headerData.meta_description ? headerData.meta_description : null);
            this.setAnalytics(title);
        }
    }

    public setTitleAndDescription(title: string, description?: string) {
        // document.title = title;
        this.seo.updateTitle(title);

        if (description?.length > 1) {
            this.seo.updateDescription(description || title);
        } else {
            if (this.seo.getDescription() != null) this.seo.removeDescription();
        }
    }

    public setAnalytics(title: string) { // used in HOME (doesn't extends BasePage)
        if(this.cookieServices.isAnalyticsEnabled()) {
            // gtag('config', environment.GOOGLE_ANALYTICS_TRACKING_CODE, { 'page_title': title, 'page_path': window.location.pathname } );

            /** https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
            gtag('event', 'page_view', { 'page_title': title });
        }
        // console.log(title, "has been logged?", this.cookieServices.isAnalyticsEnabled() || false);
    }

    setAnteoNumberGoBack(number: string) {
        this.anteoNumberGoBack = number;
    }

    getAnteoNumberGoBack() {
        return this.anteoNumberGoBack;
    }
}