import { Component, ViewChild } from '@angular/core';
import { IBasePage, IBaseSection } from '../../models/commons';
import { BasePageComponent } from '../base-page.component';
import globalJSON from '../../../assets/json/global.json';
import { paths } from 'src/app/models/paths';
import { SwiperComponent, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';

const IMAGE_WITH_FILTER = 0;
const IMAGE_NO_FILTER = 1;
@Component({
  selector: 'app-prom-hq',
  templateUrl: './prom-hq.component.html',
  styleUrls: ['./prom-hq.component.scss']
})
export class PromHqComponent extends BasePageComponent<IBasePage> {

  dataServicesSubscription: Subscription = null;

  backRoute: string = paths.socialResponsibility;
  backRouteLabel: string = "< back to OUR ‎‎‎‎‎‎‎‎‎‎‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ SOCIAL RESPONSIBILITY"; //ci sono "‏‏‎ ‎" in mezzo

  logo_chiaro: string = globalJSON["images"].cdn + globalJSON["images"].logo_chiaro;
  bgList: string[] = [];
  private zoomedSection: number = -1;

  index = 0;

  @ViewChild(SwiperComponent) componentRef: SwiperComponent;

  private paginationConfig: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  configHQ: SwiperConfigInterface  = {
    direction: 'vertical',
    slidesPerView: 1,
     
    mousewheel: true,
    scrollbar: false,  
    keyboard: true,  
    pagination: this.paginationConfig,
    breakpoints: {     
      800: {
        allowTouchMove: false, 
          
      }
    }
  };

  ngOnInit() {
    super.ngOnInit();

    this.dataServicesSubscription?.unsubscribe();
    this.dataServicesSubscription = this.dataServices.pageDataReady.subscribe(() => {
      // console.log(this.slug, this.data);

      this.data.sections.forEach((element: IBaseSection, i: number) => {
        this.bgList[i] = element.backImages[0];
      });

      // console.log(this.bgList);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.dataServicesSubscription?.unsubscribe();
    this.dataServicesSubscription = null;
  }


  mouseEnter(i: number) {
    this.bgList[i] = this.data.sections[i].backImages[IMAGE_NO_FILTER];
    this.zoomedSection = i;
    // console.log("enter", this.bgList[i]);
  }

  mouseLeave(i: number) {
    this.bgList[i] = this.data.sections[i].backImages[IMAGE_WITH_FILTER];
    this.zoomedSection = -1;
    // console.log("leave", this.bgList[i]);
  }

  isZoomedIn(i: number): boolean {
    return this.zoomedSection == i;
  }

  isAnythingZoomed(): boolean {
    return this.zoomedSection != -1;
  }

  nextSlide(): void{
    this.componentRef.directiveRef.setIndex(1);
  }

  goToIndex(index: number) {
    this.index = index;
  }

}
