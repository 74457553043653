<div [ngClass]="{
        'header': true, 
        'header--open': isOpen, 
        'header--closed': !isOpen,
        'header-not-on-intro': !isTop, 
        'header-forced-bg': forceBG, 
        'desk--no-padding-bottom-short-padding-top': checkNoVerticalPadding(),
        'header-forced-title': forceTitle, 
        'header-forced-title--no-bg': forceTitle && useTransparentBg(),
        'show-above-overlay': cdkOverlayIsOpen, 
        'header--on-iphone': deviceType == 'iphone'}" 
    *ngIf="isHeaderActive && pageSlug != 'home'">
    
    <img class="header--logo" src="{{logo_chiaro}}" [routerLink]="['/', translate.currentLang, logoRoute]" routerLinkActive="active" *ngIf="isTop && !forceDarkHeader && !isAdvisorPage">
    <img class="header--logo" src="{{logo_scuro}}" [routerLink]="['/', translate.currentLang, logoRoute]" routerLinkActive="active" *ngIf="(!isTop || forceDarkHeader) && !isAdvisorPage">
    <img class="header--logo" src="{{logo_advisor}}" [routerLink]="['/', translate.currentLang, logoRouteSIM]" routerLinkActive="active" *ngIf="isTop && !forceDarkHeader && isAdvisorPage">
    <img class="header--logo" src="{{logo_advisor_scuro}}" [routerLink]="['/', translate.currentLang, logoRouteSIM]" routerLinkActive="active" *ngIf="(!isTop || forceDarkHeader) && isAdvisorPage">

    <p class="header--page-title neutra2_bold text--{{highlightColor}} capslock desktop-only"
       *ngIf="pageTitle && pageTitle.length > 0">
        <ng-container *ngIf="!titleRoute">
            {{ pageTitle }}
        </ng-container>

        <a class="header--page-title--link neutra2_bold text--{{highlightColor}} capslock desktop-only no-underline"
           [routerLink]="['/', translate.currentLang, titleRoute]" *ngIf="titleRoute && !titleId">
            {{ pageTitle }}
        </a>

        <a class="header--page-title--link neutra2_bold text--{{highlightColor}} capslock desktop-only no-underline"
           [routerLink]="['/', translate.currentLang, titleRoute, titleId]" *ngIf="titleRoute && titleId">
            {{ pageTitle }}
        </a>
    </p>

    

    <div class="header--btn-container">
        <div class="menu-tools--tool interactive" (click)="toggleLangs()" *ngIf="showLangSelector && langServices.getLangLabel() != langServices.getNextLangLabel()">
            <p class="tool--lang capslock">{{langServices.getLangLabel()}}
                <span class="icon icon-freccia_freccina-giù-grigia lang--decoration"></span>
            </p>
            <ul class="langs-list" *ngIf="isLangsListOpen">
                <li class="interactive" (click)="changeLang()">
                    <!-- <p class="tool--lang capslock">{{'NEXT_LOCALE' | translate}}</p> -->
                    <p class="tool--lang capslock">{{langServices.getNextLangLabel()}}</p>
                </li>
            </ul>
        </div>
        <div class="logout" *ngIf="isLoggedAlumni && pageSlug == 'alumni'" (click)="logoutAlumni()">
            <img *ngIf="!isTop || forceDarkHeader" class="tool--padlock color-on" src="../../../assets/icons-svg/logout_blu.svg">
            <img class="tool--padlock color-off" src="../../../assets/icons-svg/logout_green.svg">
            <img *ngIf="isTop && !forceDarkHeader" class="tool--padlock color-on"  src="../../../assets/icons-svg/logout_white.svg">
        </div>
        <div class="logout" *ngIf="isLoggedAR() && pageSlug == 'reserved-area'" (click)="logoutAR()">
            <img *ngIf="!isTop || forceDarkHeader" class="tool--padlock color-on" src="../../../assets/icons-svg/logout_blu.svg">
            <img class="tool--padlock color-off" src="../../../assets/icons-svg/logout_green.svg">
            <img *ngIf="isTop && !forceDarkHeader" class="tool--padlock color-on"  src="../../../assets/icons-svg/logout_white.svg">
        </div>
        <div *ngIf="isAdvisorPage" class="asset-manager">
            <a class="no-underline" href="https://am.advisor.prometeia.net" target="_blank">
                <img class="tool--padlock asset-manager--icon"
                     src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_chiuso_bianco.svg" 
                     *ngIf="isTop && !forceDarkHeader && isAdvisorPage">

                <img class="tool--padlock asset-manager--icon" 
                     src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_chiuso_blu.svg"
                     *ngIf="(!isTop || forceDarkHeader) && isAdvisorPage">

                <a [ngClass]="{'lato_regular asset-manager--label no-underline': true, 
                               'text--primary': (!isTop || forceDarkHeader),
                               'text--on-dark': (isTop && !forceDarkHeader)}">
                    Asset Manager web platform
                </a>
            </a>
        </div>

        <button [ngClass]="{'btn interactive': true, 'text--primary': forceDarkHeader}" mat-icon-button aria-label="Example icon-button with a menu" (click)="openMenu()">
            <!-- <mat-icon>reorder</mat-icon> -->

            <!-- <span class="icon icon-nav-menu_menu-mobile mobile-only"></span>
            <span class="icon icon-nav-menu_menu desktop-only"></span> -->

            <!-- <mat-icon svgIcon="icon svg-icon-panino-mob mobile-only"></mat-icon>
            <mat-icon svgIcon="icon svg-icon-panino-desk desktop-only"></mat-icon> -->            

            <img class="mobile-only" *ngIf="!isTop || forceDarkHeader" src="../../../assets/icons-svg/svg-icon-nav-menu_menu-mobile.svg">
            <img class="desktop-only" *ngIf="!isTop || forceDarkHeader" src="../../../assets/icons-svg/svg-icon-nav-menu_menu.svg">
            <img class="mobile-only" *ngIf="isTop && !forceDarkHeader" src="../../../assets/icons-svg/svg-icon-nav-menu_menu-mobile__bianco.svg">
            <img class="desktop-only" *ngIf="isTop && !forceDarkHeader" src="../../../assets/icons-svg/svg-icon-nav-menu_menu__bianco.svg">


        </button>
    </div>
</div>

<app-menu [isOpenEvt]="openMenuSubject.asObservable()" *ngIf="!hideMenu"></app-menu>
