<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">
    <!-- <app-side-navbar class="side-navbar desktop-only" [data]="data.sections" [bulletColor]="highlightColor"></app-side-navbar> -->
    <app-long-arrow-down class="desktop-only" [color]="highlightColor"></app-long-arrow-down>

    <div class="country">

        <!-- dynamic sections: -->
        <ng-container *ngFor="let s of data.sections; let i = index">
            <app-section-intro-bg *ngIf="s.type == 'intro'" [data]="s" [otherCities]="otherCities" [cityID]="id" [highlightColor]="highlightColor" [mainTitle]="s._meta?.networkType"></app-section-intro-bg>

            <app-section-network *ngIf="s.type == 'network'" [data]="s"></app-section-network>

            <section *ngIf="s.type == 'news-frontpage' && cityNewsLength > 0" spyTarget="{{s.anchorId}}">
                <app-news-list [data]="s" [highlightColor]="highlightColor" [cityID]="id"></app-news-list>
            </section>

            <app-section-general *ngIf="s.type != 'intro' && s.type != 'network' && s.type != 'news-frontpage'" [data]="s"></app-section-general>

            <app-section-collapsable-list [data]="s" [highlightColor]="highlightColor" [noPaddingBottom]="checkIfNoPaddingBottom(i)" [shortPaddingTop]="checkIfShortPaddingTop(i)"></app-section-collapsable-list>

            <section data-aos="fade-up" spyTarget="{{s.anchorId}}" *ngIf="s.type == 'last' && s._meta" [ngClass]="{'contacts center-vertical': true, 'theme--lighter': cityNewsLength > 0, 'theme--light': cityNewsLength <= 0}">
                <div class="content">
                    <p class="prom-contact___lato text--accent country-info" *ngIf="s._meta.countryInfo">{{s._meta.countryInfo}}</p>
                    <a class="prom-contact text--accent mail" *ngIf="s._meta.mail" href="mailto:{{s._meta.mail}}">{{s._meta.mail}}</a>

                    <ng-container *ngIf="s._meta.address || s._meta.phone">
                        <br>
                        <hr>
                        <br>
                    </ng-container>

                    <p class="prom-caption___lato-italic text--primary address" *ngIf="s._meta.address">{{s._meta.address}}</p>
                    <p class="prom-caption___lato-regular text--primary phone" *ngIf="s._meta.phone">{{s._meta.phone}}</p>
                </div>
            </section>
        </ng-container>

    </div>
</ng-container>
