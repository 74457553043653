<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">
    <div class="our-story prom-page">
        <div class="content" *ngFor="let s of data.sections">
            <!-- only sections[0] ? -->
            <div class="our-story--title-wrap">
                <p class="prom-contact text--white our-story--text our-story--title">
                    {{ s.title || 'OUR_STORY.TITLE' | translate }}
                </p>
            </div>

            <hr>

            <div class="our-story--boxes">
                <div class="swiper" [ngStyle]="{'transform': 'translateX(' + getMargin() + 'px)', 'margin-right': getMargin() + 'px'}">
                    <ng-container *ngFor="let m of s.multi_text.list">
                        <img [ngClass]="{'our-story--boxes--image interactive': true,
                                         'selected': isMediaSelected(m),
                                         'not-selected': !isMediaSelected(m) && !areAllMediaNotSelected()}" src="{{m.contentUrl}}" (mouseenter)="more(m)" (mouseleave)="less(m)" (click)="click()" (swipe)="swipe($event)">
                    </ng-container>
                </div>
            </div>

            <hr>

            <div class="our-story--description">
                <p class="our-story--description--title our-story--text capslock">{{title}}</p>
                <div class="our-story--description--text our-story--text neutra2_book inner-html inner-html--force-white" [innerHtml]="description | safe:'html'"></div>
            </div>
        </div>

    </div>
</ng-container>
