<div [ngClass]="{'menu-tools': true, 'blue-theme': blueTheme}">
    <div class="menu-tools--tool interactive" [routerLink]="['/', translate.currentLang, searchPage]">
        <img class="tool--magnifier color-off" *ngIf="!blueTheme" src="../../../assets/icons-svg/menu_tools/svg-icon-lente_bianca.svg">
        <img class="tool--magnifier color-off" *ngIf="blueTheme" src="../../../assets/icons-svg/menu_tools/svg-icon-lente_blu.svg">
        <img class="tool--magnifier color-on" src="../../../assets/icons-svg/menu_tools/svg-icon-lente_verde.svg">
    </div>

    <div class="menu-tools--tool interactive" *ngIf="!isLoggedAR" (click)="login()">
        <!-- <mat-icon class="icon">lock</mat-icon> -->
        <!-- <span class="icon icon-bottoni_lucchetto"></span> -->
        <img class="tool--padlock color-off" *ngIf="!blueTheme" src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_chiuso_bianco.svg">
        <img class="tool--padlock color-off" *ngIf="blueTheme" src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_chiuso_blu.svg">
        <img class="tool--padlock color-on" src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_chiuso_verde.svg">
    </div>
    <div class="menu-tools--tool interactive" *ngIf="isLoggedAR" (click)="resevedArea()">
        <!-- <mat-icon class="icon">lock_open</mat-icon> -->
        <!-- <span class="icon icon-bottoni_lucchetto"></span> -->
        
        
        <img class="tool--padlock color-off" *ngIf="!blueTheme" src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_aperto_bianco.svg">
        <img class="tool--padlock color-off" *ngIf="blueTheme" src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_aperto_blu.svg">
        <img class="tool--padlock color-on" src="../../../assets/icons-svg/menu_tools/svg-icon-lucchetto_aperto_verde.svg">
        
    </div>

    <div class="menu-tools--tool interactive" (click)="toggleLangs()" *ngIf="!hideLangLabel()">
        <p class="tool--lang capslock">
            {{'LOCALE' | translate}}
            <!-- {{langServices.getLangLabel()}} -->
            <span class="icon icon-freccia_freccina-giù-grigia lang--decoration"></span>
        </p>
        <ul class="langs-list" *ngIf="isLangsListOpen">
            <li class="interactive" (click)="changeLang()">
                <p class="tool--lang capslock">{{'NEXT_LOCALE' | translate}}</p>
                <!-- <p class="tool--lang capslock">{{langServices.getNextLangLabel()}}</p> -->
            </li>
        </ul>
    </div>
</div>