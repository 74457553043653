import { Component, Input, OnInit } from '@angular/core';
import { HighlightColor, SocialShare } from 'src/app/models/commons';
import { ISocial } from 'src/app/models/components.interface';

const apiSharer: string = "/api/web/sharer";
const FB = "https://www.facebook.com/sharer/sharer.php?";
const IN = "https://www.linkedin.com/sharing/share-offsite/?";
const TW = "https://twitter.com/intent/tweet?";

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {

  @Input() data: ISocial;
  @Input() theme: HighlightColor;
  @Input() sharedText: string;

  hostname: string;
  pathname: string;
  sharedUrl: string;

  constructor() { }

  ngOnInit(): void {
    const DEV_MODE: boolean = false;

    this.hostname = "https://" + window.location.hostname;
    this.pathname = window.location.pathname;
    
    if (this.hostname == "https://localhost") this.hostname = "https://www.prometeia.3logic.it";
    if (DEV_MODE) { // TURN OFF !!!
      this.sharedUrl = this.hostname + /* apiSharer + */ this.pathname;
    } else {
      this.sharedUrl = this.hostname + apiSharer + this.pathname;
    }

    // console.log(">>>", this.sharedUrl);
  }

  checkType(): boolean {
    if (this.data.type == SocialShare.facebook || this.data.type == SocialShare.linkedin || this.data.type == SocialShare.twitter) {
      return true;
    } else {
      return false;
    }
  }

  getShareLink(): string {
    let res: string;

    switch (this.data.type) {
      case SocialShare.facebook:
        /* esempio:

            https://www.facebook.com/sharer/sharer.php?
            u=https://developers.facebook.com/docs/plugins/&amp;src=sdkpreparse"

            kid_directed_site=0
            &sdk=joey
            &u=https://developers.facebook.com/docs/plugins/
            &display=popup
            &ref=plugin
            &src=share_button
         */
        res = FB + "u=" + this.sharedUrl;
        break;
      case SocialShare.linkedin:
        res = IN + "url=" + this.sharedUrl;
        break;
      case SocialShare.twitter:
        /*  esempio:

            https://twitter.com/intent/tweet?
            original_referer=https://developer.twitter.com/
            &ref_src=twsrc^tfw
            &text=Guides | Twitter Developer
            &tw_p=tweetbutton
            &url=https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview 
        */
        res = TW + "url=" + this.sharedUrl;
        break;
    }

    return res;
  }
}
