import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Injectable, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollSpyModule } from 'ng-spy';

import { HammerModule } from '@angular/platform-browser';
import { OverlayModule } from '@angular/cdk/overlay';

import { SafePipe } from './pipes/safe.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { MustMatchDirective } from './directives/must-match.directive';

import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';


import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';


import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfoNumberComponent } from './components/info-number/info-number.component';
import { SideNavbarComponent } from './components/side-navbar/side-navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { BusinessCardComponent } from './components/business-card/business-card.component';
import { OurNetworkComponent } from './pages/our-network/our-network.component';
import { DropdownPanelComponent } from './components/dropdown-panel/dropdown-panel.component';
import { SocialResponsibilityComponent } from './pages/social-responsibility/social-responsibility.component';
import { RnDComponent } from './pages/r-n-d/r-n-d.component';
import { CollapsableList } from './components/collapsable-list/collapsable-list.component';
import { CollapsableListItemComponent } from './components/collapsable-list-item/collapsable-list-item.component';
import { PrometeiaButtonComponent } from './components/prometeia-button/prometeia-button.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SpecialMessageComponent } from './components/special-message/special-message.component';
import { MenuComponent } from './components/menu/menu.component';
import { HorizontalScrollCarouselComponent } from './components/horizontal-scroll-carousel/horizontal-scroll-carousel.component';
import { MenuToolsComponent } from './components/menu-tools/menu-tools.component';
import { SidebarSocialsComponent } from './components/sidebar-socials/sidebar-socials.component';
import { SectionIntroComponent } from './components/section-intro/section-intro.component';
import { OurCultureComponent } from './pages/our-culture/our-culture.component';
import { LongArrowDownComponent } from './components/long-arrow-down/long-arrow-down.component';
import { HighlightedTextComponent } from './components/highlighted-text/highlighted-text.component';
import { ZoomableCircularImageComponent } from './components/zoomable-circular-image/zoomable-circular-image.component';
import { AppInjector } from './services/app-injector.service';
import { VideoEmbedderComponent } from './components/video-embedder/video-embedder.component';
import { FlippingCardComponent } from './components/flipping-card/flipping-card.component';
import { MultiTextComponent } from './components/multi-text/multi-text.component';
import { FlippingCardsComponent } from './components/flipping-cards/flipping-cards.component';
import { RiskPlanningComponent } from './pages/risk-planning/risk-planning.component';
import { SectionGeneralComponent } from './components/section-general/section-general.component';
import { SectionIntroBgComponent } from './components/section-intro-bg/section-intro-bg.component';
import { SectionAccordionListComponent } from './components/section-accordion-list/section-accordion-list.component';
import { InsightsComponent } from './pages/insights/insights.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SingleNewsComponent } from './components/single-news/single-news.component';
import { SingleNewsletterComponent } from './components/single-newsletter/single-newsletter.component';
import { NewsletterFormComponent } from './components/newsletter-form/newsletter-form.component';
import { SingleTrendingTopicsComponent } from './components/single-trending-topics/single-trending-topics.component';
import { SingleTopTrendingTopicsComponent } from './components/single-top-trending-topics/single-top-trending-topics.component';
import { OurTeamComponent } from './pages/our-team/our-team.component';
import { MasterCorsiLaureaComponent } from './pages/master-corsi-laurea/master-corsi-laurea.component';
import { NewsArticleComponent } from './pages/news-article/news-article.component';
import { TrendingTopicsArticleComponent } from './pages/trending-topics-article/trending-topics-article.component';
import { FinancialAdvisoryComponent } from './pages/financial-advisory/financial-advisory.component';
import { OverviewComponent } from './components/overview/overview.component';
import { BusinessCardOfficeComponent } from './components/business-card-office/business-card-office.component';
import { TrendingTopicsArchiveComponent } from './pages/trending-topics-archive/trending-topics-archive.component';
import { FinancialAdvisoryConsultancyComponent } from './pages/financial-advisory-consultancy/financial-advisory-consultancy.component';
import { SocietaryOrgansComponent } from './pages/societary-organs/societary-organs.component';
import { AnteoComponent } from './pages/anteo/anteo.component';
import { AnteoArchiveComponent } from './pages/anteo-archivio/anteo-archivio.component';
import { AnteoArticleComponent } from './pages/anteo-article/anteo-article.component';
import { RnDOurStoryComponent } from './pages/r-n-d-our-story/r-n-d-our-story.component';
import { GeneralArticleComponent } from './pages/general-article/general-article.component';
import { AlumniComponent } from './pages/alumni/alumni.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { JobOfferComponent } from './pages/job-offer/job-offer.component';
import { FiltersComponent } from './components/filters/filters.component';
import { SliderCardsComponent } from './components/slider-cards/slider-cards.component';
import { SectionNetworkComponent } from './components/section-network/section-network.component';
import { CountryPageComponent } from './pages/country-page/country-page.component';
import { OurPartnershipComponent } from './pages/our-partnership/our-partnership.component';
import { SingleSliderCardsComponent } from './components/single-slider-cards/single-slider-cards.component';
import { PromHqComponent } from './pages/prom-hq/prom-hq.component';
import { OfferComponent } from './pages/offer/offer.component';
import { SingleJobOfferComponent } from './components/single-job-offer/single-job-offer.component';
import { SidePaginationDotsComponent } from './components/side-pagination-dots/side-pagination-dots.component';
import { PrivacyAndCookiesComponent } from './components/privacy-and-cookies/privacy-and-cookies.component';
import { AlumniAuthComponent } from './components/alumni-auth/alumni-auth.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { AlumniDialogAuthComponent } from './components/alumni-dialog-auth/alumni-dialog-auth.component';
import { ArDialogAuthComponent } from './components/ar-dialog-auth/ar-dialog-auth.component';
import { ReservedAreaComponent } from './pages/reserved-area/reserved-area.component';
import { HorizontalScrollAnimationComponent } from './components/horizontal-scroll-animation/horizontal-scroll-animation.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SectionOverviewComponent } from './components/section-overview/section-overview.component';
import { NotFoundDataComponent } from './components/not-found-data/not-found-data.component';
import { CookieService } from 'ngx-cookie-service';
import { ActivateAccountComponent } from './pages/activate-account/activate-account.component';
import { environment } from 'src/environments/environment';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { SocialShareComponent } from './components/social-share/social-share.component';
import { SectionTwoEvenColumnsComponent } from './components/section-two-even-columns/section-two-even-columns.component';
import { AtlanteAppBannerComponent } from './components/atlante-app-banner/atlante-app-banner.component';
import { SectionCollapsableListComponent } from './components/section-collapsable-list/section-collapsable-list.component';
import { AlumniUnsubscribeComponent } from './pages/alumni-unsubscribe/alumni-unsubscribe.component';
import { FeedbackFormComponent } from './pages/feedback-form/feedback-form.component';
import { ChartComponent } from './components/chart/chart.component';
import { CareersComponent } from './pages/careers/careers.component';
import { SectionIntroVideoComponent } from './components/section-intro-video/section-intro-video.component';
import { SectionMultiImageComponent } from './components/section-multi-image/section-multi-image.component';
import { SectionWideCardsListComponent } from './components/section-wide-cards-list/section-wide-cards-list.component';
import { SectionGridCardsComponent } from './components/section-grid-cards/section-grid-cards.component';
import { BusinessCardV2Component } from './components/business-card-v2/business-card-v2.component';
import { SectionFullsizeCarouselComponent } from './components/section-fullsize-carousel/section-fullsize-carousel.component';
import { SectionContactsComponent } from './components/section-contacts/section-contacts.component';
import { SectionStackedComponent } from './components/section-stacked/section-stacked.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

Amplify.configure(environment.CONFIG_COGNITO_ALUMNI);

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_ALL }
  }
}


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    HeaderComponent,
    InfoNumberComponent,
    SideNavbarComponent,
    FooterComponent,
    BusinessCardComponent,
    OurNetworkComponent,
    DropdownPanelComponent,
    SocialResponsibilityComponent,
    RnDComponent,
    CollapsableList,
    CollapsableListItemComponent,
    PrometeiaButtonComponent,
    MainMenuComponent,
    SpecialMessageComponent,
    MenuComponent,
    HorizontalScrollCarouselComponent,
    MenuToolsComponent,
    SidebarSocialsComponent,
    SectionIntroComponent,
    OurCultureComponent,
    LongArrowDownComponent,
    HighlightedTextComponent,
    ZoomableCircularImageComponent,
    VideoEmbedderComponent,
    SafePipe,
    FlippingCardComponent,
    FlippingCardsComponent,
    MultiTextComponent,
    RiskPlanningComponent,
    SectionGeneralComponent,
    SectionIntroBgComponent,
    SectionAccordionListComponent,
    InsightsComponent,
    SearchBarComponent,
    SingleNewsComponent,
    SingleNewsletterComponent,
    NewsletterFormComponent,
    SingleTrendingTopicsComponent,
    SingleTopTrendingTopicsComponent,
    OurTeamComponent,
    MasterCorsiLaureaComponent,
    NewsArticleComponent,
    TrendingTopicsArticleComponent,
    FinancialAdvisoryComponent,
    OverviewComponent,
    BusinessCardOfficeComponent,
    TrendingTopicsArchiveComponent,
    FinancialAdvisoryConsultancyComponent,
    SocietaryOrgansComponent,
    AnteoComponent,
    AnteoArchiveComponent,
    AnteoArticleComponent,
    RnDOurStoryComponent,
    GeneralArticleComponent,
    AlumniComponent,
    AlumniUnsubscribeComponent,
    NewsListComponent,
    JobOfferComponent,
    FiltersComponent,
    SliderCardsComponent,
    SectionNetworkComponent,
    CountryPageComponent,
    OurPartnershipComponent,
    SingleSliderCardsComponent,
    PromHqComponent,
    OfferComponent,
    SingleJobOfferComponent,
    SidePaginationDotsComponent,
    PrivacyAndCookiesComponent,
    AlumniAuthComponent,
    DialogComponent,
    AlumniDialogAuthComponent,
    ArDialogAuthComponent,
    ReservedAreaComponent,
    HorizontalScrollAnimationComponent,
    PageNotFoundComponent,
    SectionOverviewComponent,
    NotFoundDataComponent,
    ActivateAccountComponent,
    MustMatchDirective,
    SearchResultsComponent,
    SocialShareComponent,
    SectionTwoEvenColumnsComponent,
    AtlanteAppBannerComponent,
    SectionCollapsableListComponent,
    FeedbackFormComponent,
    ChartComponent,
    CareersComponent,
    SectionIntroVideoComponent,
    SectionMultiImageComponent,
    SectionWideCardsListComponent,
    SectionGridCardsComponent,
    BusinessCardV2Component,
    SectionFullsizeCarouselComponent,
    SectionContactsComponent,
    SectionStackedComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    SwiperModule,
    BrowserAnimationsModule,
    AmplifyUIAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ScrollSpyModule,
    HammerModule,
    OverlayModule,
    ReactiveFormsModule,
    NgPipesModule,
    FormsModule,
    MatTooltipModule,
  ],
  providers: [
    { provide: Window, useValue: window, },
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: CookieService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
