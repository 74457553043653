<section class="{{data.theme}} section center-vertical">
    <div class="vertical-spacer" *ngIf="topExtraSpace"></div>

    <h1 data-aos="fade-up" class="section_h1 neutra2_book text--primary">{{ data.title }}</h1>

    <div class="section-content">
        <div class="main">
            <div data-aos="fade-up" class="section-content--text neutra2_book inner-html" [innerHtml]="data.text | safe:'html'"></div>
            
            <div class="btn-container" data-aos="fade-up" *ngIf="data.buttons">
                <ng-container *ngFor="let b of data.buttons">
                    <app-prometeia-button class="btn" *ngIf="b._meta?.isDownloadable || !data.mail" [data]="b"></app-prometeia-button>
                </ng-container>
            </div>
        </div>

        <div class="section--extra">
            <div class="media-container" *ngIf="data.mediaList">
                <img data-aos="fade-up" class="media-container--image" *ngFor="let media of data.mediaList" src="{{media.contentUrl}}" alt="{{media.alt}}">
            </div>

            <div class="more-info">
                <div class="btn-container" data-aos="fade-up" *ngIf="data.buttons && data.mail">
                    <ng-container *ngFor="let b of data.buttons">
                        <app-prometeia-button class="btn" *ngIf="!b._meta || !b._meta.isDownloadable" [data]="changeButtonData(b)"></app-prometeia-button>
                    </ng-container>
                </div>

                <p data-aos="fade-up" class="text--accent prom-contact___lato contact-us--label" *ngIf="data.mail">{{'MORE_INFO' | translate}}</p>
                <a data-aos="fade-up" class="text--accent prom-caption___lato-italic contact-us--email" *ngIf="data.mail" href="mailto:{{data.mail}}">{{data.mail}}</a>
            </div>
        </div>
    </div>

    <div class="vertical-space-cut" *ngIf="topExtraSpace"></div>
</section>