import { Component, Input } from '@angular/core';
import { IBusinessCard } from '../../models/components.interface';

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss']
})
export class BusinessCardComponent {

  @Input() data: IBusinessCard;

  constructor() { }

}
