<div [ngClass]="{'prom-menu': true, 'browser--safari': browserType == 'safari',
                 'animation--closed-menu': !main_openState_isOpen, 'animation--open-menu': main_openState_isOpen}" id="prom-menu">
    <p *ngIf="appVersion && !appVersion.includes('-')" style="color: transparent; position: absolute; bottom: -22px; left: 6px; opacity: 0.5;">
        {{appVersion}} | {{browserType}} | {{deviceType}}
    </p>
    <p *ngIf="appVersion && appVersion.includes('-')" style="color: primary; position: absolute; bottom: -22px; left: 6px; opacity: 0.5;">
        {{appVersion}} | {{browserType}} | {{deviceType}}
    </p>

    <!-- <div [ngClass]="{'prom-menu--image-filter': true, 'prom-menu--image-filter--opaque': backgroundImage_isBlank}"></div> -->
    <div class="prom-menu--image-filter"></div>
    <div class="prom-menu--BGI" [ngStyle]="{'background-image': backgroundImage_selected, 'opacity': backgroundImage_isBlank ? 0 : 1}"></div>

    <div class="prom-menu--header">
        <img class="logo interactive" src="{{logo}}" [routerLink]="['/', translate.currentLang, 'home']" routerLinkActive="active" (click)="close()">

        <div class="header-tools">
            <app-menu-tools class="tools" [data]="data"></app-menu-tools>

            <div class="close interactive" id="prom-menu--close-icon" (click)="close(true)">
                <!-- <span class="icon icon-box-chiudi_x icon-close"></span> -->
                <img src="../../../assets/icons-svg/svg-icon-X-chiusura.svg">
            </div>
        </div>
    </div>


    <div class="prom-menu--container">

        <div *ngFor="let x of data.main_menu; let i = index" class="category {{x.id}}">
            <div class="title-wrap" [ngStyle]="{'transition-delay': getTitleDelay(i)}">
                <p [ngClass]="{'neutra2_demi capslock interactive title': true,
                                'title--highlighted': x.subMenu_openState,
                                'title--default': !x.subMenu_openState && !backgroundImage_isBlank,
                                'title--when_blank': !x.subMenu_openState && backgroundImage_isBlank }" id="{{x.id}}--title" (click)="openSubMenu(x.id)">
                    {{x.title}}
                    <span class="icon icon-freccia_freccina-giù-grigia title--decoration title--decoration--close" *ngIf="!x.subMenu_openState"></span>
                    <span class="icon icon-freccia_freccina-giù-grigia title--decoration title--decoration--open" *ngIf="x.subMenu_openState"></span>
                </p>
            </div>

            <div [ngClass]="{'submenu': true, 'submenu--open': x.subMenu_openState, 'submenu--closed': !x.subMenu_openState}" [ngStyle]="(x.subMenu_openState && sub3menuIsOpen && browserType == 'safari') && {'margin-bottom': getMarginBottom()}">
                <div class="submenu--item-wrap" *ngFor="let s of x.subMenu; let i = index" [ngStyle]="{'transition-delay': getSubtitleDelay(i)}">
                    <a *ngIf="s.route" class="text--on-dark no-underline submenu--item interactive capslock" [routerLink]="['/', translate.currentLang, s.route]" routerLinkActive="active" (click)="close()">
                            {{s.title}}
                    </a>
                    <a *ngIf="!s.route" id="{{s.sub_id}}" class="text--on-dark no-underline submenu--item interactive capslock" (click)="openSub3Menu(s.sub_id)">
                            {{s.title}}
                    </a>

                    <ng-container *ngIf="s.sub3">
                        <div [ngClass]="{'sub3menu': true, 'sub3menu--open': s.sub3_openState, 'sub3menu--closed': !s.sub3_openState}">
                            <div class="sub3menu--item-wrap" *ngFor="let s3 of s.sub3; let i = index" [ngStyle]="{'transition-delay': getSub3titleDelay(i)}">

                                <a class="text--on-dark no-underline sub3menu--item" [routerLink]="['/', translate.currentLang, s3.route]" routerLinkActive="active" (click)="close()">
                                        {{s3.title}}<span *ngIf="isEn && s3.title === 'Newsletter'">s</span>
                                </a>

                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>

    <div class="prom-menu--footer">
        <app-sidebar-socials class="socialbar"></app-sidebar-socials>

        <div class="footer-links">
            <p class="prom-caption___lato-regular text--grey second-row">{{nowYear}} © Prometeia</p>
        </div>
    </div>
</div>
