<hr *ngIf="data" />

<div class="carousel" [ngStyle]="{'min-height': data.slide_height}" *ngIf="data" (mouseenter)="stopAnimation()" (mouseleave)="startAnimation()">
    <ng-container *ngIf="!mobile; else elseBlock">
        <div class="mouse_velocity" (mouseenter)="increaseVelocity()" (mouseleave)="decreaseVelocity()"></div>
        <div class="slides slides-speed--{{data.speed || 'medium'}}" #withBuilder>
            <ng-container *ngFor="let item of [].constructor(slide_number)">
                <div class="slide" *ngFor="let s of slides" #logo>
                    <ng-container *ngIf="s.type != 'VID'">
                        <a class="slide--link" *ngIf="s.route && !s.id && !s.link" [routerLink]="['/', translate.currentLang, s.route]" routerLinkActive="active">
                            <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                            <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                        </a>

                        <a class="slide--link" *ngIf="s.route && s.id && !s.link" [routerLink]="['/', translate.currentLang, s.route, s.id]" routerLinkActive="active">
                            <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                            <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                        </a>

                        <a class="slide--link" *ngIf="s.link && !s.route" href="{{s.link}}" target="_blank">
                            <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                            <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="s.type == 'VID'">
                        <a class="slide--link interactive" (click)="$event.preventDefault(); openVideo(s)" href="{{s.contentUrl || s.id}}">
                            <img class="slide--image interactive" src="{{s.previewUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                            <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="s.type == 'IMG'">
                        <img class="slide--image interactive" src="{{s.contentUrl}}" (click)="onClick(s)" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                        <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #elseBlock>
        <swiper [config]="config" (indexChange)="onIndexChange($event)">
            <div class="swiper-slide" *ngFor="let s of slides">
                <img class="slide--image " src="{{s.contentUrl}} " [ngStyle]="{ 'height': data.slide_height} " alt="{{s.alt}} " *ngIf="!highlightColor " />
            </div>
        </swiper>
    </ng-template>
</div>
<hr *ngIf="data" />
<p data-aos="fade-up  " data-swiper-parallax="-300" class="prom-caption___neutra-book text--grey text " [innerHtml]="text_image | safe:'html'"></p>
