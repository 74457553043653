<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">
    <!-- <app-side-navbar class="side-navbar desktop-only" [data]="data.sections" [bulletColor]="highlightColor"></app-side-navbar> -->
    <app-long-arrow-down class="desktop-only" [color]="highlightColor"></app-long-arrow-down>

    <div class="rnd">
        <ng-container *ngFor="let s of data.sections; let i = index">
            <app-section-intro-bg *ngIf="s.type == 'intro'" [data]="s" [mainTitle]="data.title" [highlightColor]="highlightColor"></app-section-intro-bg>

            <app-section-two-even-columns *ngIf="s.type == 'two-even-columns'" [data]="s"></app-section-two-even-columns>

            <!-- <section spyTarget="{{data.anchorId}}" *ngIf="s.type == 'two-even-columns' || (s.type != 'intro' && s.type != 'accordion-list-images' && s.type != 'accordion-list-paragraphs')" class="{{s.theme}} section" id="{{s.theme}}">

                <div class="inner-block section-max-content">
                    <div class="text-block">
                        <h1 class="neutra2_book text--primary text-block--title" *ngIf="s.title">
                            {{ s.title.split('|')[0] }}
                            <ng-container *ngFor="let x of s.title.split('|'); let isFirst = first">
                                <span *ngIf="x && !isFirst"><br>{{ x }}</span>
                            </ng-container>
                        </h1>
                        <p class="text-block--text inner-html" *ngIf="s.text" [innerHtml]="s.text"></p>

                        <div class="image-block mobile-only" *ngIf="s.media?.contentUrl">
                            <img src="{{s.media.contentUrl}}" aria-hidden="true" alt={{s.media.alt}}>
                        </div>

                        <ng-container *ngIf="s._meta && s._meta.extra_infos">
                            <div class="extra-spacer"></div>
                            <div class="extra-container" *ngFor="let extra of s._meta.extra_infos">
                                <app-prometeia-button class="extra-btn" [data]="extra.extra_btn"></app-prometeia-button>

                                <div class="extra-container--text">
                                    <p class="prom-caption___lato-regular text--grey increased-line-height" *ngIf="extra.extra_text">{{extra.extra_text}}</p>
                                    <a class="prom-caption___lato-regular text--grey increased-line-height" *ngIf="extra.extra_email" href="mailto:{{extra.extra_email}}">{{extra.extra_email}}</a>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="image-block desktop-only" *ngIf="s.media?.contentUrl">
                        <img src="{{s.media.contentUrl}}" aria-hidden="true" alt={{s.media.alt}}>
                    </div>
                </div>
            </section> -->

            <app-section-general *ngIf="s.type != 'intro' && s.type != 'accordion-list' && s.type != 'two-even-columns' && s.type != 'accordion-list-images' && s.type != 'accordion-list-paragraphs'" [data]="s"></app-section-general>

            <app-section-collapsable-list [data]="s" [highlightColor]="highlightColor" [noPaddingBottom]="checkIfNoPaddingBottom(i)" [shortPaddingTop]="checkIfShortPaddingTop(i)"></app-section-collapsable-list>
        </ng-container>


        <section class="outro section center-vertical" *ngIf="data.ending_card">
            <div class="section-max-content">
                <div class="outro--text-block">
                    <h2 class="neutra2_book">{{ 'RND.STORY' | translate }}</h2>
                    <p>{{ 'RND.STORY_TEXT' | translate }}</p>
                </div>

                <div class="outro--btn-block">
                    <img src="{{data.ending_card.img.contentUrl}}">
                    <!-- <button class="capslock">{{ 'RND.STORY_BTN_LABEL' | translate }}</button> -->
                    <ng-container *ngFor="let btn of data.ending_card.buttons">
                        <app-prometeia-button class="outro--btn-block--btn" [data]="btn"></app-prometeia-button>
                    </ng-container>

                </div>
            </div>
        </section>
    </div>
</ng-container>
