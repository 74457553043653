<mat-card class="business-card">

    <mat-card-header>
        <p class="prom-contact name">{{data.name}}</p>
    </mat-card-header>

    <mat-card-content class="content">
        <div class="email">
            <span class="icon-mail static-icon"></span>
            <a class="prom-contact___lato email--address" href="mailto:{{data.email_addr}}">{{data.email_addr}}</a>
        </div>

        <div class="phone">
            <span class="icon-phone static-icon"></span>
            <a class="prom-contact___lato phone--number" href="tel:{{data.phone_num}}">{{data.phone_num}}</a>
        </div>
    </mat-card-content>

</mat-card>