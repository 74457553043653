<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>



<ng-container *ngIf="data && data.sections && data.sections.length > 0">
    <div class="financial-advisory-consultancy article prom-page">
        <div class="financial-advisory-consultancy--main article--main">
            <h3 class="prom-caption___lato-regular text--primary capslock financial-advisory-consultancy--main--type" *ngIf="data.title != 'Tech Solutions'">{{ 'ADVISORY' | translate }}</h3>

            <h1 class="prom-list neutra2_demi text--{{highlightColor || 'accent'}} financial-advisory-consultancy--main--title">{{data.sections[0].title}}</h1>

            <div class="inner-html financial-advisory-consultancy--main--text" [innerHtml]="data.sections[0].innerHtml | safe:'html'"></div>
        </div>

        <div class="financial-advisory-consultancy--more-info article--more-info" *ngIf="data.sections[0].buttons && data.sections[0].buttons[0]?.link">
            <p class="text--primary prom-contact___lato contact-us--label">{{'MORE_INFO' | translate}}</p>
            <a class="text--primary prom-contact no-underline contact-us--email" href="mailto:{{data.sections[0].buttons[0].link}}">{{data.sections[0].buttons[0].link}}</a>
        </div>
    </div>
</ng-container>
