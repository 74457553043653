<div class="business-card-office">
    <h3 class="text--primary prom-contact capslock city">{{data.city}}</h3>

    <p class="text--primary prom-caption___lato-regular address addr1">{{data.addresses[0].addr}}</p>
    <p class="text--primary prom-caption___lato-regular address addr2" *ngIf="data.addresses[0].addr_extraLine">{{data.addresses[0].addr_extraLine}}</p>
    <p class="text--primary prom-caption___lato-regular phone">{{data.addresses[0].phone}}</p>

    <div class="emails">
        <a class="text--primary prom-caption___lato-italic emails--email" *ngFor="let e of data.addresses[0].emails" href="mailto:{{e}}">{{e}}</a>
    </div>
</div>