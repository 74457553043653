import { SafeHtml } from '@angular/platform-browser';
import { IOfficesList } from './components.interface';

export enum Locale {
    it = 'it',
    en = 'en'
}

export enum SocialShare {
    facebook = "facebook",
    linkedin = "linkedin",
    twitter = "twitter"
}

export enum Direction {
    Up = 'Up',
    Down = 'Down'
}

export const DEFAULT_LANG = Locale.it;
export const LANGS = {"default": "ENG", "en": "ENG", "it": "ITA"};
export const DESKTOP_MIN_WIDTH = 800;
export const HEADER__EXTRA_HEIGHT = 50;
export const SCROLL_TO_ANCHOR_TIMEOUT = 600;

/** used when a function requires timings in milliseconds */
export const SECONDS = 1000;

export const lastOldAnteoIssue = "111";

export const EXTRA_LANGS = ["ar", "de", "en", "fr", "gr", "ru", "tr", "es"];
export type ExtraLangs = "ar" | "de" | "fr" | "gr" | "ru" | "tr" | "es";
export type DefaultLangs = "it" | "en";

/** SWIPER - inizio */
export const SCROLL_Y: number = 150;
export const LEFT: number = 2;
export const RIGHT: number = 4;
export const UP: number = 8;
export const DOWN: number = 16;
/** SWIPER - fine */

export type HighlightColor = "accent" | "blue" | "green" | "primary" | "red" | "white" | "yellow";

export type SectionTheme = 'theme--light' | 'theme--lighter' | 'theme--dark' | 'theme--filter' | 'theme--filter-red' | 'theme--filter-lightgrey';
export type resizeEventResult = {height?: number, width?: number, mode?: "desktop" | "mobile"};

export type FileType = "IMG" | "PDF" | "PPT" | "SVG" | "VID" | "XLS" | "other";
export type ClickableType = "button" | "link" | "text";
export type ButtonsTheme = "btn_color__blue" | "btn_color__green" | "btn_color__white" | "btn_color__yellow" ;
export type SectionType = 'intro' | 'anteo' | 'contacts' | 'contacts-people' | 'last' | 'generic' | 'generic-left' | 'overview' | 'network' | 'half-section' | 'accordion-list' | 'accordion-list-images' | 'accordion-list-paragraphs' | 'news-frontpage' | 'alumni-stories' | 'two-even-columns' | 'multi-image' | 'special' | 'stacked'; 
export type SectionSubType = 'video-and-2-columns' | 'image' | 'svg' | 'carousel'| 'carousel-tag' | 'cards' | 'multi-text' | 'grid';
export type NewsCategory = 'SOCIAL_INITIATIVES' | 'HEALTH' | 'BUSINESS' | 'CAREER' | 'ENVIRONEMENT';
export type Social = 'facebook' | 'instagram' | 'linkedin' | 'twitter' | 'youtube';
export type SpecialMessageGradientType = 'BLUE' | 'CYAN' | 'GREEN' | 'PINK' | 'YELLOW';

export type HierarchyType = "chairman" | "vice-chairmen" | "directors" | "senior-partners" | "partners";
export type TrendingTopicsType = "news" | "video" | "report" | "dataviz" | "podcast" | "tableau";
export type FilterType = "radio" | "checkbox" | "list";

export type AlumniFormMode = "register" | "login";

export const cancelletto: string = "#";


export interface IRouteDataOptions {
    slug?: string,
    id?: string,
    highlightColor?: HighlightColor,
    forceDarkHeader?: boolean,
    forceTitle?: boolean,
    isAdvisorPage?: boolean,
    showLangSelector?: boolean,
    forceBG?: boolean,
    redirectLang?: boolean,
    meta_description?: string,
}

export interface IBaseComponent {
    slug: string,
    locale: string
}

export interface IBasePage {
    _category: string,
    title: string,
    slug: string,
    locale: string,
    status: "published" | "draft",

    sections?: Array<IBaseSection>,

    /** some pages have a "special" language that overrides the italian one */
    forceLocale?: ExtraLangs,
    /** used to know the alternative translation from the eng page */
    otherLocale?: ExtraLangs,

    /** lista generica */
    list?: any[],

    _meta?: any
}

export interface IBaseSection {
    id?: string,

    /** the TYPE of the section 
     * (eg: intro, generic, anteo, ...) */
    type?: SectionType,
    /** if section.type == "generic", a subType may be specified */
    subType?: SectionSubType,
    /** the THEME of the section
     *  (eg: theme--light, theme--filter, ...) */
    theme: SectionTheme,
    
    anchorId?: string,
    title: string,
    subtitle?: string,

    text?: string,
    innerHtml?: string,
    media?: IMedia,
    backImage?: string,
    backImages?: string[],
    video?: IMedia,
    carousel?: IHorizontalScrollCarousel,
    links?: {
        title: string,
        route?: string,
        list: { text: string, route: string }[]
    }[],
    buttons?: IButtonLink[],
    offices?: IOfficesList[],

    list?: any[],

    /** general-article */
    date?: string,
    pubDate?: string,
    edition?: string,
    category?: string,
    author?: string,

    _meta?: any
}

export interface ISomeNumber {
    title: string,
    number: string,
    subtitle?: string,
    link?: string,
    route?: string,
    id?: string
}

export interface IIntro extends IBaseSection {
    some_numbers?: ISomeNumber[]
}

export interface ISectionNetwork extends IBaseSection {
    networkLinks?: {
        title: string,
        list: IOfficesList[]
    }[],
    mediaList?: IMedia[],
    mail?: string
}

export interface ISectionSocialResponsibility extends IBaseSection {
    mediaList?: IMedia[]
}

export interface IMedia {
    id?: any;

    /** type of the media */
    type: FileType,
    /** src URL for media (image, video, ...) */
    contentUrl?: string,
    /** src URL for image's thumbnail or preview image / snapshot for video, ... */
    previewUrl?: string,
    /**
     * percentage_width must be written like "100%" (with "%");
     * default value: 50%
     */
    percentage_width?: string,

    /** (optional) title */
    title?: string,
    /** (optional) description or other text */
    text?: string,

    /** alternative string for an image/video/other */
    alt?: string,

    /** must have a link or a route (NOT both)*/
    link?: string,
    /** must have a link or a route (NOT both)*/
    route?: string,

    downloadable?: boolean;
    selected?: boolean;

    /** (optional) extra data */
    _meta?: any
}


export interface IButtonLink {
    type: ClickableType,
    label: string,
    text?: string,
    id?: string,
    theme?: ButtonsTheme,
    bgType?: "light" | "dark",
    media?: IMedia,

    /** must have a link or a route (NOT both)*/
    link?: string,
    route?: string,

    /** to have a button with custom behaviour (no link, no route) */
    customBehaviour?: "more" | "subscribe" | "sign-up" | "log-in" | "register" | "calc" | "label-translation",

    date?: string,
    pubDate?: string,

    _meta?: any /** "isDownloadable", etc. */
}

export interface IHorizontalScrollCarousel {
    slide_height: string,
    carousel__highlight_color?: HighlightColor,
    slides: IMedia[],
    speed?: "slow" | "medium" | "fast",

    _meta?: any
}

export interface IStyle {
    p?: {
      font_family?: string,
      font_size?: string,
      color?: string
    }
}

/** es: Our Culture -> Formazione */
export interface IMultiText {
    default?: IMedia,  //grafica di default e testo sottostante (ha solo contentUrl e text)
    list: IMedia[]  //voci da presentare e relativa grafica (ha anche title)

    //TODO: capire se ha senso lasciarle così o se è meglio che default sia in realtà list[0]
}


export interface NewsletterSubscriptionForm {
    "email": string,
    "firstName": string,
    "lastName": string,
    "company"?: string,
    "companyCountry"?: string,
    "country"?: string,
    "job"?: string,  //position
    "jobCategory"?: string,
    "jobCategory_other"?: string,
}

export enum NewslettersSlugs {
    Anteo = 'ANTEO',
    RiskNews = 'RISK_NEWS',
    TrendingTopics = 'TRENDING_TOPICS',
    PromNext = 'PROM_NEXT'
}