<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">
    <app-side-navbar class="side-navbar desktop-only" [data]="data.sections" [bulletColor]="highlightColor"></app-side-navbar>
    <app-long-arrow-down class="desktop-only" [color]="highlightColor"></app-long-arrow-down>

    <div class="financial-advisory">
        <ng-container *ngFor="let s of data.sections; let i = index">
            <app-section-intro-bg *ngIf="s.type == 'intro'" [data]="s" [mainTitle]="data.title" [highlightColor]="highlightColor"></app-section-intro-bg>

            <app-section-general *ngIf="s.type != 'intro' && s.type != 'accordion-list' &&  s.type != 'contacts' &&  s.type != 'overview'" [data]="s" [opt]="{'highlightColor': highlightColor, 'titleColor': highlightColor}"></app-section-general>

            <app-section-collapsable-list [data]="s" [highlightColor]="highlightColor" [noPaddingBottom]="checkIfNoPaddingBottom(i)" [shortPaddingTop]="checkIfShortPaddingTop(i)"></app-section-collapsable-list>

            <app-section-overview *ngIf="s.type == 'overview'" [data]="s" [opt]="{'highlightColor': highlightColor, 'titleColor': highlightColor}"></app-section-overview>

            <section *ngIf="s.type == 'contacts'" spyTarget="{{s.anchorId}}" class="{{s.theme}} section center-vertical office-contacts">
                <ng-container *ngIf="s._meta?.sectionBackImage">
                    <div class="section--image-filter"></div>
                    <div class="section--BGI" [ngStyle]="{'background-image': 'url('+s._meta?.sectionBackImage+')' }"></div>
                </ng-container>

                <h1 data-aos="fade-up" class="section_h1 neutra2_book text--on-dark">{{ s.title }}</h1>

                <div class="office-contacts--list">
                    <app-business-card-office data-aos="fade-up" *ngFor="let office of s.offices" class="office-contacts--list--item" [data]="office"></app-business-card-office>
                </div>
            </section>

        </ng-container>

        <section class="last center-vertical">
            <h2 class="prom-list neutra2_book">{{ 'FINANCIAL_ADVISORY.NEWSLETTER' | translate }}</h2>

            <app-prometeia-button class="subscribe-button interactive" [routerLink]="['/', translate.currentLang, 'newsletter']" fragment="anteo" routerLinkActive="active" [data]="{
                                        type: 'button',
                                        label: '',
                                        id: 'anteo',
                                        theme: 'btn_color__white',
                                        bgType: 'dark',
                                        customBehaviour: 'subscribe'
                                    }"></app-prometeia-button>
        </section>

        <section class="outro section center-vertical">
            <div class="outro--image-filter {{outroThemeColor}}" [ngStyle]="{'opacity': outroThemeOpacity}" *ngIf="s0.backImage"></div>
            <div class="outro--BGI" [ngStyle]="{'background-image': 'url('+s0.backImage+')'}" *ngIf="s0.backImage"></div>

            <div class="links" *ngIf="s0.links">
                <p class="prom-section links--title {{outroThemeColor}}--title">
                    {{ s0.links[0].title }}
                </p>
                <ul class="links--list">
                    <li class="links--item" *ngFor="let link of s0.links[0].list">
                        <a class="explore-link" [routerLink]="['/',translate.currentLang, link.route]">{{link.text}}</a>
                    </li>
                </ul>
            </div>
        </section>

    </div>

</ng-container>
