import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { SafeHtml } from '@angular/platform-browser';
import { IFlippingCard, ISectionOptions } from '../../models/components.interface';
import { cancelletto, IMedia, lastOldAnteoIssue } from '../../models/commons';
import { ComponentsServices } from '../../services/components.service';
import { Subject } from 'rxjs';
import { VideoService } from 'src/app/services/video.service';
import { IAnteo, IHighlightsListItem, IOurCultureSection } from 'src/app/models/pages.interface';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { SortingServices } from 'src/app/services/sorting.service';
import { separator } from 'src/app/models/paths';
import { CookieService } from 'src/app/services/cookie.service';

const cheerio = require('cheerio');

@Component({
  selector: 'app-section-general',
  templateUrl: './section-general.component.html',
  styleUrls: ['./section-general.component.scss']
})
export class SectionGeneralComponent implements OnInit {

  @Input() data: IOurCultureSection | any;
  @Input() opt: ISectionOptions = {titleColor: "accent", highlightColor: "accent"};
  sectionOptions: ISectionOptions;

  sectionSVG: string;
  isMobile: boolean;

  openVideoSubject: Subject<boolean> = new Subject<boolean>();
  videoSource: IMedia;

  backImage: string;
  changeBackImageInterval: any;

  highlightsFirstLoad: boolean;
  carouselSlidesFilters: string[];

  alumniStoriesCounter: number = 3;
  alumniStoriesIncrement: number = this.alumniStoriesCounter * 2;

  public marketingCookieEnabled: boolean = false;

  constructor(public translate: TranslateService, 
              public componentsServices: ComponentsServices, 
              private http: HttpClient, 
              private videoService: VideoService,
              private api: ApiService,
              private sort: SortingServices,
              private cookieServices: CookieService) { }

  ngOnInit() {
    this.sectionOptions = this.opt;
    this.marketingCookieEnabled = this.cookieServices.isMarketingEnabled();

    if (this.data?.media?.type == "SVG") {
      const src: IMedia = this.data.media;
      this.loadSVG(src.contentUrl);
    }

    this.isMobile = !this.componentsServices.isDesktopMode();
    this.changeImageInterval();
    this.changeBackImageInterval = setInterval(() => { this.changeImageInterval(); }, 5000);
    // console.log("is", this.isMobile);

    if (this.data?.highlights) {
      this.highlightsFirstLoad = true;
      this.resetHighlightsFilter();
    }

    if (this.data?.title?.toLowerCase() == "anteo" || this.data?.anchorId?.toLowerCase() == "anteo" ) {
      if (this.data.buttons && this.data.buttons.length > 0) {
        this.data.buttons[0].label= "Anteo";
        this.data.buttons[0].id = "latest";
        this.updateAnteoButton();
      }
    }

    if (this.data.type == 'alumni-stories') {
      // console.log("updating alumni-stories...");
      this.loadAlumniStories();
    }
  }

  protected async updateAnteoButton() {
    try {
      let archive: IAnteo[] = await this.api.anteo.getList() as unknown as IAnteo[];
      archive = this.sort.byYear_newerAnteo(archive);
      // console.log(category, ">", archive[0].issueNumber);
      this.data.buttons[0].label= "Anteo " + archive[0].issueNumber;
      this.data.buttons[0].id = "anteo" + archive[0].issueNumber;
    }
    catch(ex) {
      console.error(ex);

      this.data.buttons[0].label= "Anteo " + lastOldAnteoIssue;
      this.data.buttons[0].id = "anteo" + lastOldAnteoIssue;
    }
  }

  protected async loadAlumniStories() {
    try {
      let category = "alumni" + cancelletto + "story";
      let archive: any[] = await this.api.archives.get(category);
      // console.log(category, ">", archive);
      
      let btns = [];
      archive.forEach(element => {
        let name = ""; //element.sections[0].title.replace(/Intervista a /gi, "");  //gi -> Global + case Insensitive
        let subtitleInnerHtml = "";

        let es1ih: string = element.sections[1].innerHtml;
        if (!es1ih.startsWith("<") && (es1ih.includes('<br>') || es1ih.includes('<br />'))) { //patch for old data
          console.log("prima-->", es1ih);
          es1ih = "<p>" + es1ih.replace(/<br>/gi, "</p><p>").replace(/<br \/>/gi, "</p><p>") + "</p>";
          console.log("dopo--->", es1ih);
        }

        const $ = cheerio.load(es1ih, null, false);
        const rows = [];
        $('*').each((i, elem) => {
          const e = ($(elem).text() || '').trim();
          if (e?.length > 0) rows.push(e);
        });
        const [firstName, ...other] = rows;
        // console.log(firstName, other.join(', '));
        name = firstName;
        subtitleInnerHtml = other.join(', ');
        
        // console.log(">sih>", "|" + subtitleInnerHtml + "|");

        const btn = {
          media: element.sections[0].media,
          route: element.slug.split(separator)[0],
          id: element.slug.split(separator)[1],
          label: name,
          text: subtitleInnerHtml,
          // text: element.sections[1].innerHtml.replace(/<br>/g, ", ").replace(/, ,/g, ",").split(name + ", ")[1],
          _meta: {pubDate: element.sections[0].pubDate || element.sections[0].date},
          // text: this.sort.stringToSimpleDate(element.sections[0].date) || element.sections[0].pubDate,
        }
        btns.push(btn);
      });
      btns = this.sort.byDate_meta(btns);
      // console.log("new content", btns);

      this.data.buttons = btns;
    }
    catch(ex) {
      console.error(ex);
    }
  }

  skipTitleColor(): boolean {
    const c = this.sectionOptions.titleColor;
    const t = this.data.theme;

    if (!c || !t) {
      return false;
    }

    if ((c == 'primary' || c == 'blue') && (t == 'theme--dark' || t == 'theme--filter')) {
      return true;
    }

    if (c == 'yellow') {
      return true;
    }

    return false;
  }

  loadSVG(pathURL: string) {
    // console.log("path:", pathURL);

    const backupLOCAL = '/assets/svg/timeline.html';
    const backupURL = "/cdn/svg/timeline.html";

    /** must be a LOCAL path */
    this.http.get(pathURL, { responseType: 'text' }).subscribe((data: string) => { 
      //console.log(data);
      this.sectionSVG = data;
    });
  }

  openVideo(media: IMedia) {
    // console.log(">>>", media.contentUrl);
    /* this.videoSource = media;
    this.openVideoSubject.next(true); */


    this.videoService.playThisVideo(media);
  }

  ngOnDestroy() {
    clearInterval(this.changeBackImageInterval);
  }

  backImagesIndex = 0;
  changeImageInterval() {
    if (this.data.backImages) {
      if (this.backImagesIndex == this.data.backImages.length) this.backImagesIndex = 0;
    }

    if (this.data.backImage || this.data.backImages) {

      this.backImage = this.data.backImages ? this.data.backImages[this.backImagesIndex] : this.data.backImage;
    }

    this.backImagesIndex++;
  }

  highlightClickSingleFilter(ht: IHighlightsListItem) {
    this.highlightClick(ht, true);
  }

  highlightClick(ht: IHighlightsListItem, singleFilter: boolean = false) {
    let incomingFilter: string = null;

    if (this.highlightsFirstLoad) {
      this.data.highlights.list.forEach((item: IHighlightsListItem) => {
        item.isSelected = false;
      });

      this.highlightsFirstLoad = false;
    }

    if (ht.id) {
      // console.log("filter by ID:", ht.id);
      incomingFilter = ht.id.toLowerCase();
    } else if (ht.text) {
      // console.log("filter by name:", ht.text);
      incomingFilter = ht.text.toLowerCase();
    } else {
      incomingFilter = null;
    }

    if (!this.carouselSlidesFilters || this.carouselSlidesFilters.length < 1) {
      this.carouselSlidesFilters = [];
    }

    if (!this.carouselSlidesFilters.includes(incomingFilter)) {
      this.carouselSlidesFilters.push(incomingFilter);
      if (singleFilter) {
        this.carouselSlidesFilters = [incomingFilter];
        const htWas = ht.isSelected;
        this.data.highlights.list.forEach((item: IHighlightsListItem) => {
          item.isSelected = false;
        });
        ht.isSelected = htWas;
        // console.log(">>", this.data.highlights.list);
      }
      ht.isSelected = true;
    } else {  // filter already exists in filter list
      const index = this.carouselSlidesFilters.indexOf(incomingFilter, 0);
      if (index > -1) {
        this.carouselSlidesFilters.splice(index, 1);
      }
      ht.isSelected = false;

      if (this.carouselSlidesFilters.length < 1) this.carouselSlidesFilters = null;
    }

    /** forces the HorizontalScrollCarousel's OnChanges */
    this.carouselSlidesFilters = this.carouselSlidesFilters ? this.carouselSlidesFilters.concat() : null;
    
    // if (this.carouselSlidesFilters) console.log("filters:", this.carouselSlidesFilters);
    // else console.log("filters disabled", this.carouselSlidesFilters);


    this.highlightsFirstLoad = true;
    this.data.highlights.list.forEach((item: IHighlightsListItem) => {
      this.highlightsFirstLoad = this.highlightsFirstLoad && !item.isSelected;
    });
    this.resetHighlightsFilter();
  }

  resetHighlightsFilter(force?: boolean): void {
    if (this.highlightsFirstLoad || force) {
      this.data.highlights.list.forEach((item: IHighlightsListItem) => {
        if (!item.isSelected) item.isSelected = true;
      });
    }
  }

  increaseAlumniStoriesCounter(): void {
    this.alumniStoriesCounter = this.alumniStoriesCounter + this.alumniStoriesIncrement;
  }

  checkCarouselHeight(): boolean {
    const slide_h__string: string = (this.data.carousel.slide_height as string).split("px")[0];
    const slide_h: number = +slide_h__string;
    if (slide_h > 100) return false;
    return true;
  }

  isLargeTitleForLargeCards(): boolean {
    if (!this.data.cards?.length) return false;
    
    return this.data.cards.find((c: IFlippingCard) => c.cardType == "large") != undefined;
  }

}
