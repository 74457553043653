<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>

<ng-container *ngIf="data && data.job && id == data.job.id">
    <div class="prom-page">
        <div class="offer section-max-content">
            <div class="offer--body">
                <div class="offer--h prom-caption___lato-heavy">
                    <span>{{data.job.area}}</span>
                    <span>{{data.job.subtitle}}</span>
                </div>
                <h1 class="offer--title neutra2_demi text--primary">{{data.job.title}}</h1>
                <div class="neutra2_book inner-html" [innerHtml]="data.job.body | safe:'html'"></div>
                <div class="offer--button {{classBtn}}">
                    <app-prometeia-button [data]="data.btn"></app-prometeia-button>
                </div>
                <div class="bottomline--socials" *ngIf="socials_list">
                    <app-social-share *ngFor="let soc of socials_list" [data]="soc"></app-social-share>
                </div>
            </div>
            <div class="job-offer--job-list">
                <a class="offer--link-label prom-caption___lato-regular" href="{{ allOffersPath }}">
                    {{ 'JOB_OFFERS.ALL_OFFERS' | translate }}
                </a>
                
                <div class="job-offer--job-list-wrap" *ngIf="data.sections">
                    <ng-container *ngFor="let job of data.sections">
                        <app-single-job-offer *ngIf="job.type != 'last' && id != job.id" [class]="'offer'" [job]="job"></app-single-job-offer>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>
    <div class="culture" *ngFor="let s of data.sections">
        <section *ngIf="s?.type == 'last'" spyTarget="last" class="section center-vertical section--last">
            <div class="section--last-bg">
                <div class="lc lc--background-color--yellow">
                </div>
            </div>
            <div class="container section-max-content">
                <div class="lc lc--background-color--yellow">
                    <div class="lc--sub lc--sub1">
                        <img class="career-app-logo" src="{{s.list[0].contentUrl}}" />
                    </div>

                    <div class="lc--sub lc--sub2">
                        <div class="lc--sub2--text">
                            <p class="text--primary prom-contact lc--sub--title title1 bold">{{s.list[0].title.split('\n')[0]}}</p>
                            <p class="text--primary prom-contact lc--sub--title title2 bold">{{s.list[0].title.split('\n')[1]}}</p>
                            <p class="text--primary lc--sub--text">{{s.list[0].text}}</p>
                        </div>

                        <a class="badge--wrap--appstore" target="_blank" href="https://apps.apple.com/it/app/prometeia-career/id1458111294">
                            <input class="badge--appstore" type="image" src="{{ 'BADGE.APP_STORE' | translate }}" />
                        </a>
                        <a class="badge--wrap--googleplay" target="_blank" href="https://play.google.com/store/apps/details?id=it.prometeia.career&hl={{translate.currentLang}}">
                            <input class="badge--googleplay" type="image" src="{{ 'BADGE.GOOGLE_PLAY' | translate }}" />
                        </a>
                    </div>
                </div>

                <div class="rc">
                    <div class="rc--sub">
                        <img class="prom-next-logo" src="{{s.list[1].contentUrl}}" />
                    </div>

                    <div class="rc--sub">
                        <p class="text--primary prom-contact title">{{s.list[1].title}}</p>
                    </div>

                    <div class="rc--sub">
                        <!-- <p class="spacer"></p> -->
                        <app-prometeia-button class="subscribe-button interactive" [routerLink]="['/', translate.currentLang, 'newsletter']" [fragment]="s.list[2].id" routerLinkActive="active" [data]="s.list[2]"></app-prometeia-button>
                        <!-- <p class="spacer"></p> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container>
