<section *ngIf="!(data.type == 'intro' || data.type == 'last') && 
    (
        data.type == 'generic' || data.type == 'generic-left' || data.type == 'documents-list' || 
        data.text || data.multi_text || data.carousel || data.buttons?.length > 0
    )"
    spyTarget="{{data.anchorId}}" class="{{data.theme || 'theme--lighter'}} section center-vertical">

    <app-video-embedder *ngIf="marketingCookieEnabled" [isOpenEvt]="openVideoSubject.asObservable()"></app-video-embedder>
    
    <div class="section--image-filter" *ngIf="backImage"></div>
    <div class="section--BGI" [ngStyle]="{'background-image': 'url('+backImage+')' }" *ngIf="backImage"></div>

    <div [ngClass]="{'two-columns': data.type == 'anteo' || data.type == 'generic-left' || data.type == 'documents-list'}" class="section-max-content">
        <div [ngClass]="{'two-columns--title-and-text': data.type == 'anteo' || data.type == 'generic-left' || data.type == 'documents-list'}">
            <h1 data-aos="fade-up" class="section_h1 neutra2_book text--{{sectionOptions.titleColor || 'accent'}}" 
                *ngIf="!skipTitleColor() && !(data.type == 'anteo' || data.type == 'generic-left' || data.type == 'documents-list')">{{ data.title }}</h1>
            <h1 data-aos="fade-up" class="section_h1 neutra2_book" *ngIf="skipTitleColor() && !(sectionOptions.titleColor == 'yellow')">{{ data.title }}</h1>
            <h1 data-aos="fade-up" [ngClass]="{'section_h1 text--primary': true, 'neutra2_book': !isLargeTitleForLargeCards(), 'neutra2_bold': isLargeTitleForLargeCards(), 'negative-bottom-margin-for-cards-title': isMobile && isLargeTitleForLargeCards()}" *ngIf="skipTitleColor() && (sectionOptions.titleColor == 'yellow')">
                {{ data.title }}
            </h1>
            <h1 data-aos="fade-up" class="section_h1 two-columns--title-and-text--title neutra2_book text--{{sectionOptions.titleColor || 'accent'}}"
                *ngIf="!skipTitleColor() && (data.type == 'anteo' || data.type == 'generic-left' || data.type == 'documents-list')">{{ data.title }}</h1>
            
            <div [ngClass]="{'text-container': true, 'with-additional-text flexible': data.type == 'generic' && (data.subType == 'video-and-2-columns') && data.htmlTexts?.length}">
                <ng-container *ngIf="data.text && !(data.type == 'anteo' || data.type == 'generic-left')">
                    <p data-aos="fade-up" [ngClass]="{'section--text': true, 'wider-text': isLargeTitleForLargeCards()}" *ngIf="!data.text.startsWith('<') || !data.text.endsWith('>')">{{data.text}}</p>
                    <div data-aos="fade-up" [ngClass]="{'section--text inner-html': true, 'wider-text': isLargeTitleForLargeCards()}" *ngIf="data.text.startsWith('<') && data.text.endsWith('>')" [innerHtml]="data.text"></div>
                </ng-container>

                <div data-aos="fade-up" [ngClass]="{'section--text two-columns--title-and-text--text inner-html inner-html--SIM': true, 'text-column-with-image': data.media?.type == 'IMG', 'inner-html--downloadable-anchors': data.type == 'generic-left'}" *ngIf="data.text && (data.type == 'anteo' || data.type == 'generic-left')" [innerHtml]="data.text | safe:'html'"></div>

                <ng-container *ngFor="let textAdditional of data.htmlTexts">
                    <div data-aos="fade-up" *ngIf="data.type == 'generic' && (data.subType == 'video-and-2-columns') && textAdditional" class="section-text-additional inner-html inner-html--{{data.subType}} inner-html--p-no-margin" [innerHtml]="textAdditional | safe:'html'"></div>
                </ng-container>
            </div>
                

            <!-- documents-list -->
            <div data-aos="fade-up" class="section--documents-list" *ngIf="data.type == 'documents-list' && data.buttons?.length > 0">
                <ng-container *ngFor="let t of data.buttons">
                    <p class="section--documents-list--item text--primary" *ngIf="t.link || t.route">
                        <span class="icon-bottoni_download"></span>
                        <a class="neutra2_bookItalic text--primary interactive underline" [routerLink]="['/', translate.currentLang, t.route ]" *ngIf="t.route">
                            {{t.label}}
                        </a>
                        <a class="neutra2_bookItalic text--primary interactive underline" [href]="t.link" *ngIf="t.link && !t.route">
                            {{t.label}}
                        </a>
                    </p>
                </ng-container>
            </div>
        </div>

        <div [ngClass]="{'two-columns--other': data.type == 'anteo' || data.type == 'generic-left', 'text-column-with-image': data.media?.type == 'IMG'}">
            <img data-aos="fade-up" class="section--image two-columns--other--img"
                 [ngStyle]="componentsServices.isDesktopMode() && data.media.percentage_width && {'width': data.media.percentage_width}"
                 *ngIf="(data.type == 'anteo') && (data.media?.type == 'IMG')" src="{{data.media.contentUrl}}" aria-hidden="true" alt="{{data.media.alt}}">

            <div class="two-columns--other--vertical-spacer" *ngIf="(data.type == 'anteo') && !(data.media)"></div>

            <div data-aos="fade-up" class="section--buttons-container two-columns--other--btn" *ngIf="(data.type == 'anteo') && data.buttons?.length > 0">
                <ng-container *ngFor="let btn of data.buttons">
                    <app-prometeia-button   *ngIf="btn.type == 'button'" 
                                            class="sections--button two-columns--other--btn--button" [data]="btn"></app-prometeia-button>

                    <a  data-aos="fade-up" class="text--primary prom-caption___lato-italic two-columns--other--link"
                        *ngIf="!btn.type || btn.type == 'link'" style="display: block"
                        [routerLink]="['/', translate.currentLang, btn.route.split('#')[0] ]" [fragment]="btn.route.split('#')[1]" routerLinkActive="active">
                        {{btn.label}}
                    </a>
                </ng-container>
            </div>
        </div>
    </div>

    <img data-aos="fade-up" class="section--image" [ngClass]="{'full-size-image': data.media._meta?.hasFullSize}" aria-hidden="true" alt="{{data.media.alt}}"
         src="{{(!componentsServices.isDesktopMode() && data.media.previewUrl) ? data.media.previewUrl : data.media.contentUrl}}"
         [ngStyle]="componentsServices.isDesktopMode() && data.media.percentage_width && {'width': data.media.percentage_width}"
         *ngIf="!(data.type == 'anteo' || data.type == 'generic-left' || data.type == 'overview') && (data.media && data.media.type == 'IMG')">

    <div data-aos="fade-up" class="section--svg desktop-only" *ngIf="data.media && data.media.type == 'SVG' && !isMobile" [innerHtml]="sectionSVG | safe:'html'"></div>
    <div data-aos="fade-up" class="section--svg section--svg--mobile mobile-only" *ngIf="data.media && data.media.type == 'SVG' && data.media.previewUrl && isMobile">
        <img src="{{data.media.previewUrl}}">
    </div>

    <div data-aos="fade-up" class="section--video-container interactive" *ngIf="data.media && data.media.type == 'VID' && marketingCookieEnabled" (click)="openVideo(data.media)">
        <!-- <span class="icon icon-video icon-video-player_player-mobile mobile-only"></span> -->
        <!-- todo: embed video (image preview + video url: data.video.contentUrl)  -->
        
        <div class="wrap-icon-video">
            <img class="round-image" src="{{data.media.previewUrl}}" aria-hidden="true" alt="{{data.media.alt}}_preview" *ngIf="data.media.previewUrl">
            <span class="icon icon-video"></span>
        </div>
    </div>

    <div data-aos="fade-up" [ngClass]="{'section--flipping-cards': true, 'no-margin-bottom': isLargeTitleForLargeCards()}" *ngIf="data.cards">
        <app-flipping-cards [cards]="data.cards" [highlightColor]="sectionOptions.highlightColor"></app-flipping-cards>
    </div>

    <div data-aos="fade-up" class="section--highlights-container" *ngIf="data.highlights">
        <div *ngFor="let ht of data.highlights.list" (click)="highlightClickSingleFilter(ht)">
            <app-highlighted-text [ngClass]="{'highlight': true, 'highlight--selected': ht.isSelected, 'highlight--not-selected': !ht.isSelected}"
                                  [text]="ht.text" [highlighter]="sectionOptions.highlightColor"></app-highlighted-text>
        </div>
    </div>

    <div data-aos="fade-up" *ngIf="data.carousel"
         [ngClass]="{'section--carousel': true, 'carousel-with-space-above': (!data.text || !data.highlights) && checkCarouselHeight()}">
        <app-horizontal-scroll-carousel [data]="data.carousel" [filters]="carouselSlidesFilters" [sectionTheme]="data.theme"></app-horizontal-scroll-carousel>
    </div>

    <div data-aos="fade-up" class="section--multi-text" *ngIf="data.multi_text">
        <app-multi-text [data]="data.multi_text" [highlighter]="sectionOptions.highlightColor"></app-multi-text>
    </div>

    <div data-aos="fade-up" class="section--buttons-container" *ngIf="!(data.type == 'anteo' || data.type == 'generic-left' || data.type == 'documents-list' || data.type == 'alumni-stories') && data.buttons?.length > 0">
        <app-prometeia-button class="sections--button" *ngFor="let btn of data.buttons" [data]="btn"></app-prometeia-button>
    </div>

    <div data-aos="fade-up" class="section--alumni-stories-container" *ngIf="(data.type == 'alumni-stories') && data.buttons?.length > 0">
        <a data-aos="fade-up" class="section--alumni-stories-container--alumn interactive no-underline" *ngFor="let alumn of data.buttons; let i = index"
            [routerLink]="['/', translate.currentLang, alumn.route, alumn.id ]">

            <ng-container *ngIf="i < alumniStoriesCounter">
                <img class="section--alumni-stories-container--alumn--image" src="{{alumn.media.contentUrl}}" alt="{{alumn.media.alt}}">
                <p class="neutra2_demi text--primary section--alumni-stories-container--alumn--name">{{alumn.label}}</p>
                <p class="text--on-light section--alumni-stories-container--alumn--text">{{alumn.text}}</p>
            </ng-container>
        </a>
    </div>
    <div data-aos="fade-up" class="section--alumni-stories-container--button" *ngIf="(data.type == 'alumni-stories') && data.buttons && data.buttons.length > alumniStoriesCounter">
        <app-prometeia-button class="btn interactive" (click)="increaseAlumniStoriesCounter()"
            [data]="{
                type: 'button',
                label: '',
                id: 'more-stories',
                theme: 'btn_color__yellow',
                customBehaviour: 'more'
            }">
        </app-prometeia-button>
    </div>

    <div data-aos="fade-up" class="section--links-table" *ngIf="!(data.type == 'anteo' || data.type == 'generic-left') && data.links?.length > 0">
        <ng-container *ngFor="let x of data.links">
            <!-- <p class="section--links-table--header text--primary capslock prom-caption___neutra-bold">
                {{x.title}}
            </p> -->
            <!-- <div class="section--links-table--row"> -->
                <div class="section--links-table--cell" *ngFor="let y of x.list; let i = index">
                    <p [ngClass]="{'section--links-table--header text--primary capslock prom-caption___neutra-bold': true, 'invisible': i != 0}">
                        {{x.title}}
                    </p>

                    <a  class="link text--primary prom-caption" *ngIf="y.route && !y.id"
                        [routerLink]="['/', translate.currentLang, y.route]" routerLinkActive="active">
                        {{y.text}}
                    </a>

                    <a  class="link text--primary prom-caption" *ngIf="y.route && y.id"
                        [routerLink]="['/', translate.currentLang, y.route, y.id]" routerLinkActive="active">
                        {{y.text}}
                    </a>

                    <a  class="link text--primary prom-caption" *ngIf="y.link && !y.route && !y.id"
                        href="{{y.link}}" target="_blank">
                        {{y.text}}
                    </a>
                </div>
            <!-- </div> -->
        </ng-container>
    </div>
</section>