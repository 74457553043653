import { Component, HostListener } from '@angular/core';
import { Throttle } from 'angular-debounce-throttle';
import { ScrollSpyService } from 'ng-spy';
import { Subscription } from 'rxjs';
import { ICareers } from 'src/app/models/pages.interface';
import { BasePageComponent } from '../base-page.component';

const TOP_JOB_OFFERS_TO_SHOW: number = 3;

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent extends BasePageComponent<ICareers> {

  private pageDataReadySubscription: Subscription = null;

  jobOffersWideCards: any[] = [];

  constructor(private spyService: ScrollSpyService) {
    super();

    this.pageDataReadySubscription?.unsubscribe();
    this.pageDataReadySubscription = this.dataServices.pageDataReady.subscribe(() => {
      this.spyService.spy({ 
        thresholdTop: this.componentsServices.getInnerHeight() / 2, 
        thresholdBottom: this.componentsServices.getInnerHeight() / 2 
      });
    });
  }

  load() {
    super.load();

    this.loadJobOffers();
  }

  async loadPageData() {
    await super.loadPageData();

    this.setJobOffersWideCards();
  }

  async loadJobOffers() {
    try {
      const jobOffers = await this.api.career.getJobOffersList();
      
      if (jobOffers?.offers?.length) {
        this.jobOffersWideCards = jobOffers.offers.slice(0, TOP_JOB_OFFERS_TO_SHOW);
        this.setJobOffersWideCards();
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  @HostListener('window:scroll', ['$event'])
  @Throttle(500)
  setJobOffersWideCards() {
    if (!this.data || !this.jobOffersWideCards.length) {
      // console.log("setJobOffersWideCards() - data not ready");
      return;
    }

    const sectionIndex = this.data.sections.findIndex(s => s.type == "special" && s.subType == "cards");

    if (this.data.sections[sectionIndex].wideCards?.length) {
      // console.log("setJobOffersWideCards() - already saved");
      return;
    }

    this.data.sections[sectionIndex].wideCards = this.jobOffersWideCards;
    this.data.sections[sectionIndex].wideCardsType = "job-offers";
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    
    this.spyService.stopSpying();
    super.ngOnDestroy();
    this.pageDataReadySubscription?.unsubscribe();
    this.pageDataReadySubscription = null;
  }

}
