import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AmplifyAuthService, AwsExportsType } from 'src/app/services/amplify-auth.service';
import { ApiArService } from 'src/app/services/api-ar.service';

@Component({
  selector: 'app-ar-dialog-auth',
  templateUrl: './ar-dialog-auth.component.html',
  styleUrls: ['./ar-dialog-auth.component.scss']
})
export class ArDialogAuthComponent  {
  private poolStatusSubscription: Subscription = null;
  data = {
    title: this.translate.instant("AR.TITLE"),
    textPrivacy : this.translate.instant("AR.TEXT")
  }

  pool : AwsExportsType = 'ar';
  tokenCognito : string = '';
  constructor(
    public dialogRef: MatDialogRef<ArDialogAuthComponent>, 
    public translate: TranslateService, 
    private amplifyService: AmplifyAuthService,
    public apiAR: ApiArService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router
    ) {
    this.poolStatusSubscription = this.amplifyService.poolARStatus.subscribe( status => {        
      if(status.islogged){
        this.tokenCognito = status.token;        
        this.loginAR();
      }      
    });
  }

  async loginAR(){    
    try{
      const login = await this.apiAR.libraries.login(this.tokenCognito);
      if(login){       
        this.dialog.closeAll(); 
        this.resevedArea();
      }                  
    }
    catch(error){
      //TODO in lingua
      this.openSnackBar(this.translate.instant('Attenzione, Account non attivo'));
    }
    finally {
      //TODO
    //  await this.amplifyService.logout('ar');
   } 
  }

  resevedArea(){
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate(['/', this.translate.currentLang, 'reserved-area']);
    else window.location.reload();
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
