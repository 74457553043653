import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrowserServices } from 'src/app/services/browser.service';
import { ComponentsServices } from 'src/app/services/components.service';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'app-atlante-app-banner',
  templateUrl: './atlante-app-banner.component.html',
  styleUrls: ['./atlante-app-banner.component.scss']
})
export class AtlanteAppBannerComponent implements OnInit {

  isAndroid: boolean = false;
  isIphone: boolean = false;
  showBanner: boolean = false;
  sessionCookieFound: boolean = false;

  constructor(
    public componentsServices: ComponentsServices, 
    public translate: TranslateService, 
    public browserService: BrowserServices,
    private cookieService: CookieService
    ) { }

  ngOnInit(): void {
    this.isAndroid = this.browserService.myDevice().toLowerCase() == "android";
    this.isIphone = this.browserService.myDevice().toLowerCase() == "iphone";

    // console.log(this.browserService.myDevice(), this.isAndroid, this.isIphone);

    this.sessionCookieFound = this.checkSessionCookie();

    setTimeout(() => {
      this.showBanner = true;
    }, 1000);
  }

  checkSessionCookie(): boolean {
    const session = this.cookieService.loadAtlanteBannerSessionCookie();
    return (session != null);
  }

  closeBanner() {
    this.showBanner = false;
    this.cookieService.setAtlanteBannerSessionCookie(this.browserService.myDevice().toLowerCase());  
  }

}
