import { ElementSchemaRegistry } from '@angular/compiler';
import { HostListener, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SwiperComponent, SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';
import { IButtonLink } from 'src/app/models/commons';
import { paths } from 'src/app/models/paths';
import { BrowserServices } from 'src/app/services/browser.service';
import { SingleSliderCardsComponent } from '../single-slider-cards/single-slider-cards.component';

@Component({
  selector: 'app-slider-cards',
  templateUrl: './slider-cards.component.html',
  styleUrls: ['./slider-cards.component.scss']
})
export class SliderCardsComponent implements OnInit, OnDestroy, OnChanges {
  deviceType: string = "";
  card_temp:any;
  extra_card_index = 0;
  private changeLangSubscription: Subscription = null;

  @Input() news: any;
  @Input() animation: any;
  @Input() events: {
    title: string,
    saveTheDate: string,
    slug: string
  }[];

  route : string = paths.trendingTopicsArticle;

  configNews: SwiperConfigInterface = {
    effect: 'coverflow',
    grabCursor: true,
    loop: true,
    spaceBetween: 400,
    allowSlidePrev: false,
    updateOnWindowResize : true,

    coverflowEffect: {
      depth: 0,
       modifier: 1,
       rotate: 0,
       stretch: 280,
       slideShadows: false
    },
    breakpoints: {

      800: {
        effect: 'slide',
        slidesPerView: 'auto',
        spaceBetween: 60,
        watchSlidesVisibility : true,
        watchSlidesProgress: true,
        loop: false,
        allowSlidePrev: true,
      }
    }
  }

  configEvents = {
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
  }

  buttonLoadMore: IButtonLink = {
    type: "button",
    theme: 'btn_color__green',
    route: paths.trendingTopics,
    label: this.translate.instant("DISCOVER"),
  }

  @ViewChild('swiperEvent') swiperEl: ElementRef;
  @ViewChild(SwiperComponent) swiperNews: SwiperComponent;
  @ViewChildren('swiperSlide') swiperSlide: QueryList<SingleSliderCardsComponent>;


  onSwiperHover( hover: boolean ) {
    if ( hover ) {

      this.swiperEl.nativeElement.swiper.autoplay.stop();
    } else {
      this.swiperEl.nativeElement.swiper.autoplay.start();
    }
  }

  constructor(public translate: TranslateService, private browserServices: BrowserServices) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.news?.currentValue?.length > 0 && this.swiperNews){
      this.swiperNews?.directiveRef.update();
      this.card_temp = this.news[this.extra_card_index];
      this.onIndexChange(0);
    }
  }

  ngOnInit(): void {
    // if (this.events) console.log("sc", this.events);
    // else console.log("sc no events");

    this.deviceType = this.browserServices.myDevice().toLowerCase();

    this.changeLangSubscription?.unsubscribe();
    this.changeLangSubscription = this.translate.onLangChange.subscribe(() => {
      this.buttonLoadMore.label = this.translate.instant("DISCOVER");    
    });
  }



  ngOnDestroy(): void {
    this.changeLangSubscription?.unsubscribe();
    this.changeLangSubscription = null;
  }

  @HostListener('window:resize', ['$event'])
    onresize(event) {
     this.swiperNews.directiveRef.update();
    }

  eventCloseCard(index) {
    let tmp =  Object.assign({}, this.news[index]);
    this.news[index].hidden = true;

    setTimeout(() => {
      this.news.splice(index, 1);
      this.news.push(tmp);
      this.swiperNews.directiveRef.update();
    }, 800);

  }

  onIndexChange(index){
    this.extra_card_index=(index+2)%this.news.length;
    this.card_temp = this.news[this.extra_card_index];
  }

}
