<section [ngClass]="{'intro section center-vertical': true, 'network-style': data._meta?.networkType}" spyTarget="intro">
    <div class="section--image-filter {{introThemeColor}}" [ngStyle]="{'opacity': introThemeOpacity}" *ngIf="data.backImage"></div>
    <div class="section--BGI" [ngStyle]="{'background-image': 'url('+data.backImage+')'}" *ngIf="data.backImage"></div>
    <div class="section-max-content">
        <h2 class="neutra2_bold {{introThemeColor}}--title capslock intro--main-title prom-text"
            [ngClass]="{'text--yellow': (highlightColor == 'yellow')}">{{mainTitle || '‏‏‎ ‎'}}</h2>
        <h1 class="neutra2_bold {{introThemeColor}}--title intro--title" [ngClass]="{'text--yellow': (highlightColor == 'yellow')}">{{ data.title }}</h1>
        
        <div class="intro--content {{introThemeColor}}">
            <!-- <div class="spacer"></div> -->
            
            <p class="intro--content--text" *ngIf="!data.text?.includes('<p>') && !data.text?.includes('</p>') && !data.text?.includes('<br')">{{ data.text }}</p>
            <p class="intro--content--text" [innerHtml]="data.text | safe:'html'" *ngIf="(data.text?.includes('<p>') && data.text?.includes('</p>')) || data.text?.includes('<br')"></p>
            
            <div class="links desktop-only" *ngIf="data.links && data.links[0]?.list?.length > 0">
                <p class="prom-caption___lato-regular lato_bold links--title {{introThemeColor}}--title" [ngClass]="{'text--yellow': (highlightColor == 'yellow')}">
                    {{ data.links[0].title }}
                </p>
                <ul class="links--list">
                    <li class="links--item" *ngFor="let link of data.links[0].list">
                        <!-- <a [ngClass]="{'prom-caption___lato-italic explore-link': true, 'shorter': link.route == 'strategic-advisory'}"
                           [routerLink]="['/', translate.currentLang, link.route]">{{link.text}}</a> -->
                        <ng-container *ngIf="link.route != 'strategic-advisory' && link.route != 'financial-distribution'">
                            <a [ngClass]="{'prom-caption___lato-italic explore-link': true}"
                               [routerLink]="['/', translate.currentLang, link.route]">{{link.text}}</a>
                        </ng-container>
                        <ng-container *ngIf="link.route == 'strategic-advisory' || link.route == 'financial-distribution'">
                            <a [ngClass]="{'prom-caption___lato-italic explore-link': true}"
                               [routerLink]="['/', translate.currentLang, link.route]">{{link.text.split(' & ')[0]}}<br>& {{link.text.split(' & ')[1]}}</a>
                        </ng-container>
                    </li>
                </ul>
            </div>

            <div class="links cities-links desktop-only" *ngIf="networkLinks">
                <div class="links-column" *ngFor="let col of networkLinks; let i = index">
                    <ng-container *ngIf="col.list?.length > 0">
                        <p class="prom-caption___lato-regular lato_bold links--title {{introThemeColor}}--title col{{i}}"
                        [ngClass]="{'text--yellow': (highlightColor == 'yellow')}">
                            {{ col.title }}
                        </p>
                        <ul class="links--list">
                            <ng-container *ngFor="let link of sort.byCity(col.list)">
                                <li class="links--item" *ngIf="link.id != cityID">
                                    <a class="prom-caption___lato-italic explore-link" [routerLink]="['/', LocaleEn, countryPageRoute, link.id]" (click)="onClick()">{{link.city}}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <app-long-arrow-down class="mobile-only" [color]="highlightColor"></app-long-arrow-down>
</section>