<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>

<ng-container *ngIf="data">
    <div class="prom-page job-offer ">
        <section class="intro section-max-content" *ngIf="data.intro && data.intro.type == 'intro'">
            <div class="intro--texts">

                <h2 class="text--primary prom-section no-underline capslock intro--main-title">{{data.title}}</h2>
                <h1 class="neutra2_bold text--primary intro--title">{{data.intro.title}}</h1>

                <p class="intro--text">{{data.intro.text}}</p>
            </div>

            <div class="intro--wrap-btn">
                <app-prometeia-button *ngFor="let btn of data.intro.btns" class="intro--btn interactive" [data]="btn"></app-prometeia-button>
            </div>

        </section>

        <ng-container>
            <div class="job-offer--body ">
                <div class="section-max-content">
                    <app-filters #filtersComponent *ngIf="data.filters" [data]="data.filters" (selectEvent)="select($event)"></app-filters>

                    <div class="job-offer--job-list" *ngIf="data.sections">
                        <ng-container *ngFor="let job of data.sections">
                            <app-single-job-offer [job]="job" *ngIf="job?.type != 'last'"></app-single-job-offer>
                        </ng-container>
                        <p class="text--primary" *ngIf="data.sections.length <= 0">{{ 'JOB_OFFERS.NO_OFFERS' | translate }}</p>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="culture" *ngFor="let s of lastSections">
            <section *ngIf="s.type == 'last'" spyTarget="last" class="section center-vertical section--last">
                <div class="section--last-bg">
                    <div class="lc lc--background-color--yellow">
                    </div>
                </div>
                <div class="container section-max-content">
                    <div class="lc lc--background-color--yellow">
                        <div class="lc--sub lc--sub1">
                            <img class="career-app-logo" src="{{s.list[0].contentUrl}}" />
                        </div>

                        <div class="lc--sub lc--sub2">
                            <div class="lc--sub2--text">
                                <p class="text--primary prom-contact lc--sub--title title1 bold">{{s.list[0].title.split('\n')[0]}}</p>
                                <p class="text--primary prom-contact lc--sub--title title2 bold">{{s.list[0].title.split('\n')[1]}}</p>
                                <p class="text--primary lc--sub--text">{{s.list[0].text}}</p>
                            </div>

                            <a class="badge--wrap--appstore" target="_blank" href="https://apps.apple.com/it/app/prometeia-career/id1458111294">
                                <input class="badge--appstore" type="image" src="{{ 'BADGE.APP_STORE' | translate }}" />
                            </a>
                            <a class="badge--wrap--googleplay" target="_blank" href="https://play.google.com/store/apps/details?id=it.prometeia.career&hl={{translate.currentLang}}">
                                <input class="badge--googleplay" type="image" src="{{ 'BADGE.GOOGLE_PLAY' | translate }}" />
                            </a>
                        </div>
                    </div>

                    <div class="rc">
                        <div class="rc--sub">
                            <img class="prom-next-logo" src="{{s.list[1].contentUrl}}" />
                        </div>

                        <div class="rc--sub">
                            <p class="text--primary prom-contact title">{{s.list[1].title}}</p>
                        </div>

                        <div class="rc--sub">
                            <!-- <p class="spacer"></p> -->
                            <app-prometeia-button class="subscribe-button interactive" [routerLink]="['/', translate.currentLang, 'newsletter']" [fragment]="s.list[2].id" routerLinkActive="active" [data]="s.list[2]"></app-prometeia-button>
                            <!-- <p class="spacer"></p> -->
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</ng-container>
