import { Component } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { IMasterCorsiLaurea } from '../../models/pages.interface';
import { Subscription } from 'rxjs';
import { ICollapsableList } from 'src/app/models/components.interface';

@Component({
  selector: 'app-master-corsi-laurea',
  templateUrl: './master-corsi-laurea.component.html',
  styleUrls: ['./master-corsi-laurea.component.scss']
})
export class MasterCorsiLaureaComponent extends BasePageComponent<IMasterCorsiLaurea> {

  keepImagesSize: boolean = true;

  masterCorsiLaureaDataReadySubscription: Subscription = null;

  masterCorsiLaureaSections: ICollapsableList[] = null;

  ngOnInit() {
    super.ngOnInit();

    this.masterCorsiLaureaDataReadySubscription?.unsubscribe();
    this.masterCorsiLaureaDataReadySubscription = this.dataServices.pageDataReady.subscribe(() => {
      if (this.data) {
        this.masterCorsiLaureaSections = this.data.sections as ICollapsableList[];
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.masterCorsiLaureaDataReadySubscription?.unsubscribe();
    this.masterCorsiLaureaDataReadySubscription = null;
  }

}
