import { Component } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { IRole, ITeam } from '../../models/pages.interface';
import { IMedia } from 'src/app/models/commons';
import { ShortenPipe } from 'ngx-pipes';
import { Subscription } from 'rxjs';
import { ComponentsServices } from 'src/app/services/components.service';

const LINES: number = 4;

@Component({
  selector: 'app-societary-organs',
  templateUrl: './societary-organs.component.html',
  styleUrls: ['./societary-organs.component.scss']
})
export class SocietaryOrgansComponent extends BasePageComponent<ITeam> {

  private pageDataReadySubScription: Subscription;

  isPicAndTextVisible: boolean = false;
  maxLength: number = 180;

  ngOnInit() {
    super.ngOnInit();
    this.maxLength = LINES * (this.componentsServices.getInnerWidth() / 7);

    this.pageDataReadySubScription?.unsubscribe();
    this.pageDataReadySubScription = this.dataServices.pageDataReady.subscribe(() => {
      this.data.roles.forEach((role: IRole) => {
        // role.isOpened = false;

        role.members.forEach((member: IMedia) => {
          member.downloadable = false;
        });
      });


      // console.log("log", this.data);
    });

    this.componentsServices.pageResizeAll.subscribe(() => {
      this.maxLength = LINES * (this.componentsServices.getInnerWidth() / 7);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.pageDataReadySubScription?.unsubscribe();
  }

  openMember(member: IMedia) {
    console.log("more about", member.title, member.id);
    
    member.downloadable = !member.downloadable;
  }

  readMore(member: IMedia) {
    console.log("more about", member.title, member.id, member.text.length);
    //member._meta.maxLength = member.text.length;
    member.downloadable = true;
  }

}
