<section *ngIf="data.type == 'overview'" spyTarget="{{data.anchorId}}" class="{{data.theme}} section center-vertical">
    <div class="section-max-content">
        <div>
            <h1 data-aos="fade-up" class="section_h1 neutra2_book text--{{sectionOptions.titleColor || 'accent'}}" 
                *ngIf="!skipTitleColor()">{{ data.title }}</h1>
            <h1 data-aos="fade-up" class="section_h1 neutra2_book" *ngIf="skipTitleColor() && !(sectionOptions.titleColor == 'yellow')">{{ data.title }}</h1>
            <h1 data-aos="fade-up" class="section_h1 neutra2_book text--primary" *ngIf="skipTitleColor() && (sectionOptions.titleColor == 'yellow')">{{ data.title }}</h1>
            
            <p data-aos="fade-up" class="section--text" *ngIf="data.text && !(data.type == 'generic-left')">{{data.text}}</p>
            <div data-aos="fade-up" [ngClass]="{'section--text two-columns--title-and-text--text neutra2_book inner-html inner-html--SIM': true, 'text-column-with-image': data.media?.type == 'IMG'}"
                *ngIf="data.text && data.type == 'generic-left'" [innerHtml]="data.text | safe:'html'"></div>
        </div>
    </div>


    <div data-aos="fade-up" class="section--overview section-max-content" *ngIf="(data.type == 'overview')">
        <app-overview [data]="data.media" *ngIf="(data.media?.type == 'IMG') && !data.slug"></app-overview>
        <app-overview [slug]="data.slug" *ngIf="(data.media?.type != 'IMG') || data.slug"></app-overview>
    </div>
</section>