<!-- MOBILE (default) -->
<div [ngClass]="{'flipping-card-container mobile-only flipping-card-container--selected': true, 'on-dark-theme': cardsOnDarkTheme}" *ngIf="!data.cardType || data.cardType == 'default'">
    <mat-card class="flipping-card back-face">
        <mat-card-content class="flipping-card--content">
            <div class="back-content inner-html--card-content" [innerHtml]="data.back_content | safe:'html'">
                contenuto retro
            </div>
        </mat-card-content>

        <mat-card-footer>
            <div class="flipping-card--label normal-text back-content">
                <p class="neutra2_demi text--{{highlighter || 'on-dark'}}">{{data.title}}</p>
            </div>
        </mat-card-footer>
    </mat-card>
</div>

<!-- MOBILE ("reversed" cards) -->
<div [ngClass]="{'reversed flipping-card-container mobile-only flipping-card-container--selected': true, 'on-dark-theme': cardsOnDarkTheme}" *ngIf="data.cardType == 'reversed'">
    <mat-card class="flipping-card back-face">
        <mat-card-header>
            <div class="flipping-card--label highlighted-text front-content">
                <p [ngClass]="{'text--primary front-content--title': true, 'neutra2_bold': true, 'short-title': !data.title.includes(' ')}" *ngIf="!cardsOnDarkTheme">
                    <span class="highlighter highlighter--{{highlighter || 'accent'}}" *ngIf="data?.title">
                        <!-- <ng-container *ngIf="data.title.includes('&')">
                            <ng-container *ngFor="let titleFragment of data.title.split('&'); let isFirst = first; let isLast = last;">
                                <ng-container *ngIf="!isFirst">& </ng-container>
                                {{titleFragment}}
                                <br *ngIf="!isLast">
                            </ng-container>
                        </ng-container> -->

                        <ng-container *ngIf="data.title.split(' ')?.length == 2">
                            {{data.title.split(' ')[0]}}
                            <br>
                            {{data.title.split(' ')[1]}}
                        </ng-container>

                        <ng-container *ngIf="data.title.split(' ')?.length != 2">
                            {{data.title}}
                        </ng-container>
                    </span>
                </p>

                <p class="no-highlight card-header--title text--center neutra2_bold text--primary" style="margin-top: 5px;" *ngIf="cardsOnDarkTheme">
                    {{ data.title }}
                </p>
            </div>
        </mat-card-header>
        
        <mat-card-content class="flipping-card--content">
            <div class="back-content inner-html--card-content" [innerHtml]="data.back_content | safe:'html'">
                contenuto retro
            </div>
        </mat-card-content>
    </mat-card>
</div>

<!-- MOBILE (large cards) -->
<div [ngClass]="{'not-flipping-card-container mobile-only not-flipping-card-container--selected': true, 'large-card': true, 'on-dark-theme': cardsOnDarkTheme}" *ngIf="data.cardType == 'large'">
    <mat-card class="not-flipping-card front-face">
        <mat-card-content class="not-flipping-card--content">
            <div class="front-content inner-html--card-content" [innerHtml]="data.front_content | safe:'html'">
                contenuto fronte
            </div>
        </mat-card-content>

        <mat-card-footer>
            <div class="not-flipping-card--label highlighted-text front-content">
                <p [ngClass]="{'text--primary front-content--title': true, 'neutra2_bold': true}">
                    <span class="highlighter highlighter--{{highlighter || 'accent'}}" *ngIf="data?.title">
                        <ng-container *ngIf="data.title.includes('&')">
                            <ng-container *ngFor="let titleFragment of data.title.split('&'); let isFirst = first; let isLast = last;">
                                <ng-container *ngIf="!isFirst">& </ng-container>
                                {{titleFragment}}
                                <br *ngIf="!isLast">
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="!data.title.includes('&') && data.title.split(' ')?.length == 2">
                            {{data.title.split(' ')[0]}}
                            <br>
                            {{data.title.split(' ')[1]}}
                        </ng-container>

                        <ng-container *ngIf="!data.title.includes('&') && data.title.split(' ')?.length != 2">
                            {{data.title}}
                        </ng-container>
                    </span>
                </p>
            </div>
        </mat-card-footer>
    </mat-card>

    <!-- <div class="back-content inner-html--card-content" [innerHtml]="data.back_content | safe:'html'">
        contenuto retro
    </div> -->
</div>



<!-- DESKTOP -->
<div [ngClass]="{'flipping-card-container desktop-only': true, 'flipping-card-container--selected': selected, 'large-card': data.cardType == 'large', 'on-dark-theme': cardsOnDarkTheme}" (click)="toggle()">
    <mat-card class="flipping-card front-face">
        <mat-card-content class="flipping-card--content">
            <div class="front-content inner-html--card-content" [innerHtml]="data.front_content | safe:'html'">
                contenuto fronte
            </div>
        </mat-card-content>

        <mat-card-footer>
            <div class="flipping-card--label highlighted-text front-content">
                <p [ngClass]="{'text--primary front-content--title': true, 'neutra2_demi': !data.cardType || data.cardType == 'default', 'neutra2_bold': data.cardType == 'large'}">
                    <span class="highlighter highlighter--{{highlighter || 'accent'}}" *ngIf="data?.title">
                        <ng-container *ngFor="let titleFragment of data.title.split('&'); let isFirst = first; let isLast = last;">
                            <ng-container *ngIf="!isFirst">& </ng-container>
                            {{titleFragment}}
                            <br *ngIf="!isLast">
                        </ng-container>
                    </span>
                </p>
            </div>
        </mat-card-footer>
    </mat-card>

    <mat-card class="flipping-card back-face">
        <mat-card-header class="header-in-large-card" *ngIf="data.cardType == 'large'">
            <div class="flipping-card--label normal-text back-content">
                <p [ngClass]="{'neutra2_bold': true}" class="text--{{highlighter || 'on-dark'}}" *ngIf="data.title">
                    <ng-container *ngFor="let titleFragment of data.title.split('&'); let isFirst = first; let isLast = last;">
                        <ng-container *ngIf="!isFirst">& </ng-container>
                        {{titleFragment}}
                        <br *ngIf="!isLast">
                    </ng-container>
                </p>
            </div>
        </mat-card-header>

        <mat-card-content [ngClass]="{'flipping-card--content': true, 'content-in-large-card': data.cardType == 'large'}">
            <div class="back-content inner-html--card-content" [innerHtml]="data.back_content | safe:'html'">
                contenuto retro
            </div>
        </mat-card-content>

        <mat-card-footer *ngIf="!data.cardType || data.cardType == 'default'">
            <div class="flipping-card--label normal-text back-content">
                <p [ngClass]="{'neutra2_demi': !data.cardType || data.cardType == 'default', 'neutra2_bold': data.cardType == 'large'}" class="text--{{highlighter || 'on-dark'}}" *ngIf="data.title">
                    <ng-container *ngFor="let titleFragment of data.title.split('&'); let isFirst = first; let isLast = last;">
                        <ng-container *ngIf="!isFirst">& </ng-container>
                        {{titleFragment}}
                        <br *ngIf="!isLast">
                    </ng-container>
                </p>
            </div>
        </mat-card-footer>

        <mat-card-footer class="footer-in-large-card" *ngIf="data.cardType == 'large'">
            <div class="bar bar--{{highlighter || 'on-dark'}}"></div>
        </mat-card-footer>
    </mat-card>

</div>