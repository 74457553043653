import { Component, ViewEncapsulation } from '@angular/core';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-financial-advisory-consultancy',
  templateUrl: './financial-advisory-consultancy.component.html',
  styleUrls: ['./financial-advisory-consultancy.component.scss'],
  encapsulation: ViewEncapsulation.None, //to style innerHtml using our styles.scss
})
export class FinancialAdvisoryConsultancyComponent extends BasePageComponent<any> {


}
