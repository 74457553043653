import { Component } from '@angular/core';
import { IBasePage, Locale } from 'src/app/models/commons';
import { oldPermalinksENG, oldPermalinksITA, paths } from 'src/app/models/paths';
import { BasePageComponent } from '../base-page.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends BasePageComponent<IBasePage> {

  pageLoaded: boolean = false;

  constructor(private location: Location) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    // console.log(window.location);   
    //es: http://localhost:4200/ricerca/position-note/marzo2017 o https://www.prometeia.it/ricerca/position-note/marzo2017

    if (oldPermalinksITA.includes(window.location.href.toLowerCase()) || 
        oldPermalinksITA.includes("https://www.prometeia.it" + window.location.pathname.toLowerCase())) {
          const pathArray: string[] = window.location.pathname.split("/");
          const id = pathArray[pathArray.length-1];
          // console.log("OLD PERMALINK FOUND", id);

          this.router.navigate(['/', Locale.it, paths.trendingTopicsArticle, id]);
    } else if (oldPermalinksENG.includes(window.location.href.toLowerCase()) || 
               oldPermalinksENG.includes("https://www.prometeia.it" + window.location.pathname.toLowerCase())) {
      const pathArray: string[] = window.location.pathname.split("/");
      const id = pathArray[pathArray.length-1];
      // console.log("OLD PERMALINK FOUND", id);

      this.router.navigate(['/', Locale.en, paths.trendingTopicsArticle, id]);
    } else if (window.location.pathname.toLowerCase() == "/" + Locale.en || 
               window.location.pathname.toLowerCase() == "/" + Locale.it) {
      this.router.navigate(['/', window.location.pathname.toLowerCase().split("/")[1], paths.home], { replaceUrl: true });
    } else {
      // console.log(window.location.pathname.toLowerCase());
      this.pageLoaded = true;
    }

    const failedRoute = this.componentsServices.getPreviousRoute();

    if (failedRoute) {
      console.log("failedRoute", failedRoute);
      // this.router.navigateByUrl(failedRoute);
    }
  }

}
