import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import Chart, { ChartTypeRegistry } from 'chart.js/auto';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit {

  /* @Input() */ type: keyof ChartTypeRegistry = 'radar' as unknown as keyof ChartTypeRegistry;
  @Input() labels: string[];
  @Input() values: number[];

  @ViewChild('chartCanvas') private chartCanvas: ElementRef;

  chartChart: any;

  spiderWeb = {
    lineWidth: 1.5,
    color: '#808080'
  }

  constructor() { }

  ngAfterViewInit(): void {
    this.chartChartBrowser();
  }

  chartChartBrowser(): void {
    this.chartChart = new Chart(this.chartCanvas.nativeElement, {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: [{
          backgroundColor: 'rgba(46, 204, 113, 0.3)',
          data: this.values
        }]
      },

      /** read: https://www.chartjs.org/docs/next/getting-started/v3-migration.html */
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        borderColor: 'rgba(46, 204, 113, 0.5)',
        scales: {
          r: {
            min: 0,
            max: 30,
            /** radial lines */
            angleLines: this.spiderWeb,
            /** line between two radius */
            grid: this.spiderWeb,
            /** ticks on the "y" (radius) */
            ticks: {
              /** tick's number (the labels) */
              font: {
                size: 14
              },
            },
            /** dataset's labels */
            pointLabels: {
              font: {
                size: 14,
              },
              color: '#343434', // "Jet" (black-ish)
            }
          }
        }
      },
    });
  }
}
