import { Component, Input, OnInit } from '@angular/core';
import { IImgNewsDefault, ITrendingTopics } from '../../models/components.interface';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentsServices } from 'src/app/services/components.service';
import { paths } from '../../models/paths';
import { TrendingTopicsType } from 'src/app/models/commons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-single-top-trending-topics',
  templateUrl: './single-top-trending-topics.component.html',
  styleUrls: ['./single-top-trending-topics.component.scss']
})
export class SingleTopTrendingTopicsComponent implements OnInit {

  @Input() data: ITrendingTopics;
  @Input() category: string;
  @Input() imgNewsDefault: IImgNewsDefault;

  typeNews : TrendingTopicsType;

  insight_route: string = "insight_route";

  route: string = paths.trendingTopicsArticle;
  archiveRoute : string = paths.trendingTopicsArchive;
  
  private overlayRef: OverlayRef;

  styleNews = {
    news : {
      hasBorder: false,
      colorBorder: '',  
      classFilterImg : 'single-trending-topics--bg--filter',
      imgRight: false,
      imgBg: false,
      viewSrcImgDefault : false,
      viewTypeNews: false,
      categoryTop : false,
      hasAll: false
    },
    video : {
      hasBorder: false,
      colorBorder: '',
      classFilterImg : 'single-trending-topics--bg--filter',
      imgRight: false,
      imgBg: false,
      viewSrcImgDefault : false,
      viewTypeNews: false,
      categoryTop : false,
      hasAll: false
    },
    report : {
      hasBorder: true,
      colorBorder: 'border-green',
      classFilterImg : '',
      imgRight: false,
      imgBg: true,
      viewSrcImgDefault : true,
      viewTypeNews: true,
      categoryTop : true,
      hasAll: true
    },
    dataviz : {
      hasBorder: true,
      colorBorder: 'border-light_sky_blue',
      classFilterImg : '',
      imgRight: true,
      imgBg: false,
      viewSrcImgDefault : false,
      viewTypeNews: true,
      categoryTop : true,
      hasAll: true
    },
    podcast : {
      hasBorder: true,
      colorBorder: 'border-sky_blue',
      classFilterImg : '',
      imgRight: true,
      imgBg: false,
      viewSrcImgDefault : true,
      viewTypeNews: true,
      categoryTop : true,
      hasAll: true
    }
  };

  constructor(public overlay: Overlay, private componentsServices: ComponentsServices, public translate: TranslateService) { }

  ngOnInit() {
    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
  }

  getSlug(): string {
    if (this.data.slug && this.data.slug != undefined) {
      return this.data.slug;
    } else {
      const pa = this.data.permalink.split("/");
      this.data.slug = pa[pa.length - 1];
      return this.data.slug;
    }
  }
}
