<div *ngIf="!isClosed" [@fade]
    [ngStyle]="{'background-image': backgroundImage, 'background-color': backgroundColor}"
    [ngClass]="{'special-message': true,
                'with-shadow': data.categoryTheme.toUpperCase() == 'BLUE' ||
                               data.categoryTheme.toUpperCase() == 'BUSINESS' }">

    <div class="special-message--close clearfix" id="special-message--close-icon">
        <!-- <mat-icon class="icon icon-close">clear</mat-icon> -->
        <!-- <span class="icon icon-box-chiudi_x icon-close"></span> -->
        <img class="icon-close interactive" src="../../../assets/icons-svg/svg-icon-X-chiusura.svg" (click)="close()">
    </div>

    <div class="special-message--container">
        <img class="content logo" [src]="logo">
        <h2 class="neutra2_demi prom-list content text" id="special-message--message">{{data.title}}</h2>
        
        <a class="content more" id="special-message--link" (click)="close()"           
            [routerLink]="['/', translate.currentLang, 'news-article', this.data.slug]">
                {{ 'READ_MORE' | translate }}
        </a>
    </div>
</div>