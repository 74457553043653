import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { paths } from 'src/app/models/paths';

@Component({
  selector: 'app-single-job-offer',
  templateUrl: './single-job-offer.component.html',
  styleUrls: ['./single-job-offer.component.scss']
})
export class SingleJobOfferComponent implements OnInit {

  @Input() job: any;
  @Input() class: any;
  offerRoute : string = paths.offer;
  
  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
