<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<div class="prom-hq" *ngIf="data">

    <img [ngClass]="{'header--logo': true, 'hidden': isAnythingZoomed()}" src="{{logo_chiaro}}" [routerLink]="['/', translate.currentLang, 'home']" routerLinkActive="active">

    <a [ngClass]="{'prom-caption___lato-regular text--on-dark capslock no-underline back-route': true, 'hidden': isAnythingZoomed()}" [routerLink]="['/', translate.currentLang, backRoute]" routerLinkActive="active">{{backRouteLabel}}</a>

    <div class="prom-hq">
        <!-- <swiper #swiperHQ class="swiper-container swiper-hq" [config]="configHQ" [(index)]="index"> -->
        <div #swiperHQ class="swiper-container swiper-hq" [swiper]="configHQ" [(index)]="index">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let s of data.sections; let i = index">
                    <section [ngClass]="{'section': true, 'section--zoom-in': isZoomedIn(i), 'hidden': !isAnythingZoomed()}" [ngStyle]="{'background-image': 'url(' + data.sections[i].backImages[1] + ')'}">
                        <h1 class="neutra2_book text--on-dark section--title">{{s.title}}</h1>

                        <div class="section--custom-button" *ngIf="s.buttons && s.buttons[0].label?.length > 0" (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave(i)">
                            <a class="neutra2_bold text--on-dark no-underline label">
                                {{s.buttons[0].label}}
                            </a>
                        </div>

                        <div class="section--image-wrap" (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave(i)">
                            <img class="section--image" src="{{s.media.contentUrl}}" *ngIf="s.media" aria-hidden="true" alt="{{s.media.alt}}">
                        </div>

                        <div [ngClass]="{'neutra2_book text--on-dark section--text': true, 'hidden': isAnythingZoomed()}" [innerHtml]="s.text | safe:'html'"></div>


                        <p class="prom-caption___lato-regular text--grey photographer" *ngIf="s._meta?.photographer && isAnythingZoomed()">© {{s._meta.photographer}}</p>
                    </section>

                    <section [ngClass]="{'section': true, 'section--zoom-in': isZoomedIn(i), 'hidden': isAnythingZoomed()}" [ngStyle]="{'background-image': 'url(' + data.sections[i].backImages[0] + ')'}">
                        <h1 class="neutra2_book text--on-dark section--title">{{s.title}}</h1>

                        <div class="section--custom-button" *ngIf="s.buttons && s.buttons[0].label?.length > 0" (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave(i)">
                            <a class="neutra2_bold text--on-dark no-underline label">
                                {{s.buttons[0].label}}
                            </a>
                        </div>

                        <div class="section--image-wrap" (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave(i)">
                            <img class="section--image" src="{{s.media.contentUrl}}" *ngIf="s.media" aria-hidden="true" alt="{{s.media.alt}}">
                        </div>

                        <div [ngClass]="{'neutra2_book text--on-dark section--text inner-html--prom-hq': true, 'hidden': isAnythingZoomed()}" [innerHtml]="s.text | safe:'html'"></div>


                        <p class="prom-caption___lato-regular text--grey photographer" *ngIf="s._meta?.photographer && isAnythingZoomed()">© {{s._meta.photographer}}</p>
                    </section>
                </div>
            </div>

            <!-- If we need pagination -->
            <!-- <div class="swiper-pagination"></div> -->
            <app-side-pagination-dots [ngClass]="{'side-paginations-dots': true, 'hidden': isAnythingZoomed()}" [data]="data.sections" [index]="index" (goToIndex)="goToIndex($event)">
            </app-side-pagination-dots>
        </div>
    </div>



</div>
