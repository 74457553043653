import { Component } from '@angular/core';
import { SECONDS } from 'src/app/models/commons';
import { SectionGeneralComponent } from '../section-general/section-general.component';

const BACK_IMAGE_INTERVAL: number = 5 * SECONDS;

@Component({
  selector: 'app-section-fullsize-carousel',
  templateUrl: './section-fullsize-carousel.component.html',
  styleUrls: ['./section-fullsize-carousel.component.scss']
})
export class SectionFullsizeCarouselComponent extends SectionGeneralComponent {

  currentSlideIndex: number = 0;
  currentSlide: any;
  slidesNumber: number = NaN;

  nextSlideIndex: number = 1;
  nextSlide: any;

  isHoveringLink: boolean = false;

  ngOnInit(): void {
    this.slidesNumber = this.data?.carousel?.slides?.length;
    this.currentSlide = this.data?.carousel?.slides?.[this.currentSlideIndex];
    // console.log(">>>>", this.currentSlideIndex, this.currentSlide);
    
    /* the code below is used to avoid the white flash between the first and the second slide occuring the first time it switches */
    if (this.slidesNumber > 1) {
      this.nextSlide = this.data?.carousel?.slides?.[1];
    }

    this.setTimer();
  }

  setTimer() {
    const automaticRequest: boolean = true;

    /** "clearInterval" is in "super.ngOnDestroy()" */
    this.changeBackImageInterval = setInterval(() => { this.changeSlide(1, automaticRequest); }, BACK_IMAGE_INTERVAL);
  }

  resetTimer() {
    clearInterval(this.changeBackImageInterval);
    this.setTimer();
    // console.log("reset", new Date());
  }

  changeSlide(movement: number, automaticRequest: boolean = false) {
    /* updating the current slide */
    this.currentSlideIndex = (this.currentSlideIndex + movement) % this.slidesNumber;
    if (this.currentSlideIndex < 0) this.currentSlideIndex = this.slidesNumber - 1;
    this.currentSlide = this.data?.carousel?.slides?.[this.currentSlideIndex];

    /* updating the next slide too */
    this.nextSlideIndex = this.currentSlideIndex + 1;
    if (this.nextSlideIndex >= this.slidesNumber) this.nextSlideIndex = 0;
    this.nextSlide = this.data?.carousel?.slides?.[this.nextSlideIndex];

    // console.log(">>>>", this.currentSlideIndex, this.currentSlide);
    if (!automaticRequest) {
      // console.log("change", new Date());
      this.resetTimer();
    } else {
      // console.log("auto-change", new Date());
    }
  }

  linkHovering(isHovering: boolean) {
    this.isHoveringLink = isHovering;

    if (isHovering) {
      clearInterval(this.changeBackImageInterval);
      console.log("link hovering IN: pausing carousel");
    } else {
      this.setTimer();
      console.log("link hovering OUT: play carousel again");
    }
  }

}
