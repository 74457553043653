<div [ngClass]="{'flipping-cards--wrapper mobile-only': true, 'reversed-card': cards[0].cardType == 'reversed'}" *ngIf="!useLargeCards">
    <swiper class="swiper-flip" [config]="configFlip" #swiperFlipping>
        <div class="swiper-slide" *ngFor="let card of cards">
            <app-flipping-card [data]="card" [highlighter]="highlightColor" [cardsOnDarkTheme]="cardsOnDarkTheme"></app-flipping-card>
        </div>
    </swiper>
    <app-flipping-card class="single-slide-1" [data]="cards[2]" [highlighter]="highlightColor" [cardsOnDarkTheme]="cardsOnDarkTheme"></app-flipping-card>
    <app-flipping-card class="single-slide-2" [data]="cards[2]" [highlighter]="highlightColor" [cardsOnDarkTheme]="cardsOnDarkTheme"></app-flipping-card>
    <div class="icon--container">
        <span class="icon-swipe_swipe icon"></span>
    </div>
</div>

<div class="flipping-cards--wrapper large-cards mobile-only" *ngIf="useLargeCards">
    <ng-container *ngFor="let card of cards; let isLastCard = last" [ngStyle]="{'margin-bottom': isLastCard ? '0px' : '50px'}">
        <div class="single-card">
            <app-flipping-card [data]="card" [highlighter]="highlightColor" [cardsOnDarkTheme]="cardsOnDarkTheme"></app-flipping-card>
        </div>

        <div class="back-content inner-html--card-content patch" [innerHtml]="card.back_content | safe:'html'">
            contenuto retro
        </div>
    </ng-container>
</div>


<div class="flipping-cards--wrapper desktop-only section-max-content">
    <app-flipping-card *ngFor="let card of cards" [data]="card" [highlighter]="highlightColor" [cardsOnDarkTheme]="cardsOnDarkTheme"></app-flipping-card>
</div>
