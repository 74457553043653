import { Component } from '@angular/core';
import { ScrollSpyService } from 'ng-spy';
import { Subscription } from 'rxjs';
import { IOurCulture, IOurCultureSection } from '../../models/pages.interface';
import { HighlightColor, IBaseSection, IMedia } from '../../models/commons';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-our-culture',
  templateUrl: './our-culture.component.html',
  styleUrls: ['./our-culture.component.scss']
})
export class OurCultureComponent extends BasePageComponent<IOurCulture> {
  private pageDataReadySubscription: Subscription = null;

  public titleColor: HighlightColor = "primary";

  constructor(private spyService: ScrollSpyService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    let lastSection: IBaseSection = null;

    this.pageDataReadySubscription = this.dataServices.pageDataReady.subscribe(() => {
      this.spyService.spy({ 
        thresholdTop: this.componentsServices.getInnerHeight() / 2, 
        thresholdBottom: this.componentsServices.getInnerHeight() / 2 
      });

      if (this.data?.sections?.length > 0) {
        this.data.sections.forEach((s: IOurCultureSection, index: number) => {
          if (s.carousel) {
            if (!s.highlights.list || s.highlights.list.length == 0) {
              const hlfs = this.getHighlightsListFromSlides(s.carousel.slides);
              // console.log("getHighlightsListFromSlides()", hlfs);
              s.highlights.list = hlfs;
            } /* else {
              console.log("using default highlightsList", section.highlights);
            } */
          }

          if (s.type == "last") {
            console.log("test");
            lastSection = s;
            this.data.sections.splice(index, 1);
          }
        });
      }
  
      this.data.sections.push(lastSection);
      // console.log("our culture", this.data);
    });
  }

  private getHighlightsListFromSlides(slides: IMedia[]): {text: string}[] {
    let resList: {text: string}[] = [];
    let list: string[] = [];

    slides.forEach((slide: IMedia) => {
      if (!list && list.length == 0 && slide.text) {
        list = [slide.text];
      } else if (slide.text && !list.includes(slide.text)) {
        list.push(slide.text);
      }
    });

    list.forEach((item: string) => {
      resList.push({text: item});
    });

    return resList;
  }

  ngOnDestroy() {
    this.spyService.stopSpying();
    super.ngOnDestroy();
    this.pageDataReadySubscription?.unsubscribe();
    this.pageDataReadySubscription = null;
  }

}
