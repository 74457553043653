import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import globalJSON from '../../../assets/json/global.json';
import { Direction, HighlightColor } from '../../models/commons';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { ComponentsServices } from '../../services/components.service';
import { HeaderDataService, IHeaderData } from '../../services/header-data.service';
import { TranslateService } from '@ngx-translate/core';
import { paths, separator } from 'src/app/models/paths';
import { ScrollSpyService } from 'ng-spy';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { slugs } from 'src/app/models/slugs';
import { AmplifyAuthService } from 'src/app/services/amplify-auth.service'
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, pairwise, share, throttleTime } from 'rxjs/operators';
import { LangServices } from 'src/app/services/lang.service';
import { BrowserServices } from 'src/app/services/browser.service';
import { ApiArService } from 'src/app/services/api-ar.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // providers: [ComponentsServices]
})
export class HeaderComponent implements OnInit, OnDestroy {

  isHeaderActive = false;

  @Input() pageTitle: string;
  @Input() highlightColor: HighlightColor;
  pageSlug: string;
  pageId: string;
  forceDarkHeader: boolean = false;
  forceTitle: boolean = false;
  isAdvisorPage: boolean = false;
  showLangSelector: boolean = false;
  forceBG: boolean = false;

  titleRoute: string;
  titleId: string;

  isTop = true;

  logo: string = globalJSON["images"].cdn + globalJSON["images"].logo_menu_home;
  logo_chiaro: string = globalJSON["images"].cdn + globalJSON["images"].logo_chiaro;
  logo_scuro: string = globalJSON["images"].cdn + globalJSON["images"].logo;
  logo_advisor: string = globalJSON["images"].cdn + globalJSON["images"].logo_advisor;
  logo_advisor_scuro: string = globalJSON["images"].cdn + globalJSON["images"].logo_advisor_scuro;

  logoRoute: string = paths.home;
  logoRouteSIM: string = paths.financialAdvisory;

  lastY = 0;
  visibilityStyle;

  isOpen: boolean = true;
  // openStateClass: "header--open" | "header--closed" = "header--open";
  isLangsListOpen: boolean = false;

  isLoggedAlumni: boolean = false;

  private headerDataServiceSubscription: Subscription = null;
  private pageScrollIsTopSubscription: Subscription = null;
  private pageScrollUpSubscription: Subscription = null;
  private pageScrollDownSubscription: Subscription = null;
  private spyServiceSubscription: Subscription = null;

  private lastViewed: string = null;

  private alumniRegisterOrLoginFormSubscription: Subscription = null;

  private poolStatusSubscription: Subscription = null;

  cdkOverlayIsOpen = false;

  hideMenu: boolean = true;

  // browserType: string = "";
  deviceType: string = "";

  // force an element to top left of the page
  topLeftMarker: HTMLElement;

  searchResultsHeaderDataBackup: IHeaderData;


  constructor(public translate: TranslateService,
              public langServices: LangServices,
              private componentsServices: ComponentsServices,
              private headerDataService: HeaderDataService,
              private spyService: ScrollSpyService,
              private location: Location,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private amplifyService: AmplifyAuthService,
              private dialog: MatDialog,
              private browserServices: BrowserServices,
              private apiArService : ApiArService,
              private cookieService: CookieService,) { }

  private setHeader(source: IHeaderData) {
    this.pageTitle = source.title || "";
    if (this.pageTitle.length <= 0) this.pageTitle = " ";
    this.highlightColor = 'primary';
    if (source.highlightColor != 'yellow') this.highlightColor = source.highlightColor;
    this.pageSlug = source.slug;
    this.pageId = source.id || null;
    this.forceDarkHeader = source.forceDarkHeader;
    this.forceTitle = source.forceTitle;
    this.isAdvisorPage = source.isAdvisorPage;
    this.showLangSelector = source.showLangSelector;
    this.forceBG = source.forceBG;
  }
  
  ngOnInit(): void {
    // this.browserType = this.browserServices.myBrowser().toLowerCase();
    this.deviceType = this.browserServices.myDevice().toLowerCase();

    this.headerDataServiceSubscription = this.headerDataService.headerDataReady.subscribe((element: IHeaderData) => {
      this.hideMenu = true;
      this.titleId = null;
      // console.log("menu disabled");

      if (element && element != null) {
        this.setHeader(element);

        if (this.pageSlug == slugs.searchResults) {
          this.searchResultsHeaderDataBackup = element;
        }

        if (this.router.url.includes("?") &&
            this.router.url.split("?")[0].replace(/\/it\//g, "").replace(/\/en\//g, "") == slugs.searchResults &&
            this.searchResultsHeaderDataBackup &&
            this.searchResultsHeaderDataBackup.slug == slugs.searchResults &&
            !this.pageSlug) {
          /* console.log(this.router.url.split("?")[0].replace(/\/it\//g, "").replace(/\/en\//g, ""), element);
          this.pageSlug = slugs.searchResults;
          this.forceDarkHeader = true,
          this.forceTitle = true
          this.isHeaderActive = true; */
          this.setHeader(this.searchResultsHeaderDataBackup);
        }

        // console.log("H >>>", element, this.pageSlug);

        if (element.slug) {
          if (element.slug == slugs.anteoArchive || element.slug == slugs.informationPathsArchive) {
            this.titleRoute = paths.financialAdvisory;
          } else if (element.slug.endsWith("-detail")) {
            this.titleRoute = element.title.split("-detail")[0].toLowerCase();
          } else if (element.slug.endsWith("-article")) {
            this.titleRoute = element.slug.split("-article")[0].toLowerCase();
            if (element.slug == paths.anteoArticle) {
              // console.log("header, slug", element.id);
              this.titleId = element.id.split("-")[0];
              if (!this.titleId.startsWith("anteo")) {
                this.titleId = "anteo" + this.headerDataService.getAnteoNumberGoBack();
              }
            }
          } else if (element.slug.endsWith("-archive")) {
            this.titleRoute = element.slug.split("-archive")[0].toLowerCase();
          } else if (element.slug.endsWith("-stories")) {
            this.titleRoute = element.slug.split("-stories")[0].toLowerCase();
          } else if (this.pageTitle.toLowerCase() == "tech solutions") {
            this.titleRoute = paths.saasCloud;
          }

          // console.log(">> pageTitle", this.pageTitle, "titleRoute:", this.titleRoute, "element.slug", element.slug);
        } else {
          this.titleRoute = null;
        }

        if (!this.titleRoute && this.pageTitle/*  && (this.forceTitle || this.isAdvisorPage) */) {
          let tmpUrl = this.URLify(this.pageTitle);
          if (tmpUrl == "research-bi--and--reporting") tmpUrl = "research-bi-reporting";
          // console.log("pageTitle", this.pageTitle, "titleRoute:", this.titleRoute, "tmpUrl", tmpUrl);

          Object.keys(paths).reverse().forEach(element => {
            // console.log("element", paths[element]);
            if ((paths[element].includes(tmpUrl) || paths[element].includes(tmpUrl.replace(/-and-/g, "-")))
              && paths[element] != this.pageSlug && this.pageTitle.toLowerCase() != "advisory") {
                this.titleRoute = paths[element];
            } else if (this.pageTitle.toLowerCase() == "who we are") {
              this.titleRoute = paths.aboutUs;
            } else if (this.pageTitle.toLowerCase().includes("risk") &&
              this.pageTitle.toLowerCase().includes(" planning") &&
              this.pageTitle.toLowerCase().includes(" control")) {
                this.titleRoute = paths.riskPlanningControl;
            } else if (this.pageTitle.toLowerCase() == "tech solutions") {
              this.titleRoute = paths.riskTechAndWealthTech;
            } else if (this.pageTitle.toLowerCase() == "our network" && this.pageSlug == slugs.ourNetwork) {
              this.titleRoute = null;
            } else if (!tmpUrl || tmpUrl == "-") {
              this.titleRoute = null;
              // console.log("??", this.pageSlug, slugs.countryPage, this.titleRoute);
            }
          });

          // console.log("+++ pageTitle", this.pageTitle, "titleRoute:", this.titleRoute, "tmpUrl", tmpUrl);
        }

        this.isHeaderActive = (this.pageTitle || ' ') != ' ';
      } else {
        this.isHeaderActive = false;
      }

      if (this.pageSlug && this.pageSlug.toLowerCase() == paths.promHQ) {
        this.isHeaderActive = false;
      }

      if (this.pageSlug && (
            this.pageSlug.toLowerCase() == paths.trendingTopics || 
            this.pageSlug.toLowerCase() == paths.article ||
            this.pageSlug.toLowerCase() == paths.socialResponsibility ||
            this.pageSlug.toLowerCase() == paths.newsArticle
      )) {
        this.isOpen = true;
        this.isTop = true;
      }

      // console.log(element.title, this.pageTitle, this.isHeaderActive);

      setTimeout(() => {
        this.hideMenu = false;
        // console.log("menu active");
      }, 10);
      this.setIsLoggedAlumni();
    });

    this.pageScrollUpSubscription?.unsubscribe();
    this.pageScrollUpSubscription = this.componentsServices.pageScrollUp.subscribe(() => {
      this.isOpen = true;
      // console.log("subscribe", "up");
    });

    this.pageScrollDownSubscription?.unsubscribe();
    this.pageScrollDownSubscription = this.componentsServices.pageScrollDown.subscribe(() => {
      this.isOpen = false;
      this.isTop = false;
      // console.log("subscribe", "down");
    });

    this.pageScrollIsTopSubscription?.unsubscribe();
    this.pageScrollIsTopSubscription = this.componentsServices.pageScrollIsTop.subscribe(() => {
      this.isTop = true;
      this.isOpen = true;
      // console.log("subscribe", "top");
    });

    this.spyServiceSubscription?.unsubscribe();
    this.spyServiceSubscription = this.spyService.activeSpyTarget.subscribe(
      (activeTargetName: string) => {
        // console.log("activeTargetName", activeTargetName/* , this.lastViewed */);

        // Generate the URL:
        let url = this.router.createUrlTree([], { relativeTo: this.activatedRoute }).toString();

        if (activeTargetName != null && !activeTargetName.includes(this.lastViewed)) {
          // console.log(activeTargetName, this.lastViewed, !activeTargetName.includes(this.lastViewed), (activeTargetName != null && !activeTargetName.includes(this.lastViewed)));

          if (!activeTargetName.endsWith("intro")) {
            // Change the URL without navigate:
            this.lastViewed = activeTargetName;
            this.location.replaceState(url + "#"+ this.lastViewed);
            // console.log(">>> goTo F", this.lastViewed);
          } else {
            // Change the URL without navigate:
            this.lastViewed = null;
            this.location.replaceState(url);
            // console.log(">>> goTo L", url + "#" + activeTargetName);
          }

        }

      }
    );

    this.alumniRegisterOrLoginFormSubscription?.unsubscribe();
    this.alumniRegisterOrLoginFormSubscription = this.componentsServices.alumniRegisterOrLoginForm.subscribe((isFormOpen: boolean) => {
      this.cdkOverlayIsOpen = isFormOpen;
      //  console.log("alumni form is open?", isFormOpen);
    });

    this.poolStatusSubscription?.unsubscribe();
    this.poolStatusSubscription = this.amplifyService.poolAlumniStatus.subscribe(status => { if (this.pageSlug == 'alumni') { this.isLoggedAlumni = status.islogged; } });

    this.topLeftMarker = document.createElement("div");
    this.topLeftMarker.style.position = "absolute";
    this.topLeftMarker.style.left = "0";
    this.topLeftMarker.style.top = "0";

    document.body.appendChild(this.topLeftMarker);
  }

  /** https://netbasal.com/reactive-sticky-header-in-angular-12dbffb3f1d3 */
  ngAfterViewInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      // distinctUntilChanged(),
      share()
    );

    const scrollUp$ = scroll$.pipe(
      filter(direction => direction === Direction.Up)
    );

    const scrollDown$ = scroll$.pipe(
      filter(direction => direction === Direction.Down)
    );

    scrollUp$.subscribe(() => {
      if (!this.componentsServices.isDesktopMode()) {
        // console.log("HEADER SHOW", window.pageYOffset);
        this.isOpen = true;

        if (this.deviceType != "iphone") {
          if (window.pageYOffset < 30) {
            this.isTop = true;
          }
        } else if (this.deviceType == "iphone") {
          const soY = this.scrollOffsetY();
          if (soY < 30) {
            this.isTop = true;
            this.isOpen = true;
          } /* else {
            console.log("false", soY, 90, soY < 90);
          } */
        }
      }
    });
    scrollDown$.subscribe(() => {
      if (!this.componentsServices.isDesktopMode()) {
        // console.log("HEADER HIDE", window.pageYOffset);
        this.isOpen = false;
        this.isTop = false;

        if (this.deviceType == "iphone" && this.scrollOffsetY() < 30) {
          this.isTop = true;
          this.isOpen = true;
        }
      }
    });
  }

  scrollOffsetY() {
    // getBoundingClientRect() returns the rectangle of the element in viewport space,
    // which *is* scrollLeft and scrollTop 
    const rect = this.topLeftMarker.getBoundingClientRect();
    // console.log("rect y =", rect.top);
    // return { x: rect.left, y: (rect.top * -1) }
    return (rect.top * -1);
  }


  ngOnDestroy(): void {
    // console.log('header.component', 'ngOnDestroy');

    if (this.headerDataServiceSubscription) {
      this.headerDataServiceSubscription.unsubscribe();
      this.headerDataServiceSubscription = null;
    }

    if (this.pageScrollIsTopSubscription) {
      this.pageScrollIsTopSubscription.unsubscribe();
      this.pageScrollIsTopSubscription = null;
    }

    if (this.pageScrollUpSubscription) {
      this.pageScrollUpSubscription.unsubscribe();
      this.pageScrollUpSubscription = null;
    }

    if (this.pageScrollDownSubscription) {
      this.pageScrollDownSubscription.unsubscribe();
      this.pageScrollDownSubscription = null;
    }

    if (this.spyServiceSubscription) {
      this.spyServiceSubscription.unsubscribe();
      this.spyServiceSubscription = null;
    }

    if (this.poolStatusSubscription) {
      this.poolStatusSubscription.unsubscribe();
      this.poolStatusSubscription = null;
    }

    this.alumniRegisterOrLoginFormSubscription?.unsubscribe();
    this.alumniRegisterOrLoginFormSubscription = null;
  }

  openMenuSubject: Subject<boolean> = new Subject<boolean>();

  openMenu() {
    // console.log("header", "openMenu");

    this.componentsServices.pauseAlumniRegisterOrLoginForm();

    this.openMenuSubject.next(true);
    this.componentsServices.toggleMenu(true);
  }

  URLify(string: string): string {
    if (string.includes("&")) string = string.replace(/&/g, " and ").replace(/ /g, " ");
    if (string.includes("  ")) string = string.replace(/ /g, " ");
    if (string.includes(",")) string = string.replace(/,/g, "");
    return string.toLowerCase().replace(/ /g, "-");
  }

  useTransparentBg(): boolean {
    return (this.pageSlug == slugs.ourNetwork || this.pageSlug.includes(slugs.countryPage));
  }


  toggleLangs() {
    this.isLangsListOpen = !this.isLangsListOpen;
  }

  checkNoVerticalPadding() {
    return this.pageSlug == paths.ourNetwork;
  }

  changeLang() {
    let nextLang: string;

    let activeLang = this.translate.currentLang.toLowerCase();

    switch (activeLang) {
      case "en":
        nextLang = "it";
        break;

      case "it":
        nextLang = "en";
        break;

      default:
        nextLang = "en";
        break;
    }

    /** updates the translation */
    this.translate.use(nextLang);


    /** updates the URL too (TODO: find the url in a better way)*/
    let r: string = this.router.url.split('/')[2];
    let i = 3;
    while (this.router.url.split('/')[i] && this.router.url.split('/')[i].length > 0) {
      let x: string = this.router.url.split('/')[i];
      r = r + '/' + x;

      i++;
    }
    r = '/' + nextLang + '/' + r;
    this.router.navigateByUrl(r);

    this.isLangsListOpen = !this.isLangsListOpen;
  }

  async setIsLoggedAlumni() {
    if (this.pageSlug == 'alumni') { this.isLoggedAlumni = await this.amplifyService.isLoggedIn('alumni'); }
  }

  async logoutAlumni() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: { title: '', text: this.translate.instant("LOGOUT"), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {
      result && await this.amplifyService.logout('alumni');
      return this.setIsLoggedAlumni();
    });

  };



  isLoggedAR(): boolean {
    return !this.componentsServices.getARnotLogged();
  }

  async logoutAR() {    
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant("LOGOUT"), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      if(result){
        this.cookieService.delete('prom-ar-token');
        await this.apiArService.libraries.logout();
        this.router.navigate(['/', this.translate.currentLang, 'home']); 
      }        
    });
  }
}
