import { Component, Input } from '@angular/core';
import { IBusinessCard } from 'src/app/models/components.interface';

@Component({
  selector: 'app-business-card-v2',
  templateUrl: './business-card-v2.component.html',
  styleUrls: ['./business-card-v2.component.scss']
})
export class BusinessCardV2Component  {
  
  @Input() data: IBusinessCard;

}
