import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchAndFiltersServices {

  searchSubString(filterString: string, string: string): boolean {
    if (!string) return false;

    const fs = this.cleanString(filterString).toLowerCase();
    const str = this.cleanString(string).toLowerCase();

    return str.includes(fs);
  }

  private cleanString(string: string): string {
    if (string.includes("-")) string = string.replace(/-/g, "");
    if (string.includes("_")) string = string.replace(/_/g, "");
    if (string.includes(" ")) string = string.replace(/ /g, "");

    return string;
  }
}
