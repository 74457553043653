import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import globalJSON from '../../../assets/json/global.json';
import { LANGS } from '../../models/commons';
import { Observable, Subscription } from 'rxjs';
import { IMenuComponent, ISubMenuItem } from '../../models/components.interface';
import { paths } from 'src/app/models/paths';
import { environment } from 'src/environments/environment';
import { ComponentsServices } from 'src/app/services/components.service';
import { BrowserServices } from 'src/app/services/browser.service';


const MAIN_TITLE_DALAY: number = 150;
const SUB_TITLE_DALAY: number = 50;
const SUB3_TITLE_DALAY: number = 60;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  appVersion: string = environment.appVersion;

  @Input() isOpen: number;
  main_openState_isOpen: boolean;

  lang: string;
  activeLangSelector: string;
  isEn = this.translate.currentLang === 'en';

  logo: string = globalJSON["images"].cdn + globalJSON["images"].logo_chiaro;
  backgroundImage_selected: string = "none";
  backgroundImage_isBlank: boolean = true;

  data: IMenuComponent = {
    lock_isOpen: true,
    main_menu: [
      {
        title: "Who We Are",
        id: "wwe",
        backgroundImage: globalJSON["home_and_menu"].cdn + globalJSON["home_and_menu"].who_we_are,
        subMenu_openState: false,
        subMenu: [
          {
            title: "About Us", route: paths.aboutUs
          },
          {
            title: "Social Responsibility", route: paths.socialResponsibility
          },
          {
            title: "Our Network", route: paths.ourNetwork
          },
        ]
      },
      {
        title: "Our Expertise",
        id: "oex",
        backgroundImage: globalJSON["home_and_menu"].cdn + globalJSON["home_and_menu"].our_expertise,
        subMenu_openState: false,
        subMenu: [
          {
            title: "Advisory",
            sub_id: "adv",
            sub3_openState: false,
            sub3: [
              {
                title: "Risk, Planning & Control", route: paths.riskPlanningControl
              },
              {
                title: "Wealth Management & Financial Distribution", route: paths.financialDistribution
              },
              {
                title: "Strategic Advisory & Corporate Finance", route: paths.strategicAdvisory
              },
              {
                title: "Financial Advisory for Institutional Investors", route: paths.financialAdvisory
              },
              {
                title: "ESG & Climate", route: paths.esgClimate
              },
            ]
          },
          /* {
            title: "Tech Solutions",
            sub_id: "sol",
            route: paths.riskTechAndWealthTech
          }, */
          {
            title: "Tech Solutions",
            sub_id: "sol",
            sub3_openState: false,
            sub3: [
              {
                title: "Saas & Cloud", route: paths.saasCloud
              },
              {
                title: "Applied Data Science & Advanced Analytics", route: paths.appliedDataScienceAndAdvancedAnalytics
              },
              {
                title: "RiskTech", route: paths.riskTech
              },
              {
                title: "WealthTech", route: paths.wealthTech
              },
            ]
          },
          {
            title: "R&D",
            sub_id: "rnd",
            sub3_openState: false,
            sub3: [
              {
                title: "Research BI, & Reporting", route: paths.research_bi_reporting
              },
              {
                title: "Data Science", route: paths.dataScience
              }
            ]
          },
          {
            title: "Insights",
            sub_id: "ins",
            sub3_openState: false,
            sub3: [
              {
                title: "Trending Topics", route: paths.trendingTopics
              },
              {
                title: "News", route: paths.news
              },
              {
                title: "Newsletter", route: paths.newsletter
              },
            ]
          },
        ]
      },
      {
        // title: "Career",
        title: "People",
        id: "car",
        backgroundImage: globalJSON["home_and_menu"].cdn + globalJSON["home_and_menu"].career,
        subMenu_openState: false,
        subMenu: [
          // {
          //   title: "Our Culture", route: paths.ourCulture
          // },
          {
            title: "Careers", route: paths.careers
          },
          {
            title: "Open Positions", route: paths.jobOffer
          },
          {
            title: "Alumni", route: paths.alumni
          },
        ]
      },
    ]
  };

  @Input() isOpenEvt: Observable<boolean>;
  private isOpenEvtSubscription: Subscription = null;

  browserType: string = "";
  deviceType: string = "";

  nowYear: number = 2021;

  constructor(public translate: TranslateService, private componentsServices: ComponentsServices, private browserServices: BrowserServices) {
    this.activeLangSelector = LANGS[this.translate.currentLang];
  }

  ngOnInit(): void {
    this.main_openState_isOpen = false;
    this.nowYear = new Date().getFullYear();

    this.isOpenEvtSubscription?.unsubscribe();
    this.isOpenEvtSubscription = this.isOpenEvt.subscribe((val) => {
      if (val) {
        this.open();
      }
      // console.log("isOpenEvt", val);
    });

    this.browserType = this.browserServices.myBrowser().toLowerCase();
    this.deviceType = this.browserServices.myDevice().toLowerCase();
    // console.log("Browser", this.browserType, "on", this.deviceType/* , navigator.userAgent, navigator.userAgent.includes('OPR') */);
  }

  ngOnDestroy(): void {
    if (this.isOpenEvtSubscription) {
      this.isOpenEvtSubscription.unsubscribe();
    }
  }

  open() {
    this.main_openState_isOpen = true;
    // console.log("main_openState_isOpen", this.main_openState_isOpen);
    // console.log(this.backgroundImage_isBlank);
  }

  close(onlyClose?: boolean) {
    this.main_openState_isOpen = false;
    this.closeAllSub();
    this.backgroundImage_isBlank = true;
    this.backgroundImage_selected = "none";

    this.componentsServices.toggleMenu(this.main_openState_isOpen);

    if (!onlyClose) this.componentsServices.closeAlumniRegisterOrLoginForm();
  }

  openSubMenu(menuId: string) {
    this.closeSub3Menus();

    this.data.main_menu.forEach(element => {
      if (element.id === menuId) {
        element.subMenu_openState = !element.subMenu_openState;
        // console.log(element.id, "now is", element.subMenu_openState);

        if (element.subMenu_openState) {
          this.backgroundImage_isBlank = false;
          this.backgroundImage_selected = 'url(' + element.backgroundImage + ')';
        } else {
          /** all submenu are closed */
          this.backgroundImage_isBlank = true;
        }
      } else {
        /** closes the "wrong" submenu if it was open */
        element.subMenu_openState = false;
      }
    });
  }

  closeSubMenus() {
    this.data.main_menu.forEach(element => {
      element.subMenu_openState = false;
    });
  }


  sub3menuLastOpenedId: string = null;
  sub3menuIsOpen: boolean = false;
  sub3menuSelected: ISubMenuItem = null;

  openSub3Menu(subMenuId: string) {
    // console.log(">>>", this.sub3menuLastOpenedId, subMenuId, this.sub3menuLastOpenedId == subMenuId);
    if (this.sub3menuLastOpenedId == subMenuId) {
      this.sub3menuIsOpen = false;
      this.sub3menuSelected = null;
      this.sub3menuLastOpenedId = null;
    } else {
      this.sub3menuIsOpen = true;
      this.sub3menuLastOpenedId = subMenuId;
    }

    this.data.main_menu.forEach(element => {
      element.subMenu.forEach(inner_element => {
        if (inner_element.sub_id === subMenuId) {
          inner_element.sub3_openState = !inner_element.sub3_openState;

          if (this.sub3menuIsOpen) this.sub3menuSelected = inner_element;

          //console.log(subMenuId, inner_element.sub3_openState);
        } else {
          /** closes the "wrong" submenu if it was open */
          inner_element.sub3_openState = false;
        }
      });
    });
  }

  closeSub3Menus() {
    this.data.main_menu.forEach(element => {
      element.subMenu.forEach(inner_element => {
        inner_element.sub3_openState = false;
      });
    });
  }

  closeAllSub() {
    this.closeSubMenus();
    this.closeSub3Menus();
  }

  /** ANIMATIONS */

  getTitleDelay(index: number) {
    const i = index + 2;
    const ret = i * MAIN_TITLE_DALAY;
    return ret + "ms";
  }

  getSubtitleDelay(index: number) {
    const i = index + 1;
    const ret = i * SUB_TITLE_DALAY;
    return ret + "ms";
  }

  getSub3titleDelay(index: number) {
    const i = index + 1;
    const ret = i * SUB3_TITLE_DALAY;
    return ret + "ms";
  }


  getMarginBottom(): string {
    let ret: number = 0;

    // console.log(this.sub3menuIsOpen, this.sub3menuSelected != null, this.sub3menuIsOpen && this.sub3menuSelected && this.sub3menuSelected != null);

    if (this.sub3menuIsOpen && this.sub3menuSelected && this.sub3menuSelected != null) {
      ret = (this.sub3menuSelected.sub3.length * 4);
      // if (this.sub3menuLastOpenedId == "adv" || this.sub3menuSelected.sub3.length == 4) {
      //   ret += 100;
      //   // console.log("ret", ret, "px");
      // }
    } else ret = 0;

    // console.log(/* this.sub3menuSelected,  */"margin-bottom", ret + "px");

    return ret + "px";
  }
}
