import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section-contacts',
  templateUrl: './section-contacts.component.html',
  styleUrls: ['./section-contacts.component.scss']
})
export class SectionContactsComponent {

  /** data is a section; data.theme will be ignored */
  @Input() data: any;

}
