<div class="info-number">
    <p class="title prom-caption___neutra-demi capslock">{{data.title}}</p>
    
    <div class="number-container text--{{highlightColor}}">
        <p class="neutra2_demi number">{{data.number}}</p>
        <p class="neutra2_demi plus">+</p>
    </div>

    <div class="subtitle">
        <a *ngIf="data.route && !data.id" class="prom-caption subtitle--link" [routerLink]="['/', translate.currentLang, data.route]">
            {{data.subtitle}}
        </a>

        <a *ngIf="data.route && data.id" class="prom-caption subtitle--link" [routerLink]="['/', translate.currentLang, data.route, data.id]">
            {{data.subtitle}}
        </a>

        <a *ngIf="data.link && !data.route" class="prom-caption subtitle--link" href="{{data.link}}" target="_blank">
            {{data.subtitle}}
        </a>

        <p *ngIf="!data.link && !data.route" class="prom-caption subtitle--no-link">
            {{data.subtitle}}
        </p>
    </div>
</div>