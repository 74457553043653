<div class="collapsable-list-item" *ngIf="data">
    <div [ngClass]="{'content': true, 'content--no-images': !data.image}">
        <div *ngIf="data.image" (click)="changeOpenState(noScroll)"
             [ngClass]="{'image-container interactive': true, 
                         'image-container--with-larger-image': isOpen,
                         'image-container--with-smaller-image': !isOpen,
                         'image-container--with-default-sizes': keepImagesSize}">
            <img *ngIf="keepImagesSize" class="content--image larger-image mobile-only default-sizes" src="{{data.image.contentUrl}}" aria-hidden="true" alt="{{data.image.alt}}">

            <img [src]="getImageSrc()" [alt]="getImageAlt()"
                [ngClass]="{'content--image desktop-only': true,
                            'larger-image': isOpen,
                            'smaller-image': !isOpen,
                            'default-sizes': keepImagesSize}">
        </div>

        
        <div class="content--text-column">
            <h2 [ngClass]="{'text--primary neutra2_book prom-list content--text-column--title': true,
                            'title-when-close': !isOpen, 'title-when-open': isOpen, 'list42': keepImagesSize}">
                <span class="interactive" (click)="changeOpenState(noScroll)">{{data.title}}</span>
            </h2>
            
            <div [ngClass]="{'content--text-column--wrap': true, 'content-is-open': isOpen, 'content-is-closed': !isOpen}">
                <ng-container *ngIf="data.text && !data.list">
                    <p class="text--primary text-column--text">{{ data.text }}</p>

                    <div class="links" *ngIf="data.links_list">
                        <p class="text--primary links--title" *ngIf="data.links_title">
                            {{data.links_title}}
                        </p>

                        <ul class="links--list">
                            <li class="list--item" *ngFor="let x of data.links_list">
                                <a class="text--primary neutra2_bold links--link short-line-height" *ngIf="x.link && !x.route" href="{{x.link}}" target="_blank">{{x.label}}</a>
                                <a class="text--primary neutra2_bold links--link short-line-height" *ngIf="x.route" [routerLink]="['/', translate.currentLang, x.route]">{{x.label}}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="btn-and-info">
                        <app-prometeia-button class="btn-and-info--btn" [data]="data.btn" *ngIf="data.btn"></app-prometeia-button>

                        <div class="btn-and-info--info" *ngIf="data.email">
                            <p class="text--accent prom-contact___lato more-info">{{ 'MORE_INFO' | translate }}</p>
                            <a class="text--accent prom-contact no-underline contact-us--email" href="mailto:{{data.email}}">{{data.email}}</a>
                        </div>
                    </div>
                </ng-container>
                
                <div class="block-list" *ngIf="!data.text && data.list">
                    <div class="block" *ngFor="let block of data.list">
                        <div class="block--main">
                            <h3 class="neutra2_bold text--primary block--title">{{block.title}}</h3>
                            <p class="text--primary block--text">{{block.text}}</p>
                        </div>
                        <div class="block--button" *ngIf="block?.button">
                            <app-prometeia-button [data]="block.button"></app-prometeia-button>
                        </div>
                    </div>

                    <div class="info" *ngIf="data.email">
                        <p class="text--accent prom-contact___lato more-info">{{ 'MORE_INFO' | translate }}: </p>
                        <a class="text--accent prom-contact no-underline contact-us--email" href="mailto:{{data.email}}">{{data.email}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="{'content--open-close': true, 'content--no-images': !data.image}">
        <div class="spacer mobile-only"></div>
        <div class="spacer desktop-only" *ngIf="isOpen"></div>
        
        <!--
        <span class="icon icon--{{highlightColor || 'accent'}} icon-list-icona-meno_meno desktop-only interactive" *ngIf="isOpen" (click)="changeOpenState()"></span>
        <span class="icon icon--{{highlightColor || 'accent'}} icon-list-icona-più_più desktop-only interactive" *ngIf="!isOpen" (click)="changeOpenState()"></span>

        <span class="icon icon--{{highlightColor || 'accent'}} icon-list-icona-meno_meno-mobile mobile-only interactive" *ngIf="isOpen" (click)="changeOpenState()"></span>
        <span class="icon icon--{{highlightColor || 'accent'}} icon-list-icona-più_più-mobile mobile-only interactive" *ngIf="!isOpen" (click)="changeOpenState()"></span>
        -->


        
        <div class="icon-plus" (click)="changeOpenState()">
            <img class="icon interactive desktop-only" *ngIf="!isOpen && (!highlightColor || highlightColor == 'accent' || highlightColor == 'green')"
                src="../../../assets/icons-svg/piu_meno/icona_più_verde.svg">
            <img class="icon interactive mobile-only" *ngIf="!isOpen && (!highlightColor || highlightColor == 'accent' || highlightColor == 'green')"
                src="../../../assets/icons-svg/piu_meno/icona_più_verde_mobile.svg">
            <img class="icon interactive desktop-only" *ngIf="!isOpen && (highlightColor == 'yellow')"
                src="../../../assets/icons-svg/piu_meno/icona_più_gialla.svg">
            <img class="icon interactive mobile-only" *ngIf="!isOpen && (highlightColor == 'yellow')"
                src="../../../assets/icons-svg/piu_meno/icona_più_gialla_mobile.svg">
        </div>


        <div class="icon-minus" (click)="changeOpenState()">
            <img class="icon interactive desktop-only" *ngIf="isOpen && (!highlightColor || highlightColor == 'accent' || highlightColor == 'green')"
                src="../../../assets/icons-svg/piu_meno/icona_meno_verde.svg">
            <img class="icon interactive mobile-only" *ngIf="isOpen && (!highlightColor || highlightColor == 'accent' || highlightColor == 'green')"
                    src="../../../assets/icons-svg/piu_meno/icona_meno_verde_mobile.svg">
            <img class="icon interactive desktop-only" *ngIf="isOpen && (highlightColor == 'yellow')"
                src="../../../assets/icons-svg/piu_meno/icona_meno_gialla.svg">
            <img class="icon interactive mobile-only" *ngIf="isOpen && (highlightColor == 'yellow')"
                src="../../../assets/icons-svg/piu_meno/icona_meno_gialla_mobile.svg">
        </div>
    </div>

</div>