import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Debounce } from 'angular-debounce-throttle';
import { IBasePage, IButtonLink } from 'src/app/models/commons';
import { INews } from 'src/app/models/components.interface';
import { slugs } from 'src/app/models/slugs';
import { HeaderData } from 'src/app/services/header-data.service';
import { SearchAndFiltersServices } from 'src/app/services/search-and-filters';
import { BasePageComponent } from '../base-page.component';
import { ShortenPipe } from 'ngx-pipes';
import { paths } from 'src/app/models/paths';
import { ITrendingTopicsArticle } from 'src/app/models/pages.interface';


const indexSite = 0;
const indexTT = 1;
const indexNW = 2;

const displayedNewsIncrement = 5;
@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  providers: [ShortenPipe]
})
export class SearchResultsComponent extends BasePageComponent<IBasePage> {
  pageTitle: string;
  query: string;
  queryReceived: boolean = false;

  primaRicercaFatta: boolean = false;
  textMaxLength: number = 160;

  resultsSite = null;       // Site
  resultsTT = null;         // Trending Topics
  resultsNW = null;         // News

  resultsArray = [
    { id: "site", title: "Site", isSelected: true, list: [], displayedResultsSite: 5 },
    { id: "tt", title: "Trending Topics", isSelected: true, list: [], displayedResultsSite: 5 },
    { id: "nw", title: "News", isSelected: true, list: [], displayedResultsSite: 5 },
  ];
  allResults = [];
  loadingResults: boolean;

  buttonLoadMore: IButtonLink = {
    type: "button",
    label: "",
    theme: 'btn_color__white',
    customBehaviour: "more",
    bgType: "dark"
  }


  constructor(private snf: SearchAndFiltersServices) {
    super();
  }

  ngOnInit() {
    this.pageTitle = this.translate.instant('SEARCH_RESULTS.PAGE_TITLE');

    this.activatedRoute.queryParams.subscribe((params: { q: string }) => {
      this.loadPageData();
      
      // console.log(params);

      this.query = params.q;
      console.log("query:", this.query);

      if (this.query && this.query?.length > 0 && this.query != ' ') {
        this.loadResults();
        this.primaRicercaFatta = true;
        this.queryReceived = true;
      }
    });

    this.translate.onLangChange.subscribe(() => {
      if (this.query && this.query?.length > 0 && this.query != ' ') {
        this.loadPageData();
        if (this.primaRicercaFatta)
          this.loadResults();
      }
    });

    super.ngOnInit();
  }

  //override
  protected async loadPageData() {
    this.headerDataService.setData(new HeaderData({
      title: this.translate.instant('SEARCH_RESULTS.PAGE_TITLE'),
      highlightColor: this.highlightColor,
      slug: this.slug,
      id: this.id || null,
      forceDarkHeader: this.forceDarkHeader,
      forceTitle: this.forceTitle,
      isAdvisorPage: false,
      showLangSelector: this.showLangSelector,
      forceBG: this.forceBG,
      meta_description: this.meta_description,
    }));
  }

  protected loadResults() {
    this.loadingResults = true;
    this._loadResults();
  }

  @Debounce(1000)
  protected async _loadResults() {
    this.resultsArray.map(item => item.isSelected = true);

    const qRes: IBasePage[] = Object.assign([], await this.api.search.site(this.query));
    // console.log(qRes);
    this.resultsSite = qRes;

    /** Trending Topics */
    const receivedTT: ITrendingTopicsArticle[] = await this.api.search.trendingTopics(this.query);
    // console.log("receivedTT", receivedTT);
    this.resultsTT = receivedTT;

    /** News */
    const receivedNews: INews[] = await this.api.search.news(this.query);
    this.resultsNW = receivedNews;


    this.resultsArray[indexSite].list = this.resultsSite;
    this.resultsArray[indexTT].list = this.resultsTT;
    this.resultsArray[indexNW].list = this.resultsNW;

    this.loadingResults = false;
  }

  setCategory(i: number) {
    /** if all of categories are "on" -> "turns off" all categories... */
    if (this.areAllCategoriesOn()) {
      this.resultsArray.map((refiner) => refiner.isSelected = false);
    }

    /** ...then lights up just the selected category */
    this.resultsArray[i].isSelected = !this.resultsArray[i].isSelected;
    
    // console.log("setCategory(" + i + ")", this.resultsArray[i].isSelected);
  }

  checkCategory(id: string) {
    // console.log(id);
    let retRefiners = false;
    let retList = false;

    this.resultsArray?.forEach((resultItem) => {
      if (resultItem.id.toLowerCase() == id.toLowerCase()) {
        retRefiners = resultItem.isSelected;
        resultItem.list?.forEach(element => {
          if (element.title && element.slug) retList = true;
        });
      }
      // else console.log(refiner.id.toLowerCase(), id.toLowerCase(), refiner.id.toLowerCase() == id.toLowerCase());
    });
    return retRefiners && retList;
  }

  isResultsEmpty(): boolean {
    let ret = true;
    // return (!this.resultsWWA) && (!this.resultsTT) && (!this.resultsNW) && (!this.resultsAdv) && (!this.resultsCar);
    this.resultsArray?.forEach(element => {
      if (element.list?.length > 0) {
        // console.log("list", element.id, element.list);
        ret = false;
      }
    });
    // console.log(ret);
    return ret;
  }

  areAllCategoriesOff(): boolean {
    let ret = true;
    this.resultsArray?.forEach((refiner) => {
      if (refiner.isSelected) ret = false;
    });
    return ret;
  }

  /** checks if all categories ("who we are", "news" etc.) are selected */
  areAllCategoriesOn(): boolean {
    let ret = true;
    this.resultsArray?.forEach((refiner) => {
      ret = ret && refiner.isSelected;
    });
    return ret;
  }

  submit(searchBoxForm: NgForm) {
    if (searchBoxForm.form.valid && searchBoxForm.form.valueChanges) {
      if (this.query && this.query?.length > 0 && this.query != ' ') {
        console.log("Confirm");
        // this.router.navigateByUrl(this.translate.currentLang + "/" + this.slug + "?q=" + this.query);
        // this.router.navigate(['/', this.translate.currentLang, this.slug, { q: this.query }]);
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            q: this.query
          },
          // queryParamsHandling: 'merge',
          // skipLocationChange: true
        });
      }
    }
  }

  getRoute(slug: string, resultsCategoryId: string) {
    // console.log("s-r slug", slug, resultsCategoryId);

    if (resultsCategoryId == "tt") return paths.trendingTopicsArticle;
    else if (resultsCategoryId == "nw") return paths.newsArticle;
    else if (slug.includes('__')) return slug.split('__')[0];
    else return slug;
  }

  getRouteId(slug: string, resultsCategoryId: string) {
    if (resultsCategoryId == "tt") return slug;
    else if (resultsCategoryId == "nw") return slug;
    else if (slug.includes('__')) return slug.split('__')[1];
    else return null;
  }

  increaseDisplayedResults(resultsCategoryId: string) {
    const id: "site" | "tt" | "nw" = resultsCategoryId as ("site" | "tt" | "nw");

    this.resultsArray?.forEach(resultItem => {
      if (resultItem.id == id) resultItem.displayedResultsSite += displayedNewsIncrement;
    });
  }

}
