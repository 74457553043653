import { Component } from '@angular/core';
import { Debounce } from 'angular-debounce-throttle';
import { Subscription } from 'rxjs';
import { cancelletto, IButtonLink, lastOldAnteoIssue } from 'src/app/models/commons';
import { ISocial } from 'src/app/models/components.interface';
import { IAnteoArticle } from 'src/app/models/pages.interface';
import { paths, separator } from 'src/app/models/paths';
import { slugs } from 'src/app/models/slugs';
import { HeaderData } from 'src/app/services/header-data.service';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-anteo-article',
  templateUrl: './anteo-article.component.html',
  styleUrls: ['./anteo-article.component.scss']
})
export class AnteoArticleComponent extends BasePageComponent<IAnteoArticle> {

  route: string = paths.anteoArticle;
  relatedNewsList: {id: string, title: string, category: string, pubDate: string}[];
  
  socials_list: ISocial[] = [
    { type: "linkedin", link: "https://linkedin.com" },
    { type: "twitter", link: "https://twitter.com" },
    { type: "facebook", link: "https://facebook.com" },
  ];

  topOnScroll : string = "35px";
  heightOnScroll : string = 'calc(100vh - 116px)';  

  anteoDataReadySubscription: Subscription = null;

  anteoIssueNumber: string = "";
 

  ngOnInit(): void {
    super.ngOnInit();

    this.anteoDataReadySubscription?.unsubscribe();
    this.anteoDataReadySubscription = this.dataServices.pageDataReady.subscribe(() => {
      if (this.data) {
        this.loadRelatedNewsList();
        this.scrollToTop();
      }
    });

    // if (this.data?.news?.typeNews == "news") this.data.news.typeNews = "dataviz";

    this.componentsServices.pageScrollUp.subscribe(() => {
      this.topOnScroll = "115px";   
      this.heightOnScroll = 'calc(100vh - 190px)';
    });
  
    this.componentsServices.pageScrollDown.subscribe(() => {
      this.topOnScroll = "35px";   
      this.heightOnScroll = 'calc(100vh - 116px)';
    });
  }

  @Debounce(10)
  protected async loadPageData() {
    if (!this.slug) {
      const wlpn = window.location.pathname.substring(1);
      if (wlpn.startsWith("it/it/") || wlpn.startsWith("en/en/") || wlpn.includes("%2523")) {
        const nr = wlpn.replace(/it\/it\//g, "it/").replace(/en\/en\//g, "en/").replace(/%2523/g, "#");
        this.router.navigateByUrl("/" + nr);
      }

      console.error('Current page does not have a SLUG. Please check app-routing.module!');
      return;
    }

    try {
      // console.log(this.slug + separator + this.id);

      let page: IAnteoArticle;

      page = await this.api.pages.get(this.slug + separator + this.id);
      if (!page) page = await this.api.anteo.getArticle(this.id) as unknown as IAnteoArticle;

      // console.log("page", page);

      if (!page?.slug) {
        page.slug = this.slug + separator + this.id;
      }
      if (page?.news?.issueNumber) {
        /** new one should have it */
        this.anteoIssueNumber = page.news.issueNumber;
        this.headerDataService.setAnteoNumberGoBack(this.anteoIssueNumber);
      }
      
      this.data = page;
      
      this.dataServices.pageDataEmit(page);

      // console.log("---", page.title, page.slug, this.slug, page);

      this.headerDataService.setData(new HeaderData({
        title: page.title,
        highlightColor: this.highlightColor,
        slug: this.slug,
        id: this.id || null,
        forceDarkHeader: this.forceDarkHeader,
        forceTitle: this.forceTitle,
        isAdvisorPage: this.isAdvisorPage,
        showLangSelector: this.showLangSelector,
        forceBG: this.forceBG,
        meta_description: this.meta_description,
      }));
      this.notFound = false;
    } catch (ex) {
      this.dataServices.pageDataError(ex);

      this.componentsServices.updatePreviousRoute(this.slug);
      //if (this.slug != slugs.pageNotFound) this.router.navigate(['404']);
      this.notFound = true;
    }
  }

  private async loadRelatedNewsList() {
    this.relatedNewsList = [];

    // console.log(this.data?.news?.related);

    if (
        (this.data?.news?.issueNumber || false) && 
        parseInt(this.data?.news?.issueNumber) > parseInt(lastOldAnteoIssue) && 
        this.data?.news?.related?.length > 0
      ) {
      this.relatedNewsList = this.data.news.related;
    } else {
      const category = "anteo" + cancelletto + "more";

      let anteoIssueNumber = "";
      let number = "";
      if (this.data?.news?.issueNumber && this.data?.news?.issueNumber != "null") {
        // console.log("data.news.issueNumber", this.data.news.issueNumber);
        anteoIssueNumber = "anteo" + this.data.news.issueNumber;
        number = this.data.news.issueNumber;
      } else {
        // console.log("id", this.id, this.data?.news);
        anteoIssueNumber = this.id.split("-")[0];
        number = anteoIssueNumber.startsWith("anteo") ? anteoIssueNumber.split("anteo")[1] : anteoIssueNumber;
      }
      // console.log("anteo number:", number, anteoIssueNumber);
      this.headerDataService.setAnteoNumberGoBack(number);
      const filter = slugs.anteoArticle + separator + anteoIssueNumber;
      // console.log(category, filter, ">>>", number);
  
      const archive: any[] = await this.api.archives.get(category);
      // console.log("archive", category, archive);
  
      archive.forEach(element => {
        const elemID = element.slug.split(separator)[1];

        // console.log(this.id != elemID && ( element.slug.startsWith(filter) || element.news.issueNumber == number ), element);

        if (this.id != elemID && (
            element.slug.startsWith(filter) || 
            element.news.issueNumber == number
            )
          ) {
          let rn: {id: string, title: string, category: string, pubDate: string} = {
            id: elemID,
            title: element.news.title,
            category: "Anteo " + number,
            pubDate: element.news.pubDate
          }
          this.relatedNewsList.push(rn);
        }
      });
    }

    // console.log("relatedNewsList", this.relatedNewsList);
  }

  beautifyCategory(cat: string) {
    return cat.replace(/_/g, " ");
  }

  scrollToTop() {
    this.componentsServices.scrollToTop();
  }

  
  download(_link: string): IButtonLink {
    return  {
      type: 'button',
      label: 'DOWNLOAD',
      _meta: {
        isDownloadable : true,
      },
      theme: 'btn_color__green',
      link: _link
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.anteoDataReadySubscription?.unsubscribe();
    this.anteoDataReadySubscription = null;
  }

}
