import { Component } from '@angular/core';
import { Debounce } from 'angular-debounce-throttle';
import { IButtonLink, lastOldAnteoIssue } from 'src/app/models/commons';
import { paths, separator } from 'src/app/models/paths';
import { HeaderData } from 'src/app/services/header-data.service';
import { IAnteo } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-anteo',
  templateUrl: './anteo.component.html',
  styleUrls: ['./anteo.component.scss']
})
export class AnteoComponent extends BasePageComponent<IAnteo> {
  /** if ID == null -> last, if ID = id -> load(anteo-id-newsletter) */

  articleRoute: string = paths.anteoArticle;
  articleArchive: string = paths.anteoArchive;

  dowloadButton: IButtonLink = {
    label: "download",
    link: "",
    theme: "btn_color__blue",
    type: "button",
    _meta: { isDownloadable: true }
  }

  @Debounce(10)
  protected async loadPageData() {
    if (!this.slug) {
      const wlpn = window.location.pathname.substring(1);
      if (wlpn.startsWith("it/it/") || wlpn.startsWith("en/en/") || wlpn.includes("%2523")) {
        const nr = wlpn.replace(/it\/it\//g, "it/").replace(/en\/en\//g, "en/").replace(/%2523/g, "#");
        this.router.navigateByUrl("/" + nr);
      }

      console.error('Current page does not have a SLUG. Please check app-routing.module!');
      return;
    }

    try {
      console.log(this.slug + separator + this.id);

      let page: IAnteo;
      const arr = this.id.split("anteo");
      const issueNumber = arr[arr.length - 1];
      //parseInt(/(\d+)/.exec('Anteo 12342154')[1])

      if (parseInt(issueNumber) <= parseInt(lastOldAnteoIssue)) {
        /* chiamata al servizio vecchio */
        page = await this.api.pages.get(this.slug + separator + this.id);
      } else {
        /* chiamata al servizio nuovo */
        page = await this.api.anteo.getIssue(issueNumber) as unknown as IAnteo;
      }
      // console.log("page", page);

      if (!page.slug) {
        page.slug = this.slug + separator + this.id;
      }
      
      this.data = page;
      
      this.dataServices.pageDataEmit(page);

      if (page.attachment) {
        this.dowloadButton.link = page.attachment;
      }

      // console.log("---", page.title, page.slug, this.slug, page);

      this.headerDataService.setData(new HeaderData({
        title: page.title,
        highlightColor: this.highlightColor,
        slug: this.slug,
        id: this.id || null,
        forceDarkHeader: this.forceDarkHeader,
        forceTitle: this.forceTitle,
        isAdvisorPage: this.isAdvisorPage,
        showLangSelector: this.showLangSelector,
        forceBG: this.forceBG,
        meta_description: this.meta_description,
      }));
      this.notFound = false;
    } catch (ex) {
      this.dataServices.pageDataError(ex);

      this.componentsServices.updatePreviousRoute(this.slug);
      //if (this.slug != slugs.pageNotFound) this.router.navigate(['404']);
      this.notFound = true;
    }
  }

  capitolize(s: string): string {
    return s[0].toUpperCase() + s.substring(1);
  }

}
