import { Component, Input, OnInit } from '@angular/core';
import { ISectionOptions } from '../../models/components.interface';

@Component({
  selector: 'app-section-two-even-columns',
  templateUrl: './section-two-even-columns.component.html',
  styleUrls: ['./section-two-even-columns.component.scss']
})
export class SectionTwoEvenColumnsComponent implements OnInit {

  @Input() data: any;
  @Input() opt: ISectionOptions = {titleColor: "primary", largerTextSmallerImg: false};
  sectionOptions: ISectionOptions;

  constructor() { }

  ngOnInit(): void {
    this.sectionOptions = this.opt;
  }

}
