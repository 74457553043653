import { Injectable } from '@angular/core';
import { ISectionAccordionListItem } from '../models/components.interface';
import { IOfficesList } from '../models/components.interface';
import { IAnteo } from '../models/pages.interface';

@Injectable({
  providedIn: 'root',
})
export class SortingServices {

  /**
   * sort list's item by their title
   * @param array 
   * es: let item of sort.byTitle(list)
   */
  public byTitle(array: ISectionAccordionListItem[]) {
    return array.sort((a, b) => (a.title > b.title) ? 1 : -1);
  }

  /**
   * sort offices by city name
   * @param array 
   * es: let city of sortByCity(data.sedi.list)
   */
  public byCity(array: IOfficesList[]) {
    return array.sort((a, b) => (a.city > b.city) ? 1 : -1);
  }

  /**
   * sort items by year
   * @param array 
   * es: let item of sort.byYear(list)
   */
  public byYear_newer(array: any[]) {
    const ret = array.sort((a, b) => (a.slug < b.slug) ? 1 : -1);
    // console.log(ret);
    return ret;
  }

  /**
   * sort items by pubDate
   * @param array 
   * es: let item of sort.byPubDate(list)
   */
  public byPubDate_newer(array: any[]) {
    const ret = array.sort((a, b) => new Date(a.pubDate).getMilliseconds() - new Date(b.pubDate).getMilliseconds());
    return ret;
  }

  /**
   * sort items by year
   * @param array 
   * es: let item of sort.byYear(list)
   */
  public byYear_newerAnteo(array: IAnteo[]) {
    const ret = array.sort((a, b) => (this.checkNumbers(a.issueNumber, b.issueNumber)) ? 1 : -1);
    // console.log(ret);
    // ret.forEach(element => {
    //   console.log(element.issueNumber, 99 > 100);
    // });
    return ret;
  }

  /**
   * sort items by year
   * @param array 
   * es: let item of sort.byYear(list)
   */
  public byYear_infoPaths(array: any[]) {
    const ret = array.sort((a, b) => (b.sections[0].date.substr(b.sections[0].date.length - 4) - (a.sections[0].date.substr(a.sections[0].date.length - 4))));
    // console.log(ret);
    // ret.forEach(element => {
    //   console.log(element.issueNumber, 99 > 100);
    // });
    return ret;
  }


  public byDate_meta(array: { _meta: { pubDate: string } }[]) {
    if (array[0]?._meta?.pubDate.includes(" ")) {
      const ret = array.sort((a, b) => (this.stringToSimpleDateNumber(a._meta.pubDate) < this.stringToSimpleDateNumber(b._meta.pubDate)) ? 1 : -1);
      // console.log("ret", ret);
      // ret.forEach(el => {
      //   console.log(this.stringToSimpleDate(el._meta.pubDate));
      // });
      return ret;
    } else {
      const ret = array.sort((a, b) => (a._meta.pubDate < b._meta.pubDate) ? 1 : -1);
      return ret;
    }
  }



  /** SUPPORT */

  checkNumbers(ain: number, bin: number) {
    const sain: string = "" + ain;
    const sbin: string = "" + bin;
    if (sain.length != sbin.length) {
      if (sain.length < sbin.length) return true;
      else return false;
    } else {
      if (ain < bin) return true;
      else return false;
    }
  }


  public stringToSimpleDateNumber(s: string): number {
    if (s.split(" ").length == 3) {
      const day = +s.split(" ")[0];
      const month = +this.convertMonth(s.split(" ")[1]);
      const year = +s.split(" ")[2];

      const date: number = year*10000 + month*100 + day;
      return date;
    } else {
      return -1;
    }
  }

  convertMonth(monthAsString: string) {
    let month = "";
    switch (monthAsString.toLowerCase()) {
      case "gennaio":
      case "january":
        month = "01";
        break;

      case "febbraio":
      case "february":
        month = "02";
        break;

      case "marzo":
      case "march":
        month = "03";
        break;

      case "aprile":
      case "april":
        month = "04";
        break;

      case "maggio":
      case "may":
        month = "05";
        break;

      case "giugno":
      case "june":
        month = "06";
        break;

      case "luglio":
      case "july":
        month = "07";
        break;

      case "agosto":
      case "august":
        month = "08";
        break;

      case "settembre":
      case "september":
        month = "09";
        break;

      case "ottobre":
      case "october":
        month = "10";
        break;

      case "novembre":
      case "november":
        month = "11";
        break;

      case "dicembre":
      case "dicember":
        month = "12";
        break;

      default:
        month = "00";
        break;
    }
    return month;
  }
}
