import { Component, OnInit } from '@angular/core';
import globalJSON from '../../../assets/json/global.json';
import { DESKTOP_MIN_WIDTH, HEADER__EXTRA_HEIGHT, IBaseSection, resizeEventResult } from '../../models/commons';
import { ComponentsServices } from '../../services/components.service';
import { Subscription } from 'rxjs';
import { IRnD } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';


@Component({
  selector: 'app-r-n-d',
  templateUrl: './r-n-d.component.html',
  styleUrls: ['./r-n-d.component.scss']
})
export class RnDComponent extends BasePageComponent<IRnD> {
  
  outroSubscription: Subscription;
  outroThemeColor: string;
  outroThemeOpacity: string;
  isDarkTheme: boolean = false;
  s0: IBaseSection;
  private pageSub: Subscription;
  f = false;

  ngOnInit() {
    super.ngOnInit();

    this.pageSub = this.dataServices.pageDataReady.subscribe(() => {
      this.s0 = this.data.sections[0];
      if (!this.f) {
        // console.log("s0 is", this.s0, this.highlightColor);
        this.f = true;
      }

      this.outroThemeColor = "intro-theme--" + this.s0.theme.split("#")[0];
      this.outroThemeOpacity = this.s0.theme.split("#")[1] + "%";

      if (this.outroThemeColor.endsWith("primary") || this.outroThemeColor.endsWith("blue")) {
        this.isDarkTheme = true;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.pageSub != null) {
      this.pageSub.unsubscribe();
      this.pageSub = null;
    }
  }

}
