<ng-container *ngIf="!data">

</ng-container>

<ng-container *ngIf="data">
    <div class="collapsable-list">
        <p class="text--primary" *ngIf="data.title && !data.hideTitle && !forceHideTitle">{{data.title}}</p>
        <hr class="first-hr">

        <ng-container *ngFor="let x of data.list; last as isLast">
            <app-collapsable-list-item [data]="x" [highlightColor]="highlightColor" [keepImagesSize]="keepImagesSize"></app-collapsable-list-item>
            <hr *ngIf="isLast" class="last-hr">
            <hr *ngIf="!isLast" class="middle-hr">
        </ng-container>
    </div>
</ng-container>