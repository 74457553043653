import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Debounce } from 'angular-debounce-throttle';
import { IBasePage } from 'src/app/models/commons';
import { paths } from 'src/app/models/paths';
import { HeaderData } from 'src/app/services/header-data.service';
import { BasePageComponent } from '../base-page.component';

export interface IAlumniUnsubPOST {
  email: string;
}

@Component({
  selector: 'app-alumni-unsubscribe',
  templateUrl: './alumni-unsubscribe.component.html',
  styleUrls: ['./alumni-unsubscribe.component.scss']
})
export class AlumniUnsubscribeComponent extends BasePageComponent<IBasePage> {  // alumni/unsubscribe

  email: string;

  account: IAlumniUnsubPOST = {
    email: null,
  };

  unsubscribed: boolean = false;

  constructor(private _snackBar: MatSnackBar) {
    super();
  }

  @Debounce(10)
  protected async loadPageData() {
    this.headerDataService.setData(new HeaderData({
      title: "Alumni",
      highlightColor: "yellow",
      slug: paths.alumniUnsubscribe,
      forceDarkHeader: true,
      forceTitle: true,

      id: null,
      isAdvisorPage: false,
      showLangSelector: false,
      forceBG: false,
      meta_description: null,
    }));
  }

  async submit(activateAccountForm: NgForm) {
    let snackBarRef;
    if(activateAccountForm.form.valid) {
      try {
        // console.log("Confirm", JSON.stringify(this.account));

        const resp = await this.api.career.alumniUnsubscribe(this.account);
  
        snackBarRef = this.openSnackBar(this.translate.instant('ALUMNI_REGISTRATION.UNSUBSCRIBED'));
        this.unsubscribed = true;
      } catch (ex) {
        const err = ex.error.error;
        // console.log("catched", err);

        if (err.code == 'USER_NOT_FOUND') {
          snackBarRef = this.openSnackBar(this.translate.instant('ALUMNI_REGISTRATION.USER_NOT_FOUND'));
        } else {
          snackBarRef = this.openSnackBar(this.translate.instant('ALUMNI_REGISTRATION.RETRY_LATER'));
        }
      }
    } else {
      snackBarRef = this.openSnackBar(this.translate.instant('ALUMNI_REGISTRATION.EMAIL_REQUIRED'));
    }
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
