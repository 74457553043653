import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IButtonLink } from 'src/app/models/commons';
import { paths } from 'src/app/models/paths';
import { IOfficesList } from '../../models/components.interface';
import { INetwork } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-our-network',
  templateUrl: './our-network.component.html',
  styleUrls: ['./our-network.component.scss']
})
export class OurNetworkComponent extends BasePageComponent<INetwork> {

  showCityRecap: boolean = false;
  cityRecap_name: string = "";
  cityRecap_addresses: any[] = null;
  cityRecap_showBtn: boolean = false;
  cityRecap_button: IButtonLink = {
    type: "button",
    theme: 'btn_color__green',
    route: paths.countryPage,
    label: "SHOW_MORE",
    id: "",
    _meta: {
      forceShorterHorizontalPadding: true,
      translate: true
    }
  }
  // private changeLangSubscriptionInOurNetwork: Subscription = null;

  defaultRadius: number = 6.02;
  hoverRadius: number = this.defaultRadius * 1.2;

  ngOnInit() {
    super.ngOnInit();

    // this.changeLangSubscriptionInOurNetwork?.unsubscribe();
    // this.changeLangSubscriptionInOurNetwork = this.translate.onLangChange.subscribe(() => {
    //   this.cityRecap_button.label = this.translate.instant("SHOW_MORE");
    //   console.log(this.cityRecap_button.label);
    // });
  }
  
  // ngOnDestroy(): void {
  //   super.ngOnDestroy();
    
  //   this.changeLangSubscriptionInOurNetwork?.unsubscribe();
  //   this.changeLangSubscriptionInOurNetwork = null;
  // }


  cityClick(cityID: string, cityData: IOfficesList, type: 'sede' | 'branch' | 'partner') {
    // console.log("clicked on city:", cityID, cityData);

    if (cityID != cityData.id) return;

    this.closeCity();

    if (['branch', 'partner'].includes(type)) {
      this.router.navigate(['/country-page', cityID]);
      return;
    }

    setTimeout(() => {
      this.cityRecap_name = cityData.city;
      this.cityRecap_addresses = cityData.addresses;
      this.cityRecap_showBtn = cityData.showBtn || false;
      this.cityRecap_button.id = cityData.id;
      this.showCityRecap = true;
    }, 100);
  }

  closeCity() {
    this.showCityRecap = false;
  }



  setHover(event: any) {
    event.path?.[1].childNodes[0].setAttribute('r', this.hoverRadius);
  }

  resetHover(event: any) {
    event.path?.[1].childNodes[0].setAttribute('r', this.defaultRadius);
  }

}
