import { Component } from '@angular/core';
import { ISocial } from '../../models/components.interface';

@Component({
  selector: 'app-sidebar-socials',
  templateUrl: './sidebar-socials.component.html',
  styleUrls: ['./sidebar-socials.component.scss']
})
export class SidebarSocialsComponent {

  socials_list: ISocial[] = [
    // { type: "facebook", link: "https://www.facebook.com/prometeiagroup" },
    // { type: "twitter", link: "https://twitter.com/PrometeiaGroup" },
    { type: "linkedin", link: "https://www.linkedin.com/company/prometeia/mycompany/" },
    { type: "youtube", link: "https://www.youtube.com/channel/UCbz97h9v96HGofLJiZrScEw" },
    { type: "instagram", link: "https://www.instagram.com/prometeiagroup/" },
  ]

  constructor() { }

}
