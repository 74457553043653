import { Overlay } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { ScrollSpyService } from 'ng-spy';
import { Subscription } from 'rxjs';
import { AmplifyAuthService } from 'src/app/services/amplify-auth.service';
import { FinancialAdvisoryComponent } from '../financial-advisory/financial-advisory.component';

@Component({
  selector: 'app-alumni',
  templateUrl: './alumni.component.html',
  styleUrls: ['./alumni.component.scss']
})
export class AlumniComponent extends FinancialAdvisoryComponent {

  showForm: boolean = false;
  showAlumniDialogAuth : boolean = false;

  private poolStatusSubscription: Subscription = null;
  
  constructor(
    protected spyService: ScrollSpyService, 
    public overlay: Overlay,
    public amplifyAuthService: AmplifyAuthService) {
    super(spyService);
  }

  ngOnInit() {
    super.ngOnInit();
    
    this.poolStatusSubscription?.unsubscribe();
    this.poolStatusSubscription = this.amplifyAuthService.poolAlumniStatus.subscribe( status => {
      console.log('LOGIN ALUMNI', status.islogged);
      if(this.showAlumniDialogAuth == status.islogged){
        this.showAlumniDialogAuth = !status.islogged; 
        if(status.islogged){
          window.location.reload();
        }
      }
    } );
  
    this.isAuthenticateUser()
  }

  ngOnDestroy(): void {
    if (this.poolStatusSubscription) {
      this.poolStatusSubscription.unsubscribe();
      this.poolStatusSubscription = null;
    }
  }

  async isAuthenticateUser(){    
    this.showAlumniDialogAuth =  !await this.amplifyAuthService.isLoggedIn('alumni');    
  }

}
