import { Component } from '@angular/core';
import { DESKTOP_MIN_WIDTH, IBaseSection } from '../../models/commons';
import { BasePageComponent } from '../base-page.component';
import { ISocialResponsibility } from 'src/app/models/pages.interface';
import { Subscription } from 'rxjs';
import { BrowserServices } from 'src/app/services/browser.service';
import { ViewportScroller } from '@angular/common';
import { ComponentsServices } from 'src/app/services/components.service';

const INITIAL_OPACITY_MAIN_BGI = 0.82;
@Component({
  selector: 'app-social-responsibility',
  templateUrl: './social-responsibility.component.html',
  styleUrls: ['./social-responsibility.component.scss']
})
export class SocialResponsibilityComponent extends BasePageComponent<ISocialResponsibility>  {

  browserType: string = "";
  deviceType: string = "";

  /** index from 1 */
  private selectedElement: number = 0;

  opacityMainBGI: number = INITIAL_OPACITY_MAIN_BGI;
  private pageScrollSubscription: Subscription = null;

  isApplyAnimationActive: boolean = false;
  isMouseOverIndex: number = 0;

  constructor(private browserServices: BrowserServices, private viewportScroller: ViewportScroller) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.browserType = this.browserServices.myBrowser().toLowerCase();
    this.deviceType = this.browserServices.myDevice().toLowerCase();

    this.componentsServices.scrollToTop();

    this.pageScrollSubscription?.unsubscribe();
    this.pageScrollSubscription = this.componentsServices.pageScroll.subscribe((element: {newY: number}) => {
      this.opacityMainBGI = INITIAL_OPACITY_MAIN_BGI - (element.newY / 100) * 0.05;  //da 1 a .15
      if (this.opacityMainBGI <= 0.15) this.opacityMainBGI = 0.15;

      //console.log(this.opacityMainBGI, element.newY);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.pageScrollSubscription?.unsubscribe();
    this.pageScrollSubscription = null;
  }

  isElementSelected(index: number, section?: IBaseSection): boolean {
    // if (index == this.selectedElement) console.log(index, section);
    return index == this.selectedElement;
  }

  getSelectedElement(): number {
    return this.selectedElement;
  }

  toggleElement(index: number): void {
    this.opacityMainBGI = INITIAL_OPACITY_MAIN_BGI;

    if (this.selectedElement == index) {
      this.closeElement();
    } else {
      this.selectedElement = index;
      // console.log(this.data.sections[index].backImage);
    }

    if (this.componentsServices.isDesktopMode()) {
      this.componentsServices.scrollToTop();
    }
  }

  closeElement() {
    this.selectedElement = 0;
    this.isApplyAnimationActive = true;

    setTimeout(() => {
      this.isApplyAnimationActive = false;
    }, 800);
  }

  isIntroHidden(): boolean {
    return this.selectedElement > 0;
  }

  getCoordName(index: number) {
    if (this.selectedElement == 0) return "left";

    if (index < this.selectedElement) return "left";

    return "right";
  }

  getLeftPositionForBGI(index: number): string {
    let offset: number = 80;
    if (!this.isIntroHidden()) offset = 126;

    if (this.getCoordName(index) == "left")
      return ((index-1) * offset) + "px";
    return "auto";
  }

  getRightPositionForBGI(index: number): string {
    if (this.getCoordName(index) == "right")
      return ((this.data.sections.length - index-1) * 80) + "px";
    return "auto";
  }

  getTransformForBGI(index: number): string {
    let offset: number = 80;
    if (!this.isIntroHidden()) offset = 126;

    let tX: string = "translateX(";
    let tX_val: number = 0;

    if (this.getCoordName(index) == "left") tX_val = (index-1) * offset;
    if (this.getCoordName(index) == "right") tX_val = (this.componentsServices.getInnerWidth() - ((index-2) * offset));
    if (this.getCoordName(index) == "right" && index == 2) tX_val = (this.componentsServices.getInnerWidth() - ((index) * offset));

    let ret: string = tX + tX_val + "px)";

    return ret;
  }


  mouseEnter(index: number) {
    this.isMouseOverIndex = index;
    // console.log("entering", this.data.sections[index].title);
  }
    
  mouseLeave() {
    // console.log("leaving", this.data.sections[this.isMouseOverIndex].title);
    this.isMouseOverIndex = 0;
  }

  getTransformScale(index: number): number {
    if (this.isMouseOverIndex == index) return 1.1;
    return 1;
  }

  getTransformTranslate(index: number): number {
    if (this.isMouseOverIndex == index) return -10;
    return 0;
  }


  getCoverImg(): string | null {
    const ret = this.getBgImg(0);
    return ret;
  }

  getSelectedImg(): string | null {
    const imgType: number = this.getSelectedElement() > 0 ? 1 : 0;
    const ret: string = this.data.sections[this.getSelectedElement()].backImages[imgType];
    // console.log(ret);
    if (ret) return ret;
    return null;
  }

  getBgImg(index: number) {
    const imgType: number = (this.getSelectedElement() == index && index > 0 ) ? 1 : 0;
    const ret: string = this.data.sections[index].backImages[imgType];
    // console.log(ret);
    if (ret) return ret;
    return null;
  }



  swapImageLink(i: number, j: number) {
    const tmp = this.data.sections[i].mediaList[j].contentUrl;
    this.data.sections[i].mediaList[j].contentUrl = this.data.sections[i].mediaList[j].previewUrl;
    this.data.sections[i].mediaList[j].previewUrl = tmp;
  }
}
