import { Component } from '@angular/core';
import { SectionGeneralComponent } from '../section-general/section-general.component';

@Component({
  selector: 'app-section-stacked',
  templateUrl: './section-stacked.component.html',
  styleUrls: ['./section-stacked.component.scss']
})
export class SectionStackedComponent extends SectionGeneralComponent {

  btnLabel: string = this.translate.instant("DISCOVER");

}
