import { Component, Input, OnInit } from '@angular/core';
import { HighlightColor } from '../../models/commons';

@Component({
  selector: 'app-highlighted-text',
  templateUrl: './highlighted-text.component.html',
  styleUrls: ['./highlighted-text.component.scss']
})
export class HighlightedTextComponent implements OnInit {

  @Input() text: string;
  @Input() text_color: "accent" | "primary";
  @Input() highlighter: HighlightColor;
  @Input() customStyle: string;

  constructor() { }

  ngOnInit(): void {
    console.log("customStyle", this.customStyle);
  }

}
