<div class="single-newsletter" spyTarget="{{data.anchorId}}" >
    <img class="single-newsletter--logo" src="{{data.media.contentUrl}}" alt="{{data.media.alt}}" *ngIf="data.media.type == 'IMG'">

    <div class="single-newsletter--content">
        <h3 class="prom-contact single-newsletter--content--title text--primary">{{data.title}}</h3>
        <p class="pi-p single-newsletter--content--text">{{data.text}}</p>

        <div class="single-newsletter--content--subscribe desktop-only">
            <app-prometeia-button class="single-newsletter--content--subscribe-button interactive" [data]="data.buttons[0]" (click)="subscribeToNewsletter()"></app-prometeia-button>
        </div>
    </div>

    <app-prometeia-button class="subscribe-button interactive mobile-only" [data]="data.buttons[0]" (click)="subscribeToNewsletter()"></app-prometeia-button>

</div>