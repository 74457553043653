<section *ngIf="data?.type == 'special' && data?.subType == 'cards'"
    spyTarget="{{data.anchorId}}" class="{{data.theme || 'theme--dark'}} section center-vertical">

    <div class="section-max-content">
        <h1 data-aos="fade-up" class="section_h1 neutra2_bold text--{{sectionOptions.titleColor || 'accent'}}">
            {{ data.title }}
        </h1>

        <div class="cards-list">
            <ng-container *ngIf="!data.wideCardsType || data.wideCardsType == 'default'">
                <p *ngFor="let c of data.wideCards">{{c.title}}</p>
            </ng-container>

            <div *ngIf="data.wideCardsType == 'job-offers'" data-aos="fade-up">
                <app-single-job-offer *ngFor="let c of data.wideCards" [class]="'offer'" [job]="c"></app-single-job-offer>
            </div>
        </div>

        <div data-aos="fade-up" class="section--buttons-container two-columns--other--btn" *ngIf="data.buttons?.length > 0">
            <ng-container *ngFor="let btn of data.buttons">
                <app-prometeia-button   *ngIf="btn.type == 'button'" 
                                        class="sections--button two-columns--other--btn--button" [data]="btn"></app-prometeia-button>
            </ng-container>
        </div>
    </div>
</section>
