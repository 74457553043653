<!-- News, Newsletter e Trending Topics -->

<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">
    <div class="insights prom-page">

        <!-- <app-atlante-app-banner *ngIf="slug == slugTrendingTopics"></app-atlante-app-banner> -->

        <section class="intro section-max-content" *ngIf="data.intro && data.intro.type == 'intro'">
            <div class="intro--texts">

                <h2 class="text--accent prom-section no-underline capslock intro--main-title">{{data.title}}</h2>
                <h1 class="neutra2_bold text--accent intro--title">{{data.intro.title}}</h1>

                <p class="intro--text">{{data.intro.text}}</p>
            </div>

            <div class="searchbar" *ngIf="data.search && data.slug == slugNews">
                <app-search-bar [data]="data.search" (filterChange)="filter($event)" (filterReset)="filterResetEvent()"></app-search-bar>
            </div>
            <div class="searchbar" *ngIf="data.search && data.slug == slugTrendingTopics">
                <app-search-bar [data]="data.search" (filterChange)="filterTT($event)" (filterReset)="filterResetEventTT()"></app-search-bar>
            </div>
            <!-- <p class="searchbar" *ngIf="data.searchbarFlag">searchbarFlag</p> -->
        </section>


        <ng-container *ngIf="slug == slugNews">
            <ng-container *ngFor="let s of filteredNewsList; let i = index">
                <section class="section-max-content" id="n{{i+1}}-{{s.id}}">
                    <hr>
                    <!-- <p>{{s.id}}</p> -->
                    <app-single-news [data]="s" *ngIf="slug == slugNews"></app-single-news>
                </section>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="slug == slugNewsletter">
            <ng-container *ngFor="let s of data.sections; let i = index">
                <section *ngIf="s.title" class="section-max-content">
                    <hr>
                    <app-single-newsletter [data]="s"></app-single-newsletter>
                </section>
            </ng-container>
        </ng-container>

        <section *ngIf="slug == slugNews">
            <hr>
            <div class="buttonLoadMore" *ngIf="showMoreButton && filteredNewsList.length > 0">
                <app-prometeia-button class="buttonLoadMore--btn interactive" [data]="buttonLoadMore" (click)="loadMore()"></app-prometeia-button>
            </div>
            <div *ngIf="showMoreButton && !(filteredNewsList.length > 0) && pageLoaded">
                <p class="text--primary" style="text-align: center;">
                    {{'SEARCH_RESULTS.NO_RESULTS' | translate}}
                </p>
            </div>
        </section>

        <section *ngIf="slug == slugTrendingTopics" class="category">
            <swiper [(index)]="trendingTopicsCategoriesIndex" *ngIf="!useFilter">
                <div *ngFor="let item of trendingTopicsList" [ngClass]="{'selectCategory' : selectedCategory == item.code  }" (click)="selectCategory(item.code)">
                    {{item.name}}
                </div>
            </swiper>
            <p class="search neutra2_bold capslock" style="text-align: center; margin: 0;" *ngIf="useFilter">{{ 'SEARCH_RESULTS.FILTER' | translate }}</p>
        </section>

        <ng-container *ngIf="!useFilter">
            <ng-container *ngFor="let item of trendingTopicsList">
                <div *ngFor="let s of item.news">
                    <app-single-top-trending-topics *ngIf="item.code == selectedCategory && s.highlights" [data]="s" [category]="item.name" [imgNewsDefault]="data.imgNewsDefault"></app-single-top-trending-topics>
                </div>
            </ng-container>
        </ng-container>

        <div class="single-trending-topics section-max-content">
            <ng-container *ngIf="!useFilter">
                <ng-container *ngFor="let item of trendingTopicsList">
                    <div *ngFor="let s of item.news">
                        <app-single-trending-topics *ngIf="item.code == selectedCategory && !s.highlights" [data]="s" [category]="item.name" [imgNewsDefault]="data.imgNewsDefault"></app-single-trending-topics>
                    </div>
                    <div class="single-trending-topics--empty" *ngIf="item.code == selectedCategory && item.news.length == 0">{{ 'TRENDING_TOPICS.EMPTY' | translate }}</div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="useFilter">
                <ng-container *ngFor="let item of filteredTrendingTopicsList">
                    <app-single-trending-topics [data]="item" [category]="item.name" [imgNewsDefault]="data.imgNewsDefault"></app-single-trending-topics>
                    <!-- <p>{{item.title}}</p> -->
                </ng-container>
                
                <div class="single-trending-topics--empty" *ngIf="filteredTrendingTopicsList.length == 0">{{ 'TRENDING_TOPICS.EMPTY' | translate }}</div>
            </ng-container>
        </div>

        <div class="section-max-content">
            <section *ngIf="slug == slugTrendingTopics && showMoreButtonsTT && showMoreButtonsTT[selectedCategory] && !useFilter" class="btnLoadMore-trending-topics">
                
                <div class="buttonLoadMore">
                    <app-prometeia-button class="buttonLoadMore--btn interactive" [data]="buttonLoadMore" (click)="loadMore()"></app-prometeia-button>
                </div>
            </section>

            <section *ngIf="slug == slugTrendingTopics && showMoreButtonFilteredTT && useFilter" class="btnLoadMore-trending-topics">
                
                <div class="buttonLoadMore">
                    <app-prometeia-button class="buttonLoadMore--btn interactive" [data]="buttonLoadMore" (click)="loadMore()"></app-prometeia-button>
                </div>
            </section>
        </div>

        <!--- da rivedere il json per l'ultima sezione -->
        <ng-container *ngFor="let s of data.sections">

            <section *ngIf="s.type == 'last'" class="section center-vertical section--last">
                <div class="container section-max-content">
                    <div class="rc">

                        <div class="rc--sub">
                            <p class="text--primary title">
                                {{s.text}}
                                <span class="neutra2_bold"><br>{{s.title}}</span>
                            </p>
                        </div>

                        <div class="rc--sub">
                            <!-- <p class="spacer"></p> -->
                            <app-prometeia-button class="subscribe-button interactive" [routerLink]="['/', translate.currentLang, slugNewsletter]" [fragment]="s.list[0].id" routerLinkActive="active" [data]="s.list[0]"></app-prometeia-button>
                            <!-- <p class="spacer"></p> -->
                        </div>
                    </div>
                </div>
            </section>
        </ng-container>
    </div>
</ng-container>
