import { Component } from '@angular/core';
import { IButtonLink } from 'src/app/models/commons';
import { IFlippingCard } from 'src/app/models/components.interface';
import { SectionGeneralComponent } from '../section-general/section-general.component';

@Component({
  selector: 'app-section-grid-cards',
  templateUrl: './section-grid-cards.component.html',
  styleUrls: ['./section-grid-cards.component.scss']
})
export class SectionGridCardsComponent extends SectionGeneralComponent {

  gridCardsCustomStyle: string = "neutra2_bold smaller-text";

  ngOnInit() {
    super.ngOnInit();

    this.convertButtonCardsToFlippingCards();
  }

  convertButtonCardsToFlippingCards() {
    if (!this.data?.cards?.length && this.data?.buttons?.length) {
      this.data.cards = [];
      this.data.buttons.forEach((b: IButtonLink) => {
        const card: IFlippingCard = {
          cardType: "reversed",
          title: b.label,
          front_content: undefined,
          back_content: b.text
        }
        this.data.cards.push(card);
      });
    }
  }

}
