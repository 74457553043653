import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { IBaseSection } from 'src/app/models/commons';
import { IFilter, IJobOfferList } from 'src/app/models/components.interface';
import { IJobOffers } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-job-offer',
  templateUrl: './job-offer.component.html',
  styleUrls: ['./job-offer.component.scss']
})
export class JobOfferComponent extends BasePageComponent<IJobOffers> {

  public jobOffersList: IJobOfferList;
  dataServicesSubscription: Subscription = null;
  private changeLangSubscriptionJO: Subscription = null;
  public activeFilters = this.getDefaultFilters();

  lastSections: IBaseSection[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.dataServicesSubscription?.unsubscribe();
    this.dataServicesSubscription = this.dataServices.pageDataReady.subscribe(() => {
      this.loadPage();
    });

    this.changeLangSubscriptionJO?.unsubscribe();
    this.changeLangSubscriptionJO = this.translate.onLangChange.subscribe(() => {
      this.loadPage();
    });
  }

  async loadPage() {
    if (!this.data) return;

    this.lastSections = this.data.sections?.filter(s => s.type == "last") || [];

    try {
      this.jobOffersList = await this.api.career.getJobOffersList();
      const filter = this.jobOffersList.filters;
      this.data.filters = this.data.filters.map(value => {
        if (value.id == 'areas') {
          value.items = filter.areas.map(area => {
            let obj: IFilter;
            obj = {
              id: area.code,
              label: area.name,
              type: "checkbox",
              value: false
            }
            return obj;
          })
        }
        if (value.id == 'categories') {
          value.items = filter.categories.map(category => {
            let obj: IFilter;
            obj = {
              id: category.code,
              label: category.name,
              type: "checkbox",
              value: false
            }
            return obj;
          })
        }
        return value;
      });

      this.filterOffers();
    }
    catch (error) {
      console.error('Job offers error - ', error);
    }
  }

  getDefaultFilters() {
    return {
      // experienced: 0,
      areas: [] as string[],
      categories: [] as string[],
      offices: [] as string[]
    };
  }

  select(filters: IFilter[]): void {

    this.activeFilters = this.getDefaultFilters();

    for (const filter of filters) {
      // if (filter.code == 'experienced') { this.activeFilters.experienced = 1 }
      if (filter.code == 'areas') { this.activeFilters.areas.push(filter.id) }
      if (filter.code == 'categories') { this.activeFilters.categories.push(filter.id) }
      if (filter.code == 'offices') { this.activeFilters.offices.push(filter.id) }
    }
    this.filterOffers();
  }

  filterOffers() {
    if (!this.jobOffersList) {
      return;
    }

    this.data.sections = this.jobOffersList.offers.filter(job =>
      // job.experienced == this.activeFilters.experienced &&
      (this.activeFilters.areas.length == 0 || this.activeFilters.areas.includes(job.areaId)) &&
      (this.activeFilters.categories.length == 0 || this.activeFilters.categories.includes(job.categoryId)) &&
      (this.activeFilters.offices.length == 0 || this.activeFilters.offices.filter(o => job.subtitle.toLowerCase().indexOf(o) != -1).length > 0)
    );
  }

  ngOnDestroy(): void {
    this.dataServicesSubscription?.unsubscribe();
    this.dataServicesSubscription = null;

    this.changeLangSubscriptionJO?.unsubscribe();
    this.changeLangSubscriptionJO = null;
  }
}
