import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '../services/cookie.service';

/**
 * Sanitize HTML
 */
@Pipe({
    name: 'safe'
})
export class SafePipe implements PipeTransform {
    /**
     * Pipe Constructor
     *
     * @param _sanitizer: DomSanitezer
     */
    // tslint:disable-next-line
    constructor(protected _sanitizer: DomSanitizer, private cookieServices: CookieService, public translate: TranslateService) {
    }

    /**
     * Transform
     *
     * @param value: string
     * @param type: string
     */
    transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        // console.log("pipe", type, value);
        
        switch (type) {
            case 'html':
                let v = value;
                if (v && !this.cookieServices.isAnalyticsEnabled()) {
                    v = v.replace(/<iframe\s+.*?\s+src=(".*?").*?<\/iframe>/, "<p>" + this.translate.instant('CONTENT_NOT_AVAIBLE') + "</p>");
                }
                return this._sanitizer.bypassSecurityTrustHtml(v);
            case 'style':
                return this._sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this._sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this._sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                const x = this._sanitizer.sanitize(SecurityContext.URL, value);
                return this._sanitizer.bypassSecurityTrustResourceUrl(x);
            default:
                return this._sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}