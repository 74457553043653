import { Component, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-not-found-data',
  templateUrl: './not-found-data.component.html',
  styleUrls: ['./not-found-data.component.scss']
})


export class NotFoundDataComponent {
  constructor(public translate:TranslateService) {

  }

}
