import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AmplifyAuthService } from 'src/app/services/amplify-auth.service';
import { ApiArService } from 'src/app/services/api-ar.service';
import { BrowserServices } from 'src/app/services/browser.service';
import { CookieService } from 'src/app/services/cookie.service';
import { CookieService as NgxCookie } from 'ngx-cookie-service';
import { LangServices } from 'src/app/services/lang.service';
import { IButtonLink } from '../../models/commons';
import { ArDialogAuthComponent } from '../ar-dialog-auth/ar-dialog-auth.component';
import { DialogComponent } from '../dialog/dialog.component';


@Component({
  selector: 'app-prometeia-button',
  templateUrl: './prometeia-button.component.html',
  styleUrls: ['./prometeia-button.component.scss']
})
export class PrometeiaButtonComponent implements OnInit {

  @Input() data: IButtonLink;
  @Input() disabled: boolean = false;
  @Input() isMini: boolean = false;
  @Input() useLightgreyShadow: boolean = false;

  browserType: string = "";


  isLangsListOpen: boolean = false;
  isLoggedAR : boolean = false;

  tokenCognito : string;
  dataLoginCognito : any;

  private poolStatusSubscription: Subscription = null;
  COOKIE_TOKEN_AR : string = 'prom-ar-token';

  constructor(
    public translate: TranslateService, 
    private browserServices: BrowserServices, 
    private cookieServices: CookieService,
    
    private dialog: MatDialog, 
    private amplifyService: AmplifyAuthService, 
    public langServices: LangServices,
    public apiAR: ApiArService,
    private AR_cookieService: NgxCookie,
    private _snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {
    // console.log(this.data.label, this.data.label.length, this.data.label.length > 16);
    // console.log("btn", this.data.label, this.data._meta?.reservedArea);

    this.browserType = this.browserServices.myBrowser().toLowerCase();

    if (this.data?._meta?.reservedArea) {
      this.setIsLoggedAR();
    }
  }

  hasTwoRows(): boolean {
    if (this.data.label?.length > 16 && !this.data._meta?.forceOneLine) return true;

    return false;
  }

  areCookiesOk() {
    let ret = true;
    
    if (this.data?.link?.includes("hsforms")) {
      // console.log("hubspot found");
      if (!this.cookieServices.isAnalyticsEnabled()) {
        // console.log("cannot display hubspot button");
        ret = false;
      }
    }

    return ret;
  }


  /** RESERVED AREA (copy of AR in menu-tool) */
  clickOnAR() {
    if (!this.isLoggedAR) this.login();
    else this.resevedArea();
  }

  ngOnDestroy(): void{
    if (this.poolStatusSubscription) {
      this.poolStatusSubscription.unsubscribe();
      this.poolStatusSubscription = null;
    }
  }

  async login() {    
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px'
    });
  }
 
  

  resevedArea(){
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate(['/', this.translate.currentLang, 'reserved-area']);
    else window.location.reload();
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  async logout() {    
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant("LOGOUT"), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      try {
        const logout = await this.apiAR.libraries.logout();
        if(logout) this.router.navigate(['/', this.translate.currentLang, 'home']); 
      } catch (error) {
        console.log("Error logour AR", error)
      }
          
    });
  }

  async setIsLoggedAR(){   
    this.isLoggedAR = this.AR_cookieService.get(this.COOKIE_TOKEN_AR) ? true : false; 
    //TODO 
    if(await this.amplifyService.isLoggedIn('ar')) await this.amplifyService.logout('ar');
  }

}
