import { Component, Input, OnInit } from '@angular/core';
import { HighlightColor } from 'src/app/models/commons';
import { ICollapsableList, ISectionAccordionList } from 'src/app/models/components.interface';

@Component({
  selector: 'app-section-collapsable-list',
  templateUrl: './section-collapsable-list.component.html',
  styleUrls: ['./section-collapsable-list.component.scss']
})
export class SectionCollapsableListComponent implements OnInit {

  @Input() data: any;
  @Input() highlightColor: HighlightColor;
  @Input() forceHideTitle: boolean = false;
  @Input() isSidenavbarActive: boolean = true;
  @Input() noPaddingBottom: boolean = false;
  @Input() shortPaddingTop: boolean = false;

  // dataSectionAccordionList: ISectionAccordionList = null;
  dataCollapsableList: ICollapsableList = null;

  ngOnInit() {
    if (this.data?.list) {
      if (this.data?.type?.startsWith("accordion-list-")) {
        // console.log("<app-section-collapsable-list>", this.data);
        
        this.dataCollapsableList = this.data;
      }
    }
  }

}
