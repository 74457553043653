import { Component, Input, OnInit } from '@angular/core';
import { IImgNewsDefault, ITrendingTopics } from '../../models/components.interface';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentsServices } from 'src/app/services/components.service';
import { paths } from '../../models/paths';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-single-trending-topics',
  templateUrl: './single-trending-topics.component.html',
  styleUrls: ['./single-trending-topics.component.scss']
})
export class SingleTrendingTopicsComponent implements OnInit {

  @Input() data: ITrendingTopics;
  @Input() category: string;
  @Input() imgNewsDefault: IImgNewsDefault;
  
  insight_route: string = "insight_route";
  archiveRoute : string = paths.trendingTopicsArchive;
  
  showForm: boolean = false;
  private overlayRef: OverlayRef;
  route: string = paths.trendingTopicsArticle;

  constructor(public overlay: Overlay, private componentsServices: ComponentsServices, public translate: TranslateService) { }

  ngOnInit() {
    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
  } 

  getSlug(): string {
    if (this.data.slug && this.data.slug != undefined) {
      return this.data.slug;
    } else {
      const pa = this.data.permalink.split("/");
      this.data.slug = pa[pa.length - 1];
      return this.data.slug;
    }
  }

}
