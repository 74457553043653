import { Component, Input } from '@angular/core';
import { HighlightColor, IIntro } from '../../models/commons';

@Component({
  selector: 'app-section-intro',
  templateUrl: './section-intro.component.html',
  styleUrls: ['./section-intro.component.scss']
})
export class SectionIntroComponent {

  @Input() data : IIntro;
  @Input() highlightColor: HighlightColor = 'accent';
  
  constructor() { }

}
