import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { HighlightColor } from 'src/app/models/commons';
import { IFlippingCard } from 'src/app/models/components.interface';

@Component({
  selector: 'app-flipping-cards',
  templateUrl: './flipping-cards.component.html',
  styleUrls: ['./flipping-cards.component.scss'],
})
export class FlippingCardsComponent implements OnInit {

  @Input() cards: IFlippingCard[];
  @Input() highlightColor: HighlightColor;

  @Input() cardsOnDarkTheme: boolean = false;

  card_index: number = 0;
  isSwipeLeft: boolean = false;
  isSwipeRight: boolean = false;
  configFlip: SwiperConfigInterface = {
    effect: 'coverflow',
    grabCursor: true,
    loop: true,
    spaceBetween: 500,
    allowSlidePrev: false,
    updateOnWindowResize : true,
    coverflowEffect: {
      depth: 0,
       modifier: 1,
       rotate: 0,
       stretch: 280,
       slideShadows: false
    }
  }

  useLargeCards: boolean = false;

  ngOnInit(): void {
    this.cards.forEach(c => {
      if (!c.cardType && c.size) c.cardType = c.size;
      if (c.cardType == "large" || c.size == "large") this.useLargeCards = true;
    });
  }
}
