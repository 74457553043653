import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {RestClientRoutesService} from './rest-client-routes.service';

/**
 * Api is a generic REST API Service. Set your API url first.
 */
@Injectable({
  providedIn: 'root'
})
export class RestClientService {
  private url: string = environment.API_BASE_URL;
  private localUrl: string = '/assets/mocks';

  public offlineException : EventEmitter<HttpErrorResponse> = new EventEmitter();  
  public serverErrorException : EventEmitter<HttpErrorResponse> = new EventEmitter(); 

  constructor(private http: HttpClient, private routes: RestClientRoutesService, public translate: TranslateService) {
  }

  invoke<T>(endpoint: string, params?: any, reqOpts?: any, locale?: string) : Promise<T> {
    const config = this.routes.get(endpoint);
    if(!config) {
        console.warn(`Route ${endpoint} not found, trying remote endpoint...`);
        //return Promise.reject(new Error(`Route ${endpoint} not found!`));
        return this.get<T>(endpoint, params, reqOpts, locale) as any;
    }

    if(environment.API_FETCH_BEHAVIOUR == 'FORCE_MOCK' || (environment.API_FETCH_BEHAVIOUR != 'FORCE_REMOTE' && config.useMock)) {
      return this.getLocal<T>(endpoint);
    }
    switch (config.method) {
      case 'POST':
        return this.post(config.remote, params, reqOpts);
      default:
        return this.get<T>(config.remote, params, reqOpts, locale) as any;
    }
  }

  getLocal<T>(endpoint: string): Promise<T>  {
    return this.http.get<T>(this.localUrl + endpoint + ".json").toPromise<T>();
  }

  get<T>(endpoint: string, params?: any, reqOpts?: any, locale?: string, tokenAR ? : string) {
    const loc = locale || this.translate.currentLang;

    if (!reqOpts || reqOpts==null ) {
      reqOpts = {
        params: new HttpParams(),
        headers: new HttpHeaders({
          'x-locale' : loc
        })
      };
    } else if (locale) {
      reqOpts.headers = new HttpHeaders({
        'x-locale' : loc
      })
      
    }

    if(tokenAR){
      reqOpts.headers = new HttpHeaders({
        'x-locale' : loc,
        'x-ar-token': tokenAR
      })

    }

   
    // Support easy query params for GET requests
    reqOpts.params = new HttpParams();
    if (params) {
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get<T>(this.url + endpoint, reqOpts).toPromise().catch(error => {
      console.error("[GET] error", error);
      if(error.status == 401 && ((error.error || {}).error || {}).code == 'INVALID_TOKEN') {
        //this.invalidTokenException.emit(error);
      } else if(error.status == 0) {
        this.offlineException.emit(error);
      } else if(error.status >= 500) {
        this.serverErrorException.emit(error);
      }
      throw error;
    });
  }

  post(endpoint: string, body: any, httpParams?: HttpParams):Promise<any> {
    const reqOpts = {
        params: new HttpParams(),
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-locale' : this.translate.currentLang
        })
      };
    
    reqOpts.params = httpParams || new HttpParams();

    return this.http.post(this.url + endpoint, body, reqOpts).toPromise()
      .catch((error) => {
        console.log("[POST] error", error);      
        if(error.status == 401 && ((error.error || {}).error || {}).code == 'INVALID_TOKEN') {
          //this.invalidTokenException.emit(error);
        } else if(error.status == 0) {
          this.offlineException.emit(error);
        } else if(error.status >= 500) {
          this.serverErrorException.emit(error);
        }
        throw error;
      });
  }

}


