import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Locale } from '../models/commons';
import { separator } from '../models/paths';
import { BrowserServices } from './browser.service';
import { AmplifyAuthService } from './amplify-auth.service';

export interface ICookieServiceRet {
    statistics_analytics: string,
    marketing: string,
}

export const ST_AN_INDEX: number = 1;
export const MRKT_INDEX: number = 2;

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    private storage = window.localStorage;
    private session = window.sessionStorage;

    private static main_key = 'LOCAL:COOKIE_MAIN';
    private static statistics_analytics_key = 'LOCAL:COOKIE_AN_ST';
    private static marketing_key = 'LOCAL:COOKIE_MRKT';

    private static special_msg_key = 'SESSION:SP_MSG';
    private static trending_topics_category = 'SESSION:TT_CAT';
    private static trending_topics_atlante_app_banner = 'SESSION:TT_ATL_apban';

    private static lang_key = 'LOCAL:LANG';

    private cookieLabelMain = "main_v1";
    private cookieLabelAnalytics = "st_an_v1";
    private cookieLabelMarketing = "markt_v1";

    public forceReopenCookieModal: boolean = false;


    constructor(private browserServices: BrowserServices, private amplifyAuthService: AmplifyAuthService) { }

    public getCookieLabelMain() {
        return this.cookieLabelMain;
    }

    public getCookieLabelAnalytics() {
        return this.cookieLabelAnalytics;
    }

    public getCookieLabelMarketing() {
        return this.cookieLabelMarketing;
    }


    private getDate() {
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return (year)*10000 + month*100 + day;
    }

    private getExpiration() {
        /** adds a year to the current date */
        return this.getDate() + 10000;
    }

    /** LOCAL STORAGE */

    /**
     * Se true l'utente ha salvato i cookie, restituisce qualcosa
     */
    public isCookiesStored(): boolean {
        return this.loadMainCookie() != null;
    }

    public loadMainCookie(): string {
        const main_cookie_and_expiration = this.storage.getItem(CookieService.main_key) || null;

        if (!main_cookie_and_expiration) return null;

        const main_cookie = main_cookie_and_expiration.split(separator)[0];
        const expiration: number = Number(main_cookie_and_expiration.split(separator)[1]);

        // console.log(main_cookie, expiration);

        if (!expiration || isNaN(expiration) || this.getDate() >= expiration) {
            console.log("Cookie expired");
            this.removeMainCookie();
            this.removeCookiePreferences();
            return null;
        } /* else {
            console.log("Cookie still valid");
        } */

        return main_cookie;
        
        // console.error("Cookie Service", "error in loadMainCookie:", main_cookie);
    }

    public async setMainCookie() {
        let main_cookie = this.getCookieLabelMain();

        if (main_cookie) {
            // console.log("Cookie Service", "setMainCookie:", main_cookie + separator + this.getExpiration());
            this.storage.setItem(CookieService.main_key, main_cookie + separator + this.getExpiration());
        } else {
            console.error("Cookie Service", "error in setMainCookie:", main_cookie);
        }

    }

    public removeMainCookie() {
        this.storage.removeItem(CookieService.main_key);
    }



    public loadCookiePreferences(): ICookieServiceRet {
        let st_an = this.storage.getItem(CookieService.statistics_analytics_key) || null;
        let mrkt = this.storage.getItem(CookieService.marketing_key) || null;

        if (st_an || mrkt) {
            if (!st_an) {
                return {statistics_analytics: null, marketing: mrkt};
            }
            if (!mrkt) {
                return {statistics_analytics: st_an, marketing: null};
            }
            return {statistics_analytics: st_an, marketing: mrkt};
        } else {
            // console.error("CookiePreferences Service", "error in loadCookiePreferences:", st_an, mrkt);
            return {statistics_analytics: null, marketing: null};
        }
    }

    public async setCookiePreferences(cookiePreference: number[]) {
        // console.log("CookiePreferences", cookiePreference, cookiePreference[ST_AN_INDEX] == 1, cookiePreference[MRKT_INDEX] == 1);

        let st_an = null;
        if (cookiePreference[ST_AN_INDEX] == 1) st_an = this.getCookieLabelAnalytics();

        let mrkt = null;
        if (cookiePreference[MRKT_INDEX] == 1) mrkt = this.getCookieLabelMarketing();
        

        if (st_an || mrkt) {
            if (st_an) {
                this.storage.setItem(CookieService.statistics_analytics_key, st_an);
            }
            if (mrkt) {
                this.storage.setItem(CookieService.marketing_key, mrkt);
            }
        } /* else {
            console.error("CookiePreferences Service", "error in setCookiePreferences:", st_an, mrkt);
        } */

    }

    public removeCookiePreferences() {
        this.storage.removeItem(CookieService.statistics_analytics_key);
        this.storage.removeItem(CookieService.marketing_key);
    }

    public isAnalyticsEnabled() {   //google analytics, hubspot & tableau
        const cookie = this.loadCookiePreferences().statistics_analytics || null;
        const cookie_enabled: boolean = cookie && cookie.length > 0 && cookie == this.getCookieLabelAnalytics();
        return cookie_enabled && this.loadMainCookie() != null;
    }

    public isMarketingEnabled() {   //youtube
        const cookie = this.loadCookiePreferences().marketing || null;
        const cookie_enabled: boolean = cookie && cookie.length > 0 && cookie == this.getCookieLabelMarketing();
        return cookie_enabled && this.loadMainCookie() != null;
    }



    /** SESSION STORAGE */
    public loadSpecialMessageSessionCookie(): string {
        let special_msg_cookie = this.session.getItem(CookieService.special_msg_key) || null;

        return special_msg_cookie;
        
        // console.error("Cookie Service", "error in loadSpecialMessageSessionCookie:", special_msg_cookie);
    }

    public async setSpecialMessageSessionCookie() {
        let special_msg_cookie = "sp_msg";
        
        if (special_msg_cookie) {
            // console.log("Cookie Service", "setSpecialMessageSessionCookie:", special_msg_cookie);
            this.session.setItem(CookieService.special_msg_key, special_msg_cookie);
        } /* else {
            console.error("Cookie Service", "error in setSpecialMessageSessionCookie:", special_msg_cookie);
        } */

    }

    public loadTrendingTopicsCategoryCookie(): number {
        let tt_cookie = this.session.getItem(CookieService.trending_topics_category) || null;

        return +tt_cookie;
    }

    public async setTrendingTopicsCategoryCookie(category: number) {
        this.session.setItem(CookieService.trending_topics_category, ""+category);
    }

    // public removeTrendingTopicsCategoryCookie() {
    //     this.storage.removeItem(CookieService.trending_topics_category);
    // }


    public loadAtlanteBannerSessionCookie(): string {
        let atlante_banner_cookie = this.session.getItem(CookieService.trending_topics_atlante_app_banner) || null;

        return atlante_banner_cookie;
    }

    public async setAtlanteBannerSessionCookie(device: string) {
        this.session.setItem(CookieService.trending_topics_atlante_app_banner, "closed|" + device);
    }


    /** COGNITO */
    /* public async isCognitoAuthenticated() {
       
        let res = false;
        for (let i = 0; i < this.storage.length; i++){
            if ( this.storage.key(i).indexOf("CognitoIdentityServiceProvider.") != -1 ) {
                res = true ;
            }
        }
        res = await this.amplifyAuthService.isLoggedIn('alumni');   

        console.log("cognito is on", res);

        return res;
    } */


    /** LANGUAGE */
    private DEFAULT_LANG = Locale.it;

    public getLang() {
        if (!this.isLangStored()) {
            this.setLangCookie();
        }
        this.DEFAULT_LANG = this.loadLangCookie() as Locale;

        return this.DEFAULT_LANG;
    }

    public isLangStored(): boolean {
        return this.loadLangCookie() != null;
    }
    
    public loadLangCookie(): string {
        const lang_cookie_and_expiration = this.storage.getItem(CookieService.lang_key) || null;

        if (!lang_cookie_and_expiration) return null;

        const lang_cookie = lang_cookie_and_expiration.split(separator)[0];
        const expiration: number = Number(lang_cookie_and_expiration.split(separator)[1]);

        // console.log(main_cookie, expiration);

        if (!expiration || isNaN(expiration) || this.getDate() >= expiration) {
            // console.log("Cookie expired");
            this.removeLangCookie();
            return null;
        } /* else {
            console.log("Cookie still valid");
        } */

        return lang_cookie;
        
        // console.error("Cookie Service", "error in loadLangCookie:", lang_cookie);
    }

    public async setLangCookie(_lang: string = null) {
        //priorità: from url, from cookies, from browser
        let lang = _lang;
        if (!lang) lang = this.browserServices.myLanguage();

        if (lang) {
            // console.log("Cookie Service", "setLangCookie:", lang + separator + this.getExpiration());
            this.storage.setItem(CookieService.lang_key, lang + separator + this.getExpiration());
        } /* else {
            console.error("Cookie Service", "error in setLangCookie:", lang);
        } */
    }

    public removeLangCookie() {
        this.storage.removeItem(CookieService.lang_key);
    }
}
