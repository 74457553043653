<div class="single-news-wrap theme--{{data.categoryTheme || 'accent'}}">
    <div class="vertical-marker mobile-only">M</div>

    <div class="single-news theme--{{data.categoryTheme || 'accent'}}">
        <div class="vertical-marker desktop-only"></div>
    
                                                                        <!-- data.slug contains the news' "id" -->
        <a class="title text--primary no-underline" [routerLink]="['/', translate.currentLang, route, data.slug]">
            <h2 class="neutra2_demi title--text text--primary">
                <span class="title--highlight">{{data.title}}</span>
            </h2>
        </a>
    
        <div class="spacer"></div>
        
        <div class="details">
            <hr>
            
            <div class="details--content">
                <p class="prom-caption___neutra-demi details--content--category capslock">{{category}}</p>
                <p class="prom-caption___neutra-book details--content--date">{{data.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}</p>
            </div>
        </div>
    
    </div>
</div>
