<section class="{{data.theme}} section center-vertical news-list" *ngIf="isSectionVisible()">
    <div class="section-max-content">
        <h1 data-aos="fade-up" class="section_h1 neutra2_book text--primary">{{ data.title }}</h1>

        <div class="news-list--list">
            <ng-container  data-aos="fade-up" *ngFor="let news of data.buttons; let i = index; let isFirst = first">
                <hr data-aos="fade-up" *ngIf="(i < newsToDisplay) && isFirst">
                <a data-aos="fade-up" class="news-list--list--item" *ngIf="i < newsToDisplay" [routerLink]="['/', translate.currentLang, news.route, news.id ]">
                    <p class="prom-caption___lato-regular text--grey date" *ngIf="news.pubDate">{{news.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}</p>
                    <p class="prom-caption___lato-regular text--grey date" *ngIf="news.date && !news.pubDate">{{news.date}}</p>
                    <p class="prom-contact text--on-light title">{{news.label}}</p>
                    <p class="prom-caption___lato-italic text--primary more">
                        {{'READ_MORE' | translate}}
                    </p>
                </a>
                <hr data-aos="fade-up" *ngIf="i < newsToDisplay">
            </ng-container>
        </div>

        <div data-aos="fade-up" class="news-list--button" *ngIf="(!pageType || pageType != 'saas-cloud') && data.buttons.length > newsToDisplay">
            <app-prometeia-button class="btn interactive" (click)="increaseNewsToDisplay()"
                [data]="getLoadMoreBtn(highlightColor)">
            </app-prometeia-button>
        </div>
        <div data-aos="fade-up" class="news-list--button" *ngIf="pageType == 'saas-cloud' && hasMoreSaasCloudNews">
            <app-prometeia-button class="btn interactive" (click)="loadMoreSaasCloudNews()"
                [data]="getLoadMoreBtn(highlightColor)">
            </app-prometeia-button>
        </div>
    </div>
</section>