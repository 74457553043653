import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IFlippingCard } from 'src/app/models/components.interface';
import { HighlightColor } from '../../models/commons';

@Component({
  selector: 'app-flipping-card',
  templateUrl: './flipping-card.component.html',
  styleUrls: ['./flipping-card.component.scss'],
  encapsulation: ViewEncapsulation.None, //to style innerHtml using our styles.scss
})
export class FlippingCardComponent {

  @Input() data: IFlippingCard;
  @Input() highlighter: HighlightColor;

  @Input() cardsOnDarkTheme: boolean = false;

  selected: boolean = false;
  
  constructor() { }

  toggle() {
    this.selected = !this.selected;
  }
}
