<div #top id="top" class="top"></div>
<app-header *ngIf="isNotSpecialPage()"></app-header>

<router-outlet *ngIf="isDesktopMode() || !isMenuOpen"></router-outlet>

<app-footer *ngIf="isNotSpecialPage()"></app-footer>



<app-privacy-and-cookies *ngIf="!areCookiesAccepted() || cookieServices.forceReopenCookieModal"></app-privacy-and-cookies>
