import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentsServices } from '../../services/components.service';
import { HighlightColor } from '../../models/commons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-long-arrow-down',
  templateUrl: './long-arrow-down.component.html',
  styleUrls: ['./long-arrow-down.component.scss']
})
export class LongArrowDownComponent implements OnInit, OnDestroy {

  @Input() color: HighlightColor;
  @Input() isInHome: boolean;
  private componentsServicesSubscriptionD: Subscription;
  private componentsServicesSubscriptionU: Subscription;
  pageScrollingDown: boolean;

  constructor(private componentsServices: ComponentsServices) { }

  ngOnInit(): void {
    this.componentsServicesSubscriptionD?.unsubscribe();
    this.componentsServicesSubscriptionD = this.componentsServices.pageScrollDown.subscribe(() => {
      this.pageScrollingDown = true;
    });

    // riattivare se serve farla riapparire con lo scroll-up
    /* this.componentsServicesSubscriptionD = this.componentsServices.pageScrollUp.subscribe(() => {
      this.pageScrollingDown = false;
    }); */
  }

  onArrowClick() {
    // console.log("window scroll");
    
    this.componentsServices.scrollToTop();
  }

  ngOnDestroy() {
    this.componentsServicesSubscriptionD?.unsubscribe();
    this.componentsServicesSubscriptionU?.unsubscribe();
  }

}
