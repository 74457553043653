import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HighlightColor, ISomeNumber } from '../../models/commons';

@Component({
  selector: 'app-info-number',
  templateUrl: './info-number.component.html',
  styleUrls: ['./info-number.component.scss']
})
export class InfoNumberComponent {

  @Input() data: ISomeNumber;
  @Input() highlightColor: HighlightColor;

  constructor(public translate: TranslateService) {}

}
