<mat-card class="business-card">

    <mat-card-content class="content">
        <div class="pic">
            <img [src]="data.thumbnail" alt="pic">
        </div>

        <div class="info">
            <p class="prom-contact name text--blue neutra2_bold">{{data.name}}</p>
            <p class="prom-contact job text--blue neutra2_bookItalic">{{data.jobTitle}}</p>

            <br><br>
    
            <div class="email" *ngIf="data.email_addr">
                <a class="prom-contact___lato email--address" href="mailto:{{data.email_addr}}">{{data.email_addr}}</a>
            </div>
    
            <!-- <div class="phone" *ngIf="data.phone_num">
                <a class="prom-contact___lato phone--number" href="tel:{{data.phone_num}}">{{data.phone_num}}</a>
            </div> -->
        </div>
    </mat-card-content>

</mat-card>
