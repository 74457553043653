import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { Locale } from 'src/app/models/commons';
import { INews } from 'src/app/models/components.interface';
import { paths, separator } from 'src/app/models/paths';
import { slugs } from 'src/app/models/slugs';
import { BrowserServices } from 'src/app/services/browser.service';
import { ICountryPage, INetwork } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-country-page',
  templateUrl: './country-page.component.html',
  styleUrls: ['./country-page.component.scss']
})
export class CountryPageComponent extends BasePageComponent<ICountryPage>{

  otherCities: INetwork;
  cityNewsLength: number = 0;

  browserType: string;
  deviceType: string;
  dataServicesSubscription :Subscription = null;

  constructor(private browserServices: BrowserServices) {
    super();
    this.checkDirectLang(this.router.url);
  }
  
  ngOnInit() {
    this.browserType = this.browserServices.myBrowser().toLowerCase();
    this.deviceType = this.browserServices.myDevice().toLowerCase();
    super.ngOnInit();
    // this.loadOtherCities();
    this.dataServicesSubscription?.unsubscribe();
    this.dataServicesSubscription = this.dataServices.pageDataReady.subscribe(() => {
      this.loadOtherCities();
      this.checkCityNews();
      if (this.data && paths.countryPage + separator + this.id != this.data.slug) {
        super.loadPageData();
      }
    });
   
    this.componentsServices.scrollToTop();
  }

  checkDirectLang(url:string){
    if(this.translate.currentLang.toLowerCase() != Locale.en){
      let paths = url.split('/');
      this.router.navigate(['/',Locale.en,'country-page',paths[paths.length-1]] ,{replaceUrl:true});
      this.translate.use(Locale.en);
    }
  }

  protected async loadOtherCities() {
    try {
      // const ot = this.otherCities = await this.api.pages.getWithLang(slugs.ourNetwork, Locale.en);
      const ot = this.otherCities = await this.cache.pagesGetWithLang(slugs.ourNetwork, Locale.en);

      // console.log("otherCities", ot, Locale.en);
    }
    catch(ex) {
      console.error(ex);
    }
  }

  protected async checkCityNews() {
    try {
      let cn: INews[] = await this.api.insights.getCityNewsList(0, 1, this.id);

      this.cityNewsLength = cn.length;
    }
    catch(ex) {
      console.error(ex);
    }
  }

  ngOnDestroy(): void {
    this.dataServicesSubscription?.unsubscribe();
    this.dataServicesSubscription = null;
  }
}
