import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie.service';

declare let gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class DataServices {
  public pageDataReady: EventEmitter<any> = new EventEmitter();


  constructor(public translate: TranslateService, private cookieServices: CookieService) {}


  public pageDataEmit(pageData: any) {
    this.pageDataReady.emit(pageData);
  }

  public pageDataError(ex: any) {
    this.pageDataReady.error(ex);
  }


  /* public folderDataEmit(folderData: IPromDocumentTree) {
    this.folderDataReady.emit(folderData);
  }

  public folderDataError(ex: any) {
    this.folderDataReady.error(ex);
  }


  public promTreeDataEmit(pageTitle: string, promData: IPromDocumentTreeDetail) {
    this.promTreeDataReady.emit({title: pageTitle, data: promData});
  } */
  
  
  private pageLoadError() {
    const title = "404 - " + this.translate.currentLang.toUpperCase() + " (" + window.location.pathname + ")";
    document.title = title;
    
    if(this.cookieServices.isAnalyticsEnabled()) {
      // gtag('config', environment.GOOGLE_ANALYTICS_TRACKING_CODE, { 'page_title': title } );
      
      /** https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
      gtag('event', 'page_view');
    }
  }
} 