<section [ngClass]="{'intro center-vertical': true, 'gradient--primary': !data.media?.contentUrl && !data.media?.previewUrl}" id="intro" spyTarget="intro">
    <div class="section-max-content" *ngIf="data">
        <div class="gradient--primary" id="background-video"></div>

        <video id="background-video" autoplay muted [muted]="true" loop webkit-playsinline playsinline *ngIf="data.media?.contentUrl || data.media?.previewUrl">
            <source [src]="data.media.contentUrl" type="video/mp4" *ngIf="data.media?.contentUrl?.endsWith('.mp4')">
            <source [src]="data.media.previewUrl" type="video/mp4" *ngIf="data.media?.previewUrl?.endsWith('.mp4')">
            <source [src]="data.media.contentUrl" type="video/ogg" *ngIf="data.media?.contentUrl?.endsWith('.ogg')">
            <source [src]="data.media.previewUrl" type="video/ogg" *ngIf="data.media?.previewUrl?.endsWith('.ogg')">
            <source [src]="data.media.contentUrl" type="video/webm" *ngIf="data.media?.contentUrl?.endsWith('.webm')">
            <source [src]="data.media.previewUrl" type="video/webm" *ngIf="data.media?.previewUrl?.endsWith('.webm')">
            Your browser does not support HTML video.
        </video>

        <p class="intro--title neutra2_bold" *ngIf="data && data.title">{{ data.title }}</p>
        <p class="section--text" *ngIf="data && data.text">
            <ng-container *ngFor="let claim of data.text.split('.'); let isLast = last;">
                {{ claim }}<ng-container *ngIf="!isLast">.</ng-container>
                <br *ngIf="!isLast">
            </ng-container>
        </p>

        <app-long-arrow-down class="mobile-only" [color]="highlightColor"></app-long-arrow-down>
    </div>
</section>
