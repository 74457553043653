import { Component } from '@angular/core';
import { ISocial } from 'src/app/models/components.interface';
import { INewsArticle } from 'src/app/models/pages.interface';
import { /* footerDocsIDs, */ paths, shortFooterDocs, simTree } from 'src/app/models/paths';
import { CookieService } from 'src/app/services/cookie.service';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-general-article',
  templateUrl: './general-article.component.html',
  styleUrls: ['./general-article.component.scss']
})
export class GeneralArticleComponent extends BasePageComponent<INewsArticle> {

  socials_list: ISocial[] = [
    { type: "linkedin", link: "https://linkedin.com" },
    { type: "twitter", link: "https://twitter.com" },
    { type: "facebook", link: "https://facebook.com" },
    // { type: "youtube", link: "https://youtube.com" },
    // { type: "instagram", link: "https://instagram.com" },
  ];

  constructor(private cookieService: CookieService) {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();

    this.dataServices.pageDataReady.subscribe(() => {
      // console.log("NewsArticleComponent", this.data);
      // console.log(this.slug, this.id);
      if (this.slug == paths.article && simTree.includes(this.id)) this.highlightColor = "red";
      this.scrollToTop();

      /** for shortest (known) articles (in footer articles), we force the footer */
      if (shortFooterDocs.includes(this.id)) {
        // console.log("fake BOTTOM");
        this.componentsServices.emulateScrollBottom();
      } else {
        // console.log("fake TOP");
        this.componentsServices.emulateScrollTop();
      }
    });
  }

  beautifyCategory(cat: string) {
    return cat.replace(/_/g, " ").replace(/-/g, " ");
  }

  scrollToTop() {
    this.componentsServices.scrollToTop();
  }

  // isFooterDoc(): boolean {
  //   for (let fid of footerDocsIDs) {
  //     if (fid == this.id.toLowerCase())
  //       return true;
  //   }
  //   return false;
  // }

  isArticleRestricted(): boolean {
    return (
      this.slug == paths.alumniStories || 
      this.slug == paths.promLife || 
      // this.isFooterDoc()  ||
      this.slug.startsWith(paths.article)
    );
  }

  isLeftButtonsEmpty(buttons: {label: string}[]) {
    if (!buttons) return true;
    
    let noLabelBtnCounter = 0;
    buttons.forEach(button => {
      if (!button.label || button.label == "") noLabelBtnCounter++;
    });
    return noLabelBtnCounter == buttons.length;
  }



  /** ARTICLE__COOKIE */
  resetCookies() {
    // console.log("resetCookies()");
    // this.cookieService.removeMainCookie();

    setTimeout(() => {
      // window.location.reload();
      this.cookieService.forceReopenCookieModal = true;
    }, 1);
  }
}
