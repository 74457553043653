import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IFilter, IFilters } from 'src/app/models/components.interface';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: IFilters[];
  @Output() selectEvent: EventEmitter<any> = new EventEmitter();

  private changeLangSubscription: Subscription = null;

  filterSelected : IFilter[] = [];
  // user: "student" | "experienced" = 'student';
  
  constructor(private translate: TranslateService) {
    // this.filterSelected = [{ code : this.user, value : true, id : null }];
  }

  ngAfterViewInit(): void {
    this.selectEvent.emit(this.filterSelected);

    // console.log(this.data);
  }
   
  ngOnInit() {
    // this.changeLangSubscription?.unsubscribe();
    // this.changeLangSubscription = this.translate.onLangChange.subscribe(() => {
    //   this.user = 'student';
    //   this.filterSelected = [{ code : this.user, value : true, id : null }];
    //   this.selectEvent.emit(this.filterSelected);
    // });
  }

  // select(filter : IFilter | IFilters, value: boolean, idCategory : string){ 
  //   if(idCategory == 'student' || idCategory == 'experienced'){
  //     this.filterSelected = this.filterSelected.map( f => { 
  //       if(f.code == 'student' || f.code == 'experienced') { f.code = idCategory }
  //       return f;
  //     });
  //   }  
  //   else{      
  //     if(!value){
  //       this.filterSelected = this.filterSelected.filter( f => 
  //         f.code != idCategory || (f.id != filter.id && f.id != filter.label.toLowerCase()) 
  //       );
  //     }
  //     else{
  //       this.filterSelected.push({
  //         code : idCategory,
  //         value,
  //         id: idCategory == 'offices' ? filter.label.toLowerCase() : filter.id
  //       })
  //     }
  //   }
    
    
  //   this.selectEvent.emit(this.filterSelected);
  // }

  selectRadio(idCategory : string){ 
    if (idCategory == 'student' || idCategory == 'experienced'){
      this.filterSelected = this.filterSelected.map( f => { 
        if (f.code == 'student' || f.code == 'experienced') { f.code = idCategory }
        return f;
      });
    }
    
    // console.log(this.filterSelected);
    this.selectEvent.emit(this.filterSelected);
  }

  selectCheckbox(filter: IFilter, value: boolean, idCategory : string){ 
    if (!value){
      this.filterSelected = this.filterSelected.filter( f => 
        f.code != idCategory || (f.id != filter.id && f.id != filter.label.toLowerCase()) 
      );
    } else {
      this.filterSelected.push({
        code : idCategory,
        value,
        id: idCategory == 'offices' ? filter.label.toLowerCase() : filter.id
      })
    }
    
    // console.log(this.filterSelected);
    this.selectEvent.emit(this.filterSelected);
  }

  ngOnDestroy() {
    this.changeLangSubscription?.unsubscribe();
    this.changeLangSubscription = null;
  }
}
