import { Component, Input, OnInit } from '@angular/core';
import { IOfficesList } from 'src/app/models/components.interface';

@Component({
  selector: 'app-business-card-office',
  templateUrl: './business-card-office.component.html',
  styleUrls: ['./business-card-office.component.scss']
})
export class BusinessCardOfficeComponent implements OnInit {

  @Input() data: IOfficesList;

  constructor() { }

  ngOnInit(): void {
  }

}
