import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IBasePage } from 'src/app/models/commons';
import { BasePageComponent } from '../base-page.component';

export interface IActivateAccountQuery {
  app: string,
  uid: string,
  email: string
}

export interface IActivateAccountPOST {
  password: string,
  confirmPassword: string,

  code: string;
  uid: string;
  email: string;
}

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent extends BasePageComponent<IBasePage> {  // activate-account?app=ar&uid=197436593w128127&email=alessandro@3logic

  app_code: string;
  uid: string;
  email: string;

  account: IActivateAccountPOST = {
    password: null,
    confirmPassword: null,

    code: null,
    uid: null,
    email: null,
  };

  constructor(private _snackBar: MatSnackBar) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.activatedRoute.queryParams.subscribe((params: IActivateAccountQuery) => {
        // console.log(params);

        this.app_code = this.account.code = params.app;
        this.uid = this.account.uid = params.uid;
        this.email = this.account.email = params.email;
        // console.log(this.app_code, this.uid, this.email);
      }
    );
  }

  submit(activateAccountForm: NgForm) {
    let snackBarRef;
    if(activateAccountForm.form.valid) {      
      console.log("Confirm", JSON.stringify(this.account));
      snackBarRef = this.openSnackBar(this.translate.instant('THANK_YOU'));
    }
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
