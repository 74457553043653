<div class="alumni-auth">    
    <amplify-authenticator *ngIf="show">
        <amplify-sign-in 
                header-text="" 
                slot="sign-in" 
                [formFields]="formFieldsLogin">            
            <div slot="federated-buttons"></div>
            <div slot="secondary-footer-content"></div>
        </amplify-sign-in> 
        <amplify-forgot-password
            header-text=""
            slot="forgot-password"
            [formFields]="formFieldForgotPassword" >
        </amplify-forgot-password>      
    </amplify-authenticator>   


    <!-- <p class="neutra2_book text--on-light sign-in-warning">{{ 'FORM_ALUMNI.SIGN_IN_WARNING' | translate }}</p> -->
</div>


