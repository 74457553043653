<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">
    <!-- <app-side-navbar class="side-navbar desktop-only" [data]="data.sections" [bulletColor]="highlightColor"></app-side-navbar> -->
    <app-long-arrow-down class="desktop-only" [color]="highlightColor"></app-long-arrow-down>

    <div class="risk-planning">
        <ng-container *ngFor="let s of data.sections; let i = index">
            <app-section-intro-bg *ngIf="s.type == 'intro'" [data]="s" [mainTitle]="data.title" [highlightColor]="highlightColor"></app-section-intro-bg>

            <app-section-general *ngIf="s.type == 'generic' || s.type == 'generic-left' || (
                s.type != 'intro' && 
                s.type != 'accordion-list' && 
                s.type != 'accordion-list-images' && 
                s.type != 'accordion-list-paragraphs' &&
                s.type != 'news-frontpage' &&
                s.type != 'special' &&
                s.type != 'stacked' &&
                s.type != 'multi-image' &&
                s.type != 'two-even-columns' &&
                s.type != 'contacts-people'
            )" [data]="s" [opt]="{'highlightColor': highlightColor}"></app-section-general>

            <app-section-multi-image *ngIf="s.type == 'multi-image'" [data]="s" [opt]="{'titleColor': 'accent', 'titleWeight': 'book', 'highlightColor': highlightColor}"></app-section-multi-image>

            <app-section-two-even-columns *ngIf="s.type == 'two-even-columns'" [data]="s" [opt]="{titleColor: 'accent', largerTextSmallerImg: true}"></app-section-two-even-columns>

            <app-section-collapsable-list [data]="s" [highlightColor]="highlightColor" [noPaddingBottom]="checkIfNoPaddingBottom(i)" [shortPaddingTop]="checkIfShortPaddingTop(i)" [forceHideTitle]="!(s.type == 'accordion-list')"></app-section-collapsable-list>

            <section *ngIf="s.type == 'news-frontpage' && newsLength > 0" spyTarget="{{s.anchorId}}">
                <app-news-list [pageType]="slug" [data]="s" [highlightColor]="highlightColor"></app-news-list>
            </section>

            <app-section-grid-cards *ngIf="s.type == 'special' && s.subType == 'grid'" [data]="s" [opt]="{'titleColor': 'accent', 'highlightColor': undefined}"></app-section-grid-cards>

            <app-section-stacked *ngIf="s.type == 'stacked'" [data]="s"></app-section-stacked>

            <app-section-contacts *ngIf="s.type == 'contacts-people' && s.people" [data]="s"></app-section-contacts>

        </ng-container>

        <section class="outro section center-vertical">
            <div class="outro--image-filter {{outroThemeColor}}" [ngStyle]="{'opacity': outroThemeOpacity}" *ngIf="s0.backImage"></div>
            <div class="outro--BGI" [ngStyle]="{'background-image': 'url('+s0.backImage+')'}" *ngIf="s0.backImage"></div>

            <div class="links" *ngIf="s0.links">
                <p class="prom-section links--title {{outroThemeColor}}--title">
                    {{ s0.links[0].title }}
                </p>
                <ul class="links--list">
                    <li class="links--item" *ngFor="let link of s0.links[0].list">
                        <a class="explore-link" [routerLink]="['/',translate.currentLang, link.route]" >{{link.text}}</a>
                    </li>
                </ul>
            </div>
        </section>

    </div>

</ng-container>
