// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-dev',
  API_BASE_URL: '/api/web',
  API_APP_URL: 'https://ws-atlante-career.prometeia.3logic.it/corporate',
  API_FETCH_BEHAVIOUR : <'DEFAULT'|'FORCE_MOCK'|'FORCE_REMOTE'> 'DEFAULT',
  GOOGLE_ANALYTICS_TRACKING_CODE: 'G-6NYF7DEGZL',
  CONFIG_COGNITO_AR : require('../aws-config/aws-export-AR.json'),
  CONFIG_COGNITO_ALUMNI : require('../aws-config/aws-export-alumni.json')
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
