<ng-container *ngIf="job">
    <a class="job-offer--job {{class}}" [routerLink]="['/', translate.currentLang, offerRoute, job.id]" *ngIf="!job.type || job.type != 'last'">                                
        <div class="job-offer--job--h prom-caption___lato-heavy">
            <span>{{job.area}}</span>
            <span>{{job.subtitle}}</span>
        </div>
        <div class="job-offer--job--title neutra2_demi text--primary">
            <span class="title--highlight">{{job.title}}</span>
        </div>                            
    </a>
</ng-container>