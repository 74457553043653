<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">
    <div class="section-max-content">
        <!-- <h1 class="text--primary prom-section capslock page-title desktop-only">{{data.title}}</h1> -->
        <div class="master-corsi-laurea prom-page" *ngIf="data.sections?.length > 0">
            <div class="section {{data.sections[i].anchorId}}" *ngFor="let s of masterCorsiLaureaSections; let i = index">
                <h2 class="neutra2_demi text--primary section--title {{data.sections[i].anchorId}}--title">{{s.title}}</h2>
                <p class="section--description {{data.sections[i].anchorId}}--description" *ngIf="s.text">{{s.text}}</p>

                <app-collapsable-list [data]="s" [highlightColor]="highlightColor" [keepImagesSize]="keepImagesSize" [forceHideTitle]="true"></app-collapsable-list>
            </div>
        </div>
    </div>
</ng-container>
