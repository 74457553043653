<div class="slider-cards {{animation}}" [ngClass]="{'no-events': !(events && events.length > 0), 'browser--on-iphone': deviceType == 'iphone'}">
    <ng-container *ngIf="news && news.length > 0">
        <swiper  [config]="configNews" #swiperNews class="swiper-card" (indexChange)="onIndexChange($event)">
            <app-single-slider-cards #swiperSlide class="swiper-slide swiper-slide-card" *ngFor="let card of news; let i=index;" [ngClass]="{'hidden': card.hidden}" (eventCloseCard)="eventCloseCard(i)" [card]="card"></app-single-slider-cards>
        </swiper>
        <app-single-slider-cards class="single-slide" [ngClass]="{'hidden': card_temp?.hidden}" (eventCloseCard)="eventCloseCard(extra_card_index)" [card]="card_temp"></app-single-slider-cards>
    </ng-container>
      <div class="mobile-only extra-margin-top">
        <span class="icon icon-swipe_swipe"></span>
    </div>

    <div class="btn-card">
        <app-prometeia-button class="buttonLoadMore--btn" [data]="buttonLoadMore"></app-prometeia-button>
    </div>

    <div class="event" *ngIf="(events && events.length > 0)">
        <div class="event--header neutra2_demi">Save the date</div>
        <div class="swiper" #swiperEvent [swiper]="configEvents" (mouseenter)="onSwiperHover( true )" (mouseleave)="onSwiperHover( false )">
            <div class="swiper-wrapper">
                <div class="swiper-slide event--content" *ngFor="let event of events" [routerLink]="['/', translate.currentLang, 'news-article', event.slug]">
                    <div class="event--content-date neutra2_demi">
                        {{event.saveTheDate | date: 'dd' }}<br>{{event.saveTheDate | date: 'MMM' : '' : translate.currentLang }}
                    </div>
                    <div class="event--content-wrap-title">
                        <div class="event--content-title prom-caption___lato-regular text--primary typewriter">
                                <strong>{{event.title}}</strong>
                        </div>
                        <div class="hiders">
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
