<div class="dropdown-panel">
    <mat-expansion-panel    class="dropdown-panel--accordion mat-elevation-z0" 
                            (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false">

        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="prom-caption___lato-heavy text--primary prom-section city">{{ data.city }}</p>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="accordion--content">
            <div class="address-block" *ngFor="let a of data.addresses">
                <p class="prom-caption___lato-regular text--primary">{{a.addr}}</p>
                <p class="prom-caption___lato-regular text--primary" *ngIf="a.addr_extraLine">{{a.addr_extraLine}}</p>
                <p class="prom-caption___lato-regular text--primary">{{a.cap}}</p>
                <p class="prom-caption___lato-regular text--primary">{{a.phone}}</p>
                <a class="prom-caption___lato-regular text--primary" *ngFor="let e of a.emails" href="mailto:{{e}}">{{e}}</a>
            </div>

            <div class="btn-container" *ngIf="data.showBtn">
                <app-prometeia-button class="btn" [data]="cityRecap_button" [isMini]="true"></app-prometeia-button>
            </div>
        </div>

    </mat-expansion-panel>
</div>