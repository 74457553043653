<section spyTarget="{{data.anchorId}}" class="{{data.theme || 'theme--filter'}}NOT theme--filter section center-vertical">
    <app-video-embedder *ngIf="marketingCookieEnabled" [isOpenEvt]="openVideoSubject.asObservable()"></app-video-embedder>

    <!-- <div class="section--image-filter" *ngIf="currentSlide"></div> -->
    <div class="section--BGI" [ngStyle]="{'background-image': 'url('+currentSlide?.previewUrl+')', 'transform': isHoveringLink ? 'scale(1.05)' : 'scale(1)' }" *ngIf="currentSlide"></div>

    <!-- the code below is used to avoid the white flash between the first and the second slide occuring the first time it switches -->
    <div class="next-bg-load" style="opacity: 0; position: absolute; top: 0; left: 0; width: 0; height: 0; object-fit: scale-down;" [ngStyle]="{'background-image': 'url('+nextSlide?.previewUrl+')'}" *ngIf="nextSlide"><p>next index: {{nextSlideIndex}}</p></div>

    <div class="section-max-content">
        <div class="content">
            <p class="neutra2_bold quote">
                <span class="text--yellow quote-start-symbol">“</span>
                <!-- <br> -->
                {{currentSlide?.text}}
            </p>
            <div class="flexible">
                <p class="name-and-job-title">
                    <span class="name bold">{{currentSlide?.title}}</span><span *ngIf="currentSlide?._meta?.jobTitle">, {{currentSlide?._meta?.jobTitle}}</span>
                </p>
                <p [ngClass]="{'video-link': true, 'link-hovering': isHoveringLink}">
                    <a class="interactive text--{{sectionOptions?.highlightColor || 'yellow'}}" (click)="openVideo(currentSlide)" (mouseenter)="linkHovering(true)"  (mouseleave)="linkHovering(false)">
                        <img class="video-icon interactive" src="../../../assets/icons-svg/video-player-player-yellow.svg">watch the interview
                    </a>
                </p>
            </div>
        </div>

        <div class="arrows section-max-content">
            <div class="interactive arrow prev" (click)="changeSlide(-1)"><span class="icon-freccia_freccina-giù-grigia"></span></div>
            <div class="interactive arrow next" (click)="changeSlide(+1)"><span class="icon-freccia_freccina-su-grigia"></span></div>
        </div>
    </div>
</section>
