import { NgModule } from '@angular/core';
import { RouterModule, Route, UrlSegment } from '@angular/router';
import { DEFAULT_LANG, IRouteDataOptions, Locale } from './models/commons';

import { paths } from './models/paths';
import { slugs } from './models/slugs';

import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { OurNetworkComponent } from './pages/our-network/our-network.component';
import { SocialResponsibilityComponent } from './pages/social-responsibility/social-responsibility.component';
import { RnDComponent } from './pages/r-n-d/r-n-d.component';
import { OurCultureComponent } from './pages/our-culture/our-culture.component';
import { RiskPlanningComponent } from './pages/risk-planning/risk-planning.component';
import { InsightsComponent } from './pages/insights/insights.component';
import { OurTeamComponent } from './pages/our-team/our-team.component';
import { MasterCorsiLaureaComponent } from './pages/master-corsi-laurea/master-corsi-laurea.component';
import { NewsArticleComponent } from './pages/news-article/news-article.component';
import { TrendingTopicsArticleComponent } from './pages/trending-topics-article/trending-topics-article.component';
import { FinancialAdvisoryComponent } from './pages/financial-advisory/financial-advisory.component';
import { TrendingTopicsArchiveComponent } from './pages/trending-topics-archive/trending-topics-archive.component';
import { FinancialAdvisoryConsultancyComponent } from './pages/financial-advisory-consultancy/financial-advisory-consultancy.component';
import { SocietaryOrgansComponent } from './pages/societary-organs/societary-organs.component';
import { AnteoComponent } from './pages/anteo/anteo.component';
import { AnteoArchiveComponent as AnteoArchiveComponent } from './pages/anteo-archivio/anteo-archivio.component';
import { AnteoArticleComponent } from './pages/anteo-article/anteo-article.component';
import { RnDOurStoryComponent } from './pages/r-n-d-our-story/r-n-d-our-story.component';
import { GeneralArticleComponent } from './pages/general-article/general-article.component';
import { AlumniComponent } from './pages/alumni/alumni.component';
import { JobOfferComponent } from './pages/job-offer/job-offer.component';
import { CountryPageComponent } from './pages/country-page/country-page.component';
import { OurPartnershipComponent } from './pages/our-partnership/our-partnership.component';
import { PromHqComponent } from './pages/prom-hq/prom-hq.component';
import { OfferComponent } from './pages/offer/offer.component';
import { ReservedAreaComponent } from './pages/reserved-area/reserved-area.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuard } from './services/auth-guard.service';
import { ActivateAccountComponent } from './pages/activate-account/activate-account.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { AlumniUnsubscribeComponent } from './pages/alumni-unsubscribe/alumni-unsubscribe.component';
import { FeedbackFormComponent } from './pages/feedback-form/feedback-form.component';
import { CareersComponent } from './pages/careers/careers.component';


interface IAppRouter extends Route {
  data?: IRouteDataOptions
}
export declare type AppRoutes = IAppRouter[];

function anteoFilters(url: UrlSegment[]) {
  return url.length == 2 && url[0].path === 'financial-advisory' && url[1].path.includes('anteo') ? ({consumed: url}) : null;
}
function informazioneFilters(url: UrlSegment[]) {
  return url.length == 2 && url[0].path === 'financial-advisory' && url[1].path.includes('informazione') ? ({consumed: url}) : null;
}
function informativaPrivacyFilters(url: UrlSegment[]) {
  return ( url.length == 1 &&  url[0].path.includes('informativa-privacy')) || (url.length == 2 &&  url[1].path.includes('privacy-information-notice') ) ? ({consumed: url}) : null 
}

const routes: AppRoutes = [
  { path: '', pathMatch: 'full', redirectTo: paths.home },
  { path: ":lang/" + '', pathMatch: 'full', redirectTo: ":lang/" + paths.home },

  /** home page */
  { path: paths.home, component: HomeComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.home, component: HomeComponent, data: {slug: slugs.home, meta_description: "Prometeia is a leading provider of cutting-edge advisory services, tech solutions and research insights. More than 900 experts work for 400+ clients in 20+ locations in the world. "} },

  /** internal pages */
    /** Who We Are */
  { path: paths.aboutUs, component: AboutUsComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.aboutUs, component: AboutUsComponent, data: {slug: slugs.aboutUs } },

    { path: paths.ourTeam, component: OurTeamComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.ourTeam, component: OurTeamComponent, data: {slug: slugs.ourTeam, forceDarkHeader: true, forceTitle: true } },

  { path: paths.socialResponsibility, component: SocialResponsibilityComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.socialResponsibility, component: SocialResponsibilityComponent, data: {slug: slugs.socialResponsibility, forceDarkHeader: true, forceBG: true } },

    { path: paths.promHQ, component: PromHqComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.promHQ, component: PromHqComponent, data: {slug: slugs.promHQ} },

  { path: paths.ourNetwork, component: OurNetworkComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.ourNetwork, component: OurNetworkComponent, data: {slug: slugs.ourNetwork, forceTitle: true } },

    /** country pages redirects - start */
    // branches
    // { path: paths.countryPage + "/cairo", pathMatch: 'full', redirectTo: paths.countryPage + "/egypt-and-northern-africa" },
    // { path: paths.countryPage + "/istanbul", pathMatch: 'full', redirectTo: paths.countryPage + "/turkiye-and-middle-east" },
    // { path: paths.countryPage + "/london", pathMatch: 'full', redirectTo: paths.countryPage + "/united-kingdom" },
    // { path: paths.countryPage + "/vienna", pathMatch: 'full', redirectTo: paths.countryPage + "/austria" },
    // { path: paths.countryPage + "/zurich", pathMatch: 'full', redirectTo: paths.countryPage + "/switzerland" },
    // { path: ":lang/" + paths.countryPage + "/cairo", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage + "/egypt-and-northern-africa" },
    // { path: ":lang/" + paths.countryPage + "/istanbul", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage + "/turkiye-and-middle-east" },
    // { path: ":lang/" + paths.countryPage + "/london", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage + "/united-kingdom" },
    // { path: ":lang/" + paths.countryPage + "/vienna", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage + "/austria" },
    // { path: ":lang/" + paths.countryPage + "/zurich", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage + "/switzerland" },
    /** country pages redirects - end */

    { path: paths.countryPage + "/:id", component: CountryPageComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.countryPage + "/:id", component: CountryPageComponent, data: {slug: slugs.countryPage, showLangSelector: true, forceTitle: true } },

    { path: paths.ourPartnership, component: OurPartnershipComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.ourPartnership, component: OurPartnershipComponent, data: {slug: slugs.ourPartnership, forceTitle: true, forceDarkHeader: true } },

    /** Our Expertise */
    // ADVISORY
    { path: paths.riskPlanningControl, component: RiskPlanningComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.riskPlanningControl, component: RiskPlanningComponent, data: {slug: slugs.riskPlanningControl} },

    { path: paths.financialDistribution, component: RiskPlanningComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.financialDistribution, component: RiskPlanningComponent, data: {slug: slugs.financialDistribution} },

    { path: paths.strategicAdvisory, component: RiskPlanningComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.strategicAdvisory, component: RiskPlanningComponent, data: {slug: slugs.strategicAdvisory} },

    { path: "financial-advisory-for-institutional-investors", pathMatch: 'full', redirectTo: paths.financialAdvisory },
    { path: ":lang/" + "financial-advisory-for-institutional-investors", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisory },
    { path: paths.financialAdvisory, component: FinancialAdvisoryComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.financialAdvisory, component: FinancialAdvisoryComponent, data: {slug: slugs.financialAdvisory, isAdvisorPage: true, highlightColor: "red"} },

      { path: paths.financialAdvisoryConsultancy + "/:id", component: FinancialAdvisoryConsultancyComponent, data: { redirectLang: true } },
      { path: ":lang/" + paths.financialAdvisoryConsultancy + "/:id", component: FinancialAdvisoryConsultancyComponent,
        data: {slug: slugs.financialAdvisoryConsultancy, isAdvisorPage: true, forceDarkHeader: true, forceTitle: true, highlightColor: "red"} },

      { path: paths.anteo + "/:id", component: AnteoComponent, data: { redirectLang: true } },
      { path: ":lang/" + paths.anteo + "/:id", component: AnteoComponent,
        data: {slug: slugs.anteo, isAdvisorPage: true, highlightColor: "red", forceDarkHeader: true, forceTitle: true} },

        { path: paths.anteoArchive, component: AnteoArchiveComponent, data: { redirectLang: true } },
        { path: ":lang/" + paths.anteoArchive, component: AnteoArchiveComponent,
          data: {slug: slugs.anteoArchive, isAdvisorPage: true, highlightColor: "red", forceDarkHeader: true, forceTitle: true} },

        { path: paths.anteoArticle + "/:id", component: AnteoArticleComponent, data: { redirectLang: true } },
        { path: ":lang/" + paths.anteoArticle + "/:id", component: AnteoArticleComponent,
          data: {slug: slugs.anteoArticle, isAdvisorPage: true, highlightColor: "red", forceDarkHeader: true, forceTitle: true} },

      { path: "societary-organs", pathMatch: 'full', redirectTo: paths.societaryOrgans }, 
      { path: ":lang/" + "societary-organs", pathMatch: 'full', redirectTo: ":lang/" + paths.societaryOrgans },
      { path: paths.societaryOrgans, component: SocietaryOrgansComponent, data: { redirectLang: true } },
      { path: ":lang/" + paths.societaryOrgans, component: SocietaryOrgansComponent,
        data: {slug: slugs.societaryOrgans, isAdvisorPage: true, highlightColor: "red", forceDarkHeader: true, forceTitle: true} },

      { path: paths.informationPaths + "/:id", component: GeneralArticleComponent, data: { redirectLang: true } },
      { path: ":lang/" + paths.informationPaths + "/:id", component: GeneralArticleComponent,
        data: {slug: slugs.informationPaths, isAdvisorPage: true, highlightColor: "red", forceDarkHeader: true, forceTitle: true} },

        { path: paths.informationPathsArchive, component: AnteoArchiveComponent, data: { redirectLang: true } },
        { path: ":lang/" + paths.informationPathsArchive, component: AnteoArchiveComponent,
          data: {slug: slugs.informationPathsArchive, isAdvisorPage: true, highlightColor: "red", forceDarkHeader: true, forceTitle: true} },

      { path: paths.discoverMore + "/:id", component: FinancialAdvisoryConsultancyComponent, data: { redirectLang: true } },
      { path: ":lang/" + paths.discoverMore + "/:id", component: FinancialAdvisoryConsultancyComponent,
        data: {slug: slugs.discoverMore, forceDarkHeader: true, forceTitle: true, highlightColor: "accent"} },

    { path: paths.esgClimate, component: RiskPlanningComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.esgClimate, component: RiskPlanningComponent, data: {slug: slugs.esgClimate} },

    // TECH SOLUTIONS
    { path: paths.riskTechAndWealthTech, pathMatch: 'full', redirectTo: paths.techSolutions },
    { path: ":lang/" + paths.riskTechAndWealthTech, pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions },
    // { path: paths.techSolutions, component: RiskPlanningComponent, data: { redirectLang: true } },
    // { path: ":lang/" + paths.techSolutions, component: RiskPlanningComponent, data: {slug: slugs.techSolutions} },
    { path: paths.techSolutions, pathMatch: 'full', redirectTo: paths.saasCloud },
    { path: ":lang/" + paths.techSolutions, pathMatch: 'full', redirectTo: ":lang/" + paths.saasCloud },

    { path: paths.saasCloud, component: RiskPlanningComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.saasCloud, component: RiskPlanningComponent, data: {slug: slugs.saasCloud} },

      { path: paths.promAWS, component: RiskPlanningComponent, data: { redirectLang: true } },
      { path: ":lang/" + paths.promAWS, component: RiskPlanningComponent, data: {slug: slugs.promAWS} },

      { path: paths.promMsAzure, component: RiskPlanningComponent, data: { redirectLang: true } },
      { path: ":lang/" + paths.promMsAzure, component: RiskPlanningComponent, data: {slug: slugs.promMsAzure} },

    { path: paths.appliedDataScienceAndAdvancedAnalytics, component: RiskPlanningComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.appliedDataScienceAndAdvancedAnalytics, component: RiskPlanningComponent, data: {slug: slugs.appliedDataScienceAndAdvancedAnalytics} },

    { path: paths.riskTech, component: RiskPlanningComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.riskTech, component: RiskPlanningComponent, data: {slug: slugs.riskTech} },

    { path: paths.wealthTech, component: RiskPlanningComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.wealthTech, component: RiskPlanningComponent, data: {slug: slugs.wealthTech} },


    // R & D here
    { path: paths.research_bi_reporting, component: RnDComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.research_bi_reporting, component: RnDComponent, data: {slug: slugs.research_bi_reporting} },

      { path: paths.ourStory, component: RnDOurStoryComponent, data: { redirectLang: true } },
      { path: ":lang/" + paths.ourStory, component: RnDOurStoryComponent, data: {slug: slugs.ourStory, forceTitle: true, forceDarkHeader: true} },

    { path: paths.dataScience, component: RnDComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.dataScience, component: RnDComponent, data: {slug: slugs.dataScience} },


  { path: paths.news, component: InsightsComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.news, component: InsightsComponent, data: {slug: slugs.news, forceDarkHeader: true} },

    { path: paths.news + "/:id", pathMatch: 'full', redirectTo: paths.newsArticle + "/:id"},
    { path: ":lang/" + paths.news + "/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.newsArticle + "/:id" },
    { path: paths.newsArticle + "/:id", component: NewsArticleComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.newsArticle + "/:id", component: NewsArticleComponent, data: {slug: slugs.newsArticle, forceDarkHeader: true, forceTitle: true} },

  { path: paths.newsletter, component: InsightsComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.newsletter, component: InsightsComponent, data: {slug: slugs.newsletter, forceDarkHeader: true} },

  { path: "atlante", pathMatch: 'full', redirectTo: paths.trendingTopics},
  { path: ":lang/" + "atlante", pathMatch: 'full', redirectTo: ":lang/" + paths.trendingTopics },
  { path: paths.trendingTopics, component: InsightsComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.trendingTopics, component: InsightsComponent, data: {slug: slugs.trendingTopics, forceDarkHeader: true} },

    { path: "atlante" + "/:id", pathMatch: 'full', redirectTo: paths.trendingTopicsArticle + "/:id"},
    { path: ":lang/" + "atlante" + "/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.trendingTopicsArticle + "/:id" },
    { path: paths.trendingTopicsArticle + "/:id", component: TrendingTopicsArticleComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.trendingTopicsArticle + "/:id", component: TrendingTopicsArticleComponent, data: {slug: slugs.trendingTopicsArticle, forceDarkHeader: true, forceTitle: true} },

    { path: paths.trendingTopicsArchive + "/:id", component: TrendingTopicsArchiveComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.trendingTopicsArchive + "/:id", component: TrendingTopicsArchiveComponent, data: {slug: slugs.trendingTopicsArchive, forceDarkHeader: true} },

    /** Career */
  // { path: paths.ourCulture, component: OurCultureComponent, data: { redirectLang: true } },
  // { path: ":lang/" + paths.ourCulture, component: OurCultureComponent, data: {slug: slugs.ourCulture, highlightColor: 'yellow'} },
  { path: paths.ourCulture, pathMatch: 'full', redirectTo: paths.careers },
  { path: ":lang/" + paths.ourCulture, pathMatch: 'full', redirectTo: ":lang/" + paths.careers },
  { path: paths.careers, component: CareersComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.careers, component: CareersComponent, data: {slug: slugs.careers, highlightColor: 'yellow' } },

    { path: paths.masterCorsiLaurea, component: MasterCorsiLaureaComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.masterCorsiLaurea, component: MasterCorsiLaureaComponent, data: {slug: slugs.masterCorsiLaurea, highlightColor: 'yellow', forceDarkHeader: true, forceTitle: true } },

  { path: "job-offer", pathMatch: 'full', redirectTo: paths.jobOffer},
  { path: ":lang/" + "job-offer", pathMatch: 'full', redirectTo: ":lang/" + paths.jobOffer},
  { path: paths.jobOffer, component: JobOfferComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.jobOffer, component: JobOfferComponent, data: {slug: slugs.jobOffer, forceDarkHeader: true, highlightColor: 'yellow'} },

    { path: paths.offer + "/:id", component: OfferComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.offer + "/:id", component: OfferComponent, data: {slug: slugs.offer, forceDarkHeader: true, forceTitle: true, highlightColor: 'yellow'} },

  { path: paths.alumniUnsubscribe, component: AlumniUnsubscribeComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.alumniUnsubscribe, component: AlumniUnsubscribeComponent, data: {slug: slugs.alumniUnsubscribe, highlightColor: 'yellow'} },

  { path: paths.alumni, component: AlumniComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.alumni, component: AlumniComponent, data: {slug: slugs.alumni, highlightColor: 'yellow'} },

    { path: paths.alumniStories + "/:id", canActivate: [AuthGuard], component: GeneralArticleComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.alumniStories + "/:id", canActivate: [AuthGuard], component: GeneralArticleComponent, data: {slug: slugs.alumniStories, forceDarkHeader: true, forceTitle: true, highlightColor: 'yellow'} },

    { path: paths.promLife + "/:id", canActivate: [AuthGuard], component: GeneralArticleComponent, data: { redirectLang: true } },
    { path: ":lang/" + paths.promLife + "/:id", canActivate: [AuthGuard], component: GeneralArticleComponent, data: {slug: slugs.promLife, forceDarkHeader: true, forceTitle: true, highlightColor: 'yellow'} },

  { path: paths.reservedArea, pathMatch: 'full', redirectTo: DEFAULT_LANG + '/' + paths.reservedArea },
  { path: ":lang/" + paths.reservedArea, component: ReservedAreaComponent, data: {slug: slugs.reservedArea, forceDarkHeader: true, highlightColor: 'primary'} },
  { path: paths.reservedArea, pathMatch: 'full', redirectTo: DEFAULT_LANG + '/' + paths.reservedArea+ "/:folder" },
  { path: ":lang/" + paths.reservedArea+ "/:folder", component: ReservedAreaComponent, data: {slug: slugs.reservedArea, forceDarkHeader: true, highlightColor: 'primary'} },


  { path: paths.article + "/:id", component: GeneralArticleComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.article + "/:id", component: GeneralArticleComponent, data: {slug: slugs.article, forceDarkHeader: true, highlightColor: 'primary'} },

  { path: paths.activateAccount, component: ActivateAccountComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.activateAccount, component: ActivateAccountComponent, data: {slug: slugs.activateAccount, forceDarkHeader: true, forceTitle: true} },

  { path: paths.searchResults, component: SearchResultsComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.searchResults, component: SearchResultsComponent, data: {slug: slugs.searchResults, forceDarkHeader: true, forceTitle: true} },

  /** extra pages */
  { path: "archetipi", pathMatch: 'full', redirectTo: DEFAULT_LANG + '/' + "archetipi" },
  { path: ":lang/" + "archetipi", component: FeedbackFormComponent, data: {slug: "archetipi"} },


  /** REDIRECTS */
  { path: "disclaimer", pathMatch: 'full', redirectTo: paths.article + "/disclaimer"},
  { path: ":lang/" + "disclaimer", pathMatch: 'full', redirectTo: ":lang/" + paths.article + "/disclaimer"},
  { path: "sedi-e-contatti", pathMatch: 'full', redirectTo: paths.ourNetwork},
  { path: ":lang/" + "sedi-e-contatti", pathMatch: 'full', redirectTo: ":lang/" + paths.ourNetwork},
  { path: "chi-siamo", pathMatch: 'full', redirectTo: paths.aboutUs},
  { path: ":lang/" + "chi-siamo", pathMatch: 'full', redirectTo: ":lang/" + paths.aboutUs},
  { path: "chi-siamo/prometeia-40/:id", pathMatch: 'full', redirectTo: paths.home},
  { path: ":lang/" + "chi-siamo/prometeia-40/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.home},
  { path: "enterprise-risk-management", pathMatch: 'full', redirectTo: paths.riskPlanningControl},
  { path: ":lang/" + "enterprise-risk-management", pathMatch: 'full', redirectTo: ":lang/" + paths.riskPlanningControl},
  { path: "enterprise-risk-management/risk-advisory", pathMatch: 'full', redirectTo: paths.riskPlanningControl},
  { path: ":lang/" + "enterprise-risk-management/risk-advisory", pathMatch: 'full', redirectTo: ":lang/" + paths.riskPlanningControl},
  { path: "enterprise-risk-management/risk-modelling", pathMatch: 'full', redirectTo: paths.riskPlanningControl},
  { path: ":lang/" + "enterprise-risk-management/risk-modelling", pathMatch: 'full', redirectTo: ":lang/" + paths.riskPlanningControl},
  { path: "enterprise-risk-management/risk-academy", pathMatch: 'full', redirectTo: paths.riskPlanningControl},
  { path: ":lang/" + "enterprise-risk-management/risk-academy", pathMatch: 'full', redirectTo: ":lang/" + paths.riskPlanningControl},
  { path: "enterprise-risk-management/implementation-services", pathMatch: 'full', redirectTo: paths.riskPlanningControl},
  { path: ":lang/" + "enterprise-risk-management/implementation-services", pathMatch: 'full', redirectTo: ":lang/" + paths.riskPlanningControl},
  { path: "enterprise-risk-management/thought-leadership", pathMatch: 'full', redirectTo: paths.riskPlanningControl},
  { path: ":lang/" + "enterprise-risk-management/thought-leadership", pathMatch: 'full', redirectTo: ":lang/" + paths.riskPlanningControl},
  { path: "enterprise-risk-management/news", pathMatch: 'full', redirectTo: paths.riskPlanningControl},
  { path: ":lang/" + "enterprise-risk-management/news", pathMatch: 'full', redirectTo: ":lang/" + paths.riskPlanningControl},
  { path: "data-science", pathMatch: 'full', redirectTo: paths.dataScience},
  { path: ":lang/" + "data-science", pathMatch: 'full', redirectTo: ":lang/" + paths.dataScience},
  { path: "enterprise-risk-management/prometeia-partner-program", pathMatch: 'full', redirectTo: paths.ourPartnership},
  { path: ":lang/" + "enterprise-risk-management/prometeia-partner-program", pathMatch: 'full', redirectTo: ":lang/" + paths.ourPartnership},
  { path: "enterprise-risk-management/balance-sheet-management", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/balance-sheet-management", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "enterprise-risk-management/performance-management-and-control", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/performance-management-and-control", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "enterprise-risk-management/regulatory-accounting-compliance", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/regulatory-accounting-compliance", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "enterprise-risk-management/credit-risk-analysis", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/credit-risk-analysis", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "enterprise-risk-management/credit-decision-management", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/credit-decision-management", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "enterprise-risk-management/model-risk-management", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/model-risk-management", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "enterprise-risk-management/financial-pricing-engineering", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/financial-pricing-engineering", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "enterprise-risk-management/risk-and-finance-integration", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/risk-and-finance-integration", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "enterprise-risk-management/ermas", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "enterprise-risk-management/ermas", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "flex/cm/pages/CommunityLoginOut.php/L/EN/BL/aHR0cHM6Ly93d3cucHJvbWV0ZWlhLml0L2ZsZXgvRml4ZWRQYWdlcy9Db21tb24vYXIucGhwL0wvRU4%3D?web=1&wdLOR=c0F2759B5-A0F6-4AAD-A848-6D41F85690F2", pathMatch: 'full', redirectTo: ":lang/" + paths.reservedArea},
  { path: "wealth-and-asset-management", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/knowledge-training-and-change-management", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management/knowledge-training-and-change-management", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/customer-insight", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management/customer-insight", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/product-innovation-and-governance", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management/product-innovation-and-governance", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/market-analysis", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management/market-analysis", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/regulation-and-compliance", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management/regulation-and-compliance", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/business-strategy", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management/business-strategy", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/thought-leadership", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management/thought-leadership", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/news", pathMatch: 'full', redirectTo: paths.financialDistribution},
  { path: ":lang/" + "wealth-and-asset-management/news", pathMatch: 'full', redirectTo: ":lang/" + paths.financialDistribution},
  { path: "wealth-and-asset-management/:id", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "wealth-and-asset-management/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "asset-management-insights", pathMatch: 'full', redirectTo: paths.techSolutions},
  { path: ":lang/" + "asset-management-insights", pathMatch: 'full', redirectTo: ":lang/" + paths.techSolutions},
  { path: "financial-advisory", pathMatch: 'full', redirectTo: paths.financialAdvisory},
  { path: ":lang/" + "financial-advisory", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisory},
  { path: "financial-advisory/performance-risk-analysis", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/performance-and-risk-analysis'},
  { path: ":lang/" + "financial-advisory/performance-risk-analysis", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/performance-and-risk-analysis'},
  { path: "financial-advisory/asset-allocation-strategica", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/strategic-asset-allocation'},
  { path: ":lang/" + "financial-advisory/strategic-asset-allocation", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/strategic-asset-allocation'},
  { path: "financial-advisory/asset-allocation-tattica", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/tactical-asset-allocation'},
  { path: ":lang/" + "financial-advisory/tactical-asset-allocation", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/tactical-asset-allocation'},
  { path: "financial-advisory/alm", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/alm'},
  { path: ":lang/" + "financial-advisory/alm", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/alm'},
  { path: "financial-advisory/selezione", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/selection-process'},
  { path: ":lang/" + "financial-advisory/selection-process", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/selection-process'},
  { path: "financial-advisory/risk-management", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/risk-management'},
  { path: ":lang/" + "financial-advisory/risk-management", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/risk-management'},
  { path: "financial-advisory/investment-process-engineering", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/investment-process-engineering'},
  { path: ":lang/" + "financial-advisory/investment-process-engineering", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/investment-process-engineering'},
  { path: "financial-advisory/rto-mediazione", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/order-routing'},
  { path: ":lang/" + "financial-advisory/order-routing", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/order-routing'},
  { path: "financial-advisory/arbitro-controversie-finanziarie", pathMatch: 'full', redirectTo: paths.financialAdvisoryConsultancy+'/ombudsman'},
  { path: ":lang/" + "financial-advisory/finance-services-ombudsman", pathMatch: 'full', redirectTo: ":lang/" + paths.financialAdvisoryConsultancy+'/ombudsman'},
  {  matcher: anteoFilters,pathMatch: 'full', redirectTo: paths.anteoArchive},
  {  matcher: informazioneFilters,pathMatch: 'full', redirectTo: paths.informationPathsArchive},
  { path: "ricerca", pathMatch: 'full', redirectTo: paths.research_bi_reporting},
  { path: ":lang/" + "research-insights", pathMatch: 'full', redirectTo: ":lang/" + paths.research_bi_reporting},
  { path: "ricerca/organi-societari-prometeia-associazione", pathMatch: 'full', redirectTo: paths.ourTeam},
  { path: ":lang/" + "research-insights/our-team-prometeia-associazione", pathMatch: 'full', redirectTo: ":lang/" + paths.ourTeam},
  { path: "ricerca/rapporto-di-previsione", pathMatch: 'full', redirectTo: paths.ourTeam},
  { path: ":lang/" + "research-insights/quarterly-economic-outlook", pathMatch: 'full', redirectTo: ":lang/" + paths.ourTeam},
  { path: "ricerca/analisi-dei-bilanci-bancari", pathMatch: 'full', redirectTo: paths.ourTeam},
  { path: ":lang/" + "research-insights/bank-balance-sheets-analysis", pathMatch: 'full', redirectTo: ":lang/" + paths.ourTeam},
  { path: "ricerca/:id", pathMatch: 'full', redirectTo: paths.research_bi_reporting},
  { path: ":lang/" + "research-insights/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.research_bi_reporting},
  { path: "prometeiamio", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ 'prometeiamio'}, //da verificare
  { path: ":lang/" + "prometeiamio", pathMatch: 'full',redirectTo: ":lang/"+ "prometeiamio" },
  { path: "carriere", pathMatch: 'full', redirectTo: paths.ourCulture},
  { path: ":lang/" + "careers", pathMatch: 'full', redirectTo: ":lang/" + paths.ourCulture},
  { path: "carriere/studenti#job-offers", pathMatch: 'full', redirectTo: paths.jobOffer},
  { path: ":lang/" + "careers/students#job-offers", pathMatch: 'full', redirectTo: ":lang/" + paths.jobOffer},
  { path: "carriere/studenti/:id", pathMatch: 'full', redirectTo: paths.masterCorsiLaurea},
  { path: ":lang/" + "careers/students/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.masterCorsiLaurea},
  { path: "carriere/experienced#job-opportunities", pathMatch: 'full', redirectTo: paths.jobOffer},
  { path: ":lang/" + "careers/experienced#job-opportunities", pathMatch: 'full', redirectTo: ":lang/" + paths.jobOffer},
  { path: "carriere/alumni/login", pathMatch: 'full', redirectTo: ":lang/" + paths.alumni},
  { path: "carriere/:id", pathMatch: 'full', redirectTo: paths.ourCulture},
  { path: ":lang/" + "careers/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.ourCulture},
  { path: "research", pathMatch: 'full', redirectTo: paths.trendingTopics},
  { path: ":lang/" + "research", pathMatch: 'full', redirectTo: ":lang/" + paths.trendingTopics},
  { path: "atlante", pathMatch: 'full', redirectTo: paths.trendingTopics},
  { path: ":lang/" + "atlante", pathMatch: 'full', redirectTo: ":lang/" + paths.trendingTopics},
  { path: "atlante/archivio", pathMatch: 'full', redirectTo: paths.trendingTopics},
  { path: ":lang/" + "atlante/archive", pathMatch: 'full', redirectTo: ":lang/" + paths.trendingTopics},
  { path: "atlante/iscriviti", pathMatch: 'full', redirectTo: paths.newsletter},
  { path: ":lang/" + "atlante/sign-up", pathMatch: 'full', redirectTo: ":lang/" + paths.newsletter},
  { path: "atlante/iscriviti", pathMatch: 'full', redirectTo: paths.newsletter},
  { path: ":lang/" + "atlante/sign-up", pathMatch: 'full', redirectTo: ":lang/" + paths.newsletter},
  { path: "news", pathMatch: 'full', redirectTo: paths.news},
  { path: ":lang/" + "news", pathMatch: 'full', redirectTo: ":lang/" + paths.news},
  { path: ":lang/" + "enterprise-risk-management"+"/:city", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage+"/:city"},
  { path: ":lang/" + "enterprise-risk-management"+"/:city"+"/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage+"/:city"},
  { path: ":lang/" + "switzerland", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage+"/zurich"},
  { path: ":lang/" + "schweiz", pathMatch: 'full', redirectTo: ":lang/" + paths.countryPage+"/zurich"},
  { path: ":lang/" + "sites-and-contacts", pathMatch: 'full', redirectTo: ":lang/" + paths.ourNetwork},
  { path: "dati-societari", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/company-information#contacts"},
  { path: ":lang/" + "company-information", pathMatch: 'full', redirectTo: ":lang/" + paths.article + "/company-information#contacts"},
  { path: "privacy-policy", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/privacy-policy"},
  { path: ":lang/" + "privacy-policy", pathMatch: 'full', redirectTo: ":lang/" + paths.article + "/privacy-policy"},
  { path: "privacy-policy"+"/:id", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/privacy-policy"},
  { path: ":lang/" + "privacy-policy"+"/:id", pathMatch: 'full', redirectTo: ":lang/" + paths.article + "/privacy-policy"},
  { path: "termini-di-utilizzo", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/terms-of-use"},
  { path: ":lang/" + "terms-of-use", pathMatch: 'full', redirectTo: ":lang/" + paths.article + "/terms-of-use"},
  { path: "codice-etico", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/ethics"},
  { path: ":lang/" + "code-ethics", pathMatch: 'full', redirectTo: ":lang/" + paths.article + "/ethics"},
  { path: "flex/AppData/WebLive/documenti/Modello%20231.pdf", pathMatch: 'full', redirectTo: "/cdn/FILES/footer/Modello 231 - Parte Generale.pdf"},//da verificare
  { path: ":lang/" + "flex/AppData/WebLive/documenti/Modello%20231.pdf", pathMatch: 'full', redirectTo: "cdn/FILES/footer/Modello%20231%20-%20Parte%20Generale.pdf"},
  { path: "politica-per-la-qualita", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/quality" },
  { path: "cookies-policy", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/cookie" },
  { path: "financial-advisory/prometeia-advisor-sim-whistleblowing", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/whistleblowing" },
  { path: "informativa-trattamento-dati-personali-publishing", pathMatch: 'full', redirectTo: DEFAULT_LANG +'/'+ paths.article + "/privacy-policy" },
  {  matcher: informativaPrivacyFilters, pathMatch: 'full', redirectTo: paths.article+"/privacy-policy"},
 


 
  


  /** PAGE NOT FOUND */
  { path: paths.pageNotFound, component: PageNotFoundComponent, data: { redirectLang: true } },
  { path: ":lang/" + paths.pageNotFound, component: PageNotFoundComponent, data: {slug: slugs.pageNotFound} },
  { path: '**', component: PageNotFoundComponent, data: { redirectLang: true } },
  { path: ":lang/" + '**', component: PageNotFoundComponent, data: {slug: slugs.pageNotFound, id: document.URL} }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'disabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
