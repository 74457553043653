import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Debounce } from 'angular-debounce-throttle';
import { Observable, Subscription } from 'rxjs';
import { BrowserServices } from 'src/app/services/browser.service';
import { ComponentsServices } from 'src/app/services/components.service';
import { DESKTOP_MIN_WIDTH, IMedia } from '../../models/commons';
import { VideoService } from '../../services/video.service';

@Component({
  selector: 'app-video-embedder',
  templateUrl: './video-embedder.component.html',
  styleUrls: ['./video-embedder.component.scss']
})
export class VideoEmbedderComponent implements OnInit, OnChanges, OnDestroy {

  openState: boolean;
  // safeUrl: SafeResourceUrl;
  @Input() source: IMedia;
  @Input() isOpenEvt: Observable<boolean>;
  private isOpenEvtSubscription: Subscription = null;
  private playVideoRequestSubscription: Subscription = null;

  videoWidth = 560;  //800   *1.4285714285714
  videoHeight = 315; //450   *1.4285714285714
  iframeMarginTop = "25vh";

  browserType: string = "";
  deviceType: string = "";

  constructor(
    private videoService: VideoService,
    private componentsServices: ComponentsServices,
    private browserServices: BrowserServices
  ) { }

  ngOnInit(): void {
    this.openState = false;

    this.browserType = this.browserServices.myBrowser().toLowerCase();
    this.deviceType = this.browserServices.myDevice().toLowerCase();
    
    if (this.componentsServices.getInnerWidth() >= 1200) {
      this.videoWidth = 1024;
      this.videoHeight = 576;
      this.iframeMarginTop = "10vh";
    } else if (this.componentsServices.getInnerWidth() >= DESKTOP_MIN_WIDTH) {
      this.videoWidth = 800;
      this.videoHeight = 450;
      this.iframeMarginTop = "22vh";
    }

    this.isOpenEvtSubscription?.unsubscribe();
    this.isOpenEvtSubscription = this.isOpenEvt.subscribe((val) => {
      if (val) {
        this.openState = true;
      }
      // console.log("isOpenEvt", val);
    });

    this.playVideoRequestSubscription?.unsubscribe();
    this.playVideoRequestSubscription = this.videoService.playVideoRequest.subscribe((src: IMedia) => {
      if (!this.openState) this.play(src);
    });
  }

  @Debounce(100)
  play(src: IMedia) {
    console.log("video-embedder", "video req", src);

    if (!this.componentsServices.isDesktopMode()) {
      this.openYouTube(src.id);
      return;
    }

    this.source = src;
    this.openState = true;
  }

  ngOnChanges() {
    if (this.source) {
      if (!this.componentsServices.isDesktopMode()) {
        this.openYouTube(this.source.id);
        return;
      }

      if (!this.source.contentUrl || !this.source.contentUrl.includes('embed')) {
        this.source.contentUrl = "https://www.youtube.com/embed/" + this.source.id;
      }
      // console.log(this.source);
      /* this.safeUrl = this.sanitizer.sanitize(SecurityContext.URL, this.source.contentUrl); */
    }
  }

  closeVideo() {
    this.openState = false;
  }

  openYouTube(videoID: string) {
    if (this.deviceType == 'iPhone') {
      window.location.href = "https://youtu.be/" + videoID;
    } else {
      /** target='_blank' is the default */
      window.open("https://youtu.be/" + videoID);
    }
  }

  ngOnDestroy() {
    if (this.isOpenEvtSubscription != null) {
      this.isOpenEvtSubscription.unsubscribe();
      this.isOpenEvtSubscription = null;
    }
    this.playVideoRequestSubscription?.unsubscribe();
  }

}
