<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>
<ng-container *ngIf="data">
    <app-side-navbar class="side-navbar desktop-only" [data]="data.sections" [bulletColor]="highlightColor"></app-side-navbar>
    <app-long-arrow-down class="desktop-only" [color]="highlightColor"></app-long-arrow-down>

    <div class="about">

        <!-- dynamic sections: -->
        <ng-container *ngFor="let s of data.sections; let i = index">
            <app-section-intro *ngIf="s.type == 'intro'" [data]="s" [highlightColor]="highlightColor"></app-section-intro>

            <app-section-general [data]="s" [opt]="{'highlightColor': highlightColor}"></app-section-general>

            <app-section-collapsable-list [data]="s" [highlightColor]="highlightColor" [noPaddingBottom]="checkIfNoPaddingBottom(i)" [shortPaddingTop]="checkIfShortPaddingTop(i)"></app-section-collapsable-list>

            <section spyTarget="{{s.anchorId}}" *ngIf="(s.type != 'intro' && s.type == 'contacts-people') && s.people"
                     class="{{s.theme}} media-and-contacts section center-vertical">
                <h1 class="section_h1 neutra2_book text--accent" data-aos="fade-up">{{ s.title }}</h1>
                <div class="contacts-wrap">
                    <div class="contacts">
                        <app-business-card-v2 data-aos="fade-up" *ngFor="let p of s.people" [data]="p"></app-business-card-v2>
                    </div>
                </div>
            </section>
        </ng-container>

    </div>
</ng-container>
