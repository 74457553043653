<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>

<div class="single-trending-topics-archive prom-page theme--{{typeNews}}" *ngIf="data">

    <!-- <app-atlante-app-banner *ngIf="slug == 'trending-topics-archive'"></app-atlante-app-banner> -->

    <ng-container>
        <div class="single-trending-topics-archive--content">
            <div class="single-trending-topics-archive--header">
                <span class="icon-{{typeNews}} static-icon"><span class="static-icon--text">{{typeNews}}</span></span>
                <!-- <div class="searchbar" *ngIf="data.search">
                    <app-search-bar [data]="data.search" (filterChange)="filter($event)" (filterReset)="filterResetEvent()"></app-search-bar>
                </div> -->
            </div>

            <div *ngFor="let archive of data.news" class="single-trending-topics-archive--item">
                <img src="{{archive.heroImage}}" *ngIf="archive.heroImage && typeNews == 'dataviz'">
                <div class="single-trending-topics-archive--item-text">
                    <span class="single-trending-topics-archive--item-category prom-caption___lato-regular">{{archive.category}}</span>
                    <span class="single-trending-topics-archive--item-date prom-caption___lato-regular">{{archive.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}</span>
                    <a href="{{typeNews != 'video' && typeNews != 'podcast' ? '/'+route+'/'+archive.slug : archive.permalink}}" class="single-trending-topics-archive--item-title"><span class="title--highlight">{{archive.title}}</span></a>
                </div>
            </div>
        </div>
    </ng-container>
</div>
