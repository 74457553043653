<div class="atlante-app-banner" *ngIf="!componentsServices.isDesktopMode() && showBanner && !sessionCookieFound && (isIphone || isAndroid)">
    <div class="close-icon">
        <img class="close-icon--image" src="../../../assets/icons-svg/svg-icon-X-chiusura_scura.svg" (click)="closeBanner()">
    </div>
    

    <p class="neutra2_bold text">{{ 'ATLANTE_APP_BANNER.TEXT_1' | translate }}<br>{{ 'ATLANTE_APP_BANNER.TEXT_2' | translate }}:</p>

    <div class="badge">
        <a class="badge--wrap--appstore" target="_blank" href="https://apps.apple.com/it/app/prometeia-atlante/id1458111287" *ngIf="isIphone">
            <input class="badge--appstore" type="image" src="{{ 'BADGE.APP_STORE' | translate }}" (click)="closeBanner()" />
        </a>
        <a class="badge--wrap--googleplay" target="_blank" href="https://play.google.com/store/apps/details?id=it.prometeia.atlante&hl={{translate.currentLang}}" *ngIf="isAndroid">
            <input class="badge--googleplay" type="image" src="{{ 'BADGE.GOOGLE_PLAY' | translate }}" (click)="closeBanner()" />
        </a>
    </div>
</div>