<div 
    class="single-trending-topics type-{{data.type}} {{styleNews[data.type].colorBorder}}" 
    data-aos="fade-up"
    [ngClass]="{
            'border': styleNews[data.type].hasBorder,  
            'category-top' : styleNews[data.type].categoryTop,
            'img-right': styleNews[data.type].imgRight,
            'img-bg': styleNews[data.type].imgBg
        }"     
    >

    <ng-container>
        <a class="single-trending-topics--bg img-zoom" *ngIf="data.type == 'video' || data.type == 'podcast'" href="{{data.permalink}}" target="_blank">
            <div [ngClass]="{'single-trending-topics--bg--filter' : styleNews[data.type].classFilterImg }"></div>
            <img class="single-trending-topics--bg--img " src="{{ !styleNews[data.type].viewSrcImgDefault ? data.heroImage : imgNewsDefault[data.type]}}">
        </a>

        <a class="single-trending-topics--bg img-zoom" *ngIf="data.type != 'video' && data.type != 'podcast'" [routerLink]="['/', translate.currentLang, route, getSlug()]">
            <div [ngClass]="{'single-trending-topics--bg--filter' : styleNews[data.type].classFilterImg }"></div>
            <img class="single-trending-topics--bg--img " src="{{ !styleNews[data.type].viewSrcImgDefault ? data.heroImage : imgNewsDefault[data.type]}}">
        </a>
    </ng-container>

    <ng-container>
        <a class="single-trending-topics--content section-max-content" *ngIf="data.type == 'video' || data.type == 'podcast'" href="{{data.permalink}}">
            <img class="single-trending-topics--content--play" *ngIf="data.type == 'video'" src="../../../assets/icons-svg/video-player-player.svg">       
            <div class="single-trending-topics--content--wrap" >
                <div *ngIf="styleNews[data.type].viewTypeNews" class="single-trending-topics--content--wrap--type">
                    <span class="icon-{{data.type}} static-icon"><span class="static-icon--text">{{data.type}}</span></span>
                </div>
                <span class="single-trending-topics--content--wrap--category">{{category}}</span>
                {{data.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}
            </div> 
            <h2 class="single-trending-topics--content--title"><span class="title--highlight" >{{data.title}}</span></h2>                          
        </a> 

        <a class="single-trending-topics--content section-max-content" *ngIf="data.type != 'video' && data.type != 'podcast'" [routerLink]="['/', translate.currentLang, route, getSlug()]">
            <div class="single-trending-topics--content--wrap" >
                <div *ngIf="styleNews[data.type].viewTypeNews" class="single-trending-topics--content--wrap--type">
                    <span class="icon-{{data.type}} static-icon"><span class="static-icon--text">{{data.type}}</span></span>
                </div>
                <span class="single-trending-topics--content--wrap--category">{{category}}</span>
                {{data.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}
            </div> 
            <h2 class="single-trending-topics--content--title"><span class="title--highlight" >{{data.title}}</span></h2>                          
        </a> 
    </ng-container>

    <a class="neutra2_bookItalic single-trending-topics--content--all" *ngIf="styleNews[data.type].hasAll" href="{{'/'+archiveRoute+'/'+data.type}}" target="_blank">All {{data.type}}<span *ngIf="data.type != 'dataviz'">s</span></a>  
</div>