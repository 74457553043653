<nav [ngClass]="{'side-navbar': true, 'hidden': isOnLastSection && !forceShowOnLastSection}">
    <ul class="anchors">
        <ng-container *ngFor="let li of data; let isFirst = first">
            <li class="anchors--item {{bulletColor}}-bullet" [ngClass]="{'isActive2': li.anchorId == lastViewed}" [spyOn]="li.anchorId" activeClass="isActive"
                *ngIf="!isFirst && li.anchorId && li.type != 'last' && li.title?.length > 0 && (
                    li.text || 
                    li.carousel || 
                    li.list || 
                    li.offices || 
                    li.subType == 'cards' || 
                    li.subType == 'multi-text' || 
                    li.type == 'contacts-people' || 
                    li.type == 'news-frontpage' ||
                    (li.type =='special' && li.subType == 'grid')
                )">
                <a  [spyOn]="li.anchorId" (click)="onClickHref(li.anchorId)"
                    [ngClass]="{'single-link prom-caption___lato-regular interactive': true, 
                                'anchors-on-intro': isOnIntroSection, 
                                'page-scrolling': pageScrolling && !isOnIntroSection,
                                'anchors-on-last': isOnLastSection}">
                        {{li.title}}
                </a>
            </li>
        </ng-container>
    </ul>
</nav>