<!-- Anteo Archive & Information Paths -->
<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>

<div class="single-trending-topics-archive prom-page theme--{{typeNews}}" *ngIf="data">
    <ng-container>
        <div class="single-trending-topics-archive--content">
            <div class="single-trending-topics-archive--header">
                <h2 class="prom-section text--primary static-icon--text">
                    <span class="highlighter highlighter--red capslock">
                        {{data.pageTitle}}
                    </span>
                </h2>

                <div class="searchbar" *ngIf="data.search">
                    <app-search-bar [data]="data.search" [useDarkerBG]="true" (filterChange)="filter($event)" (filterReset)="filterResetEvent()"></app-search-bar>
                </div>
            </div>

            <ng-container *ngIf="isAnteo">
                <div *ngFor="let archivies of filteredNewsList; let isLast = last" [ngClass]="{'single-trending-topics-archive--item': archivies.slug?.includes('__'), 'last-item': isLast}">
                    <div class="single-trending-topics-archive--item-text" *ngIf="archivies.slug?.includes('__')">
                        <span class="prom-caption___lato-regular text--red single-trending-topics-archive--item-category" *ngIf="archivies.pubDate">{{capitolize(archivies.pubDate | date:"MMMM yyyy" : '' : translate.currentLang)}}</span>
                        <span class="prom-caption___lato-regular text--red single-trending-topics-archive--item-category" *ngIf="!archivies.pubDate && archivies.date">{{archivies.date}}</span>
                        
                        <a class="single-trending-topics-archive--item-title" [routerLink]="['/', translate.currentLang, route, getAnteoNumber(archivies.issueNumber)]">
                            <ng-container *ngIf="archivies.pageTitle">
                                {{archivies.pageTitle}} {{archivies.issueNumber}}
                            </ng-container>
                            <ng-container *ngIf="archivies.title && !archivies.pageTitle">
                                {{archivies.title}}
                            </ng-container>
                        </a>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!isAnteo">
                <div *ngFor="let archivies of filteredNewsList; let isLast = last">
                    <div *ngIf="archivies.sections" [ngClass]="{'single-trending-topics-archive--item': true, 'last-item': isLast}">
                        <div class="single-trending-topics-archive--item-text">
                            <span class="prom-caption___lato-regular text--red single-trending-topics-archive--item-category">{{getNewsCategory(archivies.sections[0])}}</span>
                            <a class="single-trending-topics-archive--item-title" [routerLink]="['/', translate.currentLang, route, getNewsID(archivies)]">{{archivies.sections[0].title}}</a>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- <div class="buttonLoadMore">
                <app-prometeia-button class="buttonLoadMore--btn interactive" [data]="buttonLoadMore" (click)="loadMore()"></app-prometeia-button>
            </div> -->
        </div>

        <div class="more-more">
            <p class="prom-caption___lato-regular">{{ 'OTHER_PAST_EDITIONS' | translate }}:
                <a class="prom-caption___lato-regular text--primary" href="mailto:media@prometeia.com">media@prometeia.com</a>
            </p>
        </div>
    </ng-container>
</div>
