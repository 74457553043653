import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ScrollSpyService } from 'ng-spy';
import { HighlightColor, IBaseSection } from '../../models/commons';
import { Subscription } from 'rxjs';
import { ComponentsServices } from '../../services/components.service';
import { Router } from '@angular/router';

const isOnIntroSectionClass = "anchors-on-intro";

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit, OnDestroy {

  @Input() data: IBaseSection[];
  @Input() bulletColor: HighlightColor = 'accent';
  @Input() forceShowOnLastSection: boolean = false;

  // li_class: string = "anchors--item";
  isOnIntroSection = false;
  isOnLastSection = false;

  lastViewed: string = "intro";

  pageScrolling: boolean = false;

  private spyServiceSubscription: Subscription;
  private componentsServicesSubscription: Subscription;

  constructor(private spyService: ScrollSpyService, private componentsServices: ComponentsServices, private router:Router) {
  }
  
  ngOnInit(): void {
    // console.log("side-navbar", this.data);

    if (window.scrollY < this.componentsServices.getTopSectionH())
      this.isOnIntroSection = true;

    this.spyServiceSubscription?.unsubscribe();
    this.spyServiceSubscription = this.spyService.activeSpyTarget.subscribe(
      (activeTargetName: string) => {
        // console.log("activeTargetName", activeTargetName, this.lastViewed);

        if (activeTargetName == null) {
          if (this.lastViewed == 'intro' || this.lastViewed == this.data[1].anchorId) {
            this.lastViewed = 'intro';
          } 
        } else {
          this.lastViewed = activeTargetName;
        }
        
      }
    );

    this.componentsServicesSubscription?.unsubscribe();
    this.componentsServicesSubscription = this.componentsServices.pageScroll.subscribe((value: {newY: number}) => {
      this.pageScrolling = true;

      if (value.newY < this.componentsServices.getTopSectionH()) {
        this.isOnIntroSection = true;
        this.isOnLastSection = false;
      } else if (value.newY > this.componentsServices.getBottomSectionH()) {
        this.isOnIntroSection = false;
        this.isOnLastSection = true;
      } else {
        this.isOnIntroSection = false;
        this.isOnLastSection = false;
      }

      setTimeout(() => {     
        this.pageScrolling = false;
      }, 300);
    });
  }

  ngOnDestroy() {
    this.spyServiceSubscription?.unsubscribe();
    this.componentsServicesSubscription?.unsubscribe();
  }

  onClickHref(anchorId:string){
    this.router.navigate([], {fragment:anchorId, replaceUrl:true});
  }

}
