import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IBaseSection } from 'src/app/models/commons';
import { ISocial } from 'src/app/models/components.interface';
import { IOffer } from 'src/app/models/pages.interface';
import { paths } from 'src/app/models/paths';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent extends BasePageComponent<IOffer> {

  allOffersPath = paths.jobOffer;
  classBtn: string = '';
  dataServicesSubscription: Subscription = null;

  socials_list: ISocial[] = [
    { type: "linkedin", link: "https://linkedin.com" },
    { type: "twitter", link: "https://twitter.com" },
    { type: "facebook", link: "https://facebook.com" },
  ]

  constructor(
    public aRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dataServicesSubscription?.unsubscribe();
    this.dataServicesSubscription = this.dataServices.pageDataReady.subscribe(() => {
      if (this.data) {
        this.loadPage();
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const numb = window.scrollY;
    if (numb >= 50) {
      this.classBtn = 'show-btn';
    }
    else {
      this.classBtn = '';
    }

  }

  async loadPage() {
    const jobOffersList = await this.api.career.getJobOffersList();
    const lastSection = this.data.sections[this.data.sections.length - 1];
    this.data.sections = jobOffersList.offers.slice(0, 6 + 1); //returns the displayed offer itself (so the "+1" for displaying 6 offers)
    this.data.sections.push(lastSection);

    const jobs = await this.api.career.getJobOffersContent(this.aRoute.snapshot.params.id);
    if (jobs.length > 0) {
      this.data.job = jobs[0];
      this.data.btn.link = jobs[0].permalink;
    }
  }

  ngOnDestroy(): void {
    this.dataServicesSubscription?.unsubscribe();
    this.dataServicesSubscription = null;
  }

}
