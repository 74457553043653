import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { VideoService } from '../../services/video.service';
import { HighlightColor, IMedia } from '../../models/commons';
import { ISectionAccordionList } from '../../models/components.interface';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'app-section-accordion-list',
  templateUrl: './section-accordion-list.component.html',
  styleUrls: ['./section-accordion-list.component.scss']
})
export class SectionAccordionListComponent implements OnInit {

  @Input() data: ISectionAccordionList;
  @Input() highlightColor: HighlightColor;
  @Input() isSidenavbarActive: boolean = false;
  @Input() noPaddingBottom: boolean = false;
  @Input() shortPaddingTop: boolean = false;

  somethingIsOpen: boolean = false;
  lastOpened: number = null;

  openVideoSubject: Subject<boolean> = new Subject<boolean>();

  public marketingCookieEnabled: boolean = false;
  
  constructor(public translate: TranslateService, private videoService: VideoService, private cookieServices: CookieService) { }

  ngOnInit(): void {
    this.marketingCookieEnabled = this.cookieServices.isMarketingEnabled();

    this.data.list.forEach((listElement) => {
      listElement.isOpen = false;
    });
  }

  toggleContentOpen(item, index: number) {
    // console.log(item.title, "pressed in section-accordion-list");

    const b: boolean = item.isOpen || false;
    // console.log("was", b);

    if (this.lastOpened != null) {
      this.data.list[this.lastOpened].isOpen = false;
      // console.log("closing", this.lastOpened);
    }
    

    this.somethingIsOpen = false;
    if (!b) {
      item.isOpen = this.somethingIsOpen = true;
      this.lastOpened = index;
      // console.log("now is open", item.isOpen, index);
    }
  }

  openVideo(media: IMedia) {
    //this.videoService.playThisVideo(media);
    if (media.id && !media.link) window.location.href = "https://www.youtube.com/embed/" + media.id;
    else if (media.link) window.location.href = media.link;
  }

}
