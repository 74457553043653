import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IButtonLink } from 'src/app/models/commons';
import { paths } from 'src/app/models/paths';
import { IOfficesList } from '../../models/components.interface';

@Component({
  selector: 'app-dropdown-panel',
  templateUrl: './dropdown-panel.component.html',
  styleUrls: ['./dropdown-panel.component.scss']
})
export class DropdownPanelComponent implements OnInit, OnDestroy {

  cityRecap_button: IButtonLink = {
    type: "button",
    theme: 'btn_color__green',
    route: paths.countryPage,
    label: "SHOW_MORE",
    id: "",
    _meta: {
      translate: true
    }
  }

  @Input() data: IOfficesList;

  panelOpenState: boolean;
  private changeLangSubscription: Subscription = null;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.cityRecap_button.id = this.data.id;
    
    this.changeLangSubscription?.unsubscribe();
    this.changeLangSubscription = this.translate.onLangChange.subscribe(() => {
      this.cityRecap_button.label = this.translate.instant("SHOW_MORE");
    });
  }
  
  ngOnDestroy(): void {
    this.changeLangSubscription?.unsubscribe();
    this.changeLangSubscription = null;
  }

}
