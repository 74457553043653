import { EventEmitter, Injectable } from '@angular/core';
import { IMedia } from '../models/commons';
import { CookieService } from './cookie.service';

@Injectable({
    providedIn: 'root',
})
export class VideoService {
    public playVideoRequest: EventEmitter<IMedia> = new EventEmitter();
    videoSource: IMedia;
    
    constructor(private cookieServices: CookieService) { }

    public playThisVideo(src: IMedia) {
        if (this.cookieServices.isMarketingEnabled()) {
            this.videoSource = src;
            this.playVideoRequest.emit(src);
        }
    }
}