import { Component } from '@angular/core';
import { ISocial } from '../../models/components.interface';
import { ITrendingTopicsArticle } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';
import { paths } from '../../models/paths';
import { ComponentsServices } from 'src/app/services/components.service';
import { ActivatedRoute } from '@angular/router';
import { IButtonLink, Locale } from 'src/app/models/commons';
import { BrowserServices } from 'src/app/services/browser.service';
@Component({
  selector: 'app-trending-topics-article',
  templateUrl: './trending-topics-article.component.html',
  styleUrls: ['./trending-topics-article.component.scss']
})
export class TrendingTopicsArticleComponent extends BasePageComponent<ITrendingTopicsArticle> {

  route: string = paths.trendingTopicsArticle;

  socials_list: ISocial[] = [
    { type: "linkedin", link: "https://linkedin.com" },
    { type: "twitter", link: "https://twitter.com" },
    { type: "facebook", link: "https://facebook.com" },
  ];

  topOnScroll: string = "35px";
  heightOnScroll: string = 'calc(100vh - 116px)';
  loadDate: boolean = false;

  deviceType: string;

  // tableauVizFound: boolean = false;
  // hoverOverTableau: boolean = false;

  constructor(
    public componentsService: ComponentsServices,
    public aRoute: ActivatedRoute,
    public browserServices: BrowserServices
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.deviceType = this.browserServices.myDevice();

    this.dataServices.pageDataReady.subscribe(() => {
      // console.log("TrendingTopicsArticleComponent", this.data);
      if (this.data) {
        this.data.news = null;
        this.loadPage();
      }
    });

    this.componentsService.pageScrollUp.subscribe(() => {
      this.topOnScroll = "115px";
      this.heightOnScroll = 'calc(100vh - 190px)';
    });

    this.componentsService.pageScrollDown.subscribe(() => {
      this.topOnScroll = "35px";
      this.heightOnScroll = 'calc(100vh - 116px)';
    });


  }

  async loadPage() {
    // this.tableauVizFound = false;
    this.loadDate = false;
    try {
      const id = this.aRoute.snapshot.params.id;
      const news = await this.api.insights.getTrendingTopicsContent(id);

      const arr: string[] = news.permalink.split("/");
      const inID: string = /* this.data.news.slug ? this.data.news.slug : */ arr[arr.length - 1];
      //metodo alternativo: const inID: string = this.data.news.permalink.substr(this.data.news.permalink.lastIndexOf('/')+1);

      // console.log(id, inID, id != inID);

      if (id != inID) {
        this.router.navigate(['/', this.translate.currentLang, paths.trendingTopicsArticle, inID], { replaceUrl: true });
      } else {
        this.data.news = news;

        if (this.data.news.type == 'tableau') { 
          this.data.news.type = 'dataviz';
  
          // if (this.data.news.body.includes("<iframe") && this.data.news.body.includes("class=\"tableauViz\"")) {
          //   // console.log("tableauViz found!");
          //   this.tableauVizFound = true;
          // }
        }
  
        if (this.data.news) {
          this.loadDate = true;
          this.data.news.related = this.sort.byPubDate_newer(this.data.news.related);
        }
      }
    }
    catch (error) {
      this.data.news = null;
      this.loadDate = true;
    }
  }

  // hoverOverTableau_in(event: any) {
  //   // console.log("IN", event);
  //   // if (event?.path[0].nodeName.toLowerCase() == "iframe" && event?.path[0].className.toLowerCase() == "tableauviz") {
  //     this.hoverOverTableau = true;
  //     // console.log("ok", event?.path[0].nodeName, event?.path[0].className);
  //   // }
  // }

  // hoverOverTableau_out(event: MouseEvent) {
  //   // console.log("OUT", event);
  //   this.hoverOverTableau = false;
  // }

  beautifyCategory(cat: string) {
    return cat.replace(/_/g, " ");
  }

  getRelatedArticleCategory(n: { category: string }) {
    if (this.translate.currentLang == Locale.en) {
      switch (n.category.toLowerCase()) {
        case "Banche".toLowerCase():
          return "Banking";

        case "Regolamentazione & Mercati".toLowerCase():
          return "Regulation & Markets";

        case "Scenari globali".toLowerCase():
          return "Global scenarios";

        case "Scenari Italia".toLowerCase():
          return "Italian scenarios";

        case "Wealth & Asset Management".toLowerCase():
          return "Wealth & Asset Management";

        default:
          return n.category;
      }
    } else {
      return n.category;
    }
  }

  scrollToTop() {
    this.componentsServices.scrollToTop();
  }


  download(_link: string): IButtonLink {
    return {
      type: 'button',
      label: 'DOWNLOAD',
      _meta: {
        isDownloadable: true,
      },
      theme: 'btn_color__green',
      link: _link
    }
  }

}
