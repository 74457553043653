<ng-container>
    <div class="prom-page alumni-unsubscribe">
        <div class="inner-html text">
            <p>
                {{ 'ALUMNI_REGISTRATION.ALUMNI_UNSUBSCRIBE__TEXT1' | translate }} 
                <b>{{ 'ALUMNI_REGISTRATION.ALUMNI_UNSUBSCRIBE__NEWSLETTER_TITLE' | translate }}</b>. 
                {{ 'ALUMNI_REGISTRATION.ALUMNI_UNSUBSCRIBE__TEXT2' | translate }}.
            </p>
        </div>
        
        <div class="form-container" *ngIf="!unsubscribed">
            <form #alumniUnsubForm="ngForm" (ngSubmit)="submit(alumniUnsubForm)">
                <div class="form-group">
                    <label class="label" for="email">Email</label>
                    <input [ngClass]="{'input': true}" name="email" [(ngModel)]="account.email" type="email" required>
                </div>
                
    
                <div class="buttons">
                    <button class="interactive button" type="submit" status="success">
                        {{ 'UNSUBSCRIBE' | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="continue" *ngIf="unsubscribed">
            <p>{{ 'ALUMNI_REGISTRATION.TASK_COMPLETED' | translate }}</p>
            <a routerLink="/" class="text--primary">{{ 'ALUMNI_REGISTRATION.GO_HOME' | translate }}</a>
        </div>

        <!-- <div class="continue"><p><a class="text--primary no-underline" href="/">Prosegui la navigazione</a></p></div> -->
    </div>
    
</ng-container>

<!-- <p class="spyer" style="color:aqua">{{app_code}} - {{uid}} - {{email}}</p> -->