import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RestClientRoutesService {

  private static readonly routes = new Map<string, RestClientRouteConfigurationItem>([
    ['/page/home', { remote: '/page/home', useMock: false }],
    ['/page/404', { remote: '/page/404', useMock: false }],

    ['/page/about-us', { remote: '/page/about-us', useMock: false }],
    ['/page/our-team', { remote: '/page/our-team', useMock: false }],
    ['/page/social-responsibility', { remote: '/page/social-responsibility', useMock: false }],
    ['/page/prom-hq', { remote: '/page/prom-hq', useMock: false }],
    ['/page/our-network', { remote: '/page/our-network', useMock: false }],
    // ['/page/country-page', {remote: '/page/country-page', useMock: false}],
    ['/page/our-partnership', { remote: '/page/our-partnership', useMock: false }],

    ['/page/our-culture', { remote: '/page/our-culture', useMock: false }],
    ['/page/master-corsi-laurea', { remote: '/page/master-corsi-laurea', useMock: false }],

    ['/page/risk-planning-control', { remote: '/page/risk-planning-control', useMock: false }],
    ['/page/financial-distribution', { remote: '/page/financial-distribution', useMock: false }],
    ['/page/strategic-advisory', { remote: '/page/strategic-advisory', useMock: false }],
    ['/page/tech-solutions', { remote: '/page/tech-solutions', useMock: false }],
    ['/page/saas-cloud', { remote: '/page/saas-cloud', useMock: false }],
      ['/page/prometeia-and-aws', { remote: '/page/prometeia-and-aws', useMock: false }],
      ['/page/prometeia-and-ms-azure', { remote: '/page/prometeia-and-ms-azure', useMock: false }],
    ['/page/applied-data-science-and-advanced-analytics', { remote: '/page/applied-data-science-and-advanced-analytics', useMock: false }],
    ['/page/risk-tech', { remote: '/page/risk-tech', useMock: false }],
    ['/page/wealth-tech', { remote: '/page/wealth-tech', useMock: false }],

    ['/page/financial-advisory', { remote: '/page/financial-advisory', useMock: false }],
    ['/page/anteo', { remote: '/page/anteo', useMock: false }],
    ['/page/anteo-archive', { remote: '/page/anteo-archive', useMock: false }],
    ['/page/anteo-article', { remote: '/page/anteo-article', useMock: false }],
    // ['/page/financial-advisory-consultancy', {remote: '/page/financial-advisory-consultancy', useMock: false}],
    ['/page/societary-organs', { remote: '/page/societary-organs', useMock: false }],
    ['/page/information-paths', { remote: '/page/information-paths', useMock: false }],
    ['/page/information-paths-archive', { remote: '/page/information-paths-archive', useMock: false }],
    ['/page/research-bi-reporting', { remote: '/page/research-bi-reporting', useMock: false }],
    ['/page/our-story', { remote: '/page/our-story', useMock: false }],
    ['/page/data-science', { remote: '/page/data-science', useMock: false }],

    ['/page/news', { remote: '/page/news', useMock: false }],
    ['/page/news-article', { remote: '/page/news-article', useMock: false }],
    ['/page/newsletter', { remote: '/page/newsletter', useMock: false }],
    ['/page/trending-topics', { remote: '/page/trending-topics', useMock: false }],
    ['/page/trending-topics-article', { remote: '/page/trending-topics-article', useMock: false }],
    ['/page/trending-topics-archive', { remote: '/page/trending-topics-archive', useMock: false }],

    ['/page/careers', { remote: '/page/careers', useMock: false }],

    ['/page/job-offers', { remote: '/page/job-offers', useMock: false }],
    ['/page/offer', { remote: '/page/offer', useMock: false }],

    ['/page/alumni', { remote: '/page/alumni', useMock: false }],
    // ['/page/alumni-stories', {remote: '/page/alumni-stories', useMock: false}],
    ['/page/prom-life', { remote: '/page/prom-life', useMock: false }],

    ['/page/reserved-area', { remote: '/page/reserved-area', useMock: false }],


    /** COMPONENTS */
    ['/page/footer', { remote: '/page/footer', useMock: false }],
    ['/page/footerSIM', { remote: '/page/footerSIM', useMock: false }],


    /** with IDs */
    ['/page/article__company-information', { remote: '/page/article__company-information', useMock: false }],
    ['/page/article__cookie', { remote: '/page/article__cookie', useMock: false }],
    ['/page/article__ethics', { remote: '/page/article__ethics', useMock: false }],

    ['/page/article__privacy-policy', { remote: '/page/article__privacy-policy', useMock: false }],
    ['/page/article__web-site-information', { remote: '/page/article__web-site-information', useMock: false }],
    ['/page/article__newsletter-information', { remote: '/page/article__newsletter-information', useMock: false }],
    ['/page/article__terms-of-use', { remote: '/page/article__terms-of-use', useMock: false }],
    ['/page/article__publishing', { remote: '/page/article__publishing', useMock: false }],
    ['/page/article__send-cv', { remote: '/page/article__send-cv', useMock: false }],
    ['/page/article__app-atlante', { remote: '/page/article__app-atlante', useMock: false }],
    ['/page/article__app-career', { remote: '/page/article__app-career', useMock: false }],
    ['/page/article__quality', { remote: '/page/article__quality', useMock: false }],

    ['/page/article__privacy-policy-sim', { remote: '/page/article__privacy-policy-sim', useMock: false }],
    ['/page/article__general-information', { remote: '/page/article__general-information', useMock: false }],
    ['/page/article__anteo-newsletter', { remote: '/page/article__anteo-newsletter', useMock: false }],
    ['/page/article__send-cv-sim', { remote: '/page/article__send-cv-sim', useMock: false }],
    ['/page/article__whistleblowing', { remote: '/page/article__whistleblowing', useMock: false }],


    ['/page/financial-advisory-consultancy__performance-and-risk-analysis', { remote: '/page/financial-advisory-consultancy__performance-and-risk-analysis', useMock: false }],
    ['/page/financial-advisory-consultancy__strategic-asset-allocation', { remote: '/page/financial-advisory-consultancy__strategic-asset-allocation', useMock: false }],
    ['/page/financial-advisory-consultancy__tactical-asset-allocation', { remote: '/page/financial-advisory-consultancy__tactical-asset-allocation', useMock: false }],
    ['/page/financial-advisory-consultancy__alm', { remote: '/page/financial-advisory-consultancy__alm', useMock: false }],
    ['/page/financial-advisory-consultancy__selection-process', { remote: '/page/financial-advisory-consultancy__selection-process', useMock: false }],
    ['/page/financial-advisory-consultancy__risk-management', { remote: '/page/financial-advisory-consultancy__risk-management', useMock: false }],
    ['/page/financial-advisory-consultancy__investment-process-engineering', { remote: '/page/financial-advisory-consultancy__investment-process-engineering', useMock: false }],
    ['/page/financial-advisory-consultancy__order-routing', { remote: '/page/financial-advisory-consultancy__order-routing', useMock: false }],
    ['/page/financial-advisory-consultancy__sustainability', { remote: '/page/financial-advisory-consultancy__sustainability', useMock: false }],
    ['/page/financial-advisory-consultancy__ombudsman', { remote: '/page/financial-advisory-consultancy__ombudsman', useMock: false }],


    ['/page/discover-more__prometeia_delivery_approach', { remote: '/page/discover-more__prometeia_delivery_approach', useMock: false }],
    ['/page/discover-more__prometeia_data_suite', { remote: '/page/discover-more__prometeia_data_suite', useMock: false }],
    ['/page/discover-more__prometeia_risk_suite', { remote: '/page/discover-more__prometeia_risk_suite', useMock: false }],
    ['/page/discover-more__prometeia_modeling_platform', { remote: '/page/discover-more__prometeia_modeling_platform', useMock: false }],
    ['/page/discover-more__prometeia_credit_decision_suite', { remote: '/page/discover-more__prometeia_credit_decision_suite', useMock: false }],
    ['/page/discover-more__prometeia_wealthtech_solutions', { remote: '/page/discover-more__prometeia_wealthtech_solutions', useMock: false }],
    ['/page/discover-more__applied_data_science_advanced_analytics', { remote: '/page/discover-more__applied_data_science_advanced_analytics', useMock: false }],

    //branch
    ['/page/country-page__cairo', { remote: '/page/country-page__cairo', useMock: false }],
    ['/page/country-page__istanbul', { remote: '/page/country-page__istanbul', useMock: false }],
    ['/page/country-page__london', { remote: '/page/country-page__london', useMock: false }],
    ['/page/country-page__moscow', { remote: '/page/country-page__moscow', useMock: false }],
    ['/page/country-page__zurich', { remote: '/page/country-page__zurich', useMock: false }],
    //partner
    ['/page/country-page__amman', { remote: '/page/country-page__amman', useMock: false }],
    ['/page/country-page__athens', { remote: '/page/country-page__athens', useMock: false }],
    ['/page/country-page__kuwait-city', { remote: '/page/country-page__kuwait-city', useMock: false }],
    ['/page/country-page__lagos', { remote: '/page/country-page__lagos', useMock: false }],
    ['/page/country-page__lome', { remote: '/page/country-page__lome', useMock: false }],
    ['/page/country-page__madrid', { remote: '/page/country-page__madrid', useMock: false }],
    ['/page/country-page__paris', { remote: '/page/country-page__paris', useMock: false }],
    ['/page/country-page__stockholm', { remote: '/page/country-page__stockholm', useMock: false }],
    ['/page/country-page__tunis', { remote: '/page/country-page__tunis', useMock: false }],
    ['/page/country-page__vienna', { remote: '/page/country-page__vienna', useMock: false }],
    ['/page/country-page__dubai', { remote: '/page/country-page__dubai', useMock: false }],


    ['/page/alumni-stories__intervista-jessica-russo', { remote: '/page/alumni-stories__intervista-jessica-russo', useMock: false }],
    ['/page/alumni-stories__intervista-sonia-grieco', { remote: '/page/alumni-stories__intervista-sonia-grieco', useMock: false }],
    ['/page/alumni-stories__intervista-francesco-santoro', { remote: '/page/alumni-stories__intervista-francesco-santoro', useMock: false }],
    ['/page/alumni-stories__intervista-niccolo-diarena', { remote: '/page/alumni-stories__intervista-niccolo-diarena', useMock: false }],
    ['/page/alumni-stories__intervista-marco-la-tassa', { remote: '/page/alumni-stories__intervista-marco-la-tassa', useMock: false }],

    //percorsi informazione
    ['/page/information-paths__informazione2018', { remote: '/page/information-paths__informazione2018', useMock: false }],
    ['/page/information-paths__informazione2017', { remote: '/page/information-paths__informazione2017', useMock: false }],
    ['/page/information-paths__informazione2016', { remote: '/page/information-paths__informazione2016', useMock: false }],
    ['/page/information-paths__informazione2015', { remote: '/page/information-paths__informazione2015', useMock: false }],
    ['/page/information-paths__informazione2014', { remote: '/page/information-paths__informazione2014', useMock: false }],
    ['/page/information-paths__informazione2013', { remote: '/page/information-paths__informazione2013', useMock: false }],
    ['/page/information-paths__informazione2012', { remote: '/page/information-paths__informazione2012', useMock: false }],
    ['/page/information-paths__informazione2011', { remote: '/page/information-paths__informazione2011', useMock: false }],
    ['/page/information-paths__informazione2010', { remote: '/page/information-paths__informazione2010', useMock: false }],
    ['/page/information-paths__informazione2009', { remote: '/page/information-paths__informazione2009', useMock: false }],
    ['/page/information-paths__informazione2008', { remote: '/page/information-paths__informazione2008', useMock: false }],
    ['/page/information-paths__informazione2007', { remote: '/page/information-paths__informazione2007', useMock: false }],
    ['/page/information-paths__informazione2006', { remote: '/page/information-paths__informazione2006', useMock: false }],
    ['/page/information-paths__informazione2005', { remote: '/page/information-paths__informazione2005', useMock: false }],

    /*
        ['/notifications/subscribe', {remote: '/bin/acea-services/push/subscribe', useMock: true, method: 'POST'}],
        ['/notifications/sap', {remote: '/bin/acea-services/notification', useMock: true}],
    
        ['/notifications/categories', {remote: '/bin/acea-services/categories-notification', useMock: true}],
        ['/notifications/subscription', {remote: '/bin/acea-services/categories-notification', useMock: true, method: 'POST-FORM'}],
        ['/notifications/set_status', {remote: '/bin/acea-services/notification', useMock: true, method: 'PUT'}],
        ['/notifications/set_read', {remote: '/bin/acea-services/notification', useMock: true, method: 'POST'}],
    
        ['/comunicazione/comunicazione', {remote: '/content/areti-app/hp/comunicazione.model.json', useMock: true}],
        ['/comunicazione/news', {remote: '/bin/acea-services/news', useMock: true, method: 'POST'}],
        ['/comunicazione/biblioteca', {remote: '/bin/acea-services/library', useMock: true, method: 'POST'}],
        ['/comunicazione/like', {remote: '/bin/acea-services/like', useMock: true, method: 'POST'}],
        ['/comunicazione/dislike', {remote: '/bin/acea-services/like', useMock: true, method: 'DELETE'}],
    
        ['/contattaci', {remote: '/content/areti-app/hp/contattaci/jcr:content/home-par/app_contact.model.infinity.json', useMock: true}],
    
        ['/document_center/categories', {remote: '/bin/acea-services/categories-document-center', useMock: true, method: 'GET'}],
        ['/document_center/list', {remote: '/bin/acea-services/document', useMock: true, method: 'POST'}],
        ['/document_center/search', {remote: '/bin/acea-services/document', useMock: true, method: 'POST'}],
    
        ['/rubrica/search', {remote: '/bin/acea-services/rubrica', useMock: false, method: 'POST-FORM'}],
        ['/rubrica/aziende', {remote: '/bin/acea-services/companies', useMock: false, method: 'POST'}],
    
        ['/selfcare', {remote: '/content/areti-app/hp/selfcare.model.infinity.json', useMock: true}],
        ['/selfcare/ferie-solidali', {remote: '/content/areti-app/hp/selfcare/cessione-ferie-solidali/jcr:content/home-par/selfcare_dynamic_for.model.infinity.json', useMock: true}],
        ['/selfcare/servizio-stipendio', {remote: '/content/areti-app/hp/selfcare/certificato-servizio-stipendio/jcr:content/home-par/selfcare_dynamic_for.model.infinity.json', useMock: true}],
        ['/selfcare/competenze', {remote: '/content/areti-app/hp/selfcare/regolarizzazione-competenze/jcr:content/home-par/selfcare_dynamic_for.model.infinity.json', useMock: true}],
        ['/selfcare/chilometrico', {remote: '/content/areti-app/hp/selfcare/rimborso-km/jcr:content/home-par/selfcare_dynamic_for.model.infinity.json', useMock: true}],
        ['/selfcare/malattia', {remote: '/content/areti-app/hp/selfcare/comunicazione-malattia/jcr:content/home-par/selfcare_dynamic_for.model.infinity.json', useMock: true}],
        ['/selfcare/certificato-malattia', {remote: '/content/areti-app/hp/selfcare/comunicazione-certificato-malattia/jcr:content/home-par/selfcare_dynamic_for.model.infinity.json', useMock: true}],
        ['/selfcare/regolarizzazione-ferie', {remote: '/content/areti-app/hp/selfcare/regolarizzazione-ferie/jcr:content/home-par/selfcare_dynamic_for.model.infinity.json', useMock: true}],
        ['/selfcare/submit', {remote: '/bin/acea-services/form/submit', useMock: false, method: 'POST-FILE'}]
        */
  ]);

  get(key: string) {
    return RestClientRoutesService.routes.get(key);
  }

  constructor() { }
}

export interface RestClientRouteConfigurationItem {
  /**
   * Default: GET
   */
  method?: 'GET' | 'POST';
  /**
   * Url remoto
   */
  remote: string;
  /**
   * Se 'true' restituisce il contenuto del file locale
   */
  useMock?: boolean;
}
