import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EXTRA_LANGS, HighlightColor, IBasePage, IRouteDataOptions, Locale } from '../models/commons';
import { slugs } from '../models/slugs';
import { ApiService } from '../services/api.service';
import { AppInjector } from '../services/app-injector.service';
import { ComponentsServices } from '../services/components.service';
import { HeaderData, HeaderDataService } from '../services/header-data.service';
import { DataServices } from '../services/data.service';
import { flattenedID, oldPermalinksENG, oldPermalinksITA, paths, separator, simTree } from '../models/paths';
import { LangServices } from '../services/lang.service';
import { SortingServices } from '../services/sorting.service';
import { Debounce } from 'angular-debounce-throttle';
import { CacheService } from '../services/cache.service';


@Injectable()
export abstract class BasePageComponent<T extends IBasePage> implements OnInit, OnDestroy {
  private changeLangSubscription: Subscription = null;
  private activatedRouteSubscription: Subscription = null;
  private activatedRouteParamSubscription: Subscription = null;

  // protected pageDataReady: EventEmitter<T> = new EventEmitter();
  protected api: ApiService;
  public translate: TranslateService;
  protected activatedRoute: ActivatedRoute;
  protected router: Router;
  public componentsServices: ComponentsServices;
  protected dataServices: DataServices;
  protected langServices: LangServices;
  public sort: SortingServices;
  protected cache: CacheService;

  public data: T;
  /** app-routing params - start */
  public headerDataService: HeaderDataService;
  public slug: string;
  public id: string;
  public highlightColor: HighlightColor;
  public forceDarkHeader: boolean;
  public forceTitle: boolean;
  public isAdvisorPage: boolean;
  public showLangSelector: boolean;
  public forceBG: boolean;
  public meta_description: string;
  /** app-routing params - end */

  public notFound: boolean;


  constructor() {
    const injector = AppInjector.getInjector();
    this.api = injector.get(ApiService);
    this.translate = injector.get(TranslateService);
    this.headerDataService = injector.get(HeaderDataService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.componentsServices = injector.get(ComponentsServices);
    this.dataServices = injector.get(DataServices);
    this.langServices = injector.get(LangServices);
    this.sort = injector.get(SortingServices);
    this.cache = injector.get(CacheService);
  }

  ngOnInit(): void {
    // https://medium.com/angular-in-depth/angular-dependency-injection-and-tree-shakeable-tokens-4588a8f70d5d
    this.activatedRouteSubscription?.unsubscribe();
    this.activatedRouteSubscription = this.activatedRoute.firstChild?.data?.subscribe(data => {
      const d = data as IRouteDataOptions;
      if (d?.redirectLang) {
        const r = this.router.url.substring(1);
        const loc = (this.langServices.getDefaultLocalization() || Locale.en);
        // console.log("redirect lang", loc, r);
        if (r.includes("/")) {
          this.translate.use(loc);
          this.router.navigate(['/', loc, r.split("/")[0], r.split("/")[1]], { replaceUrl: true });
        } else {
          this.translate.use(loc);
          this.router.navigate(['/', loc, r], { replaceUrl: true });
        }
      }

      this.slug = d?.slug;
      this.highlightColor = d?.highlightColor || 'accent';
      this.forceDarkHeader = d?.forceDarkHeader || false;
      this.forceTitle = d?.forceTitle || false;
      this.isAdvisorPage = d?.isAdvisorPage || false;
      this.showLangSelector = d?.showLangSelector || false;
      this.forceBG = d?.forceBG || false;
      this.meta_description = d?.meta_description,
      //console.log("BASE PAGE", this.id);
      // console.log("BP >>", d, this.showLangSelector);

      this.checkOldPermalinks();
    });

    this.activatedRouteParamSubscription?.unsubscribe();
    this.activatedRouteParamSubscription = this.activatedRoute.firstChild?.params.subscribe(paramsId => {
      // console.log("act route param", paramsId?.id);
      // if (this.id = paramsId?.id) this.componentsServices.updateID(paramsId?.id);
      this.id = paramsId?.id;
      this.load();
    });

    if (!this.id) this.load();
    this.changeLangSubscription?.unsubscribe();
    this.changeLangSubscription = this.translate.onLangChange.subscribe(() => {
        this.load();
    });

  }

  load() {
    this.loadPageData();
  }

  @Debounce(10)
  protected async loadPageData() {
    if (!this.slug) {
      const wlpn = window.location.pathname.substring(1)
      // console.log(wlpn);
      if (wlpn.startsWith("it/it/") || wlpn.startsWith("en/en/") || wlpn.includes("%2523")) {
        //es: http://localhost:4200/carriere/studenti#formazione
        const nr = wlpn.replace(/it\/it\//g, "it/").replace(/en\/en\//g, "en/").replace(/%2523/g, "#");
        // console.log(wlpn, nr);
        this.router.navigateByUrl("/" + nr);
      }

      console.error('Current page does not have a SLUG. Please check app-routing.module!');
      return;
    }

    if (this.slug != slugs.alumni) {
      this.componentsServices.closeAlumniRegisterOrLoginForm();
    }

    try {
      let page: T;
      if (this.id && this.id != "" && flattenedID.includes(this.slug)) {
        // console.log("BASE PAGE", "requesting", this.slug + separator + this.id);
        // page = this.data = await this.cache.pagesGet(this.slug + separator + this.id);
        /* if (!page) */ page = this.data = await this.api.pages.get(this.slug + separator + this.id);
      } else {
        // console.log("BASE PAGE", "requesting", this.slug, "(no ID)");
        // page = this.data = await this.cache.pagesGet(this.slug);
        /* if (!page) */ page = this.data = await this.api.pages.get(this.slug);
      }

      if (page.forceLocale && EXTRA_LANGS.includes(page.forceLocale)) {
        this.langServices.forceLang(page.forceLocale);
      } else if (page.otherLocale && EXTRA_LANGS.includes(page.otherLocale)) {
        this.langServices.forceLang(page.otherLocale);
      } else {
        this.langServices.resetLang();
      }

      this.dataServices.pageDataEmit(page);

      // console.log("---", page.title, page.slug, this.slug, page);

      this.headerDataService.setData(new HeaderData({
        title: page.title,
        highlightColor: this.highlightColor,
        slug: this.slug,
        id: this.id || null,
        forceDarkHeader: this.forceDarkHeader,
        forceTitle: this.forceTitle,
        isAdvisorPage: this.isAdvisorPage || simTree.includes(this.slug) || simTree.includes(this.id),
        showLangSelector: this.showLangSelector,
        forceBG: this.forceBG,
        meta_description: this.meta_description,
      }));
      this.notFound = false;
    }
    catch (ex) {
      console.error(ex);

      this.dataServices.pageDataError(ex);

      this.componentsServices.updatePreviousRoute(this.slug);
      //if (this.slug != slugs.pageNotFound) this.router.navigate(['404']);
      this.notFound = true;
    }
  }

  checkOldPermalinks() {
    const pathArray: string[] = window.location.pathname.split("/");
    const id = pathArray[pathArray.length-1];

    if (oldPermalinksITA.includes(window.location.href.toLowerCase()) || 
        oldPermalinksITA.includes("https://www.prometeia.it" + window.location.pathname.toLowerCase())) {
          // console.log("OLD PERMALINK FOUND", id);

          this.router.navigate(['/', Locale.it, paths.trendingTopicsArticle, id]);
    } else if (oldPermalinksENG.includes(window.location.href.toLowerCase()) || 
               oldPermalinksENG.includes("https://www.prometeia.it" + window.location.pathname.toLowerCase())) {
      // console.log("OLD PERMALINK FOUND", id);

      this.router.navigate(['/', Locale.en, paths.trendingTopicsArticle, id]);
    } else if (this.slug == slugs.newsArticle) {
      oldPermalinksITA.forEach((permalink: string) => {
        const pl: string[] = permalink.split("/");
        const permalinkID = pl[pl.length-1];
        if (permalinkID.toLowerCase() == id.toLowerCase()) {
          this.router.navigate(['/', Locale.it, paths.trendingTopicsArticle, id]);
        }
      });
      oldPermalinksENG.forEach((permalink: string) => {
        const pl: string[] = permalink.split("/");
        const permalinkID = pl[pl.length-1];
        if (permalinkID.toLowerCase() == id.toLowerCase()) {
          this.router.navigate(['/', Locale.en, paths.trendingTopicsArticle, id]);
        }
      });
    }
  }

  checkIfNoPaddingBottom(index: number): boolean {
    const nextIndex = index + 1;
    return (nextIndex < this.data.sections.length && this.data.sections[nextIndex].type == this.data.sections[index].type);
  }

  checkIfShortPaddingTop(index: number): boolean {
    if (index == 0) return false;
    
    const prevIndex = index - 1;
    return (prevIndex > 0 && this.data.sections[prevIndex].type == this.data.sections[index].type);
  }

  ngOnDestroy(): void {
    if (this.changeLangSubscription) {
      this.changeLangSubscription.unsubscribe();
      this.changeLangSubscription = null;
    }
    if (this.activatedRouteSubscription) {
      this.activatedRouteSubscription.unsubscribe();
      this.activatedRouteSubscription = null;
    }

    if (this.activatedRouteParamSubscription) {
      this.activatedRouteParamSubscription.unsubscribe();
      this.activatedRouteParamSubscription = null;
    }
  }

}
