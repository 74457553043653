import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBasePage, IButtonLink, Locale } from '../../models/commons';
import { BasePageComponent } from '../base-page.component';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent extends BasePageComponent<IBasePage> {

  buttonCalc: IButtonLink = {
    type: "button",
    label: "",
    theme: 'btn_color__blue',
    customBehaviour: "calc"
  }

  ime: { id: number, question: string, answer?: number }[] = [];

  archetypes: { label: string, pg: string, answersToSum: number[], value: number }[] = []; //ARCHETIPI JUNGHIANI / JUNGIAN ARCHETYPES

  langChangeSubscription: Subscription;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.ime = [];
    for(let i=1; i <= 72; i++) {
      this.ime.push({id: i, question: "ARCHETYPES_TEST.QUESTION_" + i/* , answer: this.randomAnswer() */});
    }

    // this.loadArchetypes();
    this.langChangeSubscription?.unsubscribe();
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => this.loadArchetypes());
  }

  randomAnswer() {
    return Math.floor(Math.random() * (5 - 1 + 1) + 1);
  }

  loadArchetypes() {
    this.archetypes = [
      { label: this.translate.currentLang == Locale.it ? "Innocente" : "Innocent", pg: "C3PO", answersToSum: [5, 13, 34, 49, 63, 65], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Uomo Comune" : "Regular Person", pg: "Frodo Beggins", answersToSum: [14, 22, 27, 30, 50, 71], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Eroe" : "Hero", pg: "Capitan America", answersToSum: [6, 39, 40, 44, 57, 59], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Angelo Custode" : "Caregiver", pg: "Hermione Granger", answersToSum: [7, 10, 15, 24, 55, 68], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Esploratore" : "Explorer", pg: "Arya Stark", answersToSum: [33, 47, 51, 62, 70, 72], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Amante" : "Lover", pg: "Jon Snow", answersToSum: [12, 16, 17, 25, 29, 45], value: 0 },
  
      { label: this.translate.currentLang == Locale.it ? "Ribelle" : "Outlaw", pg: "Deneris", answersToSum: [2, 4, 21, 52, 61, 66], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Creatore" : "Creator", pg: "Doc", answersToSum: [8, 19, 31, 60, 64, 69], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Mago" : "Magician", pg: "Iron Man", answersToSum: [3, 23, 37, 42, 48, 58], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Sovrano" : "Ruler", pg: "Dart Fener", answersToSum: [26, 32, 35, 38, 46, 67], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Saggio" : "Sage", pg: "Yoda", answersToSum: [1, 18, 20, 36, 41, 56], value: 0 },
      { label: this.translate.currentLang == Locale.it ? "Folle" : "Jester", pg: "Stregatto", answersToSum: [9, 11, 28, 43, 53, 54], value: 0 },
    ]

    this.archetypesLabels = this.archetypes.map(a => a.label);

    // console.log("Test Archetipi", this.translate.currentLang, this.archetypesLabels);
  }

  calculateArchetype(archetype: { label: string, answersToSum: number[], value: number }): void {
    if (!archetype.value) archetype.value = 0;

    this.ime.forEach(singleImeItem => {
      if (archetype.answersToSum.includes(singleImeItem.id)) 
        archetype.value += (singleImeItem.answer || 0);
    });

    if (this.topArchetypes.length == 0) this.topArchetypes = [archetype];
    else {
      if (archetype.value > this.topArchetypes[0].value) {
        /** overrides */
        this.topArchetypes = [archetype];
      } else if (archetype.value == this.topArchetypes[0].value) {
        /** multiple "top positions" */
        this.topArchetypes.push(archetype);
      }
    }
  }

  showResult: boolean = false;
  archetypesLabels: string[] = [];
  archetypesValues: number[] = [];
  topArchetypes: { label: string, value: number }[] = [];
  submit(imeForm: NgForm) {
    if (imeForm.form.valid) {
      try {
        // console.log("ok", imeForm.form);
        this.archetypes.forEach(arch => this.calculateArchetype(arch));
        this.archetypesValues = this.archetypes.map(a => a.value);
        this.showResult = true;
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  print() {
    window.print();
    return false;
  }




  /** test-only methods */

  isProduction() {
    return environment.production;
  }

  setValues(type?: "medium" | "random") {
    this.ime.forEach(element => {
      if (type == "medium") element.answer = 3;
      else if (type == "random") element.answer = this.randomAnswer();
      else element.answer = undefined;
    });
  }
}
