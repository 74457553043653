import { Injectable } from '@angular/core';
import { IJobOffer, IJobOfferList, INews, ITrendingTopics, ITrendingTopicsList } from '../models/components.interface';
import { RestClientService } from './rest-client.service';
import { AppClientService } from './app-client.service';
import { IAnteo, IAnteoArticle, ITrendingTopicsArticle } from '../models/pages.interface';
import { IBasePage } from '../models/commons';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private restClient:RestClientService, private appClient: AppClientService) { }

  public pages = {
    get: <T>(slug: string) => this.restClient.invoke<T>(`/page/${slug}`),
    getWithLang: <T>(slug: string, requestedLocale: string) => this.restClient.invoke<T>(`/page/${slug}`, null, null, requestedLocale),
  }

  public components = {
    get: <T>(slug: string) => this.restClient.invoke<T>(`/page/${slug}`),
  }

  public archives = {
    get: <T>(_category: string) => this.restClient.invoke<T>(`/pages`, {_category: _category}),   //es: /pages?_category=orphan
  }

  public anteo = {
    // getList: () => this.restClient.get<IAnteo[]>(`/pages`, {_category: "anteo"}),
    getList: () => this.appClient.get<IAnteo[]>(`/anteo/archive`),

    getIssue: (issueNumber: string) => this.appClient.get<IAnteo>(`/anteo/${issueNumber}`),
    getArticle: (id: string) => this.appClient.get<IAnteoArticle>(`/anteo/article/${id}`),
  }

  public insights = {
    getNewsList: (from: number, page_size: number) => this.appClient.get<INews[]>(`/news/www`, {from: from, limit: page_size}),
    getCityNewsList: (from: number, page_size: number, cityID: string) => this.appClient.get<INews[]>(`/news/www`, {from: from, limit: page_size, city: cityID}),

    /** restituisce il dettaglio della news
     * @param slug "id" della notizia
    */
    getNewsContent: (slug: string) => this.appClient.get<INews>(`/news/www/${slug}`),

    getTrendingTopicsList: (from: number, page_size: number) => this.appClient.get<ITrendingTopicsList[]>(`/news/trending-topics`, {from: from, limit: page_size}),
    getTrendingTopicsForCateogry: (category_id : number, from: number, page_size: number) => this.appClient.get<ITrendingTopics[]>(`/news/trending-topics/${category_id}`, {from: from, limit: page_size}),

    /** restituisce il dettaglio della news
     * @param slug "id" della notizia
    */
     getTrendingTopicsContent: (slug: string) => this.appClient.get<ITrendingTopics>(`/news/details/${slug}`),
     getTrendingTopicsArchive: (type: string, from: number=0, page_size: number=100) => this.appClient.get<ITrendingTopics[]>(`/news/trending-topics/archives/${type}`, {from: from, limit: page_size}),
  }

  public career = {
    getJobOffersList: () => this.appClient.get<IJobOfferList>(`/career/job-offers`, {}),
    getJobOffersContent: (id : number) => this.appClient.get<IJobOffer[]>(`/career/job-offers/${id}`, {}),

    alumniRegistration: (body: string) => this.restClient.post(`/alumni/register`, body),
    alumniUnsubscribe: (body: {email: string}) => this.restClient.post(`/alumni/unsubscribe`, body),
  }

  public saasCloud = {
    getNewsList: (from: number, page_size: number) => this.appClient.get<INews[]>(`/news/www`, {from: from, limit: page_size, saasAndCloud: true}),
  }
  
  public newsletter = {
    post: (slug: string, body: string) => this.restClient.post(`/newsletters/subscribe/${slug}`, body),
  }

  public home = {
    getNewsList: () => this.appClient.get<ITrendingTopicsList[]>(`/news/home`),
    getSuperPinned: () => this.appClient.get<INews>(`/news/super-pinned`),
    getSaveTheDate: (locale: string = null) => this.appClient.get<INews[]>(`/news/www?saveTheDate=true`, {from: 0, limit: 999}, null, locale),
  }

  public search = {
    site: (q: string) => this.restClient.get<IBasePage[]>('/search', {q}),

    trendingTopics: (q: string, from: number = 0, limit: number = 30) => this.appClient.get<ITrendingTopicsArticle[]>('/news/trending-topics/search', {q, from, limit}),
    // {
      // this.appClient.get<ITrendingTopics[]>(`/news/search?q=${filter}` + (type?.length > 0 ? `&type=${type}` : ``), null, null)  //NO -> richiede autenticazione
    // },

    news: (q: string, from: number = 0, limit: number = 30) => this.appClient.get<INews[]>('/news/search', {q, from, limit}),
  }
}
