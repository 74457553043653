import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import globalJSON from '../../../assets/json/global.json';
import { trigger, transition, style, animate } from '@angular/animations';
import { NewsCategory, SpecialMessageGradientType } from '../../models/commons';
import { INews } from '../../models/components.interface';
import { CookieService } from 'src/app/services/cookie.service';
import { paths } from 'src/app/models/paths';
import { TranslateService } from '@ngx-translate/core';

const GRAD_BLUE = "linear-gradient(121deg, #053583 -27%, #1756bc 105%)";                  //da Sketch
const GRAD_CYAN = "linear-gradient(121deg, #41768e -27%, #52c0d7 105%)";                  //da Sketch
const GRAD_GREEN = "linear-gradient(121deg, #526375 -27%, #35c86e 105%)";                 //da Sketch
const GRAD_PINK = "linear-gradient(121deg, #5c3e78 -27%, rgb(238, 57, 157) 105%)";        //da Sketch (originariamente, il colore di arrivo era in rgba con .91)
const GRAD_YELLOW = "linear-gradient(121deg, #526375 -27%, #ebd236 105%)";                //da Sketch

const SPECIAL_MSG_OPACITY = '.90';
const ANIMATION_DELAY = ' 1500ms';

@Component({
  selector: 'app-special-message',
  templateUrl: './special-message.component.html',
  styleUrls: ['./special-message.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({
          /* transform: 'perspective(200px) rotateX(0deg) scale(0.1, .1) translateY(101px)',
          'transform-origin': '50% 50%',
          bottom: '-700px', */
          opacity: '0'
        }),
        animate('400ms' + ANIMATION_DELAY, style({
          /* transform: 'perspective(200px) rotateX(-18deg) scale(.5, 1) translateY(100px)',
          'transform-origin': '50% 14%',
          bottom: '-700px', */
          opacity: SPECIAL_MSG_OPACITY
        }))//,
        // animate(400, style({
        //   /* transform: 'perspective(200px) rotateX(0deg) scale(1, 1) translateY(0px)',
        //   'transform-origin': '50% 14%',
        //   bottom: '0', */
        //   opacity: '1'
        // }))
      ]),

      transition('* => void', [
        //animate(300, style({ transform: 'scale3d(0, .3, .3) translateX(-60%) translateY(150%)', opacity: '.0' }))
        // animate('150ms', style({
        //   /* transform: 'perspective(200px) rotateX(-18deg) scale(.5, 1) translateY(100px)',
        //   'transform-origin': '50% 14%',
        //   bottom: '-700px', */
        //   opacity: SPECIAL_MSG_OPACITY
        // })),
        animate('150ms', style({
          /* transform: 'perspective(200px) rotateX(0deg) scale(0.1, .1) translateY(101px)',
          'transform-origin': '50% 50%',
          bottom: '-700px', */
          opacity: '0'
        }))

      ])
    ])
  ]
})
export class SpecialMessageComponent implements OnInit, OnChanges {

  @Input() data: INews;

  isClosed = true;
  
  logo = globalJSON["images"].cdn + globalJSON["images"].logo_bianco;

  backgroundImage = null;
  backgroundColor = null;

  constructor(private cookieService: CookieService, public translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.data.isFirstChange()) {
      console.log('CHANGES!', changes);
      this.update();
    }
  }

  ngOnInit(): void {
    this.update();
  }

  update(): void {
    if(!this.data) {
      this.isClosed = true;
      return;
    }

    this.isClosed = false;

    if (this.data.categoryTheme) {
      let type: (SpecialMessageGradientType | NewsCategory) = this.data.categoryTheme;

      switch (type.toUpperCase()) {
        case "BLUE" || "BUSINESS":
          this.backgroundImage = GRAD_BLUE;
          break;

        case "CYAN" || "HEALTH":
          this.backgroundImage = GRAD_CYAN;
          break;

        case "GREEN" || "ENVIRONEMENT":
          this.backgroundImage = GRAD_GREEN; 
          break;

        case "PINK" || "SOCIAL_INITIATIVES":
          this.backgroundImage = GRAD_PINK;
          break;

        case "YELLOW" || "CAREER":
          this.backgroundImage = GRAD_YELLOW;
          break;

        default:
          this.backgroundImage = GRAD_PINK;
          break;
      }
    }
  }

  getLink(): string {
    return "/" + this.translate.currentLang + "/" + paths.newsArticle + "/" + this.data.slug;
  }

  close() {
    this.isClosed = true;
    this.cookieService.setSpecialMessageSessionCookie();

    const session = this.cookieService.loadSpecialMessageSessionCookie();
    console.log(session, session != null);
  }

}
