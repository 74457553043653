<ul class="filters lato_regular">
  
    <!-- <li class="type_radio">
      <mat-radio-group [(ngModel)]="user" class="radio-group">
        <ng-container *ngFor="let filter of data">
          <mat-radio-button *ngIf="filter.type == 'radio'" (change)="selectRadio(filter.value)" [name]="filter.id" [value]="filter.value" [color]="filter.theme">{{ filter.label }}</mat-radio-button>

        </ng-container>
      </mat-radio-group>
    </li> -->
    <ng-container *ngFor="let filter of data">
      <li *ngIf="filter.type != 'radio'" class="type_{{filter.type}}">        
        <mat-accordion *ngIf="filter.type == 'list'" class="accordion">
            
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="lato_bold text--{{filter.theme}}"> {{ filter.label }} </mat-panel-title>                
              </mat-expansion-panel-header>
              <ul>
                <li *ngFor="let item of filter.items">  
                    <mat-checkbox  class="lato_regular" (change)="selectCheckbox(item, $event.checked, filter.id)" [checked]="item.value" *ngIf="item.type == 'checkbox'" [color]="filter.theme">{{ item.label }}</mat-checkbox>
                </li>
              </ul>
            </mat-expansion-panel>

        </mat-accordion>   
        
      </li>
    </ng-container>
    
</ul>
