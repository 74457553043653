<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">

    <div class="general-article prom-page">
        <div *ngFor="let article of data.sections; first as isFirst" class="theme--{{article.category}} article">
            <div class="article--main" *ngIf="isFirst">
                <div class="general-article--news-header">
                    <div class="general-article--news-header--title-wrap">
                        <h3 class="prom-caption___lato-regular text--primary capslock general-article--news-header--type" *ngIf="article.category">{{beautifyCategory(article.category)}}</h3>

                        <h1 [ngClass]="{'prom-list neutra2_demi general-article--news-header--title': true,
                                        'text--red': highlightColor == 'red',
                                        'text--accent': highlightColor == 'accent' || highlightColor == 'green',
                                        'text--primary': highlightColor != 'red' && !(highlightColor == 'accent' || highlightColor == 'green')
                                        }">{{article.title}}</h1>

                        <p class="prom-caption___lato-regular text--primary general-article--news-header--date" *ngIf="article.date && !article.pubDate">{{article.date}}</p>
                        <p class="prom-caption___lato-regular text--primary general-article--news-header--date" *ngIf="article.pubDate">{{article.pubDate | date:"dd MMMM yyyy" : '' : translate.currentLang}}</p>
                        <p class="prom-caption___lato-regular text--primary general-article--news-header--date" *ngIf="article.edition">{{article.edition}}</p>
                    </div>

                    <div class="round-image general-article--news-header--extra-image-wrap" *ngIf="data.sections[1]?.media?.contentUrl" [ngStyle]="{'background-image': 'url(' + data.sections[1].media.contentUrl +')' }">

                    </div>
                </div>

                <div *ngIf="(slug == 'article' && id == 'cookie') || slug == 'article__cookie'">
                    <app-prometeia-button
                        class="general-article--cookie-button interactive"
                        (click)="resetCookies()"
                        [data]="{
                            type: 'button',
                            label: 'COOKIES.SETTINGS',
                            id: 'cookie-setting',
                            theme: 'btn_color__blue',
                            customBehaviour: 'label-translation'
                        }">
                    </app-prometeia-button>
                </div>

                <div class="general-article--news-body">
                    <div class="general-article--news-body--extra-colum" [ngClass]="{'extra-margin-right': !article.media}" *ngIf="article.media || (article.buttons && article.buttons.length > 0 && !isLeftButtonsEmpty(article.buttons))">
                        <img *ngIf="article.media" class="general-article--news-body--extra-colum--image" src="{{article.media.contentUrl}}" alt="{{article.media.alt}}">

                        <ng-container *ngIf="data?.sections && data.sections[1]?.text && !data.sections[1]?.innerHtml">
                            <div class="text--primary general-article--news-body--extra-colum--link inner-html--p-no-margin" [innerHtml]="data.sections[1].text | safe:'html'"></div>
                        </ng-container>

                        <ng-container *ngIf="data?.sections && data.sections[1]?.innerHtml">
                            <div class="text--primary general-article--news-body--extra-colum--link inner-html--p-no-margin" [innerHtml]="data.sections[1].innerHtml | safe:'html'"></div>
                        </ng-container>

                        <ng-container *ngFor="let x of article.buttons">
                            <app-prometeia-button *ngIf="x.type == 'button'" class="general-article--news-body--extra-colum--button" [data]="x"></app-prometeia-button>

                            <p *ngIf="x.type == 'text'" class="text--primary no-underline general-article--news-body--extra-colum--link">
                                {{x.label}}
                                <ng-container *ngIf="x.text"><br>{{x.text}}</ng-container>
                            </p>

                            <a *ngIf="x.type == 'link' && x.link && x.label && !x._meta?.moreInfo" href="{{x.link}}" class="text--primary general-article--news-body--extra-colum--link">
                                    {{x.label}}<ng-container *ngIf="x.text"><br>{{x.text}}</ng-container>
                            </a>

                            <a *ngIf="x.type == 'link' && x.route" [routerLink]="['/', translate.currentLang, x.route]" class="text--primary general-article--news-body--extra-colum--link">
                                    {{x.label}}<ng-container *ngIf="x.text"><br>{{x.text}}</ng-container>
                            </a>
                        </ng-container>
                    </div>

                    <div class="general-article--news-body--main-colum">
                        <div [ngClass]="{'text--on-light general-article--news-body--main-colum--text neutra2_book inner-html': true,
                                        'inner-html--SIM': highlightColor == 'red',
                                        'text-no-extra-column': !data.sections[0].media && !(data.sections[0].buttons?.length > 0)}" [innerHtml]="article.innerHtml | safe:'html'"></div>

                        <div class="docs" *ngIf="data?._meta?.docsList">
                            <ng-container *ngFor="let doc of data._meta.docsList">
                                <a class="text--primary docs--item" [href]="doc.contentUrl" style="display: block;">
                                    <span class="icon-bottoni_download"></span> {{doc.title}}
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>


            </div>

            <div class="article--more-info" *ngIf="data.sections[0].buttons">
                <ng-container *ngFor="let btn of data.sections[0].buttons">
                    <ng-container *ngIf="btn.type == 'link' && btn.link && btn._meta?.moreInfo">
                        <p class="text--primary prom-contact___lato contact-us--label">{{'MORE_INFO' | translate}}</p>
                        <a class="text--primary prom-contact no-underline contact-us--email" href="mailto:{{btn.link}}">
                            {{btn.link}}
                        </a>
                    </ng-container>
                </ng-container>
            </div>
        </div>


        <div class="bottomline theme--{{data.sections[0].category}}">
            <div class="bottomline--arrow interactive" (click)="scrollToTop()" *ngIf="data.sections[0].media || data.sections[0].buttons?.length > 0">
                <span class="icon icon-freccia-scroll-up_freccia-su text--primary"></span>
            </div>

            <div [ngClass]="{'bottomline--socials': true, 'socials-no-extra-column': !data.sections[0].media && !(data.sections[0].buttons?.length > 0)}" *ngIf="!isArticleRestricted()">
                <app-social-share *ngFor="let soc of socials_list" [data]="soc"></app-social-share>
            </div>

            <div class="bottomline--arrow interactive" (click)="scrollToTop()" *ngIf="!data.sections[0].media && !(data.sections[0].buttons?.length > 0)">
                <span class="icon icon-freccia-scroll-up_freccia-su text--primary"></span>
            </div>
        </div>
    </div>
</ng-container>
