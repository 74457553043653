<hr *ngIf="data && data.slides?.length > 0"/>

<div class="carousel {{sectionTheme}}" [ngStyle]="{'min-height': data.slide_height}" *ngIf="data && data.slides?.length > 0">
    
    <div class="slides slides-speed--{{data.speed || 'medium'}}"
         *ngIf="slides.length > getMinSlidesNumber() || !filters || filters.length == 0">
        <ng-container *ngFor="let item of [].constructor(n)">
            <div class="slide" *ngFor="let s of slides">
                <ng-container *ngIf="s.type != 'VID'">
                    <a class="slide--link" *ngIf="s.route && !s.id && !s.link" [routerLink]="['/', translate.currentLang, s.route]" routerLinkActive="active">
                        <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                        <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                    </a>

                    <a class="slide--link" *ngIf="s.route && s.id && !s.link" [routerLink]="['/', translate.currentLang, s.route, s.id]" routerLinkActive="active">
                        <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                        <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                    </a>
                    
                    <a class="slide--link" *ngIf="s.link && !s.route" href="{{s.link}}" target="_blank">
                        <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                        <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                    </a>
                </ng-container>

                <ng-container *ngIf="s.type == 'VID'">
                    <a class="slide--link interactive" (click)="$event.preventDefault(); openVideo(s)">
                        <img class="slide--image interactive" src="{{s.previewUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                        <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                    </a>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div [ngClass]="{'slides slides--fixed': true, 'two-on-fixed-carousel-on-mobile': slides.length == getMinSlidesNumber() && !componentsServices.isDesktopMode()}"
         *ngIf="slides.length <= getMinSlidesNumber() && (filters && filters.length != 0)">
        <div class="slide" *ngFor="let s of slides">
            <ng-container *ngIf="s.type != 'VID'">
                <a class="slide--link" *ngIf="s.route && !s.id && !s.link" [routerLink]="['/', translate.currentLang, s.route]" routerLinkActive="active">
                    <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                    <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                </a>

                <a class="slide--link" *ngIf="s.route && s.id && !s.link" [routerLink]="['/', translate.currentLang, s.route, s.id]" routerLinkActive="active">
                    <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                    <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                </a>
                
                <a class="slide--link" *ngIf="s.link && !s.route" href="{{s.link}}" target="_blank">
                    <img class="slide--image" src="{{s.contentUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                    <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                </a>
            </ng-container>

            <ng-container *ngIf="s.type == 'VID'">
                <a class="slide--link interactive" (click)="$event.preventDefault(); openVideo(s)">
                    <img class="slide--image interactive" src="{{s.previewUrl}}" [ngStyle]="{'height': data.slide_height}" alt="{{s.alt}}" *ngIf="!highlightColor" />
                    <app-zoomable-circular-image *ngIf="highlightColor" [data]="s" [highlightColor]="highlightColor"></app-zoomable-circular-image>
                </a>
            </ng-container>
        </div>
    </div>
</div>

<hr *ngIf="data && data.slides?.length > 0"/>