import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Throttle } from 'angular-debounce-throttle';
import { Subject } from 'rxjs';
import { ComponentsServices } from 'src/app/services/components.service';
import { VideoService } from 'src/app/services/video.service';
import { HighlightColor, IHorizontalScrollCarousel, IMedia, SectionTheme } from '../../models/commons';

@Component({
  selector: 'app-horizontal-scroll-carousel',
  templateUrl: './horizontal-scroll-carousel.component.html',
  styleUrls: ['./horizontal-scroll-carousel.component.scss']
})
export class HorizontalScrollCarouselComponent implements OnInit, OnChanges {

  @Input() data: IHorizontalScrollCarousel;
  @Input() filters: string[];
  @Input() sectionTheme: SectionTheme = "theme--lighter";
  slides: IMedia[];
  n: number = 1;

  /** if (data.highlightColor != null) we have a zoomable circular image instead of a regular slide */
  highlightColor: HighlightColor;

  constructor(private videoService: VideoService, public translate: TranslateService, public componentsServices: ComponentsServices) { }

  ngOnInit(): void {
    if (this.data.carousel__highlight_color) {
      this.highlightColor = this.data.carousel__highlight_color;
    }
    // console.log(this.highlightColor);

    this.slides = this.data.slides;
    this.n = this.getIterationNumber(this.slides.length);
  }

  getIterationNumber(sn: number): number {
    return Math.ceil(50/sn);
  }

  ngOnChanges(): void {
    // if (this.filters) console.log("HorizontalScrollCarousel", "filters:", this.filters);
    // else console.log("HorizontalScrollCarousel", "filters disabled", this.filters);

    this.filterSlides();
  }

  @Throttle(1000)
  filterSlides() {
    // if filters==null  -> display all
    // if filters==[???] -> display only filtered

    if (this.filters) {
      // console.log("HorizontalScrollCarousel", "filters:", this.filters);

      this.slides = [];
      this.data.slides.forEach((slide: IMedia) => {
        if ( (slide.title && this.filters.includes(slide.title.toLowerCase())) || (slide.text && this.filters.includes(slide.text.toLowerCase())) ) {
          this.slides.push(slide);
        }

        let slideTagsArray = [];
        if (slide.title?.includes(" ")) slideTagsArray = slide.title.split(" ");
        slideTagsArray.forEach((singleTag: string) => {
          if (this.filters.includes(singleTag.toLowerCase())) {
            this.slides.push(slide);
          }
        });

        if (slide.text?.includes(" ")) slideTagsArray = slide.text.split(" ");
        slideTagsArray.forEach((singleTag: string) => {
          if (this.filters.includes(singleTag.toLowerCase())) {
            this.slides.push(slide);
          }
        });
      });
    } else {
      // console.log("HorizontalScrollCarousel", "filters disabled", this.filters);

      this.slides = this.data.slides;
    }
  }

  openVideo(video: IMedia) {
    this.videoService.playThisVideo(video);
  }

  getMinSlidesNumber() {
    if (this.componentsServices.isDesktopMode()) {
      return 5;
    } else {
      return 2;
    }
  }

}
