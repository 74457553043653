/*
    More info:
    https://devblogs.microsoft.com/premier-developer/angular-how-to-simplify-components-with-typescript-inheritance/
*/

import { Injector } from '@angular/core';

/**
 * This class will hold the module’s injector.
 * It will be set once and retrieved whenever a component or service needs to get a service dependency.
 */
export class AppInjector {

    private static injector: Injector;

    static setInjector(injector: Injector) {
        AppInjector.injector = injector;
    }

    static getInjector(): Injector {
        return AppInjector.injector;
    }
}     