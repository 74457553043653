import { Component, Input } from '@angular/core';
import { SectionGeneralComponent } from '../section-general/section-general.component';

@Component({
  selector: 'app-section-wide-cards-list',
  templateUrl: './section-wide-cards-list.component.html',
  styleUrls: ['./section-wide-cards-list.component.scss']
})
export class SectionWideCardsListComponent extends SectionGeneralComponent {

}
