<div class="multi-text">

    <div class="multi-text--titles">
        <ng-container *ngFor="let m of data.list">
            <h2 class="title neutra2_book interactive" [ngStyle]="m.selected && {'color': highlighter}" (mouseenter)="more(m)" (mouseleave)="less(m)" (click)="click()">{{m.title}}</h2>
        </ng-container>
    </div>
    
    <div class="multi-text--media">
        <img [@fade]="animations.state"(@fade.done)="onDone($event)" class="image" src="{{imageUrl}}">
    </div>

    <div class="multi-text--content">
        <p class="text">{{description}}</p>
    </div>

</div>