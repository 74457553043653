import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { paths } from '../../models/paths';
import { HighlightColor, ISectionNetwork, Locale } from '../../models/commons';
import { ICitiesList, IOfficesList } from 'src/app/models/components.interface';
import { INetwork } from 'src/app/models/pages.interface';
import { SortingServices } from 'src/app/services/sorting.service';

@Component({
  selector: 'app-section-intro-bg',
  templateUrl: './section-intro-bg.component.html',
  styleUrls: ['./section-intro-bg.component.scss']
})
export class SectionIntroBgComponent implements OnInit, OnChanges {

  @Input() data: ISectionNetwork;
  @Input() mainTitle: string;
  @Input() highlightColor: HighlightColor;
  @Input() otherCities: INetwork;
  @Input() cityID: string;

  introThemeColor: string;
  introThemeOpacity: string;
  isDarkTheme: boolean = false;
  LocaleEn = Locale.en;
  countryPageRoute: string = paths.countryPage;

  networkLinks: ICitiesList[] = null;

  constructor(public translate: TranslateService, public sort: SortingServices) { }

  ngOnInit(): void {
    this.introThemeColor = "intro-theme--" + this.data.theme.split("#")[0];
    this.introThemeOpacity = this.data.theme.split("#")[1] + "%";

    if (this.introThemeColor.endsWith("primary") || this.introThemeColor.endsWith("blue")) {
      this.isDarkTheme = true;
    }

    if (/* this.mainTitle.toLowerCase() == "tech solutions" || */ this.data.title.toLowerCase() == this.mainTitle.toLowerCase()) this.mainTitle = null;

    // console.log("SectionIntroBgComponent", this.data, this.introThemeColor, this.introThemeOpacity);

    if (this.otherCities && this.otherCities.branches && this.otherCities.partners) {
      this.networkLinks = [this.otherCities.branches, this.otherCities.partners];
      if (!this.networkLinks[0].title.startsWith("Other")) this.networkLinks[0].title = "Other " + this.networkLinks[0].title.toLowerCase() + "es";
    }
  }

  ngOnChanges() {
    if (this.otherCities && this.otherCities.branches && this.otherCities.partners) {
      this.networkLinks = [this.otherCities.branches, this.otherCities.partners];
      if (!this.networkLinks[0].title.startsWith("Other")) this.networkLinks[0].title = "Other " + this.networkLinks[0].title.toLowerCase() + "es";
    }
  }

  onClick() : void {
    this.translate.use(Locale.en);
  }

}
