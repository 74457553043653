<div *ngIf="data" class="overview">
    <hr class="hr--top">
    <div class="overview--content">
        <img class="overview--image" src="{{data.contentUrl}}" aria-hidden="true" alt="{{data.alt}}">

        <div class="overview--description">
            <p class="text--primary neutra2_demi title">{{data.title}}</p>
            <a class="link" [routerLink]="['/', translate.currentLang, data.route, data.id]" routerLinkActive="active">
                <h2 class="text--primary prom-list neutra2_demi text">{{data.text}}</h2>
            </a>

            <p class="text--red  neutra2_demi title edition" *ngIf="data._meta?.edition">{{data._meta?.edition}}</p>
        </div>
    </div>
    <hr class="hr--bottom">
    <a *ngIf="data._meta?.past && !slug" class="prom-caption___lato-italic text--primary past"
        [routerLink]="['/', translate.currentLang, data._meta.past.route]" routerLinkActive="active">
            {{ 'PAST_EDITIONS' | translate }}
    </a>
    <a *ngIf="slug" class="prom-caption___lato-italic text--primary past"
        [routerLink]="['/', translate.currentLang, slug + '-archive']" routerLinkActive="active">
            {{ 'PAST_EDITIONS' | translate }}
    </a>
</div>