import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IButtonLink, NewslettersSlugs, NewsletterSubscriptionForm } from 'src/app/models/commons';
import { separator } from 'src/app/models/paths';
import { componentsSlugs } from 'src/app/models/slugs';
import { ApiService } from 'src/app/services/api.service';
import { INewsletterForm } from '../../models/components.interface';
import { ComponentsServices } from '../../services/components.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent implements OnInit {

  // campi per newsletter Anteo: https://www.prometeia.it/financial-advisory -> Iscriviti (no numeri di telefono)

  data: INewsletterForm;

  buttonSignUp: IButtonLink = {
    type: "button",
    label: "",
    theme: 'btn_color__green',
    customBehaviour: "sign-up"
  }

  date: Date = new Date();
  lastFocusIn: number;

  formControlValueChangeSubscription: Subscription;
  isFormFilled: boolean = false;
  isPrivacyCheckboxChecked: boolean = false;
  isOptionalCheckboxChecked: boolean = false;

  id: string = "";

  isDropdownOtherSelected: boolean = false; //could be true only in anteo (which has ONE dropdown)

  angularFormFields: NewsletterSubscriptionForm = {
    firstName: null,
    lastName: null,
    email: null,

    company: null,
    companyCountry: null,
    country: null,
    job: null,
    jobCategory: null,
    jobCategory_other: null,
  };
  angularFormFieldsItems = ["firstName", "lastName", "email", "company", "country", "job", "jobCategory", "jobCategory_other"];

  focusArray = {};

  /** compare in dissolvenza (niente ngIf) */
  constructor(private componentsServices: ComponentsServices, public translate: TranslateService, private api: ApiService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    //quando riceve evento "apri modale", attiva classe "visible" o simile; quando chiude con X, il contrario
    
    this.angularFormFieldsItems.forEach(element => {
      this.focusArray[element] = false;
    });

    this.id = this.componentsServices.getNewletterFormId();

    this.loadNewsletterFormFields();
  }

  private async loadNewsletterFormFields() {
    try {
      const slug: string = componentsSlugs.newsletterFormFields + separator + this.id;
      // console.log("newsletter load fields ->", slug);
      this.data = await this.api.components.get(slug);

      if (this.data) {
        if (!this.data.title) this.data.title = this.getTitleFromId( this.id );

        for (let field of this.data.sections) {
          if (field.dropdown && field.id == 'positionCategory') {
            this.anteoDropdown = field.dropdown;
          }
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  private getTitleFromId(id: string): string {
    let retArray = id.replace(/_/g, " ").split(" ");
    let ret = "";
    retArray.forEach((element: string) => {
      ret = ret + element[0].toUpperCase() + element.substring(1) + " ";
    });
    return ret;
  }

  isFieldRequired(field_id: string): boolean {
    if (this.id != "anteo") {
      return field_id && ["name", "lastName", "e-mail", "email"].includes(field_id);
    } else {
      return field_id && ["name", "lastName", "e-mail", "email", "company", "positionCategory"].includes(field_id);
    }
  }

  checkValue(event: any) {
    // console.log(event.currentTarget.id, "checkbox is", event.currentTarget.checked);
    if (event.currentTarget.id == "agreeCheckbox") {
      this.isPrivacyCheckboxChecked = event.currentTarget.checked;
    } else if (event.currentTarget.id == "agreeOptionalCheckbox") {
      this.isOptionalCheckboxChecked = event.currentTarget.checked;
    }
  }


  private async subscribeToNewsletter(slug: string, subscription: string) {
    let snackBarRef;
    try {
      const resp = await this.api.newsletter.post(slug, subscription);

      // if (resp?.status == 200 || resp?.status == "200 OK" || resp?.status == "OK") {
        // console.log("subscribtion sent", resp);
        snackBarRef = this.openSnackBar(this.translate.instant('FORM_NEWSLETTER.THANK_YOU'));
        this.closeForm();
      // }
    } catch (ex) {
      const err = ex.error.error;
      // console.log("catched", err);

      //usare gli stessi toast di alumni; usare testi standard, non ci sono preferenze
      if (err.code && err.code == 3) {
        // console.log("già iscritto");
        snackBarRef = this.openSnackBar(this.translate.instant('FORM_NEWSLETTER.ALREADY_SUBSCRIBED'));
        this.closeForm();
      } else if (err.code && err.code == 2) {
        // console.log("ricontrolla i campi");
        snackBarRef = this.openSnackBar(this.translate.instant('FORM_NEWSLETTER.CHECK_FIELDS'));
      } else /* if (err.status == 500) */ {
        // console.log("riprova più tardi");
        snackBarRef = this.openSnackBar(this.translate.instant('FORM_NEWSLETTER.RETRY_LATER'));
      }
    }
  }

  closeForm() {
    // console.log("closing form", this.data.sections);
    this.componentsServices.closeNewsletterSubscribeForm(); //+dati per compilazione automatica?
  }

  focusIn() {
    //console.log("focus in");

    this.date = new Date();
    this.lastFocusIn = this.date.getMilliseconds();
  }

  informationNoticeOpened: boolean = false;
  openInformationNotice() {
    this.informationNoticeOpened = true;
    // console.log("openInformationNotice()");
  }


  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  setDropdownOtherSelected(id: string) {
    this.isDropdownOtherSelected = (id == "other");
    this.angularFormFields.jobCategory = this.selectedOption;
    this.selectedOptionID = id;
    // if (this.isDropdownOtherSelected) console.log("id", id, this.isDropdownOtherSelected);

    // this.selectedOption = title || null;
  }

  getDropdownOtherSelected(): boolean {
    // if (this.isDropdownOtherSelected) console.log("isDropdownOtherSelected:", this.isDropdownOtherSelected);
    return this.isDropdownOtherSelected;
  }



  selectedOption: string;
  selectedOptionID: string;
  anteoDropdown: { id: string; title: string; }[];

  getLabel(id: string): string {
    let res = "";
    this.data.sections.forEach((item) => {
      if (item.id == id) res = item.title;
    })

    return res;
  }

  isNgFormFilled(): boolean {
    if (
      this.angularFormFields.firstName?.length > 0 && 
      this.angularFormFields.lastName?.length > 0 && 
      this.angularFormFields.email?.length > 0 && (
        this.id != "anteo" || (
          this.id == "anteo" &&
          this.angularFormFields.company?.length > 0 &&
          this.selectedOptionID?.length > 0 && (
            this.selectedOptionID != 'other' || (
              this.selectedOptionID == 'other' && 
              this.angularFormFields.jobCategory_other && 
              this.angularFormFields.jobCategory_other?.length > 0
            )
          )
        )
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  
  submit(activateNewsletterForm: NgForm) {
    let snackBarRef;
    let POST_slug: NewslettersSlugs;
    if(activateNewsletterForm.form.valid) {
      switch (this.id) {
        case "anteo":
          POST_slug = NewslettersSlugs.Anteo;
          break;
        case "prom_next":
          POST_slug = NewslettersSlugs.PromNext;
          break;
        case "risk_news":
          POST_slug = NewslettersSlugs.RiskNews;
          break;
        case "trending_topics":
          POST_slug = NewslettersSlugs.TrendingTopics;
          break;
      }

      if (this.selectedOption) {
        this.angularFormFields.jobCategory = this.selectedOption;
      }
      if (!this.isOptionalCheckboxChecked) {
        this.angularFormFields.company = null;
        this.angularFormFields.companyCountry = null;
        this.angularFormFields.country = null;
        this.angularFormFields.job = null;
        this.angularFormFields.jobCategory = null;
        this.angularFormFields.jobCategory_other = null;
      }   
      // console.log("Confirm", JSON.stringify(this.angularFormFields));
      // snackBarRef = this.openSnackBar(this.translate.instant('THANK_YOU') + JSON.stringify(this.angularFormFields));

      this.subscribeToNewsletter(POST_slug, JSON.stringify(this.angularFormFields));
    }
  }
}
