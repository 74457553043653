<section spyTarget="{{data.anchorId}}" class="{{data.theme}} section two-even-columns {{sectionOptions.largerTextSmallerImg ? 'larger-text-smaller-img' : ''}}" id="{{data.anchorId}}">

    <div class="inner-block section-max-content">
        <div class="text-block">
            <h1 class="neutra2_book text--{{sectionOptions.titleColor || 'primary'}} text-block--title" *ngIf="data.title">
                {{ data.title.split('|')[0] }}
                <ng-container *ngFor="let x of data.title.split('|'); let isFirst = first">
                    <span *ngIf="x && !isFirst"><br>{{ x }}</span>
                </ng-container>
            </h1>
            <div class="text-block--text neutra2_book inner-html" *ngIf="data.text" [innerHtml]="data.text"></div>

            <div class="image-block mobile-only" *ngIf="data.media?.contentUrl">
                <img src="{{data.media.contentUrl}}" aria-hidden="true" alt={{data.media.alt}}>
            </div>

            <ng-container *ngIf="data._meta && data._meta.extra_infos">
                <div class="extra-spacer"></div>
                <div class="extra-container" *ngFor="let extra of data._meta.extra_infos">
                    <app-prometeia-button class="extra-btn" [data]="extra.extra_btn"></app-prometeia-button>

                    <div class="extra-container--text">
                        <p class="prom-caption___lato-regular text--grey increased-line-height" *ngIf="extra.extra_text">{{extra.extra_text}}</p>
                        <a class="prom-caption___lato-regular text--grey increased-line-height" *ngIf="extra.extra_email" href="mailto:{{extra.extra_email}}">{{extra.extra_email}}</a>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="image-block desktop-only" *ngIf="data.media?.contentUrl">
            <img src="{{data.media.contentUrl}}" aria-hidden="true" alt={{data.media.alt}}>
        </div>
    </div>
</section>
