<section class="intro center-vertical gradient--primary" id="intro" spyTarget="intro">
    <div class="section-max-content">
        <p class="intro--title neutra2_bold text--{{highlightColor||'accent'}} capslock" *ngIf="data && data.title">{{ data.title | translate }}</p>
        <p class="section--text" *ngIf="data && data.text">{{ data.text | translate }}</p>
        
        <div class="image-container" *ngIf="data && data.media && data.media.contentUrl">
            <img class="image" src="{{data.media.contentUrl}}" alt="{{data.media.alt}}">
        </div>

        <div class="some-numbers-container" *ngIf="data && data.some_numbers">
            <app-info-number    *ngFor="let x of data.some_numbers" [data]="x" [highlightColor]="highlightColor"
                                class="animation__fade_up some-numbers-container--item"></app-info-number>
        </div>

        <app-long-arrow-down class="mobile-only" [color]="highlightColor"></app-long-arrow-down>
    </div>
</section>