import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IFooterData } from 'src/app/models/components.interface';
import { componentsSlugs } from 'src/app/models/slugs';
import { ComponentsServices } from 'src/app/services/components.service';
import { CacheService } from '../../services/cache.service';
import globalJSON from '../../../assets/json/global.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy, OnChanges {

  isFooterActive: boolean = false;
  activeRoute: string = null;

  routerSubscription: Subscription = null;

  private pageScrollUpSubscription: Subscription = null;
  private pageScrollIsBottomSubscription: Subscription = null;
  isBottom: boolean = false;
  useSimLinks: boolean = false;

  isAlumniUnsub: boolean = false;

  private changeLangSubscription: Subscription = null;

  logo_scuro: string = globalJSON["images"].cdn + globalJSON["images"].logo;
  logo_advisor_scuro: string = globalJSON["images"].cdn + globalJSON["images"].logo_advisor_scuro;

  footerSlug: string = componentsSlugs.footerSPA;
  footerData: IFooterData;
  footerDataSpa: IFooterData = null;
  footerDataSim: IFooterData = null;

  nowYear: number = 2021;

  constructor(
    private router: Router,
    public componentsService: ComponentsServices,
    public translate: TranslateService,
    private cache: CacheService
  ) { }

  ngOnInit(): void {
    this.loadComponentData();
    this.nowYear = new Date().getFullYear();

    this.routerSubscription?.unsubscribe();
    this.routerSubscription = this.router.events.subscribe((element) => {
      if (element instanceof RoutesRecognized) {
        const urlsParts = element.urlAfterRedirects.split('/');
        this.isAlumniUnsub = element.urlAfterRedirects.endsWith('alumni/unsubscribe');
        this.activeRoute = urlsParts[urlsParts.length - 1];
        this.isFooterActive = (this.activeRoute != null);
        this.useSimLinks = this.componentsService.isPathSim(this.activeRoute);

        // console.log("footer", this.activeRoute);
        this.switchFooterData();
      }
    });

    this.changeLangSubscription?.unsubscribe();
    this.changeLangSubscription = this.translate.onLangChange.subscribe((element) => {
      this.footerSlug = componentsSlugs.footerSPA;
      if (this.useSimLinks) this.footerSlug = componentsSlugs.footerSIM;
      this.loadComponentData();
    });

    this.pageScrollUpSubscription?.unsubscribe();
    this.pageScrollUpSubscription = this.componentsService.pageScrollUp.subscribe(() => {
      this.isBottom = false;
    });

    this.pageScrollIsBottomSubscription?.unsubscribe();
    this.pageScrollIsBottomSubscription = this.componentsService.pageScrollIsBottom.subscribe(() => {
      this.isBottom = true;
    });

  
    this.componentsService.isMenuOpenEvent.subscribe((isMenuOpen: boolean) => {
      if (isMenuOpen) {
        this.isFooterActive = false;
      } else {
        this.isFooterActive = this.activeRoute != null;
      }
    });
  }

  protected async loadComponentData() {
    try {
      // this.footerData = await this.api.components.get(this.footerSlug);
      this.footerDataSpa = await this.cache.componentsGet(componentsSlugs.footerSPA);
      this.footerDataSim = await this.cache.componentsGet(componentsSlugs.footerSIM);
      this.switchFooterData();
    }
    catch(ex) {
      console.error(ex);
    }
  }

  ngOnChanges() {
    this.useSimLinks = this.componentsService.isPathSim(this.activeRoute);
    console.log(this.useSimLinks);
    this.switchFooterData();
  }

  private switchFooterData() {
    if (this.useSimLinks) this.footerData = this.footerDataSim;
    else this.footerData = this.footerDataSpa;
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
    this.pageScrollUpSubscription.unsubscribe();
    this.pageScrollIsBottomSubscription.unsubscribe();
    this.changeLangSubscription.unsubscribe();

    this.routerSubscription = this.pageScrollUpSubscription = this.pageScrollIsBottomSubscription = this.changeLangSubscription = null;
  }

}
