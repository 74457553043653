import { Component, Input, OnInit } from '@angular/core';
import { paths } from '../../models/paths';
import { INews } from '../../models/components.interface';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/services/app-injector.service';

@Component({
  selector: 'app-single-news',
  templateUrl: './single-news.component.html',
  styleUrls: ['./single-news.component.scss']
})
export class SingleNewsComponent implements OnInit {

  @Input() data: INews;
  category: string;
  route: string = paths.newsArticle;

  translate: TranslateService;
  
  constructor() {
    const injector = AppInjector.getInjector();
    this.translate = injector.get(TranslateService);
  }

  ngOnInit(): void {
    this.category = this.data?.category.replace(/_/g, " ");
  }

}
