import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrowserServices } from './browser.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class LangServices {
  // public countryPageLangForced: EventEmitter<ExtraLangs> = new EventEmitter();
  // public resetForcedLang: EventEmitter<boolean> = new EventEmitter();

  locale: string = null;
  isForced: boolean = false;

  constructor(public translate: TranslateService, private cookieService: CookieService, private browserServices: BrowserServices) { }

  public getDefaultLocalization() {
    //priorità: from url, from cookies, from browser
    if (this.cookieService.isLangStored()) {
      console.log("LANG SERVICE", "lang from cookie", this.cookieService.loadLangCookie());
      return this.cookieService.loadLangCookie();
    } else {
      console.log("LANG SERVICE", "lang from device", this.browserServices.myLanguage());
      return this.browserServices.myLanguage();
    }
  }

  public forceLang(forcedLang: string) {
    this.locale = forcedLang;
    this.isForced = true;
    // this.countryPageLangForced.emit(forcedLang);
  }

  public resetLang() {
    this.locale = null;
    this.isForced = false;
    // this.resetForcedLang.emit(true);
  }

  public getLang() {
    return this.locale;
  }

  private getLabelFromLocale(loc: string) {
    let ret = null;
    switch (loc) {
      case "ar":
        ret = "AR";
        break;

      case "de":
        ret = "DEU";
        break;

      case "en":
        ret = "ENG";
        break;

      case "es":
        ret= "ESP";
        break;

      case "fr":
        ret = "FR";
        break;

      case "gr":
        ret = "GR";
        break;

      case "ru":
        ret = "RUS";
        break;

      case "tr":
        ret = "TUR";
        break;
    }
    return ret;
  }

  public getLangLabel() {
    let ret = this.translate.instant('LOCALE');
    if (this.translate.instant('LOCALE') == "ITA") {
      ret = this.getLabelFromLocale(this.locale) || this.translate.instant('LOCALE');
    }

    // console.log("now", ret, this.locale);

    return ret;
  }

  public getNextLangLabel() {
    let ret = this.translate.instant('NEXT_LOCALE');
    if (this.translate.instant('NEXT_LOCALE') == "ITA") {
      ret = this.getLabelFromLocale(this.locale) || this.translate.instant('NEXT_LOCALE');
    }

    // console.log("next", ret, this.locale);

    return ret;
  }
}
