import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  siteName: string = "Prometeia";

  constructor(private title: Title, private meta: Meta, public translate: TranslateService) { }


  updateTitle(title: string) {
    if (!title) return;

    this.title.setTitle(title);

    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'title', property: 'og:title', content: title });
  }

  updateDescription(descr: string) {
    if (!descr) return;
    
    if (descr.startsWith("SEO__PAGE_DESCR")) {
      this.meta.updateTag({ name: 'description', content: this.translate.instant(descr) });
      this.meta.updateTag({ name: 'og:description', content: this.translate.instant(descr) });
    } else {
      this.meta.updateTag({ name: 'description', content: descr });
      this.meta.updateTag({ name: 'og:description', content: descr });
    }
  }

  /** https://www.concretepage.com/angular/angular-meta-service-for-meta-tags#:~:text=removeTag()%3A%20Updates%20meta%20tag,angular%2Fplatform%2Dbrowser%20library */
  removeDescription() {
    this.meta.removeTag('name = "description"');
    this.meta.removeTag('name = "og:description"');
  }

  getDescription(): HTMLMetaElement {
    return this.meta.getTag('name = "description"') || null;
  }


  /**
    OG (Open Graph) - https://www.mrw.it/social/open-graph-guida-completa_12367.html

    og:url - serve per indicare la URL canonica della specifica risorsa web che viene condivisa ( la URL canonica è una specie di URL "ufficiale", priva cioè di tutti quegli elementi facoltativi come querystring che impostano sessioni, filtri o quant'altro di non indispensabile).
    
    og:title - serve per definire il titolo della specifica risorsa web che viene condivisa (bisogna considerare che Facebook tronca il titolo dopo 88 caratteri, quindi non esageriamo con titoli troppo lunghi).
    
    og:image - serve per indicare la URL assoluta dell'immagine che andrà a rappresentare lo specifico oggetto che verrà creato nel Social Graph (la dimensione ideale è di 1200 x 630 pixel, mentre la dimensione minima è di 600 x 315 pixel; se l'immagine è inferiore a 600 x 315 pixel continuerà a essere visibile nel post ma con dimensioni notevolmente minori).

    og:description - consente di specificare una descrizione della risorsa condivisa. Facebook suggerisce di includere nella pagina "una descrizione chiara di almeno due frasi". Queto metadovrebbe essere sempre inserito!
    
    og:locale - consente di specificare la lingua dell'oggetto condiviso (è utile quando, ad esempio, si condivide un articolo).
    
    og:site_name - consente di specificare il nome del sito web (è utile quando si condivide un elemento, come ad esempio un articolo, che è parte di un sito web più ampio ed articolato).
  */
  updateOg(image?: string, url?: string, _type: string = "article") {
    if (image) {
      if (image.startsWith("https"))
        this.meta.updateTag({ name: 'og:image:secure_url', content: image });   //image for "https" pages
      else 
        this.meta.updateTag({ name: 'og:image', content: image });   //image for "http" pages
    }
    if (url) this.meta.updateTag({ name: 'og:url', content: url });

    this.meta.updateTag({ name: 'og:locale', content: this.translate.currentLang });
    this.meta.updateTag({ name: 'og:site_name', content: this.siteName });
    this.meta.updateTag({ name: 'og:type', content: _type });
  }
}
