<div class="newsletter-form" *ngIf="data">
    <div class="desktop-only" (click)="closeForm()">
        <img class="icon-box-chiudi_x interactive" src="../../../assets/icons-svg/svg-icon-X-chiusura_scura.svg">
    </div>

    <div class="newsletter" (click)="focusIn()" (focusin)="focusIn()">
        <img class="icon-box-chiudi_x mobile-only" src="../../../assets/icons-svg/svg-icon-X-chiusura_scura.svg"
            (click)="closeForm()">
        <h2 class="neutra2_book text--primary newsletter--title">{{data.title}}</h2>
        <h3 class="neutra2_book text--primary newsletter--title capslock">Newsletter</h3>

        <ng-container>
            <form #activateNewsletterForm="ngForm" [ngClass]="{'ng-form': true, submitted: activateNewsletterForm.submitted}" (ngSubmit)="submit(activateNewsletterForm)">
                <div class="form-group field">
                    <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['firstName']}" for="name">{{getLabel('name')}}</label>
                    <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['firstName'], 'invalid': activateNewsletterForm.submitted}" name="name" [(ngModel)]="angularFormFields.firstName" type="text" placeholder="{{getLabel('name')}}" (focusin)="focusArray['firstName'] = true" (focusout)="focusArray['firstName'] = false" required>

                    <p class="text--primary field--asterisk">*</p>
                </div>

                <div class="form-group field">
                    <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['lastName']}" for="lastName">{{getLabel('lastName')}}</label>
                    <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['lastName'], 'invalid': activateNewsletterForm.submitted}" name="lastName" [(ngModel)]="angularFormFields.lastName" type="text" placeholder="{{getLabel('lastName')}}" (focusin)="focusArray['lastName'] = true" (focusout)="focusArray['lastName'] = false" required>

                    <p class="text--primary field--asterisk">*</p>
                </div>

                <div class="form-group field">
                    <ng-container *ngIf="this.id != 'anteo'">
                        <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['company']}" for="company">
                            {{getLabel('company')}}
                        </label>
                        <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['company'], 'invalid': activateNewsletterForm.submitted}" name="company" [(ngModel)]="angularFormFields.company" type="text" placeholder="{{getLabel('company')}}" (focusin)="focusArray['company'] = true" (focusout)="focusArray['company'] = false">
                    </ng-container>

                    <ng-container *ngIf="this.id == 'anteo'">
                        <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['company']}" for="company">
                            {{getLabel('company')}}
                        </label>
                        <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['company'], 'invalid': activateNewsletterForm.submitted}" name="company" [(ngModel)]="angularFormFields.company" type="text" placeholder="{{getLabel('company')}}" (focusin)="focusArray['company'] = true" (focusout)="focusArray['company'] = false" required>

                        <p class="text--primary field--asterisk">*</p>
                    </ng-container>
                </div>

                <div class="form-group field" *ngIf="this.id != 'anteo'">
                    <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['country']}" for="country">{{getLabel('country')}}</label>
                    <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['country'], 'invalid': activateNewsletterForm.submitted}" name="country" [(ngModel)]="angularFormFields.country" type="text" placeholder="{{getLabel('country')}}" (focusin)="focusArray['country'] = true" (focusout)="focusArray['country'] = false">
                </div>

                <div class="form-group field" *ngIf="this.id == 'anteo'">
                    <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['companyCountry']}" for="companyCountry">{{getLabel('companyCountry')}}</label>
                    <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['companyCountry'], 'invalid': activateNewsletterForm.submitted}" name="companyCountry" [(ngModel)]="angularFormFields.companyCountry" type="text" placeholder="{{getLabel('companyCountry')}}" (focusin)="focusArray['companyCountry'] = true" (focusout)="focusArray['companyCountry'] = false">
                </div>

                <div class="form-group field">
                    <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['job']}" for="position">{{getLabel('position')}}</label>
                    <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['job'], 'invalid': activateNewsletterForm.submitted}" name="position" [(ngModel)]="angularFormFields.job" type="text" placeholder="{{getLabel('position')}}" (focusin)="focusArray['job'] = true" (focusout)="focusArray['job'] = false">
                </div>

                <ng-container *ngIf="this.id == 'anteo'">
                    <div class="form-group field">
                        <mat-form-field appearance="fill">
                            <mat-label>{{getLabel('positionCategory')}}</mat-label>
                            <mat-select [(value)]="selectedOption">
                                <mat-option *ngFor="let opt of anteoDropdown" [value]="opt.title" (click)="setDropdownOtherSelected(opt.id)">
                                    {{opt.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <p class="text--primary field--asterisk">*</p>
                    </div>

                    <div class="form-group field" *ngIf="getDropdownOtherSelected()">
                        <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['jobCategory_other']}" for="positionCategory_other">{{getLabel('positionCategory_other')}}</label>
                        <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['jobCategory_other'], 'invalid': activateNewsletterForm.submitted}" name="positionCategory_other" [(ngModel)]="angularFormFields.jobCategory_other" type="text" placeholder="{{getLabel('positionCategory_other')}}" (focusin)="focusArray['jobCategory_other'] = true" (focusout)="focusArray['jobCategory_other'] = false" required>

                        <p class="text--primary field--asterisk">*</p>
                    </div>
                </ng-container>

                <div class="form-group field">
                    <label [ngClass]="{'label text--primary field--label-on-focus': true, 'visible': focusArray['email']}" for="e-mail">{{getLabel('e-mail')}}</label>
                    <input [ngClass]="{'field--edit-text': true, 'edit-text--focused': focusArray['email'], 'invalid': activateNewsletterForm.submitted}" name="e-mail" [(ngModel)]="angularFormFields.email" type="text" placeholder="{{getLabel('e-mail')}}" (focusin)="focusArray['email'] = true" (focusout)="focusArray['email'] = false" required>

                    <p class="text--primary field--asterisk">*</p>
                </div>

                <div class="privacy-info" (click)="focusIn()">
                    <a class="text--primary privacy-info--link" target="_blank" *ngIf="id != 'anteo'"
                        href="/{{translate.currentLang}}/article/newsletter-information" (click)="openInformationNotice()">
                        {{ 'FORM_NEWSLETTER.LINK' | translate }}
                    </a>
                    <a class="text--primary privacy-info--link" target="_blank" *ngIf="id == 'anteo'"
                        href="/{{translate.currentLang}}/article/anteo-newsletter" (click)="openInformationNotice()">
                        {{ 'FORM_NEWSLETTER.LINK' | translate }}
                    </a>
        
                    <br><br>
        
                    <ng-container *ngIf="informationNoticeOpened">
                        <input id="agreeCheckbox" class="agreeCheckbox--checkbox" type="checkbox" name="agreeCheckbox"
                            value="agreeCheckbox" (change)="checkValue($event)" autocomplete="off">
                        <label for="agreeCheckbox" class="prom-caption___lato-regular agreeCheckbox--label text--normal">
                            {{ 'FORM_NEWSLETTER.PRIVACY' | translate }}
                        </label>
        
                        <span class="text--primary neutra2_book privacy-info--asterisk">*<span
                                style="opacity: 0;">*</span></span>
        
                        <br>
        
                        <input id="agreeOptionalCheckbox" class="agreeCheckbox--checkbox" type="checkbox"
                            name="agreeOptionalCheckbox" value="agreeOptionalCheckbox" (change)="checkValue($event)" autocomplete="off">
                        <label for="agreeOptionalCheckbox"
                            class="prom-caption___lato-regular agreeCheckbox--label text--normal">
                            {{ 'FORM_NEWSLETTER.OPTIONAL_DATA' | translate }}
                        </label>
                    </ng-container>
                </div>

                <div class="buttons">
                    <button class="interactive button--prometeia-button" type="submit" status="success"
                            *ngIf="isNgFormFilled() && isPrivacyCheckboxChecked">
                        <app-prometeia-button class="buttonLoadMore--btn interactive" [data]="buttonSignUp">
                        </app-prometeia-button>
                    </button>

                    <app-prometeia-button class="buttonLoadMore--btn inactive" 
                                          *ngIf="!isNgFormFilled() || !isPrivacyCheckboxChecked"
                                          [data]="buttonSignUp" [disabled]="true">
                    </app-prometeia-button>
                </div>
            </form>
        </ng-container>
    </div>
</div>