import { Component, EventEmitter } from '@angular/core';
import { INews, ITrendingTopics, ITrendingTopicsList } from '../../models/components.interface';
import { slugs } from '../../models/slugs';
import { IInsights, ITrendingTopicsArticle } from '../../models/pages.interface';
import { BasePageComponent } from '../base-page.component';
import { Subscription } from 'rxjs';
import { Debounce } from 'angular-debounce-throttle';
import { CookieService } from 'src/app/services/cookie.service';
import { BrowserServices } from 'src/app/services/browser.service';
import { SearchAndFiltersServices } from 'src/app/services/search-and-filters';
import { IButtonLink } from 'src/app/models/commons';
import { paths } from 'src/app/models/paths';

const NEWS_PER_PAGE: number = 10;
@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent extends BasePageComponent<IInsights> {

  public newsListReady: EventEmitter<INews[]> = new EventEmitter();
  private pageDataReadySubscription: Subscription = null;
  private newsListReadySubscription: Subscription = null;
  private onLangChangeSubscription: Subscription = null;

  slugNews = paths.news;
  slugNewsletter = paths.newsletter;
  slugTrendingTopics = paths.trendingTopics;

  buttonLoadMore: IButtonLink = {
    type: "button",
    label: "",
    theme: 'btn_color__green',
    customBehaviour: "more"
  }

  selectedCategory : number;

  useFilter: boolean = false;
  
  _newsList: INews[];
  filteredNewsList: INews[];

  trendingTopicsList : ITrendingTopicsList[];
  trendingTopicsCategoriesIndex: number = 0;

  /** displayed news' increment */
  newsIndex: number = 0;
  newsPageSize: number = NEWS_PER_PAGE;
  newsFilterString: string = null;
  showMoreButton: boolean = true;

  showMoreButtonsTT : any = {};
  showMoreButtonFilteredTT: boolean = true;

  browserType: string;
  deviceType: string;

  pageLoaded: boolean = false;

  constructor(private cookieServices: CookieService, private browserServices: BrowserServices, private snf: SearchAndFiltersServices) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.browserType = this.browserServices.myBrowser().toLowerCase();
    this.deviceType = this.browserServices.myDevice().toLowerCase();

    this.pageDataReadySubscription?.unsubscribe();
    this.pageDataReadySubscription = this.dataServices.pageDataReady.subscribe(() => {
      // console.log(">>>", this.slug);
      if (this.data?.slug == slugs.news || this.data?.slug == slugs.trendingTopics) {
        this.componentsServices.scrollToTop();
      }

      if (this.data?.slug == slugs.news) {
        this.pageLoaded = false;
        this.filteredNewsList = [];
        this.newsIndex = 0;
        this.newsPageSize = NEWS_PER_PAGE;
        this.loadNewsList();
      } else if (this.data?.slug == slugs.trendingTopics) {
        this.loadTrendingTopicsList();
      }
    });

    this.onLangChangeSubscription?.unsubscribe();
    this.onLangChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.pageLoaded = false;
      if (this.data?.slug == slugs.news) {
        this.filteredNewsList = [];
        this._newsList = [];
        this.loadNewsList();
      }
      if (this.data?.slug == slugs.trendingTopics) {
        this.loadTrendingTopicsList();
      }
    });

    this.newsListReadySubscription?.unsubscribe();
    this.newsListReadySubscription = this.newsListReady.subscribe((receivedNews: INews[]) => {
      // console.log(receivedNews.length, this.newsPageSize, receivedNews.length > this.newsPageSize, receivedNews);

      if (receivedNews.length > this.newsPageSize) {
        /** there is more to load; we need to cut the last element */
        // this._newsList = receivedNews.splice(-1, 1);
        receivedNews.pop();
        if (!this._newsList || this._newsList.length == 0) this._newsList = receivedNews;
        else if(this.isListUnique(this._newsList, receivedNews)) {
          this._newsList = this._newsList.concat(receivedNews);
        }
        this.showMoreButton = true;
        // console.log("AND more news");
      } else {
        /** there are NOT any other news */
        if (!this._newsList || this._newsList.length == 0) this._newsList = receivedNews;
        else if(this.isListUnique(this._newsList, receivedNews)) {
          this._newsList = this._newsList.concat(receivedNews);
        }
        this.showMoreButton = false;
        // console.log("NO more news");
      }

      /** concatenates _newsList to filteredNewsList */
      if (!this.useFilter) this.filteredNewsList = this._newsList;
      // console.log(this.filteredNewsList);
    });

    
  }

  isListUnique(mainList: INews[], newList: INews[]): boolean {
    let ret: boolean = true;
    mainList.forEach(mainElement => {
      newList.forEach(newElement => {
        // console.log(mainElement.slug == newElement.slug, mainElement.slug, newElement.slug);
        if (mainElement.slug == newElement.slug) {
          ret = false;
        }
      });
    });

    return ret;
  }

  @Debounce(50)
  protected async loadNewsList() {
    try {
      const receivedNews = await this.api.insights.getNewsList(this.newsIndex, this.newsPageSize+1);
      //console.log("\n\nfrom:" + this.newsIndex, "limit+1:" + (this.newsPageSize+1), receivedNews);
      this.newsListReady.emit(receivedNews);
      this.pageLoaded = true;
    } catch(ex) {
      this.newsListReady.error(ex);
    }

  }

  async loadTrendingTopicsList(){
    this.newsIndex = 0;
    this.newsPageSize = NEWS_PER_PAGE;
    try{
      this.trendingTopicsList = await this.api.insights.getTrendingTopicsList(this.newsIndex, this.newsPageSize+1);
      this.selectCategory(this.cookieServices.loadTrendingTopicsCategoryCookie() || this.trendingTopicsList[0]?.code);
      this.trendingTopicsList.map(value => { 
        this.showMoreButtonsTT[value.code] =  value.news.length > 0 || value.news.length == this.newsPageSize + 1;
      });
      this.trendingTopicsList.map(value => value.news.pop());
      this.pageLoaded = true;
    }
    catch(error){
      console.log('trending topics - error', error);
    }
  }


  async increaseTrendingTopicsCounter(){
    this.trendingTopicsList.map(value => {
      if(value.code == this.selectedCategory){
        this.newsIndex = value.news.length
      }
    });
  //  this.newsIndex = this.newsIndex + this.newsPageSize;
    const news = await this.api.insights.getTrendingTopicsForCateogry(this.selectedCategory, this.newsIndex, this.newsPageSize+1);
    this.showMoreButtonsTT[this.selectedCategory] = news.length == this.newsPageSize + 1;
    
    news.pop();
    this.trendingTopicsList = this.trendingTopicsList.map(value => {
      if(value.code == this.selectedCategory){  value.news = value.news.concat(news); }
      return value;      
    });
    
    this.chengeType();
  }

  selectCategory(category: number) {
    this.selectedCategory = category;   
    this.chengeType();
    this.cookieServices.setTrendingTopicsCategoryCookie(category);
  }

  chengeType(){
    this.trendingTopicsList = this.trendingTopicsList.map(value => {
      value.news.map(news => { if(news.type == 'tableau'){ news.type = 'dataviz' }});
      return value;
    })
  }

  loadMore() {
    console.log("load more");

    switch (this.data.slug) {
      case slugs.news: 
        this.increaseNewsCounter();
        break;

      case slugs.trendingTopics: 
        if (!this.useFilter) this.increaseTrendingTopicsCounter();
        else {
          this.newsIndex = this.newsIndex + this.newsPageSize;
          this.loadFilteredTrendingTopicsList();
        }
        break;

      default: 
        
        break;
    }
  }

  increaseNewsCounter(): void {
    this.newsIndex = this.newsIndex + this.newsPageSize;

    if (!this.useFilter) this.loadNewsList();
    else this.loadFilteredNewsList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.pageDataReadySubscription?.unsubscribe();
    this.newsListReadySubscription?.unsubscribe();
    this.onLangChangeSubscription?.unsubscribe();
    this.pageDataReadySubscription = this.newsListReadySubscription = this.onLangChangeSubscription = null;
  }


  /** SEARCH-BAR */
  async filter(filterString: string) {
    // console.log(this.slug, "filtered by", filterString);

    if (!this.useFilter) {
      /** it's the first time */
      this.pageLoaded = false;
      this.newsIndex = 0;
      this.newsPageSize = NEWS_PER_PAGE;
      this.newsFilterString = null;
      this.filteredNewsList = [];
    }
    
    if (filterString && filterString.length > 0) {
      // const receivedNews = await this.api.insights.getNewsList(this.newsIndex, 100000 * this.newsPageSize+1);
      // this.filteredNewsList = receivedNews.filter((news: INews) => 
      //   news && (
      //     this.snf.searchSubString(filterString, news.title) 
      //     || this.snf.searchSubString(filterString, news.category) 
      //     || (news.author && this.snf.searchSubString(filterString, news.author)) 
      //   )
      // );
      this.newsFilterString = filterString;
      this.loadFilteredNewsList();
      this.useFilter = true;
    }
  }

  filterResetEvent() {
    // console.log("filter reset");

    if (this.useFilter) {
      /** it's the first time */
      this.newsIndex = 0;
      this.newsPageSize = NEWS_PER_PAGE;
      this.newsFilterString = null;
      this.filteredNewsList = [];
    }

    this.filteredNewsList = this._newsList;
    this.useFilter = false;
  }

  async loadFilteredNewsList() {
    try {
      const receivedNews: INews[] = await this.api.search.news(this.newsFilterString, this.newsIndex, this.newsPageSize+1);
      if (this.newsIndex == 0) {
        this.filteredNewsList = receivedNews;
        // console.log("nuova lista con filtro");
      } else {
        this.filteredNewsList.push(...receivedNews);
        // console.log("aggiunta a lista con filtro");
      }
      this.pageLoaded = true;
  
      // console.log(this.newsIndex, this.filteredNewsList.length, "risultati trovati per", this.newsFilterString, receivedNews);
      this.newsListReady.emit(receivedNews);
    } catch(ex) {
      this.newsListReady.error(ex);
    }
  }



  filteredTrendingTopicsList = [];
  trendingTopicsFilterString: string = null;
  filterTT(filterString: string) {
    if (!this.useFilter) {
      /** it's the first time */
      this.pageLoaded = false;
      this.newsIndex = 0;
      this.newsPageSize = NEWS_PER_PAGE;
      this.trendingTopicsFilterString = null;
      this.filteredTrendingTopicsList = [];
    }
    
    if (filterString && filterString.length > 0) {
      this.trendingTopicsFilterString = filterString;
      this.loadFilteredTrendingTopicsList();
      this.useFilter = true;
    }
  }

  filterResetEventTT() {
    if (this.useFilter) {
      /** it's the first time */
      this.pageLoaded = false;
      this.newsIndex = 0;
      this.newsPageSize = NEWS_PER_PAGE;
      this.trendingTopicsFilterString = null;
      this.filteredTrendingTopicsList = [];
    }

    this.useFilter = false;
  }

  async loadFilteredTrendingTopicsList() {
    try {
      const receivedTT: ITrendingTopicsArticle[] = await this.api.search.trendingTopics(this.trendingTopicsFilterString, this.newsIndex, this.newsPageSize+1);
      if (receivedTT?.length > this.newsPageSize) {
        this.showMoreButtonFilteredTT = true;
        const last = receivedTT.pop();
        // console.log("last tta was", last);
      } else this.showMoreButtonFilteredTT = false;

      if (this.newsIndex == 0) {
        this.filteredTrendingTopicsList = receivedTT;
        // console.log("nuova lista con filtro");
      } else {
        this.filteredTrendingTopicsList.push(...receivedTT);
        // console.log("aggiunta a lista con filtro");
      }
      this.pageLoaded = true;
  
      // console.log(this.newsIndex, this.filteredTrendingTopicsList.length, "risultati trovati per", this.trendingTopicsFilterString, receivedTT);
    } catch(ex) {
      console.error("trending topics filtered", ex);
    }
  }
  /** SEARCH-BAR - end */
}
