import { Component } from '@angular/core';
import { ScrollSpyService } from 'ng-spy';
import { Subscription } from 'rxjs';
import { RiskPlanningComponent } from '../risk-planning/risk-planning.component';

@Component({
  selector: 'app-financial-advisory',
  templateUrl: './financial-advisory.component.html',
  styleUrls: ['./financial-advisory.component.scss']
})
export class FinancialAdvisoryComponent extends RiskPlanningComponent {

  private pageDataReadySubscription: Subscription = null;

  changeBackImageInterval: any;

  constructor(protected spyService: ScrollSpyService) {
    super();

    this.pageDataReadySubscription?.unsubscribe();
    this.pageDataReadySubscription = this.dataServices.pageDataReady.subscribe(() => {
      this.spyService.spy({ 
        thresholdTop: this.componentsServices.getInnerHeight() / 2, 
        thresholdBottom: this.componentsServices.getInnerHeight() / 2 
      });

      this.data?.sections?.map( section => {
        if (section._meta) section._meta.backImagesIndex = 0;
        else section._meta = {backImagesIndex: 0};
  
        if(section.backImage || section.backImages){
          section._meta.sectionBackImage = section.backImages ? section.backImages[section._meta.backImagesIndex] : section.backImage;
          // console.log("s", section);
        }
      });
      this.changeImageInterval();
      this.changeBackImageInterval = setInterval(() => { this.changeImageInterval(); }, 5000);
    });
  }

  ngOnDestroy() {
    this.spyService.stopSpying();
    super.ngOnDestroy();
    this.pageDataReadySubscription?.unsubscribe();
    this.pageDataReadySubscription = null;

    clearInterval(this.changeBackImageInterval);
  }


  changeImageInterval() {
    this.data?.sections?.map( section => {
      if (section.backImages) {
        if (!section._meta) section._meta = {backImagesIndex: 0};
        if (section._meta.backImagesIndex == section.backImages.length) section._meta.backImagesIndex = 0;
      }
  
      if(section.backImage || section.backImages){
        section._meta.sectionBackImage = section.backImages ? section.backImages[section._meta.backImagesIndex] : section.backImage;
      }

      section._meta.backImagesIndex++;
    });
  }

}
