<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>
<ng-container *ngIf="data">
    <div class="prom-page activate-account">
        <div class="inner-html text" [innerHtml]="data.sections[0].innerHtml | safe:'html'"></div>
        
        <div class="form-container">
            <form #activateAccountForm="ngForm" [ngClass]="{submitted: activateAccountForm.submitted}" [mustMatch]="['password', 'confirmPassword']" (ngSubmit)="submit(activateAccountForm)">
                <div class="form-group">
                    <label class="label" for="email">Email</label>
                    <input [ngClass]="{'input': true}" name="email" [(ngModel)]="account.email" type="text" required disabled>
                </div>
                <div class="form-group">
                    <label class="label" for="name">Password</label>
                    <input [ngClass]="{'input': true, 'invalid': activateAccountForm.submitted && (password.invalid || confirmPassword.invalid)}" name="password" [(ngModel)]="account.password"  #password="ngModel" type="password" required>
                </div>
                <div class="form-group">
                    <label class="label" for="name">Confirm Password</label>
                    <input [ngClass]="{'input': true, 'invalid': activateAccountForm.submitted && confirmPassword.invalid}" name="confirmPassword" [(ngModel)]="account.confirmPassword" #confirmPassword="ngModel" type="password" required>
                </div>
    
                <div class="buttons">
                    <button class="interactive button" type="submit" status="success">Conferma</button>

                    <!-- <button [ngClass]="{'interactive button': true, 'invalid': (password.invalid || confirmPassword.invalid || password.pristine || confirmPassword.pristine || !password.touched || !confirmPassword.touched)}" type="submit" status="success">Conferma</button> -->
                </div>
            </form>
        </div>

        <!-- <div class="continue"><p><a class="text--primary no-underline" href="/">Prosegui la navigazione</a></p></div> -->
    </div>
    
</ng-container>

<!-- <p class="spyer" style="color:aqua">{{app_code}} - {{uid}} - {{email}}</p> -->