import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ComponentsServices } from 'src/app/services/components.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  @ViewChild('mainMenu', { static: true }) mainMenu: ElementRef;
  @ViewChild('thirdLevelMenuOrigin', { static: true }) thirdLevelMenuOrigin: ElementRef;
  @Input() logo_image = '';
  @Input() menu_items: any = null;
  @Output() menuItemSelected = new EventEmitter();

  private window: Window = window;
  public menuItems = [];
  public subMenuItems = [];
  public thirdLevelMenu = [];
  public thirdLevelStart = false;
  public isEn = this.translate.currentLang === 'en';
  public config = {
    arcSpace: 4, // Spazio in gradi tra un arco e l'altro
    arcSpaceExt: 3.2, // Spazio in gradi tra un arco e l'altro per il secondo livello
    arcRadius: 118, // Il raggio della circonferenza
    arcRadiusOuter: 155,
    arcRadiusUltima: 185,
    circOrigin: [175, 175],
    canvasSize: [350, 350]
  };
  public animation = {
    rotationPaused: false,
    firstLevelSelected: false,
    secondLevelSelected: false,
    currentSumRotation: 0, // Animazione e rotazione per selezione sono separate, la seconda si accumula
  };

  changeLangSubscription: Subscription = null;

  constructor(private router: Router, public translate: TranslateService, private componentsServices: ComponentsServices) {
    // console.log(">>>", this.translate.currentLang);
    this.changeLangSubscription?.unsubscribe();
    this.changeLangSubscription = this.translate.onLangChange.subscribe(() => {
      // console.log("changed ->", this.translate.currentLang);
      this.isEn = this.translate.currentLang === 'en';
    });
  }

  // Funziona solo per il menu di primo livello
  ngOnChanges(changes: SimpleChanges) {
    let hasSelection = false;
    for(const nmi of changes.menu_items.currentValue) {
      for(const mi of this.menuItems) {
        if(mi.key === nmi.key) {
          mi.selected = nmi.selected? true : false;
          hasSelection = hasSelection || mi.selected;
        }
      }
    }

    if(!hasSelection) {
      // Questo è il caso in cui si faccia una deselezione
      if(this.menuItems.length) {
        this.menuItems[0].selected = true;
        this.clickedLev1(this.menuItems[0]);
      }
    } else {
      for(const item of this.menuItems) {
        if(item.selected) {
          item.selected = false;
          this.clickedLev1(item);
          return;
        }
      }
    }
  }

  polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    var angleInRadians = (angleInDegrees - 180) * Math.PI / 180;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  describeArc = (x, y, radius, startAngle, endAngle) => {

      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);

      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d
      var d = [
          "M", start.x, start.y,
          "A", radius, radius, 0, largeArcFlag, 1, end.x, end.y
      ].join(" ");
      return d;
  }

  ngOnInit(): void {
    this.genrateMenuAttribs(this.menu_items);
  }

  genrateMenuAttribs = (vociMenu) => {
    this.menuItems = [];

    // Dimensione arco principale
    const arcSize = Math.floor(360 / vociMenu.length);
    let arcProgress = 0;

    /*
      Per fare in modo che l'etichetta si trovi sopra la scritta
      la riga deve essere disegnata in senso antiorario
      Inverto l'ordine delle voci in modo che vengano mostrate
      nel corretto ordine
    */
   vociMenu = vociMenu.concat([]).reverse();

    for(const vm of vociMenu) {
      this.menuItems.push({
        'label': vm.label,
        href: vm.href||null,
        submenu: vm.submenu,
        key: vm.key || '', id: 'mi_' + Math.ceil(Math.random() * 100000),
        selected: vm.selected || false,
        arcStart: arcProgress, arcEnd: arcProgress - (arcSize),
        d: this.describeArc(
          this.config.circOrigin[0], this.config.circOrigin[1],
          this.config.arcRadius,
          arcProgress, arcProgress - (arcSize - this.config.arcSpace)),
        d2: this.describeArc(
          this.config.circOrigin[0], this.config.circOrigin[1],
          this.config.arcRadius + 11.5,
          arcProgress, arcProgress - (arcSize - this.config.arcSpace) + 2)
      });
      arcProgress = arcProgress - arcSize;
    }
  }

  getArcSizeByLetter = (word) => {
    let size = 2;
    for(let l of word) {
      switch(l) {
        case 'i':
        case 'I':
          size += .6;
        break;
        case ' ':
          size += 1;
        break;
        case 'W':
        case 'w':
          size += 3.5;
        break;
        default:
          size += 3.25;
        break;
      }
    }
    return size;
  }

  genrateSubMenuAttribs = (vociMenu, parent) => {
    this.subMenuItems = [];

    // Dimensione arco principale
    let arcSize = 0;
    let arcProgress = parent.arcEnd;
    let menuSize = 0;
    // Sono gli angoli della parte centrale dei semi archi
    // in senso orario con l'angolo 0 in alto al centro
    let anchorAngles = [];

    /*
      Per fare in modo che l'etichetta si trovi sopra la scritta
      la riga deve essere disegnata in senso antiorario
      Inverto l'ordine delle voci in modo che vengano mostrate
      nel corretto ordine
    */
    let vociMenuTmp = vociMenu.concat([]).reverse();
    let sizeCompensationTo120 = 0;
    if (!this.componentsServices.isDesktopMode()) vociMenuTmp = vociMenu.concat([]);

    // Devo quindi spostare tutto il menu per fare in modo che parta dalla giusta posizione
    // Dimensione totale del menu
    for(const vm of vociMenuTmp) {
      if(vm.size) {
        arcSize = vm.size;
      } else {
        arcSize = this.getArcSizeByLetter(vm.label); // Calcola la dimensione in base alle lettere
        arcSize = Math.ceil(arcSize);
        arcSize = Math.max(arcSize, 19);
      }
      menuSize += arcSize;
    }
    if(menuSize < 120) {
      sizeCompensationTo120 = (120 - menuSize) / vociMenuTmp.length
      menuSize = menuSize + sizeCompensationTo120 * vociMenuTmp.length;
    }

    arcProgress = arcProgress + menuSize + 1;

    for(const vm of vociMenuTmp) {
      // Dimensione delle voci
      if(vm.size) {
        arcSize = vm.size;
      } else {
        arcSize = this.getArcSizeByLetter(vm.label);
        arcSize = Math.ceil(arcSize);
        arcSize = Math.max(arcSize, 19);
        arcSize = arcSize + sizeCompensationTo120;
      }

      this.subMenuItems.push({
        'label': vm.label,
        href: vm.href||null,
        submenu: vm.submenu,
        key: vm.key || '',
        id: 'mi2_' + Math.ceil(Math.random() * 100000),
        selected: vm.selected || false,
        arcStart: arcProgress, arcEnd: arcProgress - (arcSize),
        arcSize: arcSize,
        d: this.describeArc(
          this.config.circOrigin[0], this.config.circOrigin[1],
          this.config.arcRadiusOuter,
          arcProgress, arcProgress - (arcSize - this.config.arcSpaceExt)),
        d2: this.describeArc(
          this.config.circOrigin[0], this.config.circOrigin[1],
          this.config.arcRadiusOuter + 12, // + lo spazio verticale tra il testo e la barra
          arcProgress, arcProgress - (arcSize - this.config.arcSpaceExt) + 1) // + il padding sinistro tra il testo e l'inizio della barra
      });
      arcProgress = arcProgress - arcSize;
    }
    let offset = 0;
    let compensation = 2;
    for(let i = this.subMenuItems.length - 1; i >= 0; i--) {
      this.subMenuItems[i].anchorAngle =
        compensation +
        offset + Math.round((this.subMenuItems[i].arcSize - this.config.arcSpaceExt) / 2);
      offset += this.subMenuItems[i].arcSize;
      compensation--;
    }
  }

  matrixToAngle = (tr) => {
    if (!(tr.includes('(') && tr.includes(')') && tr.includes(','))) return 0;
    let values = tr.split('(')[1];
    values = values.split(')')[0];
    values = values.split(',');

    let a = values[0];
    let b = values[1];
    let c = values[2];
    let d = values[3];

    let scale = Math.sqrt(a*a + b*b);
    let angle = Math.round(Math.atan2(b, a) * (180/Math.PI));
    return angle;
  }

  getLineStartPoint(subMenuItem) {

    let x = this.config.circOrigin[0] + this.config.arcRadiusUltima *
      Math.cos(-(subMenuItem.anchorAngle + 96)*Math.PI/180);
    let y = this.config.circOrigin[1] + this.config.arcRadiusUltima *
      Math.sin(-(subMenuItem.anchorAngle + 96)*Math.PI/180);

    x = (100 / this.config.canvasSize[0]) * x;
    y = (100 / this.config.canvasSize[1]) * y;
    return [100 - x, y];
  }

  public mouseOver = () => {
    this.animation.rotationPaused = true;
  }
  public mouseOut = () => {
    this.animation.rotationPaused = false;
  }
  public clickedLev1 = (item) => {
    this.subMenuItems = [];
    this.thirdLevelMenu = [];
    this.thirdLevelStart = false;

    this.setCurrentLv1(item.key);

    // Se non ha sottomenu seguo il link
    if((!item.submenu || item.submenu.length == 0) && item.href) {
      // Segue il link
      this.router.navigate(['/', this.translate.currentLang, item.href]);
      return false;
    }


    // Deselezione elemento selezionato
    if(item.selected) {
      item.selected = false;
      this.animation.firstLevelSelected = false;
      this.animation.rotationPaused = false;
      this.menuItemSelected.emit({
        level1: {selected: false, item: item},
        level2: null,
        menu: this.menuItems});
      return;
    }
    // Click su altro elemento
    if(this.animation.firstLevelSelected) {
      this.animation.firstLevelSelected = false;
      for(const mi of this.menuItems){
        mi.selected = false;
      }
    }
    this.animation.firstLevelSelected = true;
    item.selected = true;
    this.menuItemSelected.emit({
      level1: {selected: true, item: item},
      level2: null,
      menu: this.menuItems});

    // Per portare l'elemento in testa si deve ruotare di fine + 90
    const trasformMatrix = this.window.getComputedStyle(this.mainMenu.nativeElement).getPropertyValue('transform');
    const outerRotation = this.matrixToAngle(trasformMatrix) % 360;
    const arcSize = ((item.arcEnd * -1) - (item.arcStart * -1));
    let newRotation = (item.arcStart * -1) - arcSize - 30 - outerRotation;
    newRotation = newRotation < 0? newRotation + 360 : newRotation;

    this.animation.currentSumRotation = newRotation > this.animation.currentSumRotation?
      newRotation :
      (newRotation > this.animation.currentSumRotation % 360?
        this.animation.currentSumRotation - (this.animation.currentSumRotation % 360) + newRotation :
        this.animation.currentSumRotation - (this.animation.currentSumRotation % 360) + newRotation + 360
        );

    this.mainMenu.nativeElement.children[0].style.transform =
      'rotate(' + this.animation.currentSumRotation + 'deg)';

    if(item.submenu.length) {
      this.genrateSubMenuAttribs(item.submenu, item);
    }
  }

  public clickedLev2(item){
    this.thirdLevelMenu = [];
    this.thirdLevelStart = false;
    // Se non ha sottomenu seguo il link
    if((!item.submenu || item.submenu.length == 0) && item.href) {
      // Segue il link
      this.router.navigate(['/', this.translate.currentLang, item.href]);
      return false;
    }

    // Deselezione elemento selezionato
    if(item.selected) {
      item.selected = false;
      this.animation.secondLevelSelected = false;

      this.menuItemSelected.emit({
        level1: {selected: true, item: this.menuItems.filter((i) => { return i.selected; })[0] || null},
        level2: {selected: false, item: item},
        menu: this.menuItems});
      return;
    }

    let hasSelected = false;
    for(const mi of this.subMenuItems){
      hasSelected = hasSelected || mi.selected;
      mi.selected = false;
    }

    // Nel caso di selezione di altro elemento
    // si lascia il tempo al primo di chiudersi
    if(hasSelected) {
      setTimeout(() => {
        this.clickedLev2(item);
      }, 300);
      return;
    }

    item.selected = true;
    this.animation.secondLevelSelected = true;

    this.menuItemSelected.emit({
      level1: {selected: true, item: this.menuItems.filter((i) => { return i.selected; })[0] || null},
      level2: {selected: true, item: item},
      menu: this.menuItems});

    this.thirdLevelMenu = item.submenu;
    let coords = this.getLineStartPoint(item);
    this.thirdLevelMenuOrigin.nativeElement.style.top = coords[1] + '%';
    this.thirdLevelMenuOrigin.nativeElement.style.left = coords[0] + '%';

    this.thirdLevelMenuOrigin.nativeElement.style.width = (80 - coords[0] + 60) + '%';

    setTimeout(() => {
      this.thirdLevelStart = true;
    }, 200);
  }

  currentLv1: string = null;
  setCurrentLv1(lv1: string) {
    this.currentLv1 = lv1;
  }

  isCurrentLv1Absolute(): boolean {
    if (!this.currentLv1) return false;
    if (this.currentLv1 == "whoweare") return true;
    return false;
  }
}
