import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ComponentsServices } from 'src/app/services/components.service';
import { HighlightColor, Locale } from '../../models/commons';
import { ICollapsableListItem } from '../../models/components.interface';

@Component({
  selector: 'app-collapsable-list-item',
  templateUrl: './collapsable-list-item.component.html',
  styleUrls: ['./collapsable-list-item.component.scss'],
})
export class CollapsableListItemComponent implements OnInit, OnDestroy {
  @Input() data: ICollapsableListItem;
  @Input() highlightColor: HighlightColor = 'accent';
  @Input() keepImagesSize: boolean = false;

  private collapsableListItemEventSubscription: Subscription = null;

  isOpen: boolean = false;
  isTechSolutionPage: boolean;
  constructor(private componentsServices: ComponentsServices, private router: Router, public translate: TranslateService) {
    this.isTechSolutionPage = router.url.split('/')[2] === 'tech-solutions';
  }

  ngOnInit(): void {
    this.collapsableListItemEventSubscription?.unsubscribe();
    this.collapsableListItemEventSubscription = this.componentsServices.collapsableListItemEvent.subscribe(
      (val: boolean) => {
        if (val) this.isOpen = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.collapsableListItemEventSubscription?.unsubscribe();
  }

  noScroll = false;
  changeOpenState(canScroll: boolean = true) {
    let timer = 1000;
    if(canScroll) {
      this.scroll();
    } else {
      timer = 0;
    }
    
    if (this.isOpen && this.isTechSolutionPage) {
      setTimeout(() => {
        const wasOpen = this.isOpen;
        this.componentsServices.toggleCollapsableListItem(wasOpen);
        this.isOpen = !wasOpen;
      }, timer);
      return;
    }
    const wasOpen = this.isOpen;
    this.componentsServices.toggleCollapsableListItem(wasOpen);
    this.isOpen = !wasOpen;
  }

  /** scrolls to the FIRST "collapsable-list" of the page */
  private scroll() {
    if (this.isTechSolutionPage) {
      let el = document.getElementsByClassName('collapsable-list')[0];
      el.scrollIntoView();
    }
  }

  getImageSrc(): string {
    if (this.isOpen) return this.data.image.contentUrl;
    return this.data.image.previewUrl ? this.data.image.previewUrl : this.data.image.contentUrl;
  }

  getImageAlt(): string {
    if (!this.data.image.alt) return '';
    if (this.isOpen) return this.data.image.alt;
    return this.data.image.alt + '__preview';
  }
}
