<div class="overlay"></div>

<div class="alumni-form">

    <div class="alumni">
        <h2 class="neutra2_book text--primary alumni--title">{{data.title}}</h2>
        <p class="neutra2_book text--on-light alumni--text" *ngIf="showLogin">{{data.text}}</p>
        
        <app-alumni-auth [pool]="pool" *ngIf="showLogin"></app-alumni-auth>

        <form class="alumni--form-register" [formGroup]="formRegister" *ngIf="!showLogin">
            <ng-container *ngFor="let item of this.data.sections">
                <mat-form-field *ngIf="item.type != 'checkbox'">
                    <mat-label class="neutra2_book">{{item.label}}</mat-label>                
                    <input matInput class="neutra2_book" [type]="item.type" [name]="item.id" (ngModelChange)="modelChanged($event, item.id)" [placeholder]="item.label" [formControlName]="item.id" [(ngModel)]="item.value">
                    <span class="alumni--form-register--icon-required text--primary neutra2_book" *ngIf="item.required">*</span>
                    <mat-error *ngFor="let item of item.formControl.errors | keyvalue"> {{ 'FORM_ALUMNI.' + item.key | translate }} </mat-error>
                </mat-form-field> 
            </ng-container>              
            <div class="privacy-info">
                <a class="text--primary privacy-info--link" href="/{{translate.currentLang}}/article/newsletter-information" target="_blank">
                    {{ 'FORM_ALUMNI.LINK' | translate }}
                </a>
            </div>
            <section>  
                <ng-container  *ngFor="let item of this.data.sections">
                    <mat-checkbox [color]="'primary'"  class="lato_regular" *ngIf="item.type == 'checkbox'" [(ngModel)]="item.value" [name]="item.id" [formControlName]="item.id"> {{ item.label }} <span *ngIf="item.required" class="text--primary neutra2_book privacy-info--asterisk">*</span></mat-checkbox>
                </ng-container>              
            </section>  
            <p class="neutra2_book text--red" *ngIf="errorRegister">{{errorMSGRegister}}</p>     
        </form>
        
        <div class="button-sign-up" *ngIf="!showLogin">
            <app-prometeia-button class="buttonLoadMore--btn interactive" [disabled]="!formRegister.valid" [data]="buttonSignUp" (click)="register()"></app-prometeia-button>
        </div>

        <div class="go-to-register">
            <p class="neutra2_book text--on-light" *ngIf="showLogin">
                {{ 'LINK_REGISTER' | translate }}
                <a class="neutra2_demi text--primary interactive underline" style="font-style: italic; font-weight: normal;" (click)="showLogin = !showLogin">
                    {{'REGISTER' | translate}}
                </a>
            </p>
            <p class="neutra2_book text--on-light" *ngIf="!showLogin">
                {{ 'LINK_LOGIN' | translate }}
                <a class="neutra2_demi text--primary interactive underline" style="font-style: italic; font-weight: normal;" (click)="showLogin = !showLogin">
                    {{'LOG_IN' | translate}}
                </a>
            </p>
        </div>
    </div>

    <div class="vertical-spacer"></div>

</div>

