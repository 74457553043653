<div class="page-not-found gradient--primary">
    <p class="neutra2_demi code">404</p>
    <p class="neutra2_demi description">
        {{ 'PAGE_NOT_FOUND' | translate }}
        <br>
        <span class="to-home">
          {{ 'GO_BACK_TO' | translate }} <a class="text--accent" [routerLink]="['/', translate.currentLang , 'home']">home</a>.
      </span>
    </p>
</div>
