<ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container>


<ng-container *ngIf="data">

    <div class="societary-organs prom-page">
        <h2 class="neutra2_demi text--primary societary-organs--page-title">{{data.pageTitle}}</h2>

        <div class="societary-organs--lists">
            <div class="role" *ngFor="let r of data.roles">

                <h3 class="prom-caption___neutra-demi text--primary capslock societary-organs--lists--role-label other-roles">{{r.title}}</h3>
                <hr>

                <div [ngClass]="{'societary-organs--lists--member': true}" *ngFor="let m of r.members; first as isFirst; last as isLast">
                    <div class="member--block">
                        <img [ngClass]="{'member-pic mobile-only round-image': true, 'top-pic': isFirst}" src="{{m.contentUrl}}" aria-hidden="true" alt="{{m.title}}" *ngIf="m.contentUrl && m.text">

                        <h3 class="neutra2_demi text--{{highlightColor || 'accent'}} member-name" [ngStyle]="!m._meta?.role && {'width': 'auto'}">{{m.title}}</h3>
                        <h3 class="neutra2_bookItalic text--{{highlightColor || 'accent'}} member-name role" *ngIf="m._meta?.role">{{m._meta.role}}</h3>
                        <span [ngClass]="{'icon-freccia_freccina-giù-grigia icona-dropdown interactive desktop-only': true, 'opened-dropdown': m.downloadable}" (click)="openMember(m)" *ngIf="m.text"></span>
                    </div>


                    <div [ngClass]="{'member--block block-info': true, 'hidden-on-desktop': !m.downloadable}">
                        <img [ngClass]="{'member-pic desktop-only round-image': true, 'top-pic': isFirst}" src="{{m.contentUrl}}" aria-hidden="true" alt="{{m.title}}" *ngIf="m.contentUrl && m.text">

                        <ng-container *ngIf="m.text">
                            <p class="member-description prom-caption___lato-regular mobile-only">
                                <ng-container *ngIf="m.downloadable">{{m.text}}</ng-container>
                                <ng-container *ngIf="!m.downloadable">{{m.text | shorten: maxLength: ' ...': false}}</ng-container>
                                <a *ngIf="!m.downloadable" class="prom-caption___lato-regular text--primary underline interactive" (click)="readMore(m)">{{'READ_MORE' | translate}}</a>
                            </p>

                            <p class="member-description prom-caption___lato-regular desktop-only">{{m.text}}</p>
                        </ng-container>
                    </div>

                    <hr class="light-hr" *ngIf="!isLast">
                </div>

            </div>
        </div>
    </div>



</ng-container>
