import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IButtonLink } from 'src/app/models/commons';
import { IAlumniForm } from 'src/app/models/components.interface';
import { AmplifyAuthService, AwsExportsType } from 'src/app/services/amplify-auth.service';
import { ApiService } from 'src/app/services/api.service';
import { DialogComponent } from '../dialog/dialog.component';

export interface IAlumniUserPOST {
  firstName?: string,
  lastName?: string,
  email?: string,
  city?: string,
  birthdate?: Date,
  company?: string,
  privacy_1: boolean,
  privacy_2: boolean,
  newsletter: boolean
  updatedAt?: Date,
}

@Component({
  selector: 'app-alumni-dialog-auth',
  templateUrl: './alumni-dialog-auth.component.html',
  styleUrls: ['./alumni-dialog-auth.component.scss']
})
export class AlumniDialogAuthComponent implements OnInit {

  data: IAlumniForm = {
    title: "Entra in Prometeia Alumni!",
    text: "Prometeia Alumni è il network dedicato alle persone che hanno lavorato in Prometeia. Ha l’obiettivo di rafforzare legami culturali e professionali, e favorire la condivisione di competenze e valori.",
    sections: [
      { id: "name", type: "text", label: "", translate: "NAME", required : true, formControl: new FormControl('',[Validators.required])  },
      { id: "lastName", type: "text", label: "", translate: "LAST_NAME", required : true, formControl: new FormControl('',[Validators.required]) },
      { id: "e-mail", type: "email", label: "", translate: "EMAIL", required : true, formControl: new FormControl('',[Validators.required, Validators.email,]) },
      { id: "e-mail2", type: "email", label: "", translate: "EMAIL_CONFIRM", required : true, formControl: new FormControl('',[Validators.required, Validators.email]) },
      { id: "city", type: "text", label: "", translate: "CITY", required: false, formControl: new FormControl('',[]) },
      { id: "birthday", type: "date", label: "", translate: "BIRTHDAY", required: false, formControl: new FormControl('',[]) },
      { id: "company", type: "text", label: "", translate: "COMPANY", required : true, formControl: new FormControl('',[Validators.required]) },
      // { id: "password", type: "password", label: "", translate: "PASSWORD", required : true, formControl: new FormControl('',[Validators.required, this.checkPassword()]) },
      // { id: "password2", type: "password", label: "", translate: "PASSOWORD_CONFIRM", required : true, formControl: new FormControl('',[Validators.required]) },
      { id: "privacy", type: "checkbox", label: "", translate: "PRIVACY", required : true, formControl: new FormControl('',[Validators.required]) },
      { id: "sendNewsletter", type: "checkbox", label: "", translate: "NEWSLETTER", required : false, formControl: new FormControl('',[]) },
    ]
  }

  showLogin : boolean = true; 
  formRegister: FormGroup;  

  buttonSignUp: IButtonLink = {
    type: "button",
    label: "",
    theme: 'btn_color__yellow',
    customBehaviour: "register"
  }
  errorRegister : boolean = false;
  errorMSGRegister : string = '';

  pool : AwsExportsType = 'alumni';
  
  constructor(
    public translate: TranslateService, 
    public amplifyAuthService: AmplifyAuthService, 
    private dialog: MatDialog,
    private api: ApiService, 
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.data.title = this.translate.instant('FORM_ALUMNI.TITLE'),
    this.data.text = this.translate.instant('FORM_ALUMNI.TEXT'),

    this.formRegister = new FormGroup(this.setFormRegister());
    this.data.sections.map( item =>{ 
      item.label = this.translate.instant( 'FORM_ALUMNI.'+item.translate );
      item.id == 'password2' && item.formControl.setValidators ( this.confirmValidator('password'));
      item.id == 'e-mail2' && item.formControl.setValidators ( this.confirmValidator('e-mail'));         
    }); 

    this.pool = 'alumni';
  }

  setFormRegister ()  {
    const initialValue = {};
    return this.data.sections.reduce((obj, item) => {
      return Object.assign(obj, { [item['id']]: item.formControl}) 
    }, initialValue);  
  };

  getObjectItemForm ()  {
    const initialValue = {};
    return this.data.sections.reduce((obj, item) => {
      return Object.assign(obj, { [item['id']]: item}) 
    }, initialValue);  
  };

  async register(){    
    let params = this.composeParams(this.formRegister.value);    
    /* this.amplifyAuthService.signUp(params, 'alumni').then( result => {
      if(result.success){
        this.registerOK();
        this.errorRegister = false;
      }
      else{
        this.errorRegister = true;
        this.errorMSGRegister = result.result.message
      }
    }) */

    // console.log(params);
    let snackBarRef;
    try {
      const resp = await this.api.career.alumniRegistration(JSON.stringify(params));

      snackBarRef = this.openSnackBar(this.translate.instant('ALUMNI_REGISTRATION.THANK_YOU'), null, 4000);
      this.showLogin = true;
    } catch (ex) {
      const err = ex.error.error;
      // console.log("catched", err);

      if (err.code && err.code == 3) {
        // console.log("già iscritto");
        snackBarRef = this.openSnackBar(this.translate.instant('ALUMNI_REGISTRATION.ALREADY_SUBSCRIBED'));
      } else if (err.code && err.code == 2) {
        // console.log("ricontrolla i campi");
        snackBarRef = this.openSnackBar(this.translate.instant('ALUMNI_REGISTRATION.CHECK_FIELDS'));
      } else /* if (err.status == 500) */ {
        // console.log("riprova più tardi");
        snackBarRef = this.openSnackBar(this.translate.instant('ALUMNI_REGISTRATION.RETRY_LATER'));
      }
    }
  }

  composeParams(fields){
    // return {
    //   username : fields['e-mail'],
    //   password :  fields.password,
    //   attributes : {
    //     family_name: fields.lastName,
    //     name: fields.name,
    //     "custom:company": fields.company,
    //     "custom:privacyDate": Date(),
    //     "custom:sendNewsletter": fields.sendNewsletter ? "1" : "0",        
    //     "custom:birthdate": fields.birthday,
    //     "custom:city": fields.city        
    //   }
    // }

    const ret: IAlumniUserPOST = {
      firstName: fields.name,
      lastName: fields.lastName,
      email: fields['e-mail'],
      city: fields.city,
      birthdate: fields.birthday,
      company: fields.company,
      privacy_1: true,
      privacy_2: true,
      newsletter: fields.sendNewsletter || false
    }
    return ret;
  }

  openSnackBar(message: string, action?: string, millisec: number = 2000) {
    this._snackBar.open(message, action, {
      duration: millisec,
    });
  }

  confirmValidator(name : string): ValidatorFn {
    let objTemp = this.getObjectItemForm();
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value !== undefined  && control.value !== objTemp[name].value ) {
            return { ['confirm_'+name]: true };
        }
        return null;
    };
  }

  modelChanged(event, field){
    this.data.sections.map( item => { if(item.id == field+'2') item.value = ''; });
  }

  checkPassword(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined  && control.value.length < 8 ) {
        return { ['checkPassword_length']: true };
      }
      else if( control.value !== undefined  && control.value.search(/[a-z]/) < 0 ){
        return { ['checkPassword_lowercase']: true };
      }
      else if( control.value !== undefined  && control.value.search(/[A-Z]/) < 0 ){
        return { ['checkPassword_uppercase']: true };
      }
      else if( control.value !== undefined  && control.value.search(/[!@#$%^&*]/) < 0 ){
        return { ['checkPassword_special']: true };
      }
      return null;
  };
  }

  async registerOK(){
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {title: '', text: this.translate.instant("REGISTER_SUCCESS"), onlyClose: true }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      this.showLogin = true;  
    });    
  };  
}
